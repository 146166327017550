<template>
  <div id="app">
    <v-app id="inspire">
      <v-card>
        <v-toolbar height="50" color="#333333">
          <v-icon color="white" class="mr-2">mail</v-icon>
          <v-toolbar-title style="color: white" class="font-weight-light">Histórico do Roteiro</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <ListaMensagens :roteiro="true" />
      </v-card>
    </v-app>
  </div>
</template>

<script>
import ListaMensagens from "@/views/components/ListaMensagens.vue";
export default {
  components: {
    ListaMensagens
  },
  data() {
    return {};
  },
  methods: {},
  beforeMount() {}
};
</script>
