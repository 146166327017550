<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">assignment</v-icon>
      <v-toolbar-title style="color: white" class="font-weight-light">
        {{ "Assinatura - " + id }}
      </v-toolbar-title>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs6 md4>
            <v-text-field
              v-model="assinatura.status"
              label="Status"
              readonly
              color="blue-grey lighten-2"
              :append-icon="clienteSite ? 'account_circle' : null"
              @click:append="dialogClienteSite = true"
            ></v-text-field>
          </v-flex>

          <v-flex xs6 md2>
            <v-text-field :value="formatDateTime(assinatura.dtCriacao)" label="Data da Captação" readonly color="blue-grey lighten-2"></v-text-field>
          </v-flex>
          <v-flex xs12 md6>
            <v-autocomplete
              autofocus
              v-model="assinatura.idOrigem"
              :items="origensVenda"
              :filter="filtrarObjetos"
              label="Origem de Venda"
              item-text="nome"
              item-value="id"
              readonly
            ></v-autocomplete>
          </v-flex>

          <v-flex xs6 md4>
            <v-autocomplete
              v-model="regraAjustePadraoSelecionada"
              :disabled="isUpdating || !direitoPadraoProximasParcelas"
              :items="regrasAjustePadraoPlano"
              :filter="filtrarObjetos"
              hint="Será utilizada para a próxima renovação"
              label="Regra de Ajuste Padrão"
              item-text="nome"
              item-value="id"
              no-data-text="Não há regra de aplicação automática para renovação deste plano"
              :rules="[validacao.idRegraAjuste == false || validacao.idRegraAjuste, (value) => (!!value && !!value.id) || 'Obrigatório']"
              return-object
              @change="validacao.idRegraAjuste = false"
            ></v-autocomplete>
          </v-flex>
          <v-flex xs6 md4>
            <v-autocomplete
              v-model="formaPgtoPadraoSelecionada"
              :disabled="isUpdating || !direitoPadraoProximasParcelas"
              :items="formasPgtoPadrao"
              :filter="filtrarObjetos"
              hint="Será utilizada para a próxima renovação"
              label="Forma de Pagamento Padrão"
              item-text="nome"
              item-value="id"
              no-data-text="Selecione a regra de ajuste padrão"
              :rules="[validacao.idFormaPgto == false || validacao.idFormaPgto, (value) => (!!value && !!value.id) || 'Obrigatório']"
              return-object
              @change="validacao.idFormaPgto = false"
            ></v-autocomplete>
          </v-flex>
          <v-flex xs6 md4>
            <v-autocomplete
              v-model="assinatura.nroParcelas"
              :disabled="isUpdating || !direitoPadraoProximasParcelas"
              :items="nrosParcelasPlano"
              hint="Será utilizada para a próxima renovação"
              label="Número de Parcelas Padrão"
              no-data-text="Selecione a forma de pagamento padrão"
              :rules="[validacao.nroParcelas == false || validacao.nroParcelas, (value) => !!value || 'Obrigatório']"
              @change="validacao.nroParcelas = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md12 v-if="assinatura.idApple">
            <v-alert :value="true" color="success" icon="check_circle" outlined>
              Compra realizada pela Loja Apple:
              {{ assinatura.idApple }}
            </v-alert>
          </v-flex>

          <v-flex xs12 md12 v-if="renovacaoAtual.id">
            <v-card>
              <v-toolbar>
                <v-toolbar-title>
                  <router-link :to="'/assinaturas/assinatura/' + id + '/' + renovacaoAtual.id">
                    Renovação Atual -
                    {{ `${renovacaoAtual.renovacao} '${renovacaoAtual.codAntigo || renovacaoAtual.id}'` }}
                  </router-link>
                </v-toolbar-title>
              </v-toolbar>
              <v-container fluid grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 md8>
                    <v-text-field
                      v-model="renovacaoAtual.cliente"
                      label="Assinante"
                      readonly
                      color="blue-grey lighten-2"
                      append-icon="account_circle"
                      @click:append="editarCliente(renovacaoAtual.idCliente)"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-text-field v-model="renovacaoAtual.origem" label="Origem" readonly color="blue-grey lighten-2"></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-text-field v-model="renovacaoAtual.plano" label="Plano" readonly color="blue-grey lighten-2"></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-text-field v-model="renovacaoAtual.produtos" label="Produtos" readonly color="blue-grey lighten-2"></v-text-field>
                  </v-flex>
                  <v-flex xs12 md3>
                    <v-text-field :value="formatDate(renovacaoAtual.dtInicio)" label="Início" readonly color="blue-grey lighten-2"></v-text-field>
                  </v-flex>
                  <v-flex xs12 md3>
                    <v-text-field :value="formatDate(renovacaoAtual.dtFim)" label="Fim" readonly color="blue-grey lighten-2"></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-text-field v-model="renovacaoAtual.tt" label="Valor Total" readonly color="blue-grey lighten-2"></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
          </v-flex>

          <v-flex xs12 md12 v-if="!renovacaoAtual.id">
            <v-alert :value="true" color="error" icon="check_circle" outlined> Não há renovação em andamento no período vigente. </v-alert>
          </v-flex>

          <v-flex xs12 md12 v-if="renovacaoAtual.renovacao != ultimaRenovacao.renovacao">
            <v-card>
              <v-toolbar>
                <v-toolbar-title>
                  <router-link :to="'/assinaturas/assinatura/' + id + '/' + ultimaRenovacao.id">
                    Última Renovação -
                    {{ `${ultimaRenovacao.renovacao} '${ultimaRenovacao.codAntigo || ultimaRenovacao.id}'` }}
                  </router-link>
                </v-toolbar-title>
              </v-toolbar>
              <v-container fluid grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 md8>
                    <v-text-field
                      v-model="ultimaRenovacao.cliente"
                      label="Assinante"
                      readonly
                      color="blue-grey lighten-2"
                      append-icon="account_circle"
                      @click:append="editarCliente(ultimaRenovacao.idCliente)"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-text-field v-model="ultimaRenovacao.origem" label="Origem" readonly color="blue-grey lighten-2"></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-text-field v-model="ultimaRenovacao.plano" label="Plano" readonly color="blue-grey lighten-2"></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-text-field v-model="ultimaRenovacao.produtos" label="Produtos" readonly color="blue-grey lighten-2"></v-text-field>
                  </v-flex>
                  <v-flex xs12 md3>
                    <v-text-field :value="formatDate(ultimaRenovacao.dtInicio)" label="Início" readonly color="blue-grey lighten-2"></v-text-field>
                  </v-flex>
                  <v-flex xs12 md3>
                    <v-text-field :value="formatDate(ultimaRenovacao.dtFim)" label="Fim" readonly color="blue-grey lighten-2"></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-text-field v-model="ultimaRenovacao.tt" label="Valor Total" readonly color="blue-grey lighten-2"></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
          </v-flex>

          <v-flex xs12 md12 v-if="assinatura.online != 0">
            <v-alert :value="true" color="success" icon="check_circle" outlined>Esta assinatura foi captada a partir do site (paywall).</v-alert>
          </v-flex>
          <v-flex xs12 md12 v-if="assinatura.rede != 0">
            <v-alert :value="true" color="error" icon="check_circle" outlined>Esta assinatura foi captada a partir da REDE.</v-alert>
          </v-flex>
        </v-layout>

        <v-layout wrap>
          <v-expansion-panels accordion v-model="painel">
            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Renovações</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headerRenovacoes"
                      :items="renovacoes"
                      no-results-text
                      no-data-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [10, 25, 50]
                      }"
                    >
                      <template v-slot:item.actions="{ item }">
                        <router-link v-if="item.situacao == 1" :to="'/assinaturas/assinatura/' + id + '/' + item.id" style="text-decoration: none">
                          <v-icon color="blue lighten-2" title="Editar">edit</v-icon>
                        </router-link>
                        <v-icon v-if="item.situacao == 0" color="red" title="EXCLUÍDA">cancel</v-icon>
                      </template>
                      <template v-slot:item.renovacao="{ item }">
                        <span v-html="item.renovacao + (item.codAntigo && item.codAntigo != item.id ? `'${item.codAntigo}'` : '')"></span>
                      </template>
                      <template v-slot:item.dtInicio="{ item }">
                        {{ formatDate(item.dtInicio) }}
                      </template>
                      <template v-slot:item.dtFim="{ item }">
                        {{ formatDate(item.dtFim) }}
                      </template>
                      <template v-slot:item.reinicio="{ item }">
                        <span :class="item.situacao == 0 ? 'red--text' : ''" v-html="item.reinicio == 1 ? 'Sim' : ''"></span>
                      </template>
                      <template v-slot:item.online="{ item }">
                        <span :class="item.situacao == 0 ? 'red--text' : ''" v-html="item.online == 1 ? 'Sim' : ''"></span>
                      </template>
                      <template v-slot:item.situacao="{ item }">
                        <span class="red--text" v-html="item.situacao == 0 ? '<b>EXCLUÍDA</b>' : ''"></span>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.observacoes ? 'red--text' : ''">
                <strong>Observações</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar>
                      <v-spacer></v-spacer>
                      <v-dialog persistent v-model="dialogObservacao" max-width="600px">
                        <template v-slot:activator="{ on }">
                          <v-btn color="success" fab small v-on="on">
                            <v-icon color="white" title="Incluir">add</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form ref="formObservacao" v-model="observacaoValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">Observação</span>
                              <v-spacer></v-spacer>
                              <v-btn icon @click="closeObservacao">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs12 md12>
                                    <v-textarea
                                      v-model="observacaoItem.observacao"
                                      autofocus
                                      label="Texto da Observação"
                                      maxlength="1000"
                                      counter="1000"
                                      rows="3"
                                      hint="Obrigatório"
                                      :rules="[
                                        (value) => !!value || 'Obrigatório.',
                                        (value) => (value != null && value.length >= 10 && value.length <= 1000) || 'Texto da observação deve ter entre 10 e 1000 caracteres'
                                      ]"
                                      color="blue-grey lighten-2"
                                    ></v-textarea>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closeObservacao">Retornar</v-btn>
                              <v-btn color="blue darken-1" @click="saveObservacao">Atualizar</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headerObservacoes"
                      :items="observacoes"
                      no-results-text
                      no-data-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [10, 25, 50]
                      }"
                    >
                      <template v-slot:item.actions="{ item }">
                        <v-icon
                          :color="item.situacao == 1 ? 'blue lighten-1' : 'blue-grey lighten-3'"
                          :title="item.situacao == 1 ? 'Desativar' : 'Ativar'"
                          @click="ativarDesativarObservacao(item)"
                        >
                          {{ item.situacao == 1 ? "radio_button_checked" : "radio_button_unchecked" }}
                        </v-icon>
                      </template>
                      <template v-slot:item.dtRegistro="{ item }">
                        {{ formatDateTime(item.dtRegistro) }}
                      </template>
                    </v-data-table>
                    <v-snackbar v-model="snackObservacoes" :timeout="2000" absolute bottom left>{{ msgObservacoes }}</v-snackbar>
                    <v-alert v-if="validacao.observacoes" :value="true" color="error" icon="priority_high" outlined>{{ validacao.observacoes }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.reclamacoes ? 'red--text' : ''">
                <strong>Reclamações</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar>
                      <v-spacer></v-spacer>
                      <v-dialog persistent v-model="dialogReclamacao" max-width="600px">
                        <template v-slot:activator="{ on }">
                          <v-btn v-if="!['PROPOSTA', 'RECUPERAÇÃO', 'CANCELADA', 'EXPIRADA'].includes(assinatura.status)" color="success" fab small v-on="on">
                            <v-icon color="white" title="Incluir">add</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form ref="formReclamacao" v-model="reclamacaoValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">Reclamação</span>
                              <v-spacer></v-spacer>
                              <v-btn icon @click="closeReclamacao">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs12 md12>
                                    <v-text-field v-model="reclamacaoItem.status" label="Status" readonly color="blue-grey lighten-2"></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 md12 v-if="!reclamacaoItem.id">
                                    <v-autocomplete
                                      v-model="reclamacaoSelecionada"
                                      autofocus
                                      :items="reclamacoesPadrao"
                                      :filter="filtrarObjetos"
                                      :rules="[(value) => (!!value && !!value.id) || 'Obrigatório.']"
                                      hint="Obrigatório"
                                      label="Selecione o Motivo da Reclamação"
                                      item-text="nome"
                                      item-value="id"
                                      no-data-text
                                      return-object
                                    ></v-autocomplete>
                                  </v-flex>

                                  <v-flex xs6 md6>
                                    <v-menu
                                      v-model="mnDtEdicaoReclamacao"
                                      :close-on-content-click="false"
                                      transition="scale-transition"
                                      offset-y
                                      max-width="290px"
                                      min-width="290px"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                          v-model="dtEdicaoReclamacao"
                                          label="Data da Edição"
                                          v-mask="'##/##/####'"
                                          prepend-icon="event"
                                          v-on="on"
                                          hint="Obrigatório"
                                          :rules="[
                                            validacao.reclamacao_dtEdicao == false || validacao.reclamacao_dtEdicao,
                                            (value) => reclamacaoItem.id != null || valida_data_passada(value) || 'Data inválida',
                                            (value) => !!value || 'Obrigatório.',
                                            (value) => (value != null && value.length == 10) || 'Preencha a data da edição'
                                          ]"
                                          color="blue-grey lighten-2"
                                          :readonly="reclamacaoItem.id != null"
                                          autocomplete="disabled"
                                          :name="Math.random()"
                                          @keyup="dtEdicaoReclamacaoUS = valida_data_passada(dtEdicaoReclamacao) ? formatDateUS(dtEdicaoReclamacao) : null"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker
                                        v-model="dtEdicaoReclamacaoUS"
                                        locale="pt-br"
                                        no-title
                                        @input="mnDtEdicaoReclamacao = false"
                                        :readonly="reclamacaoItem.id != null"
                                      ></v-date-picker>
                                    </v-menu>
                                  </v-flex>

                                  <v-flex xs12 md12>
                                    <v-textarea
                                      v-model="reclamacaoItem.reclamacao"
                                      label="Descrição da Reclamação"
                                      maxlength="1000"
                                      counter="1000"
                                      rows="5"
                                      hint="Obrigatório"
                                      :rules="[
                                        (value) => !!value || 'Obrigatório.',
                                        (value) => (value != null && value.length >= 10 && value.length <= 1000) || 'Texto da reclamação deve ter entre 10 e 1000 caracteres'
                                      ]"
                                      color="blue-grey lighten-2"
                                      :readonly="reclamacaoItem.id != null"
                                    ></v-textarea>
                                  </v-flex>
                                  <v-flex xs12 md12 v-if="reclamacaoItem.execucao">
                                    <v-textarea
                                      v-model="reclamacaoItem.execucao"
                                      label="Execução da Reclamação"
                                      rows="5"
                                      maxlength="255"
                                      counter="255"
                                      readonly
                                      color="blue-grey lighten-2"
                                    ></v-textarea>
                                  </v-flex>
                                  <v-flex xs12 md12 v-if="reclamacaoItem.solucao">
                                    <v-textarea
                                      v-model="reclamacaoItem.solucao"
                                      label="Resolução da Reclamação"
                                      rows="5"
                                      maxlength="255"
                                      counter="255"
                                      readonly
                                      color="blue-grey lighten-2"
                                    ></v-textarea>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closeReclamacao">Retornar</v-btn>
                              <v-btn v-if="reclamacaoItem.id == null" color="blue darken-1" @click="saveReclamacao">Atualizar</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headerReclamacoes"
                      :items="reclamacoes"
                      no-data-text
                      no-results-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [10, 25, 50]
                      }"
                    >
                      <template v-slot:item.actions="{ item }">
                        <v-icon v-if="item.id == null" class="mr-2" color="red" title="Excluir Reclamação" @click="excluirReclamacao(item)">delete</v-icon>
                        <v-icon class="mr-2" color="blue lighten-2" title="Visualizar" @click="visualizarReclamacao(item)">loupe</v-icon>
                      </template>
                      <template v-slot:item.idRenovacao="{ item }">
                        {{ item.idRenovacao }}
                      </template>
                      <template v-slot:item.status="{ item }">
                        <v-icon color="blue lighten-2" :title="item.status">
                          {{ item.status == "ABERTA" ? "lock_open" : item.status == "EXECUÇÃO" ? "hourglass_empty" : "lock" }}
                        </v-icon>
                      </template>
                      <template v-slot:item.dtRegistro="{ item }">
                        {{ formatDateTime(item.dtRegistro) }}
                      </template>
                      <template v-slot:item.dtEdicao="{ item }">
                        {{ formatDate(item.dtEdicao) }}
                      </template>
                      <template v-slot:item.dtSolucao="{ item }">
                        {{ formatDateTime(item.dtSolucao) }}
                      </template>
                    </v-data-table>
                    <v-alert v-if="validacao.reclamacoes" :value="true" color="error" icon="priority_high" outlined>{{ validacao.reclamacoes }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Contatos</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialogContatos" max-width="600px" @keydown.esc="dialogContatos = false">
                      <v-card>
                        <v-form ref="formContato" v-model="contatoValid" lazy-validation>
                          <v-toolbar>
                            <span class="headline">
                              Contato -
                              {{ contatoItem.tipo == 1 ? "Recuperação" : "Cobrança" }}
                            </span>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="closeContatos">
                              <v-icon color="darken-1">cancel</v-icon>
                            </v-btn>
                          </v-toolbar>
                          <v-card-text>
                            <v-container grid-list-md>
                              <v-layout wrap>
                                <v-flex xs6>
                                  <v-text-field v-model="contatoItem.dtReg" label="Registro" readonly color="blue-grey lighten-2"></v-text-field>
                                </v-flex>
                                <v-flex xs6>
                                  <v-text-field v-model="contatoItem.usuario" label="Usuário" readonly color="blue-grey lighten-2"></v-text-field>
                                </v-flex>
                                <v-flex xs6 md6 v-if="contatoItem.idParcela != null">
                                  <v-text-field v-model="contatoItem.idParcela" label="Parcela" readonly color="blue-grey lighten-2"></v-text-field>
                                </v-flex>
                                <v-flex xs6 md6 v-if="contatoItem.dtPromessa != null">
                                  <v-text-field v-model="contatoItem.dtPr" label="Promessa de Pagamento" readonly color="blue-grey lighten-2"></v-text-field>
                                </v-flex>
                                <v-flex xs12 md12 v-if="contatoItem.tipo == 1">
                                  <v-switch v-model="contatoItem.recuperado" readonly :label="`${contatoItem.recuperado == 1 ? 'Recuperado' : 'Não Recuperado'}`"></v-switch>
                                </v-flex>
                                <v-flex xs12 md12>
                                  <v-textarea v-model="contatoItem.contato" label="Descrição do Contato" rows="3" maxlength="255" readonly color="blue-grey lighten-2"></v-textarea>
                                </v-flex>
                              </v-layout>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" @click="closeContatos">Retornar</v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headerContatos"
                      :items="contatos"
                      no-results-text
                      no-data-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [10, 25, 50]
                      }"
                    >
                      <template v-slot:item.actions="{ item }">
                        <v-icon class="mr-2" color="blue lighten-2" title="Visualizar" @click="visualizarContato(item)">loupe</v-icon>
                      </template>
                      <template v-slot:[getItemTipo()]="{ item }">
                        <span v-html="item.tipo == 1 ? 'Recuperação' : 'Cobrança'"></span>
                      </template>
                      <template v-slot:item.dtRegistro="{ item }">
                        {{ formatDateTime(item.dtRegistro) }}
                      </template>
                      <template v-slot:[getItemRecuperado()]="{ item }">
                        <span v-html="item.tipo == 1 ? (item.recuperado == 1 ? 'Sim' : '') : ''"></span>
                      </template>
                      <template v-slot:[getItemIdParcela()]="{ item }">
                        <span v-html="item.tipo == 2 ? item.idParcela : ''"></span>
                      </template>
                      <template v-slot:item.dtPromessa="{ item }">
                        {{ item.tipo == 2 ? formatDate(item.dtPromessa) : "" }}
                      </template>
                    </v-data-table>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.cartoesClube ? 'red--text' : ''">
                <strong>Cartões do Clube</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar>
                      <v-spacer></v-spacer>
                      <v-dialog persistent v-model="dialogCartaoClube" max-width="600px">
                        <template v-slot:activator="{ on }">
                          <v-btn v-if="!['RECUPERAÇÃO', 'CANCELADA', 'EXPIRADA'].includes(assinatura.status)" color="success" fab small v-on="on">
                            <v-icon color="white" title="Incluir">add</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form ref="formCartaoClube" v-model="cartaoClubeValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">Cartão do Clube</span>
                              <v-spacer></v-spacer>
                              <v-btn icon @click="closeCartaoClube">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs12 md12>
                                    <v-autocomplete
                                      v-model="dependenteSelecionado"
                                      autofocus
                                      :items="dependentesCartaoCliente"
                                      :filter="filtrarObjetos"
                                      hint="Obrigatório"
                                      :rules="[(value) => (!!value && !!value.id) || 'Obrigatório.']"
                                      label="Dependente do Cliente"
                                      item-text="nome"
                                      item-value="id"
                                      clearable
                                      no-data-text
                                      :disabled="cartaoClubeItem.id != null"
                                      return-object
                                    ></v-autocomplete>
                                  </v-flex>
                                  <v-flex xs12 md12>
                                    <v-autocomplete
                                      v-model="produtoSelecionado"
                                      autofocus
                                      :items="produtosClube"
                                      :filter="filtrarObjetos"
                                      hint="Obrigatório"
                                      :rules="[(value) => (!!value && !!value.id) || 'Obrigatório.']"
                                      label="Produto do Dependente"
                                      item-text="nome"
                                      item-value="id"
                                      clearable
                                      no-data-text
                                      :disabled="cartaoClubeItem.id != null"
                                      return-object
                                    ></v-autocomplete>
                                  </v-flex>
                                  <v-expansion-panels accordion>
                                    <v-expansion-panel v-if="cartaoClubeItem.dtExportacao != null" focusable>
                                      <v-expansion-panel-header>
                                        <strong>Exportação</strong>
                                      </v-expansion-panel-header>
                                      <v-expansion-panel-content>
                                        <v-divider></v-divider>
                                        <v-container>
                                          <v-layout wrap>
                                            <v-flex xs6>
                                              <v-text-field :value="formatDateTime(cartaoClubeItem.dtExportacao)" label="Data" disabled color="blue-grey lighten-2"></v-text-field>
                                            </v-flex>
                                            <v-flex xs6>
                                              <v-text-field v-model="cartaoClubeItem.usuarioExportacao" label="Usuário" readonly color="blue-grey lighten-2"></v-text-field>
                                            </v-flex>
                                          </v-layout>
                                        </v-container>
                                      </v-expansion-panel-content>
                                    </v-expansion-panel>

                                    <v-expansion-panel v-if="cartaoClubeItem.dtCancelamento != null" focusable>
                                      <v-expansion-panel-header>
                                        <strong>Cancelamento</strong>
                                      </v-expansion-panel-header>

                                      <v-expansion-panel-content>
                                        <v-divider></v-divider>
                                        <v-container>
                                          <v-layout wrap>
                                            <v-flex xs6>
                                              <v-text-field
                                                :value="formatDateTime(cartaoClubeItem.dtCancelamento)"
                                                label="Data"
                                                readonly
                                                color="blue-grey lighten-2"
                                              ></v-text-field>
                                            </v-flex>
                                            <v-flex xs6>
                                              <v-text-field v-model="cartaoClubeItem.usuarioCancelamento" label="Usuário" readonly color="blue-grey lighten-2"></v-text-field>
                                            </v-flex>
                                          </v-layout>
                                        </v-container>
                                      </v-expansion-panel-content>
                                    </v-expansion-panel>
                                  </v-expansion-panels>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closeCartaoClube">Retornar</v-btn>
                              <v-btn color="blue darken-1" @click="saveCartaoClube" v-if="cartaoClubeItem.id == null">Atualizar</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headerCartoesClube"
                      :items="cartoesClube"
                      no-data-text
                      no-results-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [10, 25, 50]
                      }"
                    >
                      <template v-slot:item.actions="{ item }">
                        <v-icon v-if="item.dtCancelamento == null" class="mr-2" color="red" title="Cancelar Solicitação de Cartão do Clube" @click="cancelarCartaoClube(item)"
                          >delete</v-icon
                        >
                        <v-icon color="blue lighten-2" title="Editar" @click="visualizarCartaoClube(item)">loupe</v-icon>
                      </template>
                      <template v-slot:item.dtRegistro="{ item }">
                        {{ formatDateTime(item.dtRegistro) }}
                      </template>
                      <template v-slot:item.dtValidade="{ item }">
                        {{ formatDate(item.dtValidade) }}
                      </template>
                      <template v-slot:item.dtExportacao="{ item }">
                        {{ formatDateTime(item.dtExportacao) }}
                      </template>
                      <template v-slot:item.dtCancelamento="{ item }">
                        {{ formatDateTime(item.dtCancelamento) }}
                      </template>
                    </v-data-table>
                    <v-snackbar v-model="snackCartoesClube" :timeout="2000" absolute bottom left>{{ msgCartoesClube }}</v-snackbar>
                    <v-alert v-if="validacao.cartoesClube" :value="true" color="error" icon="priority_high" outlined>{{ validacao.cartoesClube }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>

              <v-expansion-panel-header :class="validacao.suspensoes ? 'red--text' : ''">
                <strong>Suspensões</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar>
                      <v-spacer></v-spacer>
                      <v-dialog persistent v-model="dialogSuspensao" max-width="600px">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-if="!suspensaoAtiva && !['PROPOSTA', 'RECUPERAÇÃO', 'CANCELADA', 'EXPIRADA', 'SUSPENSA'].includes(assinatura.status) && direitoCriarSuspensao"
                            color="success"
                            fab
                            small
                            v-on="on"
                          >
                            <v-icon color="white" title="Incluir">add</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form ref="formSuspensao" v-model="suspensaoValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">Suspensão</span>
                              <v-spacer></v-spacer>
                              <v-btn icon @click="closeSuspensao">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs6 md6>
                                    <v-menu
                                      v-model="mnDtInicioSuspensao"
                                      :close-on-content-click="false"
                                      transition="scale-transition"
                                      offset-y
                                      max-width="290px"
                                      min-width="290px"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                          autofocus
                                          v-model="dtInicioSuspensao"
                                          label="Data de Início"
                                          v-mask="'##/##/####'"
                                          prepend-icon="event"
                                          v-on="on"
                                          hint="Obrigatório"
                                          :rules="[
                                            validacao.suspensao_dtInicio == false || validacao.suspensao_dtInicio,
                                            (value) => (suspensaoItem.id != null && !suspensaoItem.editInicio) || valida_data_futura_susp(value, false) || 'Data inválida',
                                            (value) => !!value || 'Obrigatório.',
                                            (value) => (value != null && value.length == 10) || 'Preencha a data de início da suspensão'
                                          ]"
                                          color="blue-grey lighten-2"
                                          :readonly="suspensaoItem.id != null && !suspensaoItem.editInicio"
                                          autocomplete="disabled"
                                          :name="Math.random()"
                                          @keyup="dtInicioSuspensaoUS = valida_data_futura_susp(dtInicioSuspensao, false) ? formatDateUS(dtInicioSuspensao) : null"
                                          @change="validacao.suspensao_dtInicio = false"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker
                                        v-model="dtInicioSuspensaoUS"
                                        locale="pt-br"
                                        no-title
                                        @input="mnDtInicioSuspensao = false"
                                        :readonly="suspensaoItem.id != null && !suspensaoItem.editInicio"
                                        @change="validacao.suspensao_dtInicio = false"
                                      ></v-date-picker>
                                    </v-menu>
                                  </v-flex>

                                  <v-flex xs6 md6>
                                    <v-menu v-model="mnDtFimSuspensao" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                          v-model="dtFimSuspensao"
                                          label="Data de Término"
                                          v-mask="'##/##/####'"
                                          hint="Obrigatório"
                                          prepend-icon="event"
                                          v-on="on"
                                          :rules="[
                                            validacao.suspensao_dtFim == false || validacao.suspensao_dtFim,
                                            (value) => (suspensaoItem.id != null && !suspensaoItem.editFim) || valida_data_futura_susp(value, true) || 'Data inválida',
                                            (value) => !!value || 'Obrigatório.',
                                            (value) => (value != null && value.length == 10) || 'Preencha a data de término da suspensão'
                                          ]"
                                          color="blue-grey lighten-2"
                                          :readonly="suspensaoItem.id != null && !suspensaoItem.editFim"
                                          autocomplete="disabled"
                                          :name="Math.random()"
                                          @keyup="dtFimSuspensaoUS = valida_data_futura_susp(dtFimSuspensao, true) ? formatDateUS(dtFimSuspensao) : null"
                                          @change="validacao.suspensao_dtFim = false"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker
                                        v-model="dtFimSuspensaoUS"
                                        locale="pt-br"
                                        no-title
                                        @input="mnDtFimSuspensao = false"
                                        :readonly="suspensaoItem.id != null && !suspensaoItem.editFim"
                                        @change="validacao.suspensao_dtFim = false"
                                      ></v-date-picker>
                                    </v-menu>
                                  </v-flex>
                                  <v-flex xs12 md12>
                                    <v-autocomplete
                                      v-model="suspensaoSelecionada"
                                      :items="suspensoesPadrao"
                                      :filter="filtrarObjetos"
                                      :rules="[(value) => (!!value && !!value.id) || 'Obrigatório.']"
                                      hint="Obrigatório"
                                      label="Selecione o Motivo da Suspensão"
                                      :readonly="suspensaoItem.id != null"
                                      item-text="nome"
                                      item-value="id"
                                      no-data-text
                                      return-object
                                    >
                                      <template v-slot:item="data">
                                        <v-list-item-content>
                                          <v-list-item-title class="ml-2">{{ data.item.nome }}</v-list-item-title>
                                          <v-list-item-subtitle class="ml-2">{{
                                            (data.item.bonificar ? "Bonificar" : "") +
                                            (data.item.bonificar && data.item.digital ? " + " : "") +
                                            (data.item.digital ? "Suspender Digital" : "")
                                          }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                      </template>
                                    </v-autocomplete>
                                  </v-flex>
                                  <v-flex xs12 md12>
                                    <v-textarea
                                      v-model="suspensaoItem.motivo"
                                      label="Descrição da Suspensão"
                                      maxlength="300"
                                      counter="300"
                                      rows="3"
                                      hint="Obrigatório"
                                      :rules="[
                                        validacao.suspensao_motivo == false || validacao.suspensao_motivo,
                                        (value) => !!value || 'Obrigatório.',
                                        (value) => (value != null && value.length >= 10 && value.length <= 300) || 'Texto do motivo da suspensão deve ter entre 10 e 300 caracteres'
                                      ]"
                                      color="blue-grey lighten-2"
                                      :readonly="suspensaoItem.id != null"
                                    ></v-textarea>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closeSuspensao">Retornar</v-btn>
                              <v-btn v-if="suspensaoItem.id == null || suspensaoItem.editInicio || suspensaoItem.editFim" color="blue darken-1" @click="saveSuspensao"
                                >Atualizar</v-btn
                              >
                              <!-- TODO: só pode ser incluída suspensão para o dia seguinte ATÉ A EMISSÃO DO ROTEIRO -->
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headerSuspensoes"
                      :items="suspensoes"
                      no-results-text
                      no-data-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [10, 25, 50]
                      }"
                    >
                      <template v-slot:item.actions="{ item }">
                        <v-icon
                          v-if="item.excluir && (direitoAlterarSuspensao || item.idUsuario == $store.state.usuario.id)"
                          class="mr-2"
                          color="red"
                          title="Finalizar/Excluir Suspensão"
                          @click="excluirSuspensao(item)"
                          >delete</v-icon
                        >
                        <v-icon
                          v-if="direitoAlterarSuspensao || item.idUsuario == $store.state.usuario.id"
                          class="mr-2"
                          color="blue lighten-2"
                          title="Editar"
                          @click="editarSuspensao(item)"
                          >edit</v-icon
                        >
                      </template>
                      <template v-slot:item.dtInicio="{ item }">
                        {{ formatDate(item.dtInicio) }}
                      </template>
                      <template v-slot:item.dtFim="{ item }">
                        {{ formatDate(item.dtFim) }}
                      </template>
                      <template v-slot:item.dtRegistro="{ item }">
                        {{ formatDateTime(item.dtRegistro) }}
                      </template>
                    </v-data-table>
                    <v-snackbar v-model="snackSuspensoes" :timeout="2000" absolute bottom left>{{ msgSuspensoes }}</v-snackbar>
                    <v-alert v-if="validacao.suspensoes" :value="true" color="error" icon="priority_high" outlined>{{ validacao.suspensoes }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>

              <v-expansion-panel-header>
                <strong>Histórico de Status</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headerCarteira"
                      :items="carteira"
                      no-results-text
                      no-data-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [10, 25, 50]
                      }"
                    >
                      <template v-slot:item.dtStatus="{ item }">
                        {{ formatDate(item.dtStatus) }}
                      </template>
                    </v-data-table>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Regras de Ajuste de Preços</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-dialog persistent v-model="dialogRegraAjusteView" max-width="800px">
                      <v-card>
                        <v-form lazy-validation>
                          <v-toolbar>
                            <span class="headline">Regra de Ajuste Aplicada</span>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="closeRegraAjuste">
                              <v-icon color="darken-1">cancel</v-icon>
                            </v-btn>
                          </v-toolbar>
                          <v-card-text>
                            <v-container grid-list-md>
                              <v-layout wrap>
                                <v-flex xs12 md12>
                                  <v-text-field v-model="regraAjusteItem.regraAjuste" label="Nome" disabled color="blue-grey lighten-2"></v-text-field>
                                </v-flex>
                                <v-flex xs12 md12>
                                  <v-textarea v-model="regraAjusteItem.regraAjusteDescricao" label="Descrição" disabled rows="3" color="blue-grey lighten-2"></v-textarea>
                                </v-flex>
                                <v-flex xs12 md12>
                                  <v-toolbar>
                                    <v-toolbar-title>Parcelas Afetadas</v-toolbar-title>
                                  </v-toolbar>
                                  <v-data-table
                                    mobile-breakpoint="0"
                                    :headers="headerParcelasAjuste"
                                    :items="regraAjusteItem.parcelas"
                                    no-results-text
                                    :footer-props="{
                                      itemsPerPageText: 'Registros por página',
                                      itemsPerPageOptions: [12, 25, 50]
                                    }"
                                  >
                                    <template v-slot:item.dtVectoOriginal="{ item }">
                                      {{ formatDate(item.dtVectoOriginal) }}
                                    </template>
                                  </v-data-table>
                                </v-flex>
                              </v-layout>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" @click="closeRegraAjuste">Retornar</v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headerRegrasAjuste"
                      :items="regrasAjuste"
                      no-data-text
                      no-results-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [10, 25, 50]
                      }"
                    >
                      <template v-slot:item.actions="{ item }">
                        <v-icon class="mr-2" color="blue lighten-2" title="Visualizar" @click="visualizarRegraAjuste(item)">loupe</v-icon>
                      </template>
                      <template v-slot:item.dtRegistro="{ item }">
                        {{ formatDateTime(item.dtRegistro) }}
                      </template>
                    </v-data-table>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>

              <v-expansion-panel-header :class="validacao.cancelamentos ? 'red--text' : ''">
                <strong>Cancelamentos</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar>
                      <!-- para cancelar um cancelamento: se ainda não foi efetivamente cancelado = recuperar, se já foi ou nem chegou a ir para recuperação = reverter -->
                      <v-spacer></v-spacer>
                      <v-btn v-if="cancelamentoAtivo && !assinatura.idApple && direitoReverter" color="red" fab small>
                        <v-icon color="white" title="Reverter Cancelamento" @click="reverterCancelamento()">undo</v-icon>
                      </v-btn>
                      <v-dialog persistent v-model="dialogReverterCancelamento" max-width="600px">
                        <v-card>
                          <v-form ref="formReverterCancelamento" v-model="reverterCancelamentoValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">Reverter Cancelamento</span>
                              <v-spacer></v-spacer>
                              <v-btn icon @click="closeReverterCancelamento">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs12 md12>
                                    <v-textarea
                                      v-model="cancelamentoItem.motivoReversao"
                                      label="Motivo da Reversão"
                                      maxlength="255"
                                      counter="255"
                                      rows="5"
                                      hint="Obrigatório"
                                      :rules="[
                                        (value) => !!value || 'Obrigatório.',
                                        (value) => (value != null && value.length >= 10 && value.length <= 255) || 'Texto do motivo da reversão deve ter entre 10 e 255 caracteres'
                                      ]"
                                      color="blue-grey lighten-2"
                                    ></v-textarea>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closeReverterCancelamento">Retornar</v-btn>
                              <v-btn color="blue darken-1" @click="saveReverterCancelamento">Atualizar</v-btn>
                              <!-- TODO: só pode ser agendado cancelamento para o dia seguinte até a emissão do roteiro -->
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                      <v-dialog persistent v-model="dialogCancelamento" max-width="600px">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-if="!cancelamentoAtivo && !assinatura.idApple && !['PROPOSTA', 'CANCELADA', 'RECUPERAÇÃO'].includes(assinatura.status) && direitoCancelar"
                            color="success"
                            fab
                            small
                            v-on="on"
                          >
                            <v-icon color="white" title="Incluir">add</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form ref="formCancelamento" v-model="cancelamentoValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">Cancelamento</span>
                              <v-spacer></v-spacer>
                              <v-btn icon @click="closeCancelamento">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs12 md6>
                                    <v-menu v-model="mndtAgCancelamento" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                          autofocus
                                          v-model="dtAgCancelamento"
                                          label="Data de Agendamento"
                                          v-mask="'##/##/####'"
                                          hint="Obrigatório"
                                          prepend-icon="event"
                                          v-on="on"
                                          :rules="[
                                            (value) => valida_data_futura(value) || 'Data inválida',
                                            (value) => !!value || 'Obrigatório.',
                                            (value) => (value != null && value.length == 10) || 'Preencha a data de agendamento do cancelamento'
                                          ]"
                                          color="blue-grey lighten-2"
                                          autocomplete="disabled"
                                          :name="Math.random()"
                                          :readonly="cancelamentoItem.id != null"
                                          @keyup="dtAgCancelamentoUS = valida_data_futura(dtAgCancelamento) ? formatDateUS(dtAgCancelamento) : null"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker
                                        v-model="dtAgCancelamentoUS"
                                        locale="pt-br"
                                        no-title
                                        @input="mndtAgCancelamento = false"
                                        :readonly="cancelamentoItem.id != null"
                                      ></v-date-picker>
                                    </v-menu>
                                  </v-flex>

                                  <v-flex xs12 md6 v-if="cancelamentoItem.dtCancelamento != null">
                                    <v-text-field
                                      :value="formatDate(cancelamentoItem.dtCancelamento)"
                                      label="Data do Efetivo Cancelamento"
                                      disabled
                                      color="blue-grey lighten-2"
                                    ></v-text-field>
                                  </v-flex>

                                  <v-flex xs12 md12>
                                    <v-autocomplete
                                      v-model="cancelamentoSelecionado"
                                      :items="motivosCancelamento"
                                      :filter="filtrarObjetos"
                                      hint="Obrigatório"
                                      :rules="[(value) => (!!value && !!value.id) || 'Obrigatório.']"
                                      label="Selecione o Motivo do Cancelamento"
                                      item-text="nome"
                                      item-value="id"
                                      no-data-text
                                      :disabled="cancelamentoItem.id != null"
                                      return-object
                                    ></v-autocomplete>
                                  </v-flex>

                                  <v-flex xs12 md12>
                                    <v-textarea
                                      v-model="cancelamentoItem.motivoCancelamento"
                                      label="Descrição do Cancelamento"
                                      maxlength="1000"
                                      counter="1000"
                                      rows="5"
                                      hint="Obrigatório"
                                      :rules="[
                                        (value) => !!value || 'Obrigatório.',
                                        (value) =>
                                          (value != null && value.length >= 10 && value.length <= 1000) || 'Texto do motivo do cancelamento deve ter entre 10 e 1000 caracteres'
                                      ]"
                                      color="blue-grey lighten-2"
                                      :readonly="cancelamentoItem.id != null"
                                    ></v-textarea>
                                  </v-flex>
                                  <v-expansion-panels accordion>
                                    <v-expansion-panel v-if="cancelamentoItem.dtRecuperacao != null" focusable>
                                      <v-expansion-panel-header>
                                        <strong>Recuperação</strong>
                                      </v-expansion-panel-header>
                                      <v-expansion-panel-content>
                                        <v-divider></v-divider>
                                        <v-container>
                                          <v-layout wrap>
                                            <v-flex xs12 md6>
                                              <v-text-field
                                                :value="formatDateTime(cancelamentoItem.dtRecuperacao)"
                                                label="Data"
                                                readonly
                                                color="blue-grey lighten-2"
                                              ></v-text-field>
                                            </v-flex>
                                            <v-flex xs12 md12>
                                              <v-text-field v-model="cancelamentoItem.usuarioRecuperacao" label="Usuário" readonly color="blue-grey lighten-2"></v-text-field>
                                            </v-flex>
                                            <v-flex xs12 md12>
                                              <v-textarea
                                                v-model="cancelamentoItem.motivoRecuperacao"
                                                label="Motivo da Recuperação"
                                                rows="5"
                                                maxlength="255"
                                                counter="255"
                                                readonly
                                                color="blue-grey lighten-2"
                                              ></v-textarea>
                                            </v-flex>
                                          </v-layout>
                                        </v-container>
                                      </v-expansion-panel-content>
                                    </v-expansion-panel>

                                    <v-expansion-panel v-if="cancelamentoItem.dtReversao != null" focusable>
                                      <v-expansion-panel-header>
                                        <strong>Reversão</strong>
                                      </v-expansion-panel-header>
                                      <v-expansion-panel-content>
                                        <v-divider></v-divider>
                                        <v-container>
                                          <v-layout wrap>
                                            <v-flex xs12 md6>
                                              <v-text-field :value="formatDateTime(cancelamentoItem.dtReversao)" label="Data" readonly color="blue-grey lighten-2"></v-text-field>
                                            </v-flex>
                                            <v-flex xs12 md12>
                                              <v-text-field v-model="cancelamentoItem.usuarioReversao" label="Usuário" readonly color="blue-grey lighten-2"></v-text-field>
                                            </v-flex>
                                            <v-flex xs12 md12>
                                              <v-textarea
                                                v-model="cancelamentoItem.motivoReversao"
                                                label="Motivo da Reversão"
                                                rows="5"
                                                maxlength="255"
                                                counter="255"
                                                readonly
                                                color="blue-grey lighten-2"
                                              ></v-textarea>
                                            </v-flex>
                                          </v-layout>
                                        </v-container>
                                      </v-expansion-panel-content>
                                    </v-expansion-panel>
                                  </v-expansion-panels>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closeCancelamento">Retornar</v-btn>
                              <v-btn v-if="cancelamentoItem.id == null" color="blue darken-1" @click="saveCancelamento">Atualizar</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headerCancelamentos"
                      :items="cancelamentos"
                      no-data-text
                      no-results-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [10, 25, 50]
                      }"
                    >
                      <template v-slot:item.actions="{ item }">
                        <v-icon class="mr-2" color="blue lighten-2" title="Editar" @click="editarCancelamento(item)">edit</v-icon>
                        <v-icon v-if="direitoRecuperacao && assinatura.status == 'RECUPERAÇÃO'" color="blue" title="Recuperação" @click="editarContato()"
                          >supervisor_account</v-icon
                        >
                      </template>
                      <template v-slot:item.dtRegistro="{ item }">
                        {{ formatDateTime(item.dtRegistro) }}
                      </template>
                      <template v-slot:item.dtAgendamento="{ item }">
                        {{ formatDate(item.dtAgendamento) }}
                      </template>
                      <template v-slot:item.cancelarParcelas="{ item }">
                        {{ item.cancelarParcelas == 1 ? "CANCELA" : item.cancelarParcelas == 2 ? "RECALCULA" : "NÃO" }}
                      </template>
                      <template v-slot:item.dtRecuperacao="{ item }">
                        {{ formatDateTime(item.dtRecuperacao) }}
                      </template>
                      <template v-slot:item.dtCancelamento="{ item }">
                        {{ formatDate(item.dtCancelamento) }}
                      </template>
                      <template v-slot:item.dtReversao="{ item }">
                        {{ formatDateTime(item.dtReversao) }}
                      </template>
                      <template v-slot:item.saldo="{ item }">
                        {{ formatCurrency(item.saldo) }}
                      </template>
                    </v-data-table>
                    <v-snackbar v-model="snackCancelamentos" :timeout="2000" absolute bottom left>{{ msgCancelamentos }}</v-snackbar>
                    <v-alert v-if="validacao.cancelamentos" :value="true" color="error" icon="priority_high" outlined>{{ validacao.cancelamentos }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Parcelas</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar>
                      <v-spacer></v-spacer>
                      <v-dialog persistent v-model="dialogRegraAjuste" max-width="800px">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-if="!['PROPOSTA', 'RECUPERAÇÃO', 'CANCELADA', 'EXPIRADA'].includes(assinatura.status) && !assinatura.ajustada && direitoAjustar"
                            color="warning"
                            fab
                            small
                            v-on="on"
                          >
                            <v-icon color="white" title="Ajustar Parcelas">monetization_on</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form ref="formRegraAjuste" v-model="regraAjusteValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">Ajustar Parcelas</span>
                              <v-spacer></v-spacer>
                              <v-btn icon @click="closeAjuste">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs12 md12>
                                    <v-textarea
                                      v-model="regraAjusteAplicar.motivo"
                                      autofocus
                                      label="Informe o Motivo do Ajuste das Parcelas"
                                      maxlength="255"
                                      counter="255"
                                      rows="3"
                                      hint="Obrigatório"
                                      :rules="[
                                        (value) => !!value || 'Obrigatório.',
                                        (value) => (value != null && value.length >= 10 && value.length <= 255) || 'Texto do motivo do ajuste deve ter entre 10 e 255 caracteres'
                                      ]"
                                      color="blue-grey lighten-2"
                                    ></v-textarea>
                                  </v-flex>
                                  <v-flex xs12 md12>
                                    <v-autocomplete
                                      v-model="formaPagamentoSelecionada"
                                      :items="formasPgtoPlano"
                                      :filter="filtrarObjetos"
                                      :rules="[(value) => (!!value && !!value.id) || 'Obrigatório.']"
                                      hint="Obrigatório"
                                      label="Selecione a Forma de Pagamento a Ser Utilizada"
                                      item-text="nome"
                                      item-value="idFormaPgto"
                                      no-data-text
                                      return-object
                                    ></v-autocomplete>
                                  </v-flex>
                                  <v-flex xs12 md12>
                                    <v-autocomplete
                                      v-model="regraAjusteSelecionada"
                                      :items="regrasAjustePlano"
                                      :filter="filtrarObjetos"
                                      :rules="[(value) => (!!value && !!value.id) || 'Obrigatório.']"
                                      hint="Obrigatório"
                                      label="Selecione a Regra de Ajuste"
                                      item-text="nome"
                                      item-value="idRegraAjuste"
                                      no-data-text
                                      :disabled="regraAjusteAplicar.idFormaPgto == null"
                                      clearable
                                      return-object
                                    >
                                      <template v-slot:item="data">
                                        <v-list-item-content>
                                          <v-list-item-title v-html="data.item.regraAjuste"></v-list-item-title>
                                          <v-list-item-subtitle v-html="data.item.regraAjusteDescricao + ' - ' + data.item.regraAjusteTipo"></v-list-item-subtitle>
                                        </v-list-item-content>
                                      </template>
                                    </v-autocomplete>
                                  </v-flex>
                                  <v-flex xs12 md12>
                                    <v-alert :value="regraAjusteAplicar.regraAjusteItens > 0 && contadorAjuste != regraAjusteAplicar.regraAjusteItens" color="error" outlined>{{
                                      msgAjuste
                                    }}</v-alert>
                                  </v-flex>
                                  <v-flex xs12 md12>
                                    <v-data-table
                                      mobile-breakpoint="0"
                                      :headers="headerParcelasAjuste"
                                      :items="parcelasAjustePlano"
                                      no-data-text="Selecione a forma de pagamento e a regra de ajuste para obter as parcelas"
                                      no-results-text
                                      :footer-props="{
                                        itemsPerPageText: 'Registros por página',
                                        itemsPerPageOptions: [12, 25, 50]
                                      }"
                                    >
                                      <template v-slot:item.actions="{ item }">
                                        <v-checkbox
                                          :indeterminate="item.ajustada == 1"
                                          :v-model="item.selected"
                                          primary
                                          hide-details
                                          :disabled="item.ajustada == 1"
                                          @change="validaAjuste(item)"
                                        ></v-checkbox>
                                      </template>
                                      <template v-slot:item.dtVectoOriginal="{ item }">
                                        {{ formatDate(item.dtVectoOriginal) }}
                                      </template>
                                    </v-data-table>
                                  </v-flex>
                                  <v-flex xs12 md12>
                                    <v-alert :value="contadorAjuste == regraAjusteAplicar.regraAjusteItens && regraDisponivel" color="blue" outlined>
                                      Perfeito! Você selecionou
                                      {{ contadorAjuste }} parcela{{ contadorAjuste > 1 ? "s" : "" }}. Confira os novos valores e clique [ATUALIZAR] para aplicar o ajuste.
                                    </v-alert>
                                    <v-alert :value="contadorAjuste == regraAjusteAplicar.regraAjusteItens && !regraDisponivel" color="red" outlined>
                                      Esta regra de ajuste não pode ser aplicada para a forma de pagamento selecionada.
                                    </v-alert>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-alert v-if="validacao.regraAjuste" :value="true" color="error" icon="priority_high" outlined>{{ validacao.regraAjuste }}</v-alert>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closeAjuste">Retornar</v-btn>
                              <v-btn v-if="contadorAjuste == regraAjusteAplicar.regraAjusteItens" color="blue darken-1" @click="saveAjuste">Atualizar</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                        <v-snackbar v-model="snackRegraAjuste" :timeout="2000" absolute bottom left>{{ msgRegraAjuste }}</v-snackbar>
                      </v-dialog>
                      <v-dialog persistent v-model="dialogParcela" max-width="700px">
                        <v-card>
                          <Parcela :id="idParcela" receber="true" :dialogParcela.sync="dialogParcela" />
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headerParcelas"
                      :items="parcelas"
                      no-data-text
                      no-results-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [12, 25, 50]
                      }"
                    >
                      <template v-slot:item.actions="{ item }">
                        <span class="red--text" v-if="!item.situacao">EXCLUÍDA</span>
                        <v-icon v-if="item.situacao" class="mr-2" color="blue lighten-2" title="Editar" @click="editarParcela(item)">edit</v-icon>
                      </template>
                      <template v-slot:item.renovacao="{ item }">
                        {{ item.renovacao + ` '${item.codAntigo || item.idRenovacao}'` }}
                      </template>
                      <template v-slot:item.dtVectoOriginal="{ item }">
                        {{ formatDate(item.dtVectoOriginal) }}
                      </template>
                      <template v-slot:item.dtPromessa="{ item }">
                        {{ formatDate(item.dtPromessa) }}
                      </template>
                    </v-data-table>
                  </v-card>
                </v-flex>
                <v-flex xs12 md3 v-if="parcelas.length > 0 && parcelas[0].id && direitoParcelas">
                  <v-icon color="blue lighten-2" title="Abrir no Financeiro" @click="visualizarParcelas">add_circle_outline</v-icon>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">
              {{ validacao.mensagemErro }}
            </v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn to="/assinaturas/assinaturas" id="btn-rsn" color="orange lighten-2 white" style="color: white">
        <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
      </v-btn>
      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" id="btn-rsn" color="blue white" style="color: white" depressed @click="salvar">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar
      </v-btn>
    </v-card-actions>
    <v-dialog v-model="dialogConfirm" max-width="290">
      <v-card>
        <v-toolbar>
          <span class="headline">Confirmação</span>
        </v-toolbar>
        <v-card-text>{{ msgConfirm }}</v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="naoConfirmado">Não</v-btn>
          <v-btn color="green darken-1" text @click="confirmado">Sim</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogClienteSite" max-width="400">
      <v-card>
        <v-form ref="formClienteSite" v-model="clienteSiteValid" lazy-validation>
          <v-toolbar>
            <span class="headline">Alteração do Cliente Jornal</span>
          </v-toolbar>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    autofocus
                    v-model="novoCliente"
                    label="Identificação do Novo Cliente"
                    v-mask="'##############'"
                    hint="Obrigatório"
                    :rules="[(value) => !!value || 'Obrigatório.', (value) => valida_identificacao(value)]"
                    color="blue-grey lighten-2"
                    autocomplete="disabled"
                    :name="Math.random()"
                    @change="validacao.clienteSite = false"
                  ></v-text-field>
                </v-flex>
                <v-alert v-if="validacao.clienteSite" :value="true" color="error" icon="priority_high" outlined>{{ validacao.clienteSite }}</v-alert>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" @click="dialogClienteSite = false">Retornar</v-btn>
            <v-btn color="blue darken-1" @click="alterarClienteSite">Atualizar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialogContato" max-width="1200px">
      <v-card>
        <Contato
          idEtapa=""
          idParcela=""
          :idAssinatura="idAssinatura"
          :idRenovacao="idRenovacao"
          idAnuncio=""
          :idCliente="idCliente"
          :cliente="cliente"
          :tipo="tipo"
          :dialogContato.sync="dialogContato"
        />
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackRefresh" color="info" :timeout="1" relative bottom left></v-snackbar>
  </v-card>
</template>
<script>
import Parcela from "@/views/fin/Parcela.vue";
import Contato from "@/views/ass/Contato.vue";
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  components: {
    Parcela,
    Contato
  },
  data() {
    return {
      assinatura: {},
      validacao: {
        idRegraAjuste: false,
        idFormaPgto: false,
        nroParcelas: false,
        observacoes: false,
        reclamacoes: false,
        cartoesClube: false,
        suspensoes: false,
        cancelamentos: false,
        regraAjuste: false,
        mensagemErro: false,
        reclamacao_dtEdicao: false,
        suspensao_dtInicio: false,
        suspensao_dtFim: false,
        suspensao_motivo: false,
        clienteSite: false
      },
      renovacoes: [],
      renovacaoAtual: {},
      ultimaRenovacao: {},
      observacoes: [],
      reclamacoes: [],
      contatos: [],
      cartoesClube: [],
      suspensoes: [],
      carteira: [],
      regrasAjuste: [],
      cancelamentos: [],
      parcelas: [],
      origensVenda: [],

      clienteSite: false,
      dialogClienteSite: false,
      novoCliente: "",
      clienteSiteValid: true,

      suspensaoAtiva: false,
      cancelamentoAtivo: false,
      motivosCancelamento: [],
      reclamacoesPadrao: [],
      dependentesCartaoCliente: [],
      produtosClube: [],
      suspensoesPadrao: [],

      formaPgtoPadraoSelecionada: {},
      formasPgtoPadrao: [],

      regraAjustePadraoSelecionada: {},
      regrasAjustePadraoPlano: [],

      nrosParcelasPlano: [],

      headerRenovacoes: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Renovação", value: "renovacao" },
        { text: "Assinante", value: "cliente" },
        { text: "Origem", value: "origem" },
        { text: "Plano", value: "plano" },
        { text: "Início", value: "dtInicio" },
        { text: "Término", value: "dtFim" },
        { text: "Produtos", value: "produtos" },
        { text: "Tipos", value: "produtosTipos" },
        { text: "Modalidade", value: "modalidade" },
        { text: "Total", value: "tt" },
        { text: "Reinício", value: "reinicio" },
        { text: "Online", value: "online" },
        { text: "", value: "situacao" }
      ],
      headerObservacoes: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Observação", value: "observacao" },
        { text: "Usuário", value: "usuario" },
        { text: "Registro", value: "dtRegistro" }
      ],
      headerReclamacoes: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Renovação", value: "idRenovacao" },
        { text: "Status", value: "status" },
        { text: "Reclamação", value: "reclamacaoPadrao" },
        { text: "Usuário", value: "usuario" },
        { text: "Registro", value: "dtRegistro" },
        { text: "Data Edição", value: "dtEdicao" },
        { text: "Usuário Solução", value: "usuarioSolucao" },
        { text: "Registro Solução", value: "dtSolucao" }
      ],
      headerContatos: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Tipo", value: "tipo" },
        { text: "Usuário", value: "usuario" },
        { text: "Registro", value: "dtRegistro" },
        { text: "Recuperado", value: "recuperado" },
        { text: "Parcela", value: "idParcela" },
        { text: "Promessa", value: "dtPromessa" }
      ],
      headerCartoesClube: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Dependente", value: "dependente" },
        { text: "Produto", value: "produto" },
        { text: "Usuário", value: "usuario" },
        { text: "Registro", value: "dtRegistro" },
        { text: "Validade", value: "dtValidade" },
        { text: "Exportação", value: "dtExportacao" },
        { text: "Cancelamento", value: "dtCancelamento" }
      ],
      headerSuspensoes: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Início", value: "dtInicio" },
        { text: "Término", value: "dtFim" },
        { text: "Motivo", value: "suspensaoPadrao" },
        { text: "Usuário", value: "usuario" },
        { text: "Registro", value: "dtRegistro" }
      ],
      headerCarteira: [
        { text: "Data", value: "dtStatus" },
        { text: "Status", value: "status" }
      ],
      headerRegrasAjuste: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Nome", value: "regraAjuste" },
        { text: "Motivo", value: "motivo" },
        { text: "Usuário", value: "usuario" },
        { text: "Registro", value: "dtRegistro" }
      ],
      headerCancelamentos: [
        { text: "Ações", value: "actions", width: "140" },
        { text: "ID", value: "id" },
        { text: "Registro", value: "dtRegistro" },
        { text: "Usuário", value: "usuarioCancelamento" },
        { text: "Agendamento", value: "dtAgendamento" },
        { text: "Motivo", value: "motivoCancelamentoPadrao" },
        { text: "Cancelar Parcelas", value: "cancelarParcelas" },
        { text: "Recuperação", value: "dtRecuperacao" },
        { text: "Cancelamento", value: "dtCancelamento" },
        { text: "Reversão", value: "dtReversao" },
        { text: "Saldo", value: "saldo" }
      ],
      headerParcelas: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Índice", value: "indice" },
        { text: "Renovação", value: "renovacao" },
        { text: "Parcela", value: "numero" },
        { text: "Status", value: "status" },
        { text: "Forma de Pagamento", value: "formaPagamento" },
        { text: "Vencimento Original", value: "dtVectoOriginal" },
        { text: "Promessa", value: "dtPromessa" },
        { text: "Lote", value: "idLoteFaturamento" },
        { text: "NF", value: "idNF" },
        { text: "Valor", value: "valor", align: "end" }
      ],
      headerParcelasAjuste: [
        { text: "Ações", value: "actions" },
        { text: "Índice", value: "indice" },
        { text: "Vencimento Original", value: "dtVectoOriginal" },
        { text: "Forma Pagamento", value: "formaPagamento" },
        { text: "Valor Original", value: "vlr", align: "end" },
        { text: "Novo Valor", value: "vlrNovo", align: "end" }
      ],

      //* Parâmetros dos modais
      dialogObservacao: false,
      observacaoValid: true,
      observacaoIndex: -1,
      observacaoItem: {
        id: null,
        observacao: "",
        situacao: 1
      },
      defaultObservacaoItem: {
        id: null,
        observacao: "",
        situacao: 1
      },

      reclamacaoSelecionada: {},
      dialogReclamacao: false,
      reclamacaoValid: true,
      reclamacaoIndex: -1,
      reclamacaoItem: {
        id: null,
        status: "ABERTA",
        idReclamacao: null,
        reclamacao: "",
        execucao: "",
        solucao: "",
        dtEdicao: null
      },
      defaultReclamacaoItem: {
        id: null,
        status: "ABERTA",
        idReclamacao: null,
        reclamacao: "",
        execucao: "",
        solucao: "",
        dtEdicao: null
      },
      dtEdicaoReclamacao: null,
      dtEdicaoReclamacaoUS: null,
      mnDtEdicaoReclamacao: false,

      dialogContatos: false,
      contatoValid: true,
      contatoIndex: -1,
      contatoItem: {
        id: null,
        tipo: null,
        recuperado: null,
        idParcela: null,
        dtPromessa: null
      },

      dialogContato: false,
      idAssinatura: "",
      idRenovacao: "",
      idCliente: "",
      cliente: "",
      tipo: 1,

      dependenteSelecionado: {},
      produtoSelecionado: {},
      dialogCartaoClube: false,
      cartaoClubeValid: true,
      cartaoClubeIndex: -1,
      cartaoClubeItem: {
        id: null,
        idDependente: null,
        dependente: "",
        usuario: "",
        usuarioExportacao: null,
        dtExportacao: null,
        usuarioCancelamento: null,
        dtCancelamento: null
      },
      defaultCartaoClubeItem: {
        id: null,
        idDependente: null,
        dependente: "",
        usuario: "",
        usuarioExportacao: null,
        dtExportacao: null,
        usuarioCancelamento: null,
        dtCancelamento: null
      },

      suspensaoSelecionada: {},
      dialogSuspensao: false,
      suspensaoValid: true,
      suspensaoIndex: -1,
      suspensaoItem: {
        id: null,
        dtInicio: null,
        editInicio: true,
        dtFim: null,
        editFim: true,
        idMotivoSuspensao: null,
        motivo: "",
        usuario: "",
        dtRegistro: null,
        bonificar: 0
      },

      dtInicioSuspensao: null,
      dtInicioSuspensaoUS: null,
      mnDtInicioSuspensao: false,

      dtFimSuspensao: null,
      dtFimSuspensaoUS: null,
      mnDtFimSuspensao: false,

      dtAgCancelamento: null,
      dtAgCancelamentoUS: null,
      mndtAgCancelamento: false,

      defaultSuspensaoItem: {
        id: null,
        dtInicio: null,
        editInicio: true,
        dtFim: null,
        editFim: true,
        idMotivoSuspensao: null,
        motivo: "",
        usuario: "",
        dtRegistro: null,
        bonificar: 0
      },

      formasPgtoPlano: [],
      regrasAjustePlano: [],

      regraAjusteSelecionada: {},
      formaPagamentoSelecionada: {},

      parcelasAjustePlano: [],

      dialogRegraAjusteView: false,
      regraAjusteItem: {},
      regraAjusteAplicar: {},
      dialogRegraAjuste: false,
      regraAjusteValid: true,
      contadorAjuste: 0,

      cancelamentoSelecionado: {},
      dialogCancelamento: false,
      cancelamentoValid: true,
      cancelamentoIndex: -1,
      cancelamentoItem: {
        id: null,
        usuarioCancelamento: "",
        dtAgendamento: null,
        idMotivoCancelamento: null,
        motivoCancelamento: "",
        cancelarParcelas: null,
        dtRecuperacao: null,
        usuarioRecuperacao: "",
        motivoRecuperacao: null,
        dtReversao: null,
        usuarioReversao: "",
        motivoReversao: "",
        dtCancelamento: null
      },
      defaultCancelamentoItem: {
        id: null,
        usuarioCancelamento: "",
        dtAgendamento: null,
        idMotivoCancelamento: null,
        motivoCancelamento: "",
        cancelarParcelas: null,
        dtRecuperacao: null,
        usuarioRecuperacao: "",
        motivoRecuperacao: null,
        dtReversao: null,
        usuarioReversao: "",
        motivoReversao: "",
        dtCancelamento: null
      },

      dialogReverterCancelamento: false,
      reverterCancelamentoValid: true,

      //* Dados para o componente DialogParcela
      idParcela: "",
      dialogParcela: false,

      idProdutoHelp: null,
      idProdutoClube: null,
      dataInicio: new Date(),
      id: "",
      idSitePrincipal: null,
      direitoCriarSuspensao: false,
      direitoAlterarSuspensao: false,
      direitoCancelar: false,
      direitoReverter: false,
      direitoAjustar: false,
      direitoAssinante: false,
      direitoParcelas: false,
      direitoPadraoProximasParcelas: false,
      direitoClienteSite: false,
      direitoRecuperacao: false,
      regraDisponivel: true,
      elementoValid: true,
      painel: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      dialogConfirm: false,
      msgConfirm: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,
      snackRefresh: false,
      msgObservacoes: "",
      snackObservacoes: false,
      msgCartoesClube: "",
      snackCartoesClube: false,
      msgSuspensoes: "",
      snackSuspensoes: false,
      msgCancelamentos: "",
      snackCancelamentos: false,
      msgRegraAjuste: "",
      snackRegraAjuste: false,
      msgAjuste: "",

      isUpdating: false,
      bloqueiaBotoes: false
    };
  },

  watch: {
    reclamacaoSelecionada(val) {
      this.reclamacaoItem.idReclamacao = val.id;
      this.reclamacaoItem.reclamacaoPadrao = val.nome;
    },
    suspensaoSelecionada(val) {
      this.suspensaoItem.idMotivoSuspensao = val.id;
      this.suspensaoItem.suspensaoPadrao = val.nome;
      this.suspensaoItem.bonificar = val.bonificar;
    },
    dependenteSelecionado(val) {
      this.cartaoClubeItem.idDependente = val.id;
      this.cartaoClubeItem.dependente = val.nome;
    },
    produtoSelecionado(val) {
      this.cartaoClubeItem.idProduto = val.id;
      this.cartaoClubeItem.produto = val.nome;
    },
    cancelamentoSelecionado(val) {
      this.cancelamentoItem.idMotivoCancelamento = val.id;
      this.cancelamentoItem.motivoCancelamentoPadrao = val.nome;
      this.cancelamentoItem.cancelarParcelas = val.cancelarParcelas;
      this.cancelamentoItem.imediato = val.imediato;
      // if (val.imediato) {
      //se período já começou, então hoje já houve entrega/acesso (o agendamento precisa ser para amanhã) - se período ainda não começou, pode ser alterado (ou excluído se ainda não foi ao Protheus)
      //   this.dtAgCancelamentoUS = util.dateToStringUS(new Date());
      // }
    },
    formaPagamentoSelecionada(val) {
      if (val) {
        this.regraAjusteAplicar.idFormaPgto = val.idFormaPgto;
        this.regraAjusteAplicar.formaPagamento = val.nome;
        this.carregarRegrasAjustePlano();
      }
    },
    formaPgtoPadraoSelecionada(val) {
      this.montaNrosParcelas(val);
    },
    regraAjustePadraoSelecionada(val) {
      if (val) {
        this.carregarFormasPgtoPadrao(val.id);
      } else {
        this.formasPgtoPadrao = [];
        this.nrosParcelasPlano = [];
      }
    },
    regraAjusteSelecionada(val) {
      if (val) {
        this.regraAjusteAplicar.idRegraAjuste = val.idRegraAjuste;
        this.regraAjusteAplicar.regraAjuste = val.regraAjuste;
        this.regraAjusteAplicar.regraAjusteItens = val.regraAjusteItens;
        this.regraAjusteAplicar.regraAjusteTipo = val.regraAjusteTipo;
        this.carregarParcelasAjustePlano();
      } else {
        this.parcelasAjustePlano = [];
        this.regraAjusteAplicar.regraAjusteItens = null;
        this.contadorAjuste = 0;
        this.regraDisponivel = false;
      }
    },
    dialogObservacao(val) {
      val || this.closeObservacao();
    },
    dialogReclamacao(val) {
      val || this.closeReclamacao();
    },
    dialogContatos(val) {
      val || this.closeContatos();
    },
    dialogCartaoClube(val) {
      val || this.closeCartaoClube();
    },
    dialogSuspensao(val) {
      val || this.closeSuspensao();
    },
    dialogRegraAjusteView(val) {
      val || this.closeRegraAjuste();
    },
    dialogCancelamento(val) {
      val || this.closeCancelamento();
    },
    dialogReverterCancelamento(val) {
      val || this.closeReverterCancelamento();
    },
    dialogParcela(val) {
      if (val == null) {
        this.carregarParcelas();
      }
      val || this.closeParcela();
    },
    dialogContato(val) {
      if (val == null) {
        this.$router.push("/assinaturas/assinatura/" + this.id);
        this.$router.go("/assinaturas/assinatura/" + this.id);
      }
      val || this.closeContato();
    },

    dtEdicaoReclamacaoUS() {
      this.dtEdicaoReclamacao = util.formatDate(this.dtEdicaoReclamacaoUS);
      this.mnDtEdicaoReclamacao = false;
    },

    dtInicioSuspensaoUS() {
      this.dtInicioSuspensao = util.formatDate(this.dtInicioSuspensaoUS);
      this.mnDtInicioSuspensao = false;
    },
    dtFimSuspensaoUS() {
      this.dtFimSuspensao = util.formatDate(this.dtFimSuspensaoUS);
      this.mnDtFimSuspensao = false;
    },
    dtAgCancelamentoUS() {
      this.dtAgCancelamento = util.formatDate(this.dtAgCancelamentoUS);
      this.mndtAgCancelamento = false;
    }
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    getItemActions() {
      return "item.actions";
    },
    getItemStatus() {
      return "item.status";
    },
    getItemTipo() {
      return "item.tipo";
    },
    getItemRecuperado() {
      return "item.recuperado";
    },
    getItemIdParcela() {
      return "item.idParcela";
    },
    async salvar() {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      //* Prepara as observações da assinatura para envio a base
      var observacoesAssinaturaCommit = [];
      this.observacoes.forEach((element) => {
        observacoesAssinaturaCommit.push({
          id: element.id,
          idAssinatura: this.id,
          idRenovacao: this.renovacaoAtual.id || this.ultimaRenovacao.id,
          observacao: element.observacao,
          idUsuario: element.idUsuario,
          situacao: element.situacao
        });
      });

      //* Prepara as reclamações da assinatura para envio a base
      var reclamacoesAssinaturaCommit = [];
      this.reclamacoes.forEach((element) => {
        reclamacoesAssinaturaCommit.push({
          id: element.id,
          idAssinatura: this.id,
          idRenovacao: this.renovacaoAtual.id || this.ultimaRenovacao.id,
          idReclamacao: element.idReclamacao,
          idUsuario: element.idUsuario,
          reclamacao: element.reclamacao,
          status: element.status,
          dtEdicao: element.dtEdicao
        });
      });

      //* Prepara os cartões do clube do assinante da assinatura para envio a base
      var dependentes = [];
      var cartoesClubeAssinaturaCommit = [];
      var dtValidade = util.parseDateUS(this.renovacaoAtual.dtFim || this.ultimaRenovacao.dtFim);
      var dtLimite = util.parseDateUS(this.renovacaoAtual.dtInicio || this.ultimaRenovacao.dtInicio);
      dtLimite.setFullYear(dtLimite.getFullYear() + 1);
      dtLimite.setDate(dtLimite.getDate() - 1);

      this.cartoesClube.forEach((element) => {
        if (element.dtCancelamento == null) {
          dependentes.push(element.dependente);
        }
        cartoesClubeAssinaturaCommit.push({
          id: element.id,
          idAssinatura: this.id,
          idDependente: element.idDependente,
          idProduto: element.idProduto,
          help: element.idProduto == this.idProdutoHelp,
          dtValidade: util.dateToStringUS(dtValidade < dtLimite ? dtLimite : dtValidade),
          idUsuario: element.idUsuario
        });
      });
      //* Se já há uma renovação posterior, não será mais possível inserir dependente do clube na renovação atual

      //* Prepara as suspensões da assinatura para envio a base
      var bonificar = false;
      var suspensoesAssinaturaCommit = [];
      this.suspensoes.forEach((element) => {
        if (!element.id && element.bonificar) {
          bonificar = true;
        }
        suspensoesAssinaturaCommit.push({
          id: element.id,
          idAssinatura: this.id,
          idRenovacao: this.renovacaoAtual.id || this.ultimaRenovacao.id,
          idMotivoSuspensao: element.idMotivoSuspensao,
          idUsuario: element.idUsuario,
          dtInicio: element.dtInicio,
          dtFim: element.dtFim,
          motivo: element.motivo.trim()
        });
      });

      //* Prepara os cancelamentos da assinatura para envio a base
      var agendado = false;
      var cancelamentosAssinaturaCommit = [];
      this.cancelamentos.forEach((element) => {
        if (!element.id) {
          agendado = true;
        }
        cancelamentosAssinaturaCommit.push({
          id: element.id,
          idAssinatura: this.id,
          idRenovacao: this.renovacaoAtual.id || this.ultimaRenovacao.id,
          idUsuarioCancelamento: element.idUsuarioCancelamento,
          dtAgendamento: util.dateToStringUS(element.dtAgendamento),
          idMotivoCancelamento: element.idMotivoCancelamento,
          motivoCancelamento: element.motivoCancelamento,
          cancelarParcelas: element.cancelarParcelas
        });
      });

      var renovacaoCommit = {
        dtInicio: this.renovacaoAtual.id ? this.renovacaoAtual.dtInicio : this.ultimaRenovacao.dtInicio,
        dtFim: this.renovacaoAtual.id ? this.renovacaoAtual.dtFim : this.ultimaRenovacao.dtFim
      };

      this.assinatura.idRegraAjuste = this.regraAjustePadraoSelecionada.id;
      this.assinatura.idFormaPgto = this.formaPgtoPadraoSelecionada.id;
      this.assinatura.renovacao = renovacaoCommit;
      this.assinatura.observacoes = observacoesAssinaturaCommit;
      this.assinatura.reclamacoes = reclamacoesAssinaturaCommit;
      this.assinatura.cartoesClube = cartoesClubeAssinaturaCommit;
      this.assinatura.suspensoes = suspensoesAssinaturaCommit;
      this.assinatura.cancelamentos = cancelamentosAssinaturaCommit;

      this.isUpdating = true;
      try {
        await api.put(process.env.VUE_APP_URL_WEB_API + "ass/assinatura/" + this.id, this.assinatura);
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;

        if (bonificar) {
          setTimeout(() => {
            this.$router.push("/assinaturas/assinatura/" + this.id);
            this.$router.go("/assinaturas/assinatura/" + this.id);
          }, 1000);
        } else {
          this.carregarObservacoes();
          this.carregarReclamacoes();
          this.carregarCartoesClube();
          this.carregarSuspensoes();
          this.carregarCarteira();
          this.carregarRegrasAjuste();
          this.carregarCancelamentos();
          if (agendado) {
            this.carregarParcelas();
          }
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },

    async carregarOrigensVenda() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/origem/all");
        if (response.data) {
          this.origensVenda = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as origens de venda";
        this.snackErro = true;
      }
    },

    async carregarObservacoes() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/assinatura-observacao/assinatura/" + this.id);
        if (response.data) {
          this.observacoes = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as observações da assinatura";
        this.snackErro = true;
      }
    },

    async carregarReclamacoes() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/assinatura-reclamacao/assinatura/" + this.id);
        if (response.data) {
          this.reclamacoes = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as reclamações da assinatura";
        this.snackErro = true;
      }
    },

    async carregarContatos() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/contato/assinatura/" + this.id);
        if (response.data) {
          this.contatos = response.data;
          this.contatos.forEach((element) => {
            element.idParcela = element.tipo == 2 ? element.idParcela : "";
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os contatos da assinatura";
        this.snackErro = true;
      }
    },

    async carregarCartoesClube() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/assinatura-clube-assinante-cartao/assinatura/" + this.id);
        if (response.data) {
          this.cartoesClube = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os cartões do clube da assinatura";
        this.snackErro = true;
      }
    },

    async carregarSuspensoes() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/assinatura-suspensao/assinatura/" + this.id);
        if (response.data) {
          this.suspensoes = response.data;
          this.suspensaoAtiva = false;
          this.suspensoes.forEach((element) => {
            var dtFim = new Date(element.dtFim);
            dtFim.setHours(23);
            if (dtFim > new Date()) {
              this.suspensaoAtiva = true;
            }
            element.excluir = new Date(element.dtFim) > new Date();
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as suspensões da assinatura";
        this.snackErro = true;
      }
    },

    async carregarCarteira() {
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/assinatura-carteira/assinatura/" + this.id);
      if (response.data) {
        this.carteira = response.data;
      }
    },

    async carregarRegrasAjuste() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/assinatura-regra-ajuste/assinatura/" + this.id);
        if (response.data) {
          this.regrasAjuste = response.data;
          //* Carrega as parcelas com cada uma das regras de ajuste aplicadas
          this.regrasAjuste.map(async (element) => {
            element.parcelas = await this.carregarParcelasRegraAjuste(element.id);
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as regras de ajuste aplicadas as parcelas da assinatura";
        this.snackErro = true;
      }
    },

    async carregarCancelamentos() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/assinatura-cancelamento/assinatura/" + this.id);
        if (response.data) {
          this.cancelamentos = response.data;
          this.cancelamentoAtivo = false;
          this.cancelamentos.forEach((element) => {
            if (
              (element.dtCancelamento != null && element.dtReversao == null) ||
              (util.parseDateUS(element.dtAgendamento) > util.parseDateUS(new Date()) && element.dtRecuperacao == null && element.dtReversao == null)
            ) {
              //* se nem chegou a data do agendamento ou já foi cancelado e ainda não revertido, permite reverter
              this.cancelamentoAtivo = true;
            }
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os cancelamentos da assinatura";
        this.snackErro = true;
      }
    },

    async carregarParcelas() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/parcela/assinatura/" + this.id);
        if (response.data) {
          this.parcelas = response.data;
          this.parcelas.forEach((element) => {
            element.valor = util.formatCurrency(element.valor + element.descAcr);
            element.idNF = element.idNFe || element.idNFSC || element.idNFSe;
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as parcelas da assinatura";
        this.snackErro = true;
      }
    },

    async carregarParcelasRegraAjuste(idRegraAjuste) {
      try {
        var retorno = [];
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/parcela/regraAjuste/" + idRegraAjuste);
        if (response.data) {
          retorno = response.data;
        }
        retorno.map(async (item) => {
          item.vlr = util.formatCurrency(item.valor);
          item.vlrNovo = util.formatCurrency(item.valor + item.descAcr);
        });

        return retorno;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as parcelas da regra de ajuste";
        this.snackErro = true;
      }
    },

    async carregarReclamacoesPadrao() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/reclamacao-padrao/lista?idSite=" + this.idSitePrincipal);
        if (response.data) {
          this.reclamacoesPadrao = response.data;
          if (this.reclamacoesPadrao.length == 1) {
            this.reclamacaoSelecionada = {
              id: this.reclamacoesPadrao[0].id,
              nome: this.reclamacoesPadrao[0].nome
            };
          }
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as reclamações padrão";
        this.snackErro = true;
      }
    },

    async carregarDependentesCartaoCliente() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-dependente/lista?idCliente=" + this.ultimaRenovacao.idCliente);
        if (response.data) {
          this.dependentesCartaoCliente = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os dependentes do cliente";
        this.snackErro = true;
      }
    },

    async carregarSuspensoesPadrao() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/motivo-suspensao/lista?idSite=" + this.idSitePrincipal);
        if (response.data) {
          this.suspensoesPadrao = response.data;
          if (this.suspensoesPadrao.length == 1) {
            this.suspensaoSelecionada = {
              id: this.suspensoesPadrao[0].id,
              nome: this.suspensoesPadrao[0].nome,
              bonificar: this.suspensoesPadrao[0].bonificar
            };
          }
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os motivos de suspensão";
        this.snackErro = true;
      }
    },

    async carregarMotivosCancelamentoPadrao() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/motivo-cancelamento/lista?idSite=" + this.idSitePrincipal);
        if (response.data) {
          this.motivosCancelamento = response.data;
          if (this.motivosCancelamento.length == 1) {
            this.cancelamentoSelecionado = {
              id: this.motivosCancelamento[0].id,
              nome: this.motivosCancelamento[0].nome,
              cancelarParcelas: this.motivosCancelamento[0].cancelarParcelas,
              imediato: this.motivosCancelamento[0].imediato
            };
          }
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os motivos de cancelamento";
        this.snackErro = true;
      }
    },

    async carregarFormasPgtoPlano() {
      try {
        //* Busca as formas de pagamento associadas a regras de renovação e automática (pois pode ser que uma forma só possua parcelamento para captação ou nem seja automática)
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/plano-forma-pagamento/plano/" + this.ultimaRenovacao.idPlano + "?ativos=1");
        if (response.data) {
          this.formasPgtoPlano = response.data;
          this.formasPgtoPlano.forEach((element) => {
            element.nome = element.formaPagamento;
            element.id = element.idFormaPgto;
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as formas de pagamento do plano de assinatura";
        this.snackErro = true;
      }
    },

    async carregarFormasPgtoPadrao(idRegraAjuste) {
      if (idRegraAjuste) {
        this.formasPgtoPadrao = [];
        this.nrosParcelasPlano = [];
        try {
          //* Busca as formas de pagamento associadas a regra de renovação automática padrão
          const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/regra-ajuste-item/formasPgtoRegra/" + idRegraAjuste + "?idPlano=" + this.ultimaRenovacao.idPlano);
          if (response.data) {
            this.formasPgtoPadrao = response.data;
            this.formasPgtoPadrao.forEach((element) => {
              if (this.assinatura.idFormaPgto && this.assinatura.idFormaPgto == element.id) {
                this.formaPgtoPadraoSelecionada = element;
              }
            });
          }
        } catch (e) {
          this.isUpdating = true;
          this.mensagem = "Ocorreu um erro ao carregar as formas de pagamento do plano de assinatura";
          this.snackErro = true;
        }
      }
    },
    async carregarProdutosClube() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/produto/lista?tipo=CLUBE");
        this.produtosClube = response.data;
        this.produtosClube.forEach((element) => {
          if (element.nome.toUpperCase() == "HELP") {
            this.idProdutoHelp = element.id;
          } else {
            this.idProdutoClube = element.id;
          }
        });
      } catch (e) {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de produtos do clube";
        this.snackErro = true;
      }
    },

    montaNrosParcelas(formaPgto) {
      this.nrosParcelasPlano = [];
      if (formaPgto) {
        if (util.isFormaPgto1Parcela(formaPgto.tipo)) {
          this.nrosParcelasPlano.push(1);
        } else {
          for (let index = 1; index <= formaPgto.maxParcelas; index++) {
            this.nrosParcelasPlano.push(index);
          }
        }
        if (!this.nrosParcelasPlano.includes(this.assinatura.nroParcelas)) {
          this.assinatura.nroParcelas = 1;
        }
      }
    },

    async carregarRegrasAjustePlano() {
      try {
        this.regrasAjustePlano = [];
        this.regraAjusteSelecionada = {};
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "ass/plano-regra-ajuste/plano/" +
            this.ultimaRenovacao.idPlano +
            "?ativos=1&recuperacao=1&idFormaPgto=" +
            this.regraAjusteAplicar.idFormaPgto
        );
        if (response.data) {
          response.data.forEach((element) => {
            element.nome = element.regraAjuste;
            if (element.regraAjusteItens) {
              this.regrasAjustePlano.push(element);
            }
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as regras de ajuste do plano de assinatura";
        this.snackErro = true;
      }
    },

    async carregarRegrasAjustePadraoPlano() {
      try {
        this.regrasAjustePadraoPlano = [];
        this.regraAjustePadraoSelecionada = {};
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/plano-regra-ajuste/plano/" + this.ultimaRenovacao.idPlano + "?ativos=1&renovacaoAuto=1");
        if (response.data) {
          response.data.forEach((element) => {
            element.nome = element.regraAjuste;
            element.id = element.idRegraAjuste;
            this.regrasAjustePadraoPlano.push(element);
            if (this.assinatura.idRegraAjuste && this.assinatura.idRegraAjuste == element.id) {
              this.regraAjustePadraoSelecionada = element;
            }
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as regras de ajuste do plano de assinatura";
        this.snackErro = true;
      }
    },
    async carregarParcelasAjustePlano() {
      try {
        this.parcelasAjustePlano = [];
        this.contadorAjuste = 0;
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/parcela/assinaturaPlano/" + this.id + "/" + this.ultimaRenovacao.idPlano);
        if (response.data) {
          this.regraDisponivel = true;
          this.parcelasAjustePlano = response.data;
          this.parcelasAjustePlano.forEach(async (element) => {
            element.valorNovo = 0;
            element.selected = false;
          });
          if (this.parcelasAjustePlano.length < this.regraAjusteAplicar.regraAjusteItens) {
            var indice = "";
            var dtFinal = "";
            do {
              const retorno = await api.get(
                process.env.VUE_APP_URL_WEB_API +
                  "ass/renovacao/proximasParcelas/" +
                  this.id +
                  "/" +
                  this.regraAjusteAplicar.idFormaPgto +
                  "?formaPagamento=" +
                  this.regraAjusteAplicar.formaPagamento +
                  "&indice=" +
                  indice +
                  "&dtFinal=" +
                  dtFinal
              );
              const novasParcelas = retorno.data;

              novasParcelas.map(async (element) => {
                indice = element.indice;
                dtFinal = element.dtFinal;
                element.valorNovo = 0;
                element.selected = false;
              });

              Array.prototype.push.apply(this.parcelasAjustePlano, novasParcelas);
            } while (this.parcelasAjustePlano.length < this.regraAjusteAplicar.regraAjusteItens);

            this.parcelasAjustePlano.push({});
            this.parcelasAjustePlano.splice(this.parcelasAjustePlano.length - 1, 1);
          }
          var contaAjustadas = 0;
          this.parcelasAjustePlano.forEach((element) => {
            element.vlr = util.formatCurrency(element.valor);
            element.vlrNovo = util.formatCurrency(element.valorNovo);
            if (element.ajustada) {
              contaAjustadas++;
            }
          });
          if (this.parcelasAjustePlano.length - contaAjustadas < this.regraAjusteAplicar.regraAjusteItens) {
            this.msgAjuste = "Parcelas indicadas com [-] já constam com regra de ajuste aplicada. Não será possível reaplicar.";
          } else {
            this.msgAjuste =
              "Selecione " +
              this.regraAjusteAplicar.regraAjusteItens +
              " parcela" +
              (this.regraAjusteAplicar.regraAjusteItens > 1 ? "s" : "") +
              " para aplicação da regra de ajuste.";
          }
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as parcelas para aplicação da regra de ajuste";
        this.snackErro = true;
      }
    },

    // * Métodos para controle dos itens
    async ativarDesativarObservacao(item) {
      var observacaoItem = Object.assign({}, item);
      var observacaoIndex = this.observacoes.indexOf(item);
      if (observacaoItem.id == null) {
        this.observacoes.splice(observacaoIndex, 1);
      } else {
        observacaoItem.situacao = observacaoItem.situacao == 1 ? 0 : 1;
        var situacao = { situacao: observacaoItem.situacao };

        try {
          await api.put(process.env.VUE_APP_URL_WEB_API + "ass/assinatura-observacao/situacao/" + observacaoItem.id, situacao);
          Object.assign(this.observacoes[observacaoIndex], observacaoItem);
          this.msgObservacoes = "Item " + (observacaoItem.situacao == 1 ? "Ativado" : "Desativado");
          this.snackObservacoes = true;
        } catch (e) {
          this.msgObservacoes =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.snackObservacoes = true;
        }
      }
      this.observacaoItem = Object.assign({}, this.defaultObservacaoItem);
      this.observacaoIndex = -1;
    },
    closeObservacao() {
      this.dialogObservacao = false;
      setTimeout(() => {
        this.observacaoItem = Object.assign({}, this.defaultObservacaoItem);
        this.observacaoIndex = -1;
        this.$refs.formObservacao.resetValidation();
      }, 300);
    },
    saveObservacao() {
      if (this.$refs.formObservacao.validate()) {
        this.observacaoItem.dtRegistro = new Date();
        this.observacaoItem.usuario = this.$store.state.usuario.nome;
        this.observacaoItem.idUsuario = this.$store.state.usuario.id;
        if (this.observacaoIndex > -1) {
          Object.assign(this.observacoes[this.observacaoIndex], this.observacaoItem);
        } else {
          this.observacoes.push(this.observacaoItem);
        }
        this.closeObservacao();
        this.observacaoItem = Object.assign({}, this.defaultObservacaoItem);
        this.observacaoIndex = -1;
        this.$refs.formObservacao.resetValidation();
        this.validacao.observacoes = false;
      }
    },

    visualizarReclamacao(item) {
      this.reclamacaoIndex = this.reclamacoes.indexOf(item);
      this.reclamacaoItem = Object.assign({}, item);
      this.dtEdicaoReclamacaoUS = this.reclamacaoItem.dtEdicao;
      this.reclamacaoSelecionada = {
        id: item.idReclamacao,
        nome: item.reclamacaoPadrao
      };
      this.dialogReclamacao = true;
    },
    closeReclamacao() {
      this.dialogReclamacao = false;
      setTimeout(() => {
        this.reclamacaoItem = Object.assign({}, this.defaultReclamacaoItem);
        this.reclamacaoIndex = -1;
        this.reclamacaoSelecionada = {};
        this.$refs.formReclamacao.resetValidation();
      }, 300);
    },
    saveReclamacao() {
      if (this.$refs.formReclamacao.validate()) {
        if (util.parseDateUS(this.dtEdicaoReclamacaoUS) < util.parseDateUS(this.assinatura.dtCriacao) || util.parseDateUS(this.dtEdicaoReclamacaoUS) > new Date()) {
          if (!this.reclamacaoItem.id) {
            //* Não deixa incluir data edição fora do período permitido
            this.validacao.reclamacao_dtEdicao = "Data da edição inválida";
            return;
          } else {
            this.dtEdicaoReclamacaoUS = this.reclamacaoItem.dtEdicao;
          }
        }
        this.reclamacaoItem.dtRegistro = new Date();
        this.reclamacaoItem.dtEdicao = this.dtEdicaoReclamacaoUS;
        this.reclamacaoItem.usuario = this.$store.state.usuario.nome;
        this.reclamacaoItem.idUsuario = this.$store.state.usuario.id;
        if (this.reclamacaoIndex > -1) {
          Object.assign(this.reclamacoes[this.reclamacaoIndex], this.reclamacaoItem);
          //Como não estava atualizando a reclamação ao editar, inclui um push e splice para atualizar o array
          this.reclamacoes.push({});
          this.reclamacoes.splice(this.reclamacoes.length - 1, 1);
        } else {
          this.reclamacoes.push(this.reclamacaoItem);
        }
        this.closeReclamacao();
        this.$refs.formReclamacao.resetValidation();
        this.validacao.reclamacoes = false;
      }
    },
    excluirReclamacao(item) {
      this.reclamacaoIndex = this.reclamacoes.indexOf(item);
      this.reclamacaoItem = Object.assign({}, item);
      if (this.reclamacaoItem.id == null) {
        this.reclamacoes.splice(this.reclamacaoIndex, 1);
        this.reclamacaoItem = Object.assign({}, this.defaultReclamacaoItem);
        this.reclamacaoIndex = -1;
        this.reclamacaoSelecionada = {};
        return;
      }
    },

    visualizarContato(item) {
      this.contatoIndex = this.contatos.indexOf(item);
      this.contatoItem = Object.assign({}, item);
      this.contatoItem.dtPr = this.contatoItem.dtPromessa;
      this.contatoItem.dtReg = this.contatoItem.dtRegistro;
      this.dialogContatos = true;
    },
    closeContatos() {
      this.dialogContatos = false;
    },

    visualizarCartaoClube(item) {
      this.cartaoClubeIndex = this.cartoesClube.indexOf(item);
      this.cartaoClubeItem = Object.assign({}, item);
      this.dependenteSelecionado = {
        id: item.idDependente,
        nome: item.dependente
      };
      this.produtoSelecionado = {
        id: item.idProduto,
        nome: item.produto
      };
      this.dialogCartaoClube = true;
    },
    async cancelarCartaoClube(item) {
      this.cartaoClubeIndex = this.cartoesClube.indexOf(item);
      this.cartaoClubeItem = Object.assign({}, item);
      if (this.cartaoClubeItem.id == null) {
        this.cartoesClube.splice(this.cartaoClubeIndex, 1);
        this.cartaoClubeItem = Object.assign({}, this.defaultCartaoClubeItem);
        this.cartaoClubeIndex = -1;
        this.dependenteSelecionado = {};
        this.produtoSelecionado = {};
      } else {
        var usuarioCartao = {
          idUsuarioCancelamento: this.$store.state.usuario.id
        };

        try {
          await api.put(process.env.VUE_APP_URL_WEB_API + "ass/assinatura-clube-assinante-cartao/cancelar/" + this.cartaoClubeItem.id, usuarioCartao);

          this.cartaoClubeItem.dtCancelamento = new Date();
          this.cartaoClubeItem.usuarioCancelamento = this.$store.state.usuario.nome;
          this.cartaoClubeItem.idUsuarioCancelamento = this.$store.state.usuario.id;
          Object.assign(this.cartoesClube[this.cartaoClubeIndex], this.cartaoClubeItem);
          this.cartaoClubeItem = Object.assign({}, this.defaultCartaoClubeItem);
          this.cartaoClubeIndex = -1;
          this.dependenteSelecionado = {};
          this.produtoSelecionado = {};
          this.msgCartoesClube = "Dados foram atualizados";
          this.snackCartoesClube = true;
        } catch (e) {
          this.msgCartoesClube =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.snackCartoesClube = true;
        }
      }
    },
    closeCartaoClube() {
      this.dialogCartaoClube = false;
      setTimeout(() => {
        this.cartaoClubeItem = Object.assign({}, this.defaultCartaoClubeItem);
        this.cartaoClubeIndex = -1;
        this.dependenteSelecionado = {};
        this.produtoSelecionado = {};
        this.$refs.formCartaoClube.resetValidation();
      }, 300);
    },
    saveCartaoClube() {
      if (this.$refs.formCartaoClube.validate()) {
        if (this.idProdutoHelp) {
          //* Validação específica para o HELP (Jornal ND)
          var existeHelp = 0;
          var cartoes = [];
          Array.prototype.push.apply(cartoes, this.cartoesClube);

          var dtValidade = util.parseDateUS(this.renovacaoAtual.dtFim || this.ultimaRenovacao.dtFim);
          var dtLimite = util.parseDateUS(this.renovacaoAtual.dtInicio || this.ultimaRenovacao.dtInicio);
          dtLimite.setFullYear(dtLimite.getFullYear() + 1);
          dtLimite.setDate(dtLimite.getDate() - 1);
          this.cartaoClubeItem.dtValidade = util.dateToStringUS(dtValidade < dtLimite ? dtLimite : dtValidade);

          if (this.cartaoClubeIndex > -1) {
            Object.assign(cartoes[this.cartaoClubeIndex], this.cartaoClubeItem);
          } else {
            cartoes.push(this.cartaoClubeItem);
          }
          cartoes.forEach((element) => {
            if (element.idProduto == this.idProdutoHelp && !element.dtCancelamento && util.parseDateUS(element.dtValidade) > util.parseDateUS(new Date())) {
              existeHelp++;
            }
          });
          if (existeHelp > 1) {
            this.mensagem = "Só é possível a inclusão de 1 Dependente HELP para a Assinatura";
            this.snackErro = true;
            return;
          } else if (existeHelp && !this.renovacaoAtual.produtosTipos.includes("IMPRESSO")) {
            this.mensagem = "Só é possível a inclusão de HELP para Assinatura de IMPRESSO";
            this.snackErro = true;
            return;
          }
        }

        //* Verifica duplicidade de CLUBE
        var cartoesC = [];
        Array.prototype.push.apply(cartoesC, this.cartoesClube);

        if (this.cartaoClubeIndex > -1) {
          Object.assign(cartoesC[this.cartaoClubeIndex], this.cartaoClubeItem);
        } else {
          cartoesC.push(this.cartaoClubeItem);
        }
        var existeClube = false;
        var deps = [];
        cartoesC.forEach((element) => {
          if (element.idProduto == this.idProdutoClube && !element.dtCancelamento) {
            if (deps.includes(element.idDependente)) {
              existeClube = true;
            }
            deps.push(element.idDependente);
          }
        });
        if (existeClube) {
          this.mensagem = "Dependente já foi incluído anteriormente";
          this.snackErro = true;
          return;
        }

        this.cartaoClubeItem.dtRegistro = new Date();
        this.cartaoClubeItem.usuario = this.$store.state.usuario.nome;
        this.cartaoClubeItem.idUsuario = this.$store.state.usuario.id;
        if (this.cartaoClubeIndex > -1) {
          Object.assign(this.cartoesClube[this.cartaoClubeIndex], this.cartaoClubeItem);
        } else {
          this.cartoesClube.push(this.cartaoClubeItem);
        }
        this.closeCartaoClube();
        this.cartaoClubeItem = Object.assign({}, this.defaultCartaoClubeItem);
        this.cartaoClubeIndex = -1;
        this.dependenteSelecionado = {};
        this.produtoSelecionado = {};
        this.$refs.formCartaoClube.resetValidation();
        this.validacao.cartoesClube = false;
      }
    },

    editarSuspensao(item) {
      this.suspensaoIndex = this.suspensoes.indexOf(item);
      this.suspensaoItem = Object.assign({}, item);
      this.dtInicioSuspensaoUS = this.suspensaoItem.dtInicio;
      this.dtFimSuspensaoUS = this.suspensaoItem.dtFim;

      this.suspensaoItem.editInicio = new Date(this.suspensaoItem.dtInicio) > new Date();
      this.suspensaoItem.editFim = new Date(this.suspensaoItem.dtFim) > new Date();

      this.suspensaoSelecionada = {
        id: item.idMotivoSuspensao,
        nome: item.suspensaoPadrao,
        bonificar: item.bonificar
      };

      this.dialogSuspensao = true;
    },
    closeSuspensao() {
      this.dialogSuspensao = false;
      setTimeout(() => {
        this.suspensaoItem = Object.assign({}, this.defaultSuspensaoItem);
        this.dtInicioSuspensaoUS = null;
        this.dtFimSuspensaoUS = null;
        this.suspensaoIndex = -1;
        this.suspensaoSelecionada = {};
        this.$refs.formSuspensao.resetValidation();
      }, 300);
    },
    async saveSuspensao() {
      await util.limparObjeto(this.validacao, false);
      if (this.$refs.formSuspensao.validate()) {
        if (util.parseDateUS(this.dtInicioSuspensaoUS) < this.dataInicio) {
          if (!this.suspensaoItem.id) {
            //* Não deixa incluir ou alterar data inicial para antes da data permitida
            this.validacao.suspensao_dtInicio = "Data inicial inválida";
            return;
          } else {
            this.dtInicioSuspensaoUS = this.suspensaoItem.dtInicio;
          }
        }

        var dtLimite = util.parseDateUS(this.dtFimSuspensaoUS);
        dtLimite.setDate(dtLimite.getDate() + 1); //adiciona 1 dia para permitir suspensão no dia seguinte (com dtFim=hoje)
        if (dtLimite < this.dataInicio) {
          //* Data final nunca pode ser inferior a data permitida
          this.validacao.suspensao_dtFim = "Data final inválida";
          return;
        }

        if (util.parseDateUS(this.dtFimSuspensaoUS) < util.parseDateUS(this.dtInicioSuspensaoUS)) {
          this.validacao.suspensao_dtFim = "Data de término não pode ser inferior a data de início";
          return;
        }

        if (this.suspensaoItem.dtRegistro == null) {
          this.suspensaoItem.dtRegistro = new Date();
          this.suspensaoItem.usuario = this.$store.state.usuario.nome;
          this.suspensaoItem.idUsuario = this.$store.state.usuario.id;
        }
        this.suspensaoItem.dtInicio = this.dtInicioSuspensaoUS;
        this.suspensaoItem.dtFim = this.dtFimSuspensaoUS;

        if (this.suspensaoItem.id != null) {
          //* Alteração de suspensão, chama método para alterar imediatamente
          this.alterarSuspensao();
          return;
        }

        if (this.suspensaoIndex > -1) {
          Object.assign(this.suspensoes[this.suspensaoIndex], this.suspensaoItem);
        } else {
          this.suspensoes.push(this.suspensaoItem);
        }
        this.closeSuspensao();
        this.$refs.formSuspensao.resetValidation();
        this.validacao.suspensoes = false;
      }
    },

    async alterarSuspensao() {
      var suspensaoCommit = {
        dtInicio: this.suspensaoItem.dtInicio,
        dtFim: this.suspensaoItem.dtFim
      };
      try {
        var bonificar = this.suspensaoItem.bonificar;
        await api.put(process.env.VUE_APP_URL_WEB_API + "ass/assinatura-suspensao/" + this.suspensaoItem.id, suspensaoCommit);
        Object.assign(this.suspensoes[this.suspensaoIndex], this.suspensaoItem);
        this.closeSuspensao();
        this.$refs.formSuspensao.resetValidation();
        this.msgSuspensoes = "Dados foram atualizados";
        this.snackSuspensoes = true;

        if (bonificar) {
          setTimeout(() => {
            this.$router.push("/assinaturas/assinatura/" + this.id);
            this.$router.go("/assinaturas/assinatura/" + this.id);
          }, 1000);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.msgSuspensoes = "SESSÃO EXPIRADA";
          this.snackSuspensoes = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.msgSuspensoes = "Verifique os campos indicados em vermelho neste formulário";
          this.snackSuspensoes = true;
        } else {
          this.msgSuspensoes = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackSuspensoes = true;
        }
      }
    },

    async excluirSuspensao(item) {
      await util.limparObjeto(this.validacao, false);
      this.suspensaoIndex = this.suspensoes.indexOf(item);
      this.suspensaoItem = Object.assign({}, item);

      if (this.suspensaoItem.id == null) {
        this.suspensoes.splice(this.suspensaoIndex, 1);
        this.suspensaoItem = Object.assign({}, this.defaultSuspensaoItem);
        this.dtInicioSuspensaoUS = null;
        this.dtFimSuspensaoUS = null;
        this.suspensaoIndex = -1;
        this.suspensaoSelecionada = {};
        return;
      }

      if (new Date(this.suspensaoItem.dtFim) < new Date()) {
        util.validaErros(this.validacao, [
          {
            campo: "suspensoes",
            mensagem: "Não é possível alterar suspensão já finalizada"
          }
        ]);
        this.suspensaoItem = Object.assign({}, this.defaultSuspensaoItem);
        this.dtInicioSuspensaoUS = null;
        this.dtFimSuspensaoUS = null;
        this.suspensaoIndex = -1;
        this.suspensaoSelecionada = {};
        return;
      }

      this.msgConfirm = "Tem certeza que deseja finalizar/excluir esta suspensão?";
      this.dialogConfirm = true;
    },
    async excluiSuspensao() {
      try {
        await api.delete(process.env.VUE_APP_URL_WEB_API + "ass/assinatura-suspensao/" + this.suspensaoItem.id);

        this.msgSuspensoes = "Suspensão foi excluída/finalizada";
        this.snackSuspensoes = true;

        if (this.suspensaoItem.bonificar) {
          setTimeout(() => {
            this.$router.push("/assinaturas/assinatura/" + this.id);
            this.$router.go("/assinaturas/assinatura/" + this.id);
          }, 1000);
        } else {
          this.closeSuspensao();
          this.carregarSuspensoes();
        }
      } catch (e) {
        this.msgSuspensoes =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackSuspensoes = true;
      }
    },

    visualizarRegraAjuste(item) {
      this.regraAjusteItem = Object.assign({}, item);
      this.dialogRegraAjusteView = true;
    },
    closeRegraAjuste() {
      this.dialogRegraAjusteView = false;
    },

    editarCancelamento(item) {
      this.cancelamentoIndex = this.cancelamentos.indexOf(item);
      this.cancelamentoItem = Object.assign({}, item);
      this.dtAgCancelamentoUS = this.cancelamentoItem.dtAgendamento;

      this.cancelamentoSelecionado = {
        id: item.idMotivoCancelamento,
        nome: item.motivoCancelamentoPadrao,
        cancelarParcelas: item.cancelarParcelas,
        imediato: item.imediato
      };
      this.dialogCancelamento = true;
    },
    closeCancelamento() {
      this.dialogCancelamento = false;
      setTimeout(() => {
        this.cancelamentoItem = Object.assign({}, this.defaultCancelamentoItem);
        this.dtAgCancelamentoUS = null;
        this.cancelamentoIndex = -1;
        this.cancelamentoSelecionado = {};
        this.$refs.formCancelamento.resetValidation();
      }, 300);
    },
    saveCancelamento() {
      if (this.$refs.formCancelamento.validate()) {
        this.cancelamentoItem.dtRegistro = new Date();
        this.cancelamentoItem.usuarioCancelamento = this.$store.state.usuario.nome;
        this.cancelamentoItem.idUsuarioCancelamento = this.$store.state.usuario.id;
        this.cancelamentoItem.dtAgendamento = this.dtAgCancelamentoUS;

        if (this.cancelamentoIndex > -1) {
          Object.assign(this.cancelamentos[this.cancelamentoIndex], this.cancelamentoItem);
        } else {
          this.cancelamentos.push(this.cancelamentoItem);
        }
        this.closeCancelamento();
        this.cancelamentoItem = Object.assign({}, this.defaultCancelamentoItem);
        this.dtAgCancelamentoUS = null;
        this.cancelamentoIndex = -1;
        this.cancelamentoSelecionado = {};
        this.cancelamentoAtivo = true;
        this.$refs.formCancelamento.resetValidation();
        this.validacao.cancelamentos = false;
      }
    },

    reverterCancelamento() {
      if (this.cancelamentos.length > 0) {
        var reverter = true;
        this.cancelamentos.forEach((item) => {
          if (reverter) {
            if (item.id && item.dtReversao == null && item.dtRecuperacao == null) {
              this.cancelamentoIndex = this.cancelamentos.indexOf(item);
              this.cancelamentoItem = Object.assign({}, item);
              this.cancelamentoItem.motivoReversao = "";
              this.dialogReverterCancelamento = true;
              reverter = false;
            }
          }
        });
        if (reverter) {
          this.carregarCancelamentos();
        }
      }
    },
    closeReverterCancelamento() {
      this.dialogReverterCancelamento = false;
      setTimeout(() => {
        this.cancelamentoItem = Object.assign({}, this.defaultCancelamentoItem);
        this.dtAgCancelamentoUS = null;
        this.cancelamentoIndex = -1;
        this.$refs.formReverterCancelamento.resetValidation();
      }, 300);
    },
    async saveReverterCancelamento() {
      if (this.$refs.formReverterCancelamento.validate()) {
        var reversaoCommit = {
          idUsuarioReversao: this.$store.state.usuario.id,
          motivoReversao: this.cancelamentoItem.motivoReversao
        };
        try {
          await api.put(process.env.VUE_APP_URL_WEB_API + "ass/assinatura/reverteCancelamento/" + this.id, reversaoCommit);

          this.closeReverterCancelamento();
          this.cancelamentoItem = Object.assign({}, this.defaultCancelamentoItem);
          this.dtAgCancelamentoUS = null;
          this.cancelamentoIndex = -1;
          this.$refs.formReverterCancelamento.resetValidation();
          this.carregarCancelamentos();
          this.carregarParcelas();
          this.carregarCarteira();

          var dtFim = this.renovacaoAtual.id ? this.renovacaoAtual.dtFim : this.ultimaRenovacao.dtFim;
          this.assinatura.status = util.parseDateUS(dtFim) > new Date() ? "CORRENTE" : "EXPIRADA";

          this.msgCancelamentos = "Cancelamento foi revertido";
          this.snackCancelamentos = true;
        } catch (e) {
          this.msgCancelamentos =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.snackCancelamentos = true;
        }
      }
    },

    editarParcela(item) {
      this.idParcela = item.id + "";
      this.dialogParcela = true;
    },
    closeParcela() {
      this.dialogParcela = false;
      this.idParcela = "";
    },
    visualizarParcelas() {
      window.open("/financeiro/parcelas/receber?idAssinatura=" + this.assinatura.id, "_blank");
    },

    closeAjuste() {
      this.dialogRegraAjuste = false;
      setTimeout(() => {
        this.$refs.formRegraAjuste.resetValidation();
      }, 300);
    },
    async saveAjuste() {
      //TODO: não deixar selecionar para aplicar se a parcela está no banco
      await util.limparObjeto(this.validacao, false);
      if (this.$refs.formRegraAjuste.validate() && this.contadorAjuste == this.regraAjusteAplicar.regraAjusteItens) {
        this.$refs.formRegraAjuste.resetValidation();

        var ajusteCommit = {
          idAssinatura: this.id,
          idRegraAjuste: this.regraAjusteAplicar.idRegraAjuste,
          idUsuario: this.$store.state.usuario.id,
          motivo: this.regraAjusteAplicar.motivo
        };

        var parcelasCommit = [];
        this.parcelasAjustePlano.map((element) => {
          if (element.selected) {
            parcelasCommit.push(element);
          }
        });
        ajusteCommit.parcelas = parcelasCommit;

        try {
          await api.post(process.env.VUE_APP_URL_WEB_API + "ass/regra-ajuste/aplicar?idSite=" + this.idSitePrincipal, ajusteCommit);

          this.assinatura.ajustada = true;
          this.carregarRegrasAjuste();
          this.carregarParcelas();
          this.closeAjuste();
        } catch (e) {
          if (e.toString().includes("status code 400")) {
            this.msgRegraAjuste = "SESSÃO EXPIRADA";
            this.snackRegraAjuste = true;
          } else if (e.response && e.response.data) {
            util.validaErros(this.validacao, e.response.data);
            this.msgRegraAjuste = "Verifique os campos indicados em vermelho neste formulário";
            this.snackRegraAjuste = true;
          } else {
            this.msgRegraAjuste = "Ocorreu um erro indeterminado, verifique sua conexão";
            this.snackRegraAjuste = true;
          }
        }
      } else {
        this.msgRegraAjuste = "Verifique os campos indicados em vermelho neste formulário";
        this.snackRegraAjuste = true;
      }
    },

    async validaAjuste(item) {
      try {
        if (item.ajustada) {
          return;
        }
        item.selected = !item.selected;
        if (item.selected) {
          this.contadorAjuste++;
        } else {
          this.contadorAjuste--;
        }
        if (this.contadorAjuste == this.regraAjusteAplicar.regraAjusteItens) {
          // Carrega os itens da regra de ajuste para a forma de pagamento selecionada
          const response = await api.get(
            process.env.VUE_APP_URL_WEB_API +
              "ass/regra-ajuste-item/regraAjuste/" +
              this.regraAjusteAplicar.idRegraAjuste +
              "?ativos=1&idFormaPgto=" +
              this.regraAjusteAplicar.idFormaPgto
          );
          const itensRegra = response.data;
          var x = 1;
          this.parcelasAjustePlano.map(async (element) => {
            if (element.selected) {
              if (itensRegra.length == 0) {
                this.regraDisponivel = false;
              }
              //* Busca o valor do item da regra de ajuste para aplicar ao valor da parcela
              itensRegra.forEach((itemRegra) => {
                if (x >= itemRegra.de && x <= itemRegra.ate) {
                  element.idItemRegra = itemRegra.id;
                  if (this.regraAjusteAplicar.regraAjusteTipo == "%") {
                    element.valorNovo = (element.valor + element.descAcr) * (1 + itemRegra.valor / 100);
                    element.valorNovo = element.valorNovo.toFixed(2);
                  } else if (this.regraAjusteAplicar.regraAjusteTipo == "$") {
                    element.valorNovo = element.valor + element.descAcr + itemRegra.valor;
                    element.valorNovo = element.valorNovo.toFixed(2);
                  } else {
                    element.valorNovo = itemRegra.valor;
                    element.valorNovo = element.valorNovo.toFixed(2);
                  }
                  if (element.valorNovo < 0) {
                    element.valorNovo = 0;
                  }
                }
                element.vlrNovo = util.formatCurrency(element.valorNovo);
              });
              x++;
            }
          });
        } else {
          this.parcelasAjustePlano.forEach((element) => {
            element.idItemRegra = null;
            element.valorNovo = 0;
            element.vlrNovo = "0,00";
          });
        }
        this.snackRefresh = true;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao validar a regra de ajuste";
        this.snackErro = true;
      }
    },

    confirmado() {
      this.dialogConfirm = false;
      this.excluiSuspensao();
    },
    naoConfirmado() {
      this.dialogConfirm = false;
      this.suspensaoItem = Object.assign({}, this.defaultSuspensaoItem);
      this.dtInicioSuspensaoUS = null;
      this.dtFimSuspensaoUS = null;
      this.suspensaoIndex = -1;
      this.$refs.formSuspensao.resetValidation();
    },

    async alterarClienteSite() {
      if (this.$refs.formClienteSite.validate()) {
        try {
          await api.put(process.env.VUE_APP_URL_WEB_API + "ass/assinatura/clienteSite/" + this.id + "/" + this.novoCliente);
          this.mensagem = "Cliente alterado com sucesso";

          this.snackbar = true;
          setTimeout(() => {
            //* É melhor recarregar toda a tela, pois há muitos parâmetros a serem ajustados aqui
            this.$router.push("/assinaturas/assinatura/" + this.id);
            this.$router.go("/assinaturas/assinatura/" + this.id);
          }, 2000);
        } catch (e) {
          this.validacao.clienteSite =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
        }
      }
    },

    editarCliente(idCli) {
      if (this.direitoAssinante) {
        window.open("/assinaturas/assinante/" + idCli, "_blank");
      }
    },

    async calcularDtInicio() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/config-fechamento-roteiro/dtInicio?idSite=" + this.idSitePrincipal);
        if (response.data) {
          this.dataInicio = util.parseDateUS(response.data.dtInicio);
        } else {
          throw "";
        }
      } catch (e) {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a data de início para suspensões/cancelamentos";
        this.snackErro = true;
      }
    },

    async editarContato() {
      const liberado = await this.clienteLiberado(this.ultimaRenovacao.idCliente);
      if (!liberado) {
        this.mensagem = "Este assinante está sendo atendido por outro usuário.";
        this.snackErro = true;
        return;
      }
      await this.bloquearCliente();
      this.idAssinatura = this.id + "";
      this.idRenovacao = this.renovacaoAtual.id || this.ultimaRenovacao.id;
      this.idCliente = this.ultimaRenovacao.idCliente + "";
      this.cliente = this.ultimaRenovacao.cliente;
      this.tipo = "1"; //recuperação
      this.dialogContato = true;
    },
    closeContato() {
      this.dialogContato = false;
      this.idAssinatura = "";
      this.idRenovacao = "";
    },
    async clienteLiberado(idCliente) {
      const retorno = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente/atendimento/" + idCliente + "?idUsuario=" + this.$store.state.usuario.id);
      return retorno.data.length == 1;
    },
    async bloquearCliente() {
      var clienteItem = { idUsuarioCall: this.$store.state.usuario.id };
      try {
        await api.put(process.env.VUE_APP_URL_WEB_API + "adm/cliente/call/" + this.ultimaRenovacao.idCliente, clienteItem);
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },

    formatDateUS(date) {
      if (date && date.length == 10) {
        return util.formatDateUS(date);
      }
    },
    formatDate(date) {
      return util.formatDate(date);
    },
    formatDateTime(datetime) {
      return util.formatDateTime(datetime);
    },
    formatCurrency(valor) {
      return util.formatCurrency(valor);
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    valida_data_futura(date) {
      var retorno = null;
      if (date && this.cancelamentoItem.id == null) {
        var data = util.parseDate(date);
        // if (this.cancelamentoSelecionado.imediato) {
        //se período já começou, então hoje já houve entrega/acesso (o agendamento precisa ser para amanhã) - se período ainda não começou, pode ser alterado (ou excluído se ainda não foi ao Protheus)
        //   data.setDate(data.getDate() + 1);
        // }
        if (data && data < this.dataInicio) {
          retorno = false;
        } else {
          retorno = true;
        }
      } else {
        retorno = true;
      }
      return retorno;
    },
    valida_data_futura_susp(date, susp) {
      var retorno = null;
      if (date) {
        var dtLimite = util.parseDate(date);
        if (!dtLimite) {
          retorno = true;
        } else {
          if (susp) {
            dtLimite.setDate(dtLimite.getDate() + 1); //adiciona 1 dia para permitir suspensão no dia seguinte (com dtFim=hoje)
          }
          if (dtLimite < this.dataInicio) {
            retorno = false;
          } else {
            retorno = true;
          }
        }
      } else {
        retorno = true;
      }
      return retorno;
    },
    valida_data_passada(date) {
      var retorno = null;
      if (date) {
        var data = util.parseDateTime(date + " 00:00:00");
        var dtLimite = util.parseDateUS(this.assinatura.dtCriacao);
        if (data < dtLimite || data > new Date()) {
          retorno = false;
        } else {
          retorno = true;
        }
      } else {
        retorno = true;
      }
      return retorno;
    },
    valida_identificacao(text) {
      if (this.$store.state.usuario.site_pais == "BR") {
        if (text.length != 11 && text.length != 14) {
          return "Identificação deve ter 11 ou 14 dígitos";
        } else {
          if (text.length == 11) {
            return util.valida_cpf(text) ? true : "CPF inválido";
          } else {
            return util.valida_cnpj(text) ? true : "CNPJ inválido";
          }
        }
      } else {
        if (text.length != 9) {
          return "Identificação deve ter 9 dígitos";
        } else {
          return util.valida_nif(text) ? true : "NIF inválido";
        }
      }
    }
  },

  async created() {
    this.isUpdating = false;
    this.bloqueiaBotoes = true;
    this.id = this.$route.params.id ? this.$route.params.id : "";

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach((element) => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_SUSPENSÕES_CRIAR") {
        this.direitoCriarSuspensao = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_SUSPENSÕES_ALTERAR") {
        this.direitoAlterarSuspensao = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINATURAS_CANCELAR") {
        this.direitoCancelar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINATURAS_REVERTER_CANCELAMENTO") {
        this.direitoReverter = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINATURAS_AJUSTAR_PARCELAS") {
        this.direitoAjustar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINANTES_EDITAR") {
        this.direitoAssinante = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_ACESSO") {
        this.direitoParcelas = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINATURAS_PADRÃO_PRÓXIMAS_PARCELAS") {
        this.direitoPadraoProximasParcelas = true;
      }
      //Descontinuado: recurso havia sido desenvolvido para o Diário Popular
      // if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINATURAS_CORRIGIR_CLIENTE_SITE") {
      //   this.direitoClienteSite = true;
      // }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_RECUPERAÇÃO_ACESSO") {
        this.direitoRecuperacao = true;
      }
    });
    if (this.$store.state.usuario.site_protheus) {
      this.headerRenovacoes.push({ text: "Protheus", value: "idProtheus" });
    }

    //* Se é edição, carrega a assinatura
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_ASSINATURAS_EDITAR"]);
    //* Carrega a assinatura - somente se for do site do usuário logado
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

    await this.carregarOrigensVenda();

    const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/assinatura/" + this.id + "?idSite=" + this.idSitePrincipal);

    if (!response.data || !response.data.ultimaRenovacao) {
      this.$router.push("/assinaturas/assinaturas");
      return;
    }
    this.assinatura = response.data;
    this.assinatura.ajustada = false;

    //* Carrega os itens dos selects
    await this.carregarReclamacoesPadrao();
    await this.carregarSuspensoesPadrao();
    await this.carregarMotivosCancelamentoPadrao();
    await this.carregarProdutosClube();

    try {
      //* Carrega os subitens da assinatura
      const responseRenovacoes = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/renovacao/assinatura/" + this.id);
      if (responseRenovacoes.data.length) {
        this.renovacoes = responseRenovacoes.data;
        this.ultimaRenovacao = {};

        this.renovacoes.forEach((element) => {
          //Descontinuado: recurso havia sido desenvolvido para o Diário Popular
          // if (this.direitoClienteSite && element.identificacao == this.$store.state.usuario.site_identificacao) {
          //   this.clienteSite = true;
          // }
          element.tt = util.formatCurrency(element.total);
          if (!this.ultimaRenovacao.id && element.situacao) {
            this.ultimaRenovacao = element;
          }
        });

        this.ultimaRenovacao.tt = util.formatCurrency(this.ultimaRenovacao.total);
        var hoje = util.parseDateUS(new Date());
        this.renovacoes.map((element) => {
          if (element.situacao && util.parseDateUS(element.dtInicio) <= hoje && hoje <= util.parseDateUS(element.dtFim)) {
            this.renovacaoAtual = element;

            if (this.renovacaoAtual.produtosTipos.includes("IMPRESSO")) {
              this.calcularDtInicio();
            }

            this.renovacaoAtual.tt = util.formatCurrency(this.renovacaoAtual.total);
          }
        });
      }

      //* Carrega os itens dos selects que dependem da renovação
      await this.carregarDependentesCartaoCliente();
      await this.carregarFormasPgtoPlano();
      await this.carregarRegrasAjustePadraoPlano();

      await this.carregarObservacoes();
      await this.carregarReclamacoes();
      await this.carregarContatos();
      await this.carregarCartoesClube();
      await this.carregarSuspensoes();
      await this.carregarCarteira();
      await this.carregarRegrasAjuste();
      await this.carregarCancelamentos();
      await this.carregarParcelas();
    } catch {
      this.isUpdating = true;
      this.mensagem = "Ocorreu um erro ao carregar os dados da assinatura";
      this.snackErro = true;
    }

    setTimeout(() => {
      this.bloqueiaBotoes = false;
    }, 1000);
  }
};
</script>
