<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <!-- Barra de Título -->
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">format_list_numbered</v-icon>
      <v-toolbar-title v-if="!informacoesOk" style="color: white" class="font-weight-light">{{ informacoesOkTexto }}</v-toolbar-title>
      <v-toolbar-title v-if="informacoesOk" style="color: white" class="font-weight-light"> {{ nomeEditoria }} : {{ nomeArtigo }} </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-toolbar-title
        v-if="retornoMedeTexto && 'alturaTotal' in retornoMedeTexto && 'totalCaracteres' in retornoMedeTexto"
        style="color: white"
        class="font-weight-light d-flex flex-column dados-medir"
      >
        <div v-if="'alturaTotal' in retornoMedeTexto">Altura Total: {{ Number(retornoMedeTexto.alturaTotal).toFixed(2) }}</div>
        <div v-if="'totalCaracteres' in retornoMedeTexto">Caracteres: {{ retornoMedeTexto.totalCaracteres }}</div>
      </v-toolbar-title>
    </v-toolbar>

    <v-form v-if="informacoesOk" ref="formElemento" class="mb-5" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <!-- Barra de Progresso -->
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <!-- Seleção de Site (Restrito a Sites em Grupo) -->
          <v-flex xs12 md12 v-if="siteGrupo.length > 0">
            <v-autocomplete
              v-model="editoria.idSite"
              :disabled="isUpdating"
              :items="siteGrupo"
              :filter="filtrarObjetos"
              label="Restrita ao Site"
              item-text="nome"
              item-value="id"
              :rules="[validacao.idSite == false || validacao.idSite]"
              autocomplete="disabled"
              :name="Math.random()"
              no-data-text
              clearable
              @change="validacao.idSite = false"
            ></v-autocomplete>
          </v-flex>
          <!-- Campo de Título do Artigo -->
          <v-flex xs12> </v-flex>

          <!-- Editor de Texto (Quill) -->
          <v-flex xs12>
            <div class="box-editor-quill" ref="quillContainer">
              <quill-editor :style="{ fontSize: this.editorFontSize + 'px' }" v-model="content" ref="myQuillEditor" :options="editorOptions"></quill-editor>
            </div>
          </v-flex>

          <!-- Mostra Modal NOME -->
          <v-dialog v-model="abreModalTitle" max-width="800px" @keydown.enter="salvarTitulo">
            <v-card>
              <v-toolbar color="#333333">
                <v-toolbar-title style="color: white" class="font-weight-light">Nome da Matéria</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="abreModalTitle = false">
                  <v-icon color="white">cancel</v-icon>
                </v-btn>
              </v-toolbar>
              <div class="px-6 mt-4">
                <v-card-text>
                  <v-alert v-if="tituloErro" type="error" prominent icon="mdi-alert-circle-outline">
                    {{ mensagemAviso }}
                  </v-alert>
                  <v-form @submit.prevent>
                    <v-text-field
                      autofocus
                      v-model="nomeArtigo"
                      label="Nome"
                      maxlength="300"
                      hint="Obrigatório"
                      :rules="[validacao.nome == false || validacao.nome, (value) => !!value || 'Obrigatório']"
                      color="blue-grey lighten-2"
                      autocomplete="disabled"
                      :name="Math.random()"
                      @change="
                        nomeArtigo = pLetra(nomeArtigo);
                        validacao.nome = false;
                      "
                    ></v-text-field>
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="salvarTitulo">Salvar</v-btn>
                </v-card-actions>
              </div>
            </v-card>
          </v-dialog>
          <!-- Mostra Modal NOME -->

          <!-- Mostra Modal Imagem -->
          <!-- Modal com o iframe -->
          <v-dialog v-model="abreModalImagem" persistent max-width="1000px">
            <v-card>
              <v-toolbar color="#333333">
                <v-icon color="white" class="mr-2">mdi-file-image</v-icon>
                <v-toolbar-title style="color: white" class="font-weight-light">Incluir Imagem</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="abreModalImagem = false">
                  <v-icon color="white">cancel</v-icon>
                </v-btn>
              </v-toolbar>

              <v-card-text>
                <!-- Iframe carregado dentro do modal -->
                <iframe :src="iframeSrc" width="100%" height="600px" frameborder="0" title="iframe-modal"></iframe>
              </v-card-text>
            </v-card>
          </v-dialog>

          <!-- Mostra Modal Imagem -->

          <!-- Modal de Inclusão de Estilo -->
          <v-dialog v-model="mostrarModalEstilos" max-width="800px">
            <v-card>
              <v-toolbar color="#333333">
                <v-icon color="white" class="mr-2">mdi-spellcheck</v-icon>
                <v-toolbar-title style="color: white" class="font-weight-light">Incluir Estilo</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="mostrarModalEstilos = false">
                  <v-icon color="white">cancel</v-icon>
                </v-btn>
              </v-toolbar>
              <div class="px-6 mt-4">
                <v-card-text>
                  <v-form>
                    <!-- Seleção de Grupo de Estilos -->
                    <v-autocomplete
                      v-model="selectedGrupoEstilos"
                      :items="registroGruposEstilos"
                      item-text="nome"
                      :item-value="(grupo) => ({ id: grupo.id, nome: grupo.nome })"
                      label="Grupos Estilos"
                      no-data-text="Nenhum grupo de estilo disponível"
                      @change="fnRecebeGrupoEstilos"
                    ></v-autocomplete>
                  </v-form>
                </v-card-text>

                <v-card-text>
                  <v-form>
                    <!-- Seleção de Estilo -->
                    <v-autocomplete
                      v-model="selectedEstilo"
                      :items="registroEstilos"
                      item-text="nome"
                      :item-value="(estilo) => ({ id: estilo.id, nome: estilo.nome })"
                      label="Estilos"
                      no-data-text="Escolha primeiro o grupo de estilos"
                      @change="fnCriaEstilo"
                    ></v-autocomplete>
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="fnAplicaEstiloFechaModal">Inserir</v-btn>
                </v-card-actions>
              </div>
            </v-card>
          </v-dialog>

          <!-- MODAL AVISOS -->
          <v-dialog v-model="dialogAviso" persistent max-width="500px">
            <v-card>
              <v-card-title class="headline">Aviso</v-card-title>
              <v-card-text>
                {{ mensagemAviso }}
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogAviso = false">OK</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- MODAL AVISOS -->

          <!-- MODAL SALVAR SAIR -->
          <v-dialog v-model="modalSalvarSair" max-width="500px">
            <v-card>
              <v-card-title>Alterações não salvas</v-card-title>
              <v-card-text> Você possui alterações não salvas. Deseja salvá-las antes de sair? </v-card-text>
              <v-card-actions>
                <v-btn color="primary" @click="salvar">Salvar</v-btn>
                <v-btn color="error" @click="fecharSemSalvar">Sair</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- MODAL SALVAR SAIR -->

          <!-- Mensagem de Erro de Validação -->
          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">{{ validacao.mensagemErro }}</v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <!-- Snackbars para Mensagens -->
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>

<script>
import util from "../../util.js";
import { api } from "@/services/api";
import { mapMutations } from "vuex";

// Importações para o Quill Editor
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.snow.css";

export default {
  components: {
    quillEditor
  },
  data() {
    return {
      id: "",
      idEditoria: "",
      idConteudo: "",
      nomeEditoria: "",

      editoria: {
        id: "",
        idSite: null,
        idSuperior: null,
        ordem: 0,
        aliasInternet: "",
        tempoLimpeza: 0,
        tipo: 0,
        situacao: 1
      },
      validacao: {
        id: false,
        idSite: false,
        idSuperior: false,
        ordem: false,
        nome: false,
        aliasInternet: false,
        tempoLimpeza: false,
        tipo: false,
        situacao: false,
        mensagemErro: false,
        urlImgInsert: false
      },
      hierarquia: "",
      siteGrupo: [],
      tipos: [
        { id: 0, nome: "Edição" },
        { id: 1, nome: "Internet" },
        { id: 2, nome: "Lixeira" },
        { id: 3, nome: "Produção" }
      ],
      idSitePrincipal: null,
      elementoValid: true,
      mensagem: "",
      snackbar: false,
      snackErro: false,
      isUpdating: false,

      selectedEditoria: null,
      nomeEditoriaSelecionada: "",
      editorFontSize: 16, // Tamanho de fonte inicial do editor

      conteudoEscrito: "",
      conteudoEscritoRft: "",
      conteudoEscritoRftConvertido: "",

      //ARQUIVO DIGITAL IMAGENS
      urlsImgArqmidia: [], // Array para armazenar URLs das imagens
      iframeSrc: "", // URL do iframe
      //ARQUIVO DIGITAL IMAGENS

      nomeArtigo: "",
      modalSalvarSair: false,
      // Dados para envio do novo artigo
      newArtigo: {
        idMateria: "0",
        idEditoria: "",
        //  idUsuario: "110",
        idUsuario: this.$store.state.usuario.id,
        nome: "",
        altura: "0",
        conteudo: "",
        idMateriaAnterior: "0",
        dtCriacao: "",
        corrigida: "0",
        autoSave: "0",
        renomearMateria: "0"
      },

      cursorIndex: "",
      informacoesOk: true,
      informacoesOkTexto: "",

      dialogAviso: false,
      mensagemAviso: "",
      tituloErro: false,
      // Dados para o Modal de Incluir Estilo
      mostrarModalEstilos: false,
      selectedGrupoEstilos: null,
      registroGruposEstilos: [],
      selectedEstilo: null,
      idSelectGrupo: "",
      nomeSelectGrupo: "",
      registroEstilos: [],

      actionArtigo: "",
      // Dados para controle do cursor no Quill Editor
      cursorLine: null,
      textToInsert: "",
      contentAlterado: "",
      content: " ",
      salvarFechar: false,

      blockedKeys: ["<", ">"], // Keys to block
      // Opções do Quill Editor

      operacaoSalvar: "0", // operacao == 0 savar / operacao == 1 autoSave / operacao == 2 Altera nome / Salvar primeiro nome 3

      //Temporizador
      timerId: null, // ID do temporizador
      lastActivity: null, // Timestamp da última atividade
      inatividadeTimeout: 10000, // Tempo limite de inatividade em milissegundos (10 segundos)

      abreModalImagem: false,
      abreModalTitle: false,

      //INCLUIR GRUPO DE MODELO
      idGrupoModelo: "",
      retornoGrupoModelos: [],
      //INCLUIR GRUPO DE MODELO

      // MEDE TEXTO
      retornoMedeTexto: [],

      editorOptions: {
        theme: "snow",
        placeholder: ". . .",
        modules: {
          toolbar: {
            container: [
              /*   ["bold", "italic", "underline", "strike"], */
              ["bold", "italic"],
              [
                { imgButton: "Imagem" },
                { insertStyle: "Estilo" },
                { titleButtonAumentaTexto: "+" },
                { titleButtonDiminuiTexto: "-" },
                { titleAltera: "title" },
                { infoButton: "Info" },
                { anotherButton: "Salvar" },
                { fecharButton: "Fechar" }
              ]
            ],
            handlers: {
              imgButton: () => {
                // console.log("Insert Imagem");
                this.abrirModalImagem();
              },
              insertStyle: () => {
                // console.log("Insert Style button clicked");
                this.mostrarModalSelecionaEstilo();
              },
              titleButtonAumentaTexto: () => {
                // console.log("Aumenta Texto");
                if (this.editorFontSize < 32) {
                  this.editorFontSize += 2;
                  this.updateEditorFontSize();
                }
              },
              titleButtonDiminuiTexto: () => {
                // console.log("Diminui Texto");
                if (this.editorFontSize > 10) {
                  this.editorFontSize -= 2;
                  this.updateEditorFontSize();
                }
              },
              titleAltera: () => {
                // console.log("Title");
                this.abreModalTitle = true;
              },
              anotherButton: () => {
                // console.log("Salvar Button clicked");
                this.operacaoSalvar = "0";
                this.salvar();
              },
              infoButton: () => {
                // console.log("Busca informações");
                this.fnBuscaInfo();
              },
              fecharButton: () => {
                // console.log("Fechar Button clicked");
                this.tentarSair();
              }
            }
          }
        },
        formats: ["font", "size", "bold", "italic", "underline", "strike", "image"]
      }
    };
  },

  beforeMount() {
    this.OCULTAR_MENU();
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO", "OCULTAR_MENU"]),

    addTooltips() {
      const toolbar = this.$refs.quillContainer.querySelector(".ql-toolbar");
      if (!toolbar) return;
      const buttons = toolbar.querySelectorAll("button");
      buttons.forEach((button) => {
        if (button.classList.contains("ql-bold")) {
          button.setAttribute("title", "Bold");
        }
        if (button.classList.contains("ql-italic")) {
          button.setAttribute("title", "Italic");
        }
        if (button.classList.contains("ql-underline")) {
          button.setAttribute("title", "Underline");
        }
        if (button.classList.contains("ql-strike")) {
          button.setAttribute("title", "Strike");
        }
        if (button.classList.contains("ql-imgButton")) {
          button.setAttribute("title", "Inserir Imagens");
        }
        if (button.classList.contains("ql-insertStyle")) {
          button.setAttribute("title", "Estilos Texto");
        }
        if (button.classList.contains("ql-titleButtonAumentaTexto")) {
          button.setAttribute("title", "Aumantar fonte");
        }
        if (button.classList.contains("ql-titleButtonDiminuiTexto")) {
          button.setAttribute("title", "Diminuir fonte");
        }
        if (button.classList.contains("ql-titleAltera")) {
          button.setAttribute("title", "Altera Título");
        }
        if (button.classList.contains("ql-anotherButton")) {
          button.setAttribute("title", "Salvar");
        }
        if (button.classList.contains("ql-info")) {
          button.setAttribute("title", "Informações");
        }
        if (button.classList.contains("ql-fecharButton")) {
          button.setAttribute("title", "Fechar");
        }
      });
    },

    //Focus Editor
    focusEditor() {
      const quillEditor = this.$refs.myQuillEditor.quill;
      if (quillEditor) {
        quillEditor.focus(); // Foca no editor
        // console.log("Cursor posicionado no editor.");
        // } else {
        //   console.error("Editor Quill não está disponível.");
      }
    },

    salvarTitulo() {
      if (this.id == "") {
        this.operacaoSalvar = "3";
        // console.log("@@@@@@ !!!!!!!! id ARTIGO NÂO SALVO OPERAÇÂO SALVAR 333333333333333333333333333333333333");
      } else {
        this.operacaoSalvar = "0";
        // console.log("@@@@@@ !!!!!!!! id ARTIGO jÀ SALVO OPERAÇÂO SALVAR 000000000000000000000000000000000000");
      }
      this.salvar();
    },

    // Salva o artigo (novo ou edição)
    async salvar() {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      this.isUpdating = true;
      this.fnDtCriacao();

      this.convertHtmlToBase64();

      if (this.nomeArtigo == "") {
        this.abrirModalTitle();
        this.isUpdating = false;
        return;
      } else {
        this.abreModalTitle = false;
        this.tituloErro = false;
      }

      //VERIFICA <> no TEXTO
      // console.log("TEXTO:- " + this.newArtigo.conteudo);
      const resultadoVerificacao = this.verificarCaracteres();

      if (!resultadoVerificacao.resultado) {
        // Mostra a mensagem de aviso se houver algo errado
        this.mensagemAviso = resultadoVerificacao.mensagemAviso;
        this.dialogAviso = true;
        return;
      } else {
      }
      //VERIFICA <> no TEXTO

      try {
        var retorno = "";
        if (this.id) {
          this.fnVerificaAlteraNome(); // Função verifica se nome mudou

          // Edição de artigo existente
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "edt/editorial/salvaMateria?idSite=" + this.idSitePrincipal, this.newArtigo, {
            headers: {
              "Content-Type": "application/json; charset=utf-8"
            }
          });

          if (this.newArtigo.autoSave == "1") {
            this.newArtigo.autoSave = "0";
          } //TEMPORIZADOR

          // retorna ERRO se o título já existe
          if (retorno.data.erro == true) {
            // console.log(retorno.data);
            this.mensagemAviso = "Houve algum problema ao tentar atualizar a matéria.";
            this.dialogAviso = true;
            return;
          } else {
            if (this.contentAlterado != this.content) {
              this.salvar();
            }
            this.contentAlterado = this.content;
          }
        } else {
          this.codificarNome(); // Pegar o valor do nome do input

          // Criação de novo artigo
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "edt/editorial/salvaMateria?idSite=" + this.idSitePrincipal, this.newArtigo, {
            headers: {
              "Content-Type": "application/json; charset=utf-8"
            }
          });

          // console.log(retorno.data);
          // console.log(retorno.data.erro);
          // console.log(retorno.data.descricao);

          // retorna ERRO se o título já existe
          if (retorno.data.erro == true) {
            this.mensagemAviso = retorno.data.descricao;
            this.tituloErro = true;
            this.abrirModalTitle();
            return;
          }

          this.id = retorno.data.idMateria;
          this.idConteudo = "0";

          this.$router.replace("/editorial/artigo/" + this.idEditoria + "/" + retorno.data.idMateria + "/null");

          this.fnCarregaArtigoEdit(retorno.data.idMateria);
        }
        if (this.salvarFechar == true) {
          window.close();
        }
        this.checkAlteracao = false; //Verifica se houve alteração no editor após o último salvar

        // console.log(this.operacaoSalvar);
        // console.log("this.operacaoSalvar");
        if (this.operacaoSalvar == "0") {
          this.mensagem = "Dados foram atualizados";
          this.snackbar = true;
        } else if (this.operacaoSalvar == "2") {
          this.mensagem = "Título atualizado com sucesso!";
          this.snackbar = true;
        } else if (this.operacaoSalvar == "3") {
          this.operacaoSalvar = "0";
          this.mensagem = "Título salvo com sucesso!";
          this.snackbar = true;
          this.focusEditor(); // Faz o foco no editor após a função
        }
      } catch (e) {
        // Tratamento de erros
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },

    // Filtra objetos em um autocomplete
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },

    // Formata a primeira letra para maiúscula
    pLetra(text) {
      return util.pLetra(text);
    },

    abrirModalTitle() {
      this.abreModalTitle = true;
    },

    fnInsertImagem() {
      const imagensString = document.cookie
        .split("; ")
        .find((row) => row.startsWith("imagens_arqmidia="))
        ?.split("=")[1];

      if (imagensString) {
        // console.log("String armazenada no cookie:", imagensString);
        this.urlsImgArqmidia = imagensString.split(",").filter((url) => url.trim() !== "");
        // console.log("Imagens carregadas:", this.urlsImgArqmidia);

        const quillEditor = this.$refs.myQuillEditor.quill;
        // Insere uma quebra de linha antes das imagens
        let insertIndex = quillEditor.getLength();

        // Constrói uma única string com todas as imagens em sequência
        const allImagesHtml = `\n ${this.urlsImgArqmidia.map((url) => `<img class="img-artigo" src="${encodeURI(url.trim())}" alt="Imagem inserida">`).join(" ")} \n`; // Adiciona um espaço entre as imagens para separá-las e quebra de linha antes e depois

        // console.log("Inserindo imagens em sequência:", allImagesHtml);
        // Insere todas as imagens no final do editor
        quillEditor.clipboard.dangerouslyPasteHTML(insertIndex, allImagesHtml);

        // Define o cursor após todas as imagens
        quillEditor.setSelection(quillEditor.getLength(), 0);

        document.cookie = "imagens_arqmidia=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; Secure; SameSite=Strict;";

        // console.log(`Imagens inseridas com sucesso no final do editor`);
        this.abreModalImagem = false;
        this.urlsImgArqmidia = [];
        // } else {
        //   console.log("Nenhuma imagem encontrada no sessionStorage");
      }
    },

    fnVerificaImagensCookies() {
      const interval = setInterval(() => {
        if (!this.abreModalImagem) {
          clearInterval(interval);
          // console.log("Modal fechado. Parando a verificação do cookie.");
          return;
        }

        const imagensString = document.cookie
          .split("; ")
          .find((row) => row.startsWith("imagens_arqmidia="))
          ?.split("=")[1];

        if (imagensString) {
          // console.log("Cookie encontrado:", imagensString);
          this.urlsImgArqmidia = decodeURIComponent(imagensString)
            .split(",")
            .filter((url) => url.trim() !== "");
          // console.log("Imagens carregadas:", this.urlsImgArqmidia);

          clearInterval(interval);
          this.fnInsertImagem();
          // } else {
          //   console.log("Cookie 'imagens_arqmidia' ainda não encontrado.");
        }
      }, 1000);
    },

    abrirModalImagem() {
      this.abreModalImagem = true;
      const quillEditor = this.$refs.myQuillEditor.quill;
      const selection = quillEditor.getSelection();
      if (selection) {
        this.cursorIndex = selection.index;
        //   console.log("Índice do cursor capturado:", this.cursorIndex);
        // } else {
        //   console.log("Cursor não está no editor");
      }
      this.fnVerificaImagensCookies();
    },
    // ... Outros métodos ...

    // Abre o modal de seleção de estilo
    mostrarModalSelecionaEstilo() {
      this.mostrarModalEstilos = true;

      // Obtém a posição do cursor no Quill Editor
      const quillEditor = this.$refs.myQuillEditor.quill;
      const selection = quillEditor.getSelection();
      if (selection) {
        const index = selection.index;
        const textBeforeCursor = quillEditor.getText(0, index);
        this.cursorLine = textBeforeCursor.split("\n").length;
        //   console.log("Cursor is on line:", this.cursorLine);
        // } else {
        //   console.log("Cursor is not in the editor");
      }
    },

    // Aplica o estilo selecionado e fecha o modal
    fnAplicaEstiloFechaModal() {
      this.mostrarModalEstilos = false;

      if (this.cursorLine !== null) {
        const quillEditor = this.$refs.myQuillEditor.quill;
        const fullText = quillEditor.getText();
        const lines = fullText.split("\n");

        // Calcula o índice da linha para inserir o estilo
        let insertIndex = 0;
        for (let i = 0; i < this.cursorLine - 1; i++) {
          insertIndex += lines[i].length + 1;
        }

        // Insere o estilo na posição calculada
        quillEditor.insertText(insertIndex, this.textToInsert + "\n", "user");
        quillEditor.setSelection(insertIndex + "Texto Inserido\n".length, 0);

        //   console.log(`Texto inserido na posição: ${insertIndex}`);
        // } else {
        //   console.log("Linha do cursor não armazenada");
      }
    },

    // Define o texto a ser inserido no editor
    async fnCriaEstilo() {
      this.textToInsert = "<ParaStyle:" + this.selectedGrupoEstilos.nome + ":" + this.selectedEstilo.nome + ">";
    },

    // Carrega os grupos de estilos disponíveis
    async fnCarregaGrupos() {
      this.registroGruposEstilos = [];

      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "edt/editorial/buscaGrupoEstilos");
        this.registroGruposEstilos = response.data.grupoEstilos;
      } catch (error) {
        console.error("Erro ao buscar grupos de estilos:", error);
      }
    },

    // Carrega os estilos de um grupo específico
    async fnRecebeGrupoEstilos(retornoSelectGrupo) {
      this.idSelectGrupo = retornoSelectGrupo.id;
      this.nomeSelectGrupo = retornoSelectGrupo.nome;
      this.fnCarregarEstilos();
    },

    async fnCarregarEstilos() {
      this.registroEstilos = [];

      const bodyEstilos = {
        idGrupo: this.idSelectGrupo,
        idEstilo: "",
        filtroBusca: "0"
      };

      try {
        const response = await api.post(process.env.VUE_APP_URL_WEB_API + "edt/editorial/buscaEstilos", bodyEstilos);
        this.registroEstilos = response.data.estilos;
      } catch (error) {
        console.error("Erro ao buscar estilos:", error);
      }
    },

    // Obtém o conteúdo HTML do Quill Editor
    convertHtmlToBase64() {
      const quillEditor = this.$refs.myQuillEditor.quill;
      let htmlContent = quillEditor.root.innerHTML;

      // Cria um elemento temporário para manipular o HTML
      let tempDiv = document.createElement("div");
      tempDiv.innerHTML = htmlContent;
      // Seleciona todas as tags <img> e adiciona a classe "img-artigo"
      const images = tempDiv.querySelectorAll("img");
      images.forEach((img) => {
        img.classList.add("img-artigo");
      });
      // Atualiza o conteúdo HTML com as classes aplicadas
      htmlContent = tempDiv.innerHTML;
      // Codifica o conteúdo HTML
      // const conteudoCodificado = btoa(unescape(encodeURIComponent(htmlContent)));
      const conteudoCodificado = htmlContent;
      // console.log("*****************************************************************");
      // console.log(conteudoCodificado);
      this.newArtigo.conteudo = conteudoCodificado;
    },

    verificarCaracteres() {
      //Verifica se existe < digitado pelo usuário sem ser um estilo
      // Expressão regular para capturar &lt; seguido de algo que não seja letra maiúscula ou número
      const regexInvalid = /&lt;[^A-Z0-9]/g;
      // Expressão regular para capturar &lt; seguido de letra maiúscula ou número
      const regexValid = /&lt;[A-Z0-9]/g;

      // Verifica se existem ocorrências de &lt; seguidas de algo que não seja uma letra maiúscula ou número
      const invalidMatches = this.newArtigo.conteudo.match(regexInvalid);

      // Verifica se existem ocorrências válidas de &lt; seguidas por uma letra maiúscula ou número
      const validMatches = this.newArtigo.conteudo.match(regexValid);

      // Lógica de verificação
      if (invalidMatches) {
        // Se houver ocorrências inválidas, retorna a mensagem de aviso
        return {
          mensagemAviso: "Não usar os caracteres < ou > no texto.",
          resultado: false
        };
      } else if (validMatches) {
        // Se houver ocorrências válidas, retorna que o texto está correto
        // console.log("O texto contém '&lt;' seguido de uma letra maiúscula ou número.");
        return {
          resultado: true
        };
      } else {
        // Se não houver nem válidos nem inválidos, retorna sem alertas
        return {
          resultado: true
        };
      }
    },

    // Carrega as editorias disponíveis
    async carregarEditorias() {
      try {
        const response = await api.post(process.env.VUE_APP_URL_WEB_API + "edt/editoria/all");
        const editoriaEncontrada = response.data.find((editoria) => editoria.id == this.idEditoria);
        if (editoriaEncontrada) {
          if (editoriaEncontrada.tipo == 2 || editoriaEncontrada.tipo == 3) {
            this.informacoesOk = false;
            this.informacoesOkTexto = "EDITORIA DE PRODUÇÃO";
          } else {
            this.newArtigo.idEditoria = this.idEditoria;
            // console.log("Editoria encontrada:", editoriaEncontrada);
            this.nomeEditoria = editoriaEncontrada.nome;
          }
        } else {
          // console.log("Editoria com ID", this.idEditoria, "não encontrada.");
          this.informacoesOk = false;
          this.informacoesOkTexto = "EDITORIA NÃO EXISTE";
        }
      } catch (error) {
        console.error("Erro ao buscar editorias:", error);
      }
    },

    // Define a editoria do artigo
    async carregarArtigos(selectEditoria) {
      this.newArtigo.idEditoria = selectEditoria.id;
      this.nomeEditoriaSelecionada = selectEditoria.nome;
    },

    // Obtém a data e hora atual formatada
    fnDtCriacao() {
      const options = {
        timeZone: "America/Sao_Paulo",
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric"
      };
      const dataHoraBrasilia = new Date().toLocaleString("pt-BR", options);
      this.newArtigo.dtCriacao = dataHoraBrasilia.replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$2-$1").replace(",", "");
    },

    // Codifica o nome do artigo para Base64
    codificarNome() {
      this.newArtigo.nome = this.nomeArtigo;
    },

    // Verifica se Título foi alterado
    fnVerificaAlteraNome() {
      // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
      // console.log(this.nomeArtigo + " + " + this.newArtigo.nome);
      //Verifica Se É Alteração de Título
      if (this.nomeArtigo != this.newArtigo.nome) {
        this.newArtigo.renomearMateria = "1";
        this.operacaoSalvar = 2;
        this.codificarNome(); // Pegar o valor do nome do input
      } else {
        this.newArtigo.renomearMateria = "0";
      }
    },

    /* ########### EDIÇÃO ################# */

    async fnCarregaArtigoEdit(idArtigoEdita) {
      const body = {
        idMateria: idArtigoEdita,
        idConteudo: this.idConteudo,
        idUsuario: this.$store.state.usuario.id,
        destravar: "1" //DIREITO DE EDITAR
      };
      // console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%% AQUII");
      // console.log(this.idConteudo);
      // console.log("body" + body);

      //Organiza e exibie as editorias na lateral
      try {
        // Faz a pesquisa no banco de dados
        const response = await api.post(process.env.VUE_APP_URL_WEB_API + "edt/editorial/editaMateria", body);

        // console.log("response.data");
        // console.log(response.data);
        // console.log(this.idEditoria + " - - " + response.data.idEditoria);

        if (response.data.erro != true) {
          if (this.idEditoria != response.data.idEditoria) {
            this.informacoesOk = false;
            this.informacoesOkTexto = "EDITORIA DA MATÉRIA É DIFERENTE DA EDITORIA ENVIADA NA URL";
          } else {
            // Seta as variáveis com o retorno
            // Cria um buffer contendo o texto em base64

            this.content = response.data.textoMateria;
            this.contentAlterado = response.data.textoMateria;

            this.nomeArtigo = response.data.nomeMateria;
            this.newArtigo.nome = response.data.nomeMateria;
            this.newArtigo.idMateria = idArtigoEdita;

            // console.log("ID EDITORIA MATÉRIA" + response.data.idEditoria);

            if (this.idGrupoModelo == "null") {
              this.idGrupoModelo = "";
            } else {
              this.fnCarregaModelo();
            }
          }
        } else {
          this.informacoesOk = false;
          this.informacoesOkTexto = "ERRO NÃO TEM DIREITO";

          // console.log(response.data);
        }
      } catch (error) {
        console.error("Erro ao buscar editorias:", error);
        // Tratar o erro (exibir mensagem ao usuário, etc.)
      }
      // Converte e exibe texto plano
    },

    /* ########### EDIÇÃO ################# */

    valida_url(url, assinante) {
      if (!util.valida_url(url)) {
        return assinante && !url ? true : "Endereço inválido.";
      } else {
        return true;
      }
    },

    updateEditorFontSize() {
      // Acessa o elemento .ql-editor diretamente e aplica o tamanho da fonte
      const quillEditor = this.$refs.myQuillEditor;
      if (quillEditor && quillEditor.$el) {
        const editor = quillEditor.$el.querySelector(".ql-editor");
        if (editor) {
          editor.style.fontSize = this.editorFontSize + "px";
        }
      }
    },

    /* MODAL SALVAR SAIR */
    tentarSair() {
      if (this.contentAlterado !== this.content) {
        this.modalSalvarSair = true; // Exibe o modal de confirmação
        this.salvarFechar = true; // fecha a janela após salvar
      } else {
        this.fecharSemSalvar();
      }
    },
    fecharSemSalvar() {
      window.close();
      // Lógica para fechar ou navegar
    },
    /* MODAL SALVAR SAIR */

    /* MODAL AVISO */
    mostrarAviso() {
      this.dialogAviso = true;
    },
    /* MODAL AVISO */

    /* VERIFICA BLOQUEIA <> */
    handleKeyPress(event) {
      if (this.blockedKeys.includes(event.key)) {
        event.preventDefault();
      }
    },
    /* VERIFICA BLOQUEIA <> */

    /* VERIFICA FECHAMENTO DA ABA */
    beforeUnloadHandler(event) {
      if (this.contentAlterado !== this.content) {
        event.preventDefault();
        event.returnValue = "";
      }
    },
    /* VERIFICA FECHAMENTO DA ABA */

    /* BUSCA INFORMAÇÔES DO TAMANHO DO TEXTO */
    async fnBuscaInfo() {
      this.retornoMedeTexto = [];

      const quill = this.$refs.myQuillEditor.quill;
      if (!quill) return;

      const selection = quill.getSelection();
      let plainText;

      if (selection && selection.length > 0) {
        plainText = quill.getText(selection.index, selection.length);
      } else {
        plainText = quill.getText(); // Todo o texto
      }

      plainText = plainText.trim();

      // Cria um elemento temporário para manipular o HTML
      const bodyMedeTexto = {
        texto: plainText
      };

      try {
        const response = await api.post(process.env.VUE_APP_URL_WEB_API + "edt/editorial/medeTexto", bodyMedeTexto);

        if (response.data.erro == true) {
          this.mensagem = response.data.descricao;
          this.snackErro = true;
        } else {
          this.retornoMedeTexto = response.data;
          // console.log(response.data);
        }
      } catch (error) {
        console.error("Erro ao buscar estilos:", error);
      }
    },
    /* BUSCA INFORMAÇÔES DO TAMANHO DO TEXTO */

    // TEMPORIZADOR AUTO SAVE
    resetTimer() {
      clearTimeout(this.timerId); // Limpa o temporizador anterior
      this.lastActivity = Date.now();
      this.startTimer();
    },
    startTimer() {
      this.timerId = setTimeout(() => {
        this.handleInactivity();
      }, this.inatividadeTimeout);
    },
    handleInactivity() {
      // Código a ser executado após 10 segundos de inatividade
      // console.log("Usuário inativo por 10 segundos!");

      if (this.contentAlterado != this.content) {
        this.newArtigo.autoSave = "1";
        this.operacaoSalvar = "1";
        this.salvar();
      }
      // ... sua lógica ...
    },
    // TEMPORIZADOR AUTO SAVE

    //INCLUIR GRUPO DE MODELO
    async fnCarregaModelo() {
      const body = {
        idModelo: this.idGrupoModelo
      };

      // Limpa o array antes de buscar os dados
      this.retornoGrupoModelos = [];

      try {
        // Faz a pesquisa no banco de dados
        const response = await api.post(`${process.env.VUE_APP_URL_WEB_API}edt/editorial/buscaEstilosModelo`, body);

        // console.log("DADOS MODELOS", response.data);

        // Foca no editor antes de inserir os estilos
        this.focusEditor();

        const modelosPagina = response.data.estilosModelo;

        if (modelosPagina && modelosPagina.length > 0) {
          modelosPagina.forEach((item) => {
            // console.log(`<${item.grupo_estilo}:${item.nome_estilo}>`);

            const quillEditor = this.$refs.myQuillEditor.quill;
            const selection = quillEditor.getSelection();

            if (selection) {
              const index = selection.index;
              const textBeforeCursor = quillEditor.getText(0, index);
              const cursorLine = textBeforeCursor.split("\n").length;

              // console.log("Cursor está na linha:", cursorLine);

              if (cursorLine !== null) {
                const fullText = quillEditor.getText(); // Obtém o texto completo do editor
                const lines = fullText.split("\n"); // Divide o texto em linhas

                // Calcula o índice da linha onde o estilo será inserido
                let insertIndex = 0;
                for (let i = 0; i < cursorLine - 1; i++) {
                  insertIndex += lines[i].length + 1; // +1 para contar o caractere de nova linha
                }

                // Insere o estilo na posição calculada, seguido de uma nova linha
                const textoInserido = `<ParaStyle:${item.grupo_estilo}:${item.nome_estilo}>\n\n`;
                quillEditor.insertText(insertIndex, textoInserido, "user");

                // Move o cursor para o final do texto inserido
                quillEditor.setSelection(insertIndex + textoInserido.length, 0);

                // console.log(`Texto inserido na posição: ${insertIndex}`);
              }
              // } else {
              //   console.log("Cursor não está no editor");
            }
          });
          // } else {
          //   console.log("Nenhum estilo foi retornado");
        }
      } catch (error) {
        // console.error("Erro ao buscar modelos:", error);
        // Tratar o erro (exibir mensagem ao usuário, etc.)
      }
    }
  },

  mounted() {
    this.fnCarregaGrupos();
    this.updateEditorFontSize();
    /*    setTimeout(() => {
      if (this.$refs.myQuillEditor && this.$refs.myQuillEditor.quill) {
        this.$refs.myQuillEditor.quill.focus(); // Corrigido: quill em vez de quillEditor
      }
    }, 500); // Ajuste o tempo se necessário */

    /* VERIFICA BLOQUEIA <> */
    this.$nextTick(() => {
      this.$refs.quillContainer.addEventListener("keydown", this.handleKeyPress);
    });
    /* VERIFICA BLOQUEIA <> */

    /* VERIFICA FECHAMENTO DA ABA */
    window.addEventListener("beforeunload", this.beforeUnloadHandler);
    /* VERIFICA FECHAMENTO DA ABA */

    this.$nextTick(() => {
      if (this.$refs.myQuillEditor && this.$refs.myQuillEditor.quill) {
        this.$refs.myQuillEditor.quill.focus();
      }
    });

    //TEMPORIZADOR
    this.$nextTick(() => {
      this.$refs.myQuillEditor.quill.on("text-change", this.resetTimer);
    });
    //TEMPORIZADOR

    // console.log("######################!@$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$4");
  }, // FIM mounted()

  updated() {
    this.$nextTick(() => {
      if (this.$refs.myQuillEditor) {
        this.addTooltips();
      }
    });
  },

  beforeDestroy() {
    this.$refs.myQuillEditor.removeEventListener("keydown", this.handleKeyPress); /* BLOQUEIA <> */

    window.removeEventListener("beforeunload", this.beforeUnloadHandler); /* VERIFICA FECHAMENTO DA ABA */

    clearTimeout(this.timerId); //LIMPA TEMPORIZDOR QUANDO ENCERRA O DOM
  },

  computed: {},

  async created() {

    var urlApi = (window.location.host.includes("localhost") ? "http://localhost" : window.location.host) + "/";
    if (!urlApi.includes("http")) {
      urlApi = "https://" + urlApi;
    }
    this.iframeSrc = urlApi + "arqmidia/galeria.html#";

    this.idEditoria = this.$route.params.idEditoria ? this.$route.params.idEditoria : "";

    //SE EXISTIR JÁ MRECEBE MODELO ARTIGO
    this.idGrupoModelo = this.$route.params.idModelos ? this.$route.params.idModelos : "";
    if (this.idGrupoModelo == "null") {
      this.idGrupoModelo = "";
    }

    //SE EXISTIR ID É EDIÇÃO
    this.id = this.$route.params.id ? this.$route.params.id : "";
    if (this.id == "null") {
      this.id = "";
    }

    this.idConteudo = this.$route.params.idConteudo ? this.$route.params.idConteudo : "";
    if (this.idConteudo == "null") {
      this.idConteudo = "0";
    }
    // console.log(this.idConteudo);
    // console.log("IDDD CONTEUDOOOOOO VERSÃOOOOOOOOOOOOOOOOOOOOO");

    // console.log("EDITORIA!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
    // console.log(this.idEditoria);

    // console.log("ID ARTIGO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
    // console.log(this.id);

    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

    // Define os sites permitidos para o usuário
    var idGrupoSite = this.$store.state.usuario.site_idGrupo;
    if (idGrupoSite != null) {
      this.siteGrupo = [
        {
          id: this.idSitePrincipal,
          nome: this.$store.state.usuario.site_nome
        }
      ];
    }

    // console.log(this.idSitePrincipal + " ID SITE PRINCIPAL");
    // console.log(this.$store.state.usuario.site_nome + " nome USUÁRIO LOGADO");
    // console.log(this.$store.state.usuario.id + " ID USUÁRIO LOGADO");

    try {
      if (this.id == "") {
        // Criação de novo artigo
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "EDITORIAL_MATÉRIA_CRIAR"]);

        this.carregarEditorias();
        this.actionArtigo = "Novo";
        //ABRE MODAL NOME
        this.abrirModalTitle();
        //ABRE MODAL NOME
      } else {
        // Edição de artigo existente
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "EDITORIAL_MATÉRIA_CRIAR"]);

        this.actionArtigo = "Editar";
        this.fnCarregaArtigoEdit(this.id);

        this.carregarEditorias();
      }
    } catch {
      this.mensagem = "Ocorreu um erro ao carregar os dados";
      this.snackErro = true;
    }
  }
};
</script>

<style>
/* Importa o CSS do Quill Editor */
@import "~quill/dist/quill.snow.css";

/* CSS MEDIR TEXTO */
.v-toolbar__title.font-weight-light.d-flex.flex-column.dados-medir {
  font-size: 14px;
  line-height: normal;
}

/* EDITOR */
.ql-container.ql-snow {
  margin-left: 45px;
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
}
.quill-editor {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.ql-container.ql-snow {
  height: 81vh;
}

/* scroll barra de ferramentas*/
.ql-toolbar.ql-snow {
  overflow-y: auto;
  direction: rtl; /* Move a barra para a esquerda */
  display: flex;
  /* Para navegadores WebKit (Chrome, Safari, Edge) */
  scrollbar-width: thin; /* Para Firefox */
  scrollbar-color: #666 #f1f1f1; /* Cor do polegar e trilho do scrollbar */
  flex-direction: column;
  width: 45px;
  position: fixed;
  top: 130px;
  border: none;
  overflow: auto;
  padding-bottom: 200px;
  height: 100vh;
}

/* Estilos da barra de rolagem para WebKit */
.ql-toolbar.ql-snow::-webkit-scrollbar {
  width: 6px;
}

.ql-toolbar.ql-snow::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.ql-toolbar.ql-snow::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 3px;
}

.ql-toolbar.ql-snow::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Mantém o conteúdo alinhado à esquerda */
.ql-toolbar.ql-snow > * {
  direction: ltr;
}
/* EDITOR */

/* btn imagem */
.ql-toolbar .ql-imgButton::before {
  font-family: "Material Icons";
  content: "add_photo_alternate";
  font-size: 25px;
}
.ql-toolbar .ql-imgButton:hover {
  background-color: #e0e0e0;
}

/* btn title */
.ql-toolbar .ql-titleAltera::before {
  font-family: "Material Icons";
  content: "title";
  font-size: 25px;
}
.ql-toolbar .ql-titleAltera:hover {
  background-color: #e0e0e0;
}
button.ql-titleAltera {
  margin-right: 10px;
}
/* btn title */

/* btn informações */
.ql-toolbar .ql-infoButton::before {
  font-family: "Material Icons";
  content: "straighten";
  font-size: 25px;
}
.ql-toolbar .ql-infoButton:hover {
  background-color: #e0e0e0;
}
button.ql-infoButton {
  margin-right: 10px;
}
/* btn informações */

.ql-toolbar .ql-insertStyle::before {
  font-family: "Material Icons";
  content: "segment";
  font-size: 25px;
}

.ql-toolbar .ql-titleButtonAumentaTexto::before {
  font-family: "Material Icons";
  content: "zoom_in";
  font-size: 25px;
}

.ql-toolbar .ql-titleButtonDiminuiTexto::before {
  font-family: "Material Icons";
  content: "zoom_out";
  font-size: 25px;
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 15px;
  width: auto;
}
button.ql-imgButton {
  margin-right: 10px;
}
button.ql-insertStyle {
  margin-right: 10px;
}

/* Estilo personalizado para o botão anotherButton */
.ql-toolbar .ql-anotherButton::before {
  font-family: "Material Icons";
  content: "save"; /* Texto visível no botão */
  font-size: 25px;
}

.ql-toolbar .ql-fecharButton::before {
  font-family: "Material Icons";
  content: "close"; /* Texto visível no botão */
  font-size: 25px;
}
.ql-toolbar .ql-anotherButton:hover,
.ql-toolbar .ql-insertStyle:hover {
  background-color: #e0e0e0; /* Cor de fundo ao passar o mouse */
}

.ql-toolbar .ql-insertStyle,
.ql-toolbar .ql-anotherButton,
.ql-toolbar .ql-fecharButton,
.ql-toolbar .ql-titleButtonAumentaTexto,
.ql-toolbar .ql-titleButtonDiminuiTexto,
.ql-toolbar .ql-info {
  background: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  height: 28px !important;
  padding: 0px 0px !important;
  width: 28px !important;
  margin: 5px 0px !important;
}
.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  background: none !important;
  border: none !important;
  cursor: pointer !important;
  display: inline-block !important;
  height: 28px !important;
  padding: 0 !important;
  width: 28px !important;
  margin: 8px 0px !important;
}
.ql-snow .ql-editor img {
  width: 300px;
  height: auto;
}
</style>
