<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">library_books</v-icon>
      <v-toolbar-title style="color: white" class="font-weight-light">{{ pacote.id ? "Pacote - " + pacote.id : "Novo Pacote" }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12 md3>
            <v-text-field v-model="pacote.status" label="Status" readonly></v-text-field>
          </v-flex>

          <v-flex xs12 md3>
            <v-text-field v-model="pacote.dtOp" label="Captado em" readonly color="blue-grey lighten-2"></v-text-field>
          </v-flex>

          <v-flex xs12 md3>
            <v-text-field v-model="pacote.usuario" label="Captado por" readonly color="blue-grey lighten-2"></v-text-field>
          </v-flex>

          <v-flex xs12 md3>
            <v-text-field
              v-model="pacote.autorizacao"
              label="Autorização/PI"
              :rules="[validacao.autorizacao == false || validacao.autorizacao, (value) => valida_nome(value) || 'Autorização inválida']"
              maxlength="15"
              :disabled="isUpdating"
              :readonly="!editar"
              color="blue-grey lighten-2"
              @change="validacao.autorizacao = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs11>
            <v-autocomplete
              autofocus
              v-model="clienteSelecionado"
              :items="clientes"
              hint="Obrigatório"
              :rules="[validacao.idCliente == false || validacao.idCliente, (value) => !!value || 'Obrigatório.']"
              :filter="filtrarObjetos"
              label="Anunciante"
              item-text="nome"
              item-value="id"
              :disabled="id != ''"
              no-data-text
              placeholder="Pesquisa pelos primeiros 50 registros que coincidirem com o nome digitado"
              :loading="loadingClientes"
              :search-input.sync="search"
              @keyup.enter="buscarCliente"
              return-object
              @change="validacao.idCliente = false"
            >
              <template v-slot:append v-if="pacote.idCliente == null">
                <v-btn color="blue" fab small>
                  <v-icon color="white" @click="buscarCliente" title="Busca pelo nome ou identificação do anunciante">search</v-icon>
                </v-btn>
              </template>
            </v-autocomplete>
          </v-flex>
          <v-flex xs1 class="text-right">
            <v-spacer></v-spacer>
            <v-icon v-if="direitoAnunciante && pacote.idCliente != null" color="orange" @click="visualizarCliente" title="Editar Anunciante">account_circle</v-icon>
          </v-flex>

          <v-flex xs12 md12 v-if="departamentosCliente.length > 0">
            <v-autocomplete
              v-model="pacote.idDeptoCliente"
              :items="departamentosCliente"
              :filter="filtrarObjetos"
              label="Departamento do Anunciante"
              item-text="departamento"
              item-value="id"
              :rules="[validacao.idDeptoCliente == false || validacao.idDeptoCliente]"
              :disabled="id != ''"
              no-data-text
              @change="validacao.idDeptoCliente = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md12>
            <v-text-field
              v-model="pacote.descricao"
              label="Descrição do Pacote"
              maxlength="180"
              hint="Obrigatório"
              :rules="[
                validacao.descricao == false || validacao.descricao,
                (value) => !!value || 'Obrigatório.',
                (value) => valida_nome(value) || 'Descrição inválida',
                (value) => value.length > 2 || 'Preencha a descrição do pacote'
              ]"
              :disabled="isUpdating || !nfNaoEmitida"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="
                pacote.descricao = pLetra(pacote.descricao);
                validacao.descricao = false;
              "
            ></v-text-field>
          </v-flex>

          <v-flex md3 xs6>
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="pacote.vlrLiquido"
              label="Valor Liquido"
              :allowNegative="false"
              :max="9999999"
              hint="Obrigatório"
              :rules="[validacao.vlrLiquido == false || validacao.vlrLiquido, (value) => (value && value.length < 13) || 'Verifique o valor líquido']"
              :disabled="!nfNaoEmitida || anuncios.length > 0"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="
                pacote.vlrCredito = pacote.vlrCredito ? pacote.vlrCredito : pacote.vlrLiquido;
                limpaParcelas();
                validacao.vlrLiquido = false;
              "
            ></v-currency-field>
          </v-flex>

          <v-flex md3 xs6>
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="pacote.vlrCredito"
              label="Valor Crédito"
              :allowNegative="false"
              :max="9999999"
              hint="Obrigatório"
              :rules="[
                validacao.vlrCredito == false || validacao.vlrCredito,
                (value) => !!value || 'Obrigatório.',
                (value) => (value && value.length < 13) || 'Verifique o valor do crédito',
                (value) => valida_credito(value)
              ]"
              :disabled="isUpdating || !nfNaoEmitida"
              autocomplete="disabled"
              :name="Math.random()"
              color="blue-grey lighten-2"
              @change="validacao.vlrCredito = false"
            ></v-currency-field>
          </v-flex>

          <v-flex md3 xs6 v-if="id != ''">
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="vlrUtilizado"
              label="Valor Utilizado"
              :allowNegative="false"
              disabled
              color="blue-grey lighten-2"
            ></v-currency-field>
          </v-flex>

          <v-flex md3 xs6 v-if="id != ''">
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="vlrSaldo"
              label="Valor Saldo"
              :allowNegative="false"
              disabled
              color="blue-grey lighten-2"
            ></v-currency-field>
          </v-flex>

          <v-flex md3 xs6 v-if="parcelas.length == 0">
            <v-text-field
              v-model="pacote.nroParcelas"
              label="Parcelas"
              v-mask="'###'"
              hint="Obrigatório"
              :rules="[
                validacao.nroParcelas == false || validacao.nroParcelas,
                (value) => !!value || 'Obrigatório.',
                (value) => parseInt(value) != 0 || 'Verifique o número de parcelas'
              ]"
              :disabled="parcelas.length != 0"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.nroParcelas = false"
            ></v-text-field>
          </v-flex>

          <v-flex md3 xs6 v-if="parcelas.length == 0">
            <v-menu ref="mnuDtVecto" v-model="mnuDtVecto" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dtVecto"
                  label="Vencimento 1ª Parcela"
                  v-mask="'##/##/####'"
                  return-v-masked-value
                  hint="Obrigatório"
                  prepend-icon="event"
                  v-on="on"
                  :rules="[
                    validacao.dtVecto == false || validacao.dtVecto,
                    (value) => valida_data_vecto(value) || 'Data de vencimento não pode ser inferior a da operação',
                    (value) => !!value || 'Obrigatório.',
                    (value) => (value != null && value.length == 10) || 'Preencha a data de vencimento da primeira parcela'
                  ]"
                  color="blue-grey lighten-2"
                  @keyup="dtVectoUS = valida_data(dtVecto) ? formatDateUS(dtVecto) : null"
                  @change="validacao.dtVecto = false"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dtVectoUS" locale="pt-br" no-title @input="mnuDtVecto = false"></v-date-picker>
            </v-menu>
          </v-flex>

          <v-flex xs12 md12>
            <v-switch
              :readonly="!editar || !nfNaoEmitida || anuncios.length > 0 || !direitoDescComissao"
              v-model="pacote.descComissao"
              :disabled="isUpdating"
              :label="`${pacote.descComissao == 1 ? 'Descontar Comissão da Agência' : 'Sem Desconto de Comissão'}`"
              @change="limpaParcelas"
            ></v-switch>
          </v-flex>

          <v-flex xs12>
            <v-alert v-if="pacote.dtCancelamento" :value="true" color="error" icon="priority_high" outlined>
              Este pacote foi cancelado em
              {{ formatDateTime(pacote.dtCancelamento) }}.
            </v-alert>
          </v-flex>
          <v-flex xs12>
            <v-alert v-if="!nfNaoEmitida" :value="true" color="blue" icon="check_circle" outlined>Pacote com Nota Fiscal Emitida.</v-alert>
          </v-flex>
          <v-flex xs12>
            <v-alert v-if="nfNaoEmitida && emCobranca" :value="true" color="info" icon="priority_high" outlined> Pacote com Parcela em Cobrança. </v-alert>
          </v-flex>
        </v-layout>

        <v-layout wrap>
          <v-expansion-panels v-model="painel" focusable>
            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.agentes ? 'red--text' : ''">
                <strong>Agentes/Agências</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar>
                      <v-spacer></v-spacer>
                      <v-dialog persistent v-model="dialogAgente" max-width="700px">
                        <template v-slot:activator="{ on }">
                          <v-btn v-if="nfNaoEmitida && editar" color="success" fab small v-on="on">
                            <v-icon color="white" title="Incluir">add</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form ref="formAgente" v-model="agenteValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">Agente/Agência</span>
                              <v-spacer></v-spacer>
                              <v-btn icon @click="closeAgente">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs10>
                                    <v-autocomplete
                                      autofocus
                                      v-model="agenteSelecionado"
                                      :items="agentesOrigem"
                                      :rules="[validacao.idAgente == false || validacao.idAgente, (value) => (!!value && !!value.id) || 'Obrigatório.']"
                                      hint="Obrigatório"
                                      :filter="filtrarObjetosIdentificacao"
                                      label="Selecione o Agente"
                                      item-text="agente"
                                      item-value="idAgente"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      no-data-text
                                      return-object
                                    ></v-autocomplete>
                                  </v-flex>
                                  <v-flex xs2>
                                    <v-currency-field
                                      :locale="`pt-${$store.state.usuario.site_pais}`"
                                      v-model="agenteItem.comissao"
                                      label="Comissão"
                                      :allowNegative="false"
                                      :max="100"
                                      suffix="%"
                                      hint="Obrigatório"
                                      :readonly="!direitoComissao"
                                      :rules="[
                                        validacao.comissao == false || validacao.comissao,
                                        (value) => !value || parseFloat(value.replace('.', '').replace(',', '.')) <= 99.99 || 'Verifique a comissão'
                                      ]"
                                      color="blue-grey lighten-2"
                                    ></v-currency-field>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closeAgente">Retornar</v-btn>
                              <v-btn color="blue darken-1" @click="saveAgente">Atualizar</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headerAgentes"
                      :items="agentes"
                      no-data-text
                      no-results-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [10, 25, 50],
                        pageText: ''
                      }"
                    >
                      <template v-slot:[getItemActions()]="{ item }">
                        <v-icon v-if="nfNaoEmitida && editar" class="mr-2" color="red" title="Remover Agente" @click="removerAgente(item)">delete</v-icon>
                      </template>
                    </v-data-table>
                    <v-alert v-if="validacao.agentes" :value="true" color="error" icon="priority_high" outlined>{{ validacao.agentes }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-if="id != '' || pacote.nroParcelas > 0" dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.parcelas ? 'red--text' : ''">
                <strong>Parcelas</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card v-if="parcelas.length == 0">
                  <v-container fluid grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12 md6>
                        <v-autocomplete
                          autofocus
                          v-model="formaPagamentoSelecionada"
                          :items="formasPgto"
                          :filter="filtrarObjetos"
                          label="Selecione a Forma de Pagamento"
                          hint="Obrigatório"
                          :rules="[(value) => (!!value && !!value.id) || 'Obrigatório.']"
                          item-text="nome"
                          item-value="id"
                          autocomplete="disabled"
                          :name="Math.random()"
                          no-data-text
                          return-object
                        ></v-autocomplete>
                      </v-flex>
                      <v-flex xs12 md6>
                        <v-btn color="success" :loading="isUpdating" style="color: white" depressed @click="gerarParcelas"> <v-icon left>library_add</v-icon>Gerar Parcelas </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <v-alert v-if="validacao.parcelas" :value="true" color="error" icon="priority_high" outlined>{{ validacao.parcelas }}</v-alert>
                </v-card>

                <v-flex xs12 md12 v-if="parcelas.length > 0">
                  <v-card>
                    <v-spacer></v-spacer>
                    <v-dialog persistent v-model="dialogAjuste" max-width="600px">
                      <v-card>
                        <v-form ref="formAjuste" v-model="ajusteValid" lazy-validation>
                          <v-toolbar>
                            <span class="headline">Ajustar Parcela</span>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="closeAjuste">
                              <v-icon color="darken-1">cancel</v-icon>
                            </v-btn>
                          </v-toolbar>
                          <v-card-text>
                            <v-container grid-list-md>
                              <v-layout wrap>
                                <v-flex xs6>
                                  <v-currency-field
                                    :locale="`pt-${$store.state.usuario.site_pais}`"
                                    v-model="ajusteItem.valor"
                                    label="Novo Valor"
                                    :allowNegative="false"
                                    :max="9999999"
                                    :rules="[validacao.ajuste_valor == false || validacao.ajuste_valor, (value) => (value && value.length < 13) || 'Verifique o valor da parcela']"
                                    color="blue-grey lighten-2"
                                    autocomplete="disabled"
                                    :name="Math.random()"
                                  ></v-currency-field>
                                </v-flex>
                                <v-flex xs6>
                                  <v-menu
                                    ref="mnuAjusteItemDtVectoO"
                                    v-model="mnuAjusteItemDtVectoO"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        v-model="ajusteItemDtVectoO"
                                        label="Novo Vencimento"
                                        v-mask="'##/##/####'"
                                        prepend-icon="event"
                                        v-on="on"
                                        return-v-masked-value
                                        hint="Obrigatório"
                                        :rules="[
                                          validacao.ajuste_vecto == false || validacao.ajuste_vecto,
                                          (value) => valida_data_vecto(value) || 'Data de vencimento não pode ser inferior a da operação',
                                          (value) => !!value || 'Obrigatório.',
                                          (value) => (value != null && value.length == 10) || 'Preencha a data de vencimento da parcela'
                                        ]"
                                        color="blue-grey lighten-2"
                                        @keyup="ajusteItemDtVectoOUS = valida_data_vecto(ajusteItemDtVectoO) ? formatDateUS(ajusteItemDtVectoO) : null"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="ajusteItemDtVectoOUS" locale="pt-br" no-title @input="mnuAjusteItemDtVectoO = false"></v-date-picker>
                                  </v-menu>
                                </v-flex>
                                <v-flex xs6>
                                  <v-switch v-model="ajusteItem.liquido" :label="`${ajusteItem.liquido == 1 ? 'Manter Valor Líquido' : 'Recalcular Valor Líquido'}`"></v-switch>
                                </v-flex>
                              </v-layout>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" @click="closeAjuste">Retornar</v-btn>
                            <v-btn color="blue darken-1" @click="saveAjuste">Atualizar</v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                    <v-dialog persistent v-model="dialogParcela" max-width="600px">
                      <v-card>
                        <Parcela :id="idParcela" receber="true" :dialogParcela.sync="dialogParcela" />
                      </v-card>
                    </v-dialog>
                    <v-container fluid grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12>
                          <v-data-table
                            mobile-breakpoint="0"
                            :headers="headerParcelas"
                            :items="parcelas"
                            no-data-text
                            no-results-text
                            :footer-props="{
                              itemsPerPageText: 'Registros por página',
                              itemsPerPageOptions: [12, 25, 50],
                              pageText: ''
                            }"
                          >
                            <template v-slot:[getItemActions()]="{ item }">
                              <v-icon v-if="item.id" class="mr-2" color="blue lighten-2" title="Editar" @click="editarParcela(item)">edit</v-icon>
                              <v-icon v-if="!item.id && parcelas.length > 1" class="mr-2" color="blue lighten-2" title="Editar" @click="editarAjuste(item)">edit</v-icon>
                            </template>

                            <template v-slot:[getItemDtVectoOriginal()]="{ item }">
                              {{ formatDate(item.dtVectoOriginal) }}
                            </template>

                            <template v-slot:[getItemDtPromessa()]="{ item }">
                              {{ formatDate(item.dtPromessa) }}
                            </template>

                            <template v-slot:[getItemValor()]="{ item }">
                              {{ formatCurrency(item.valor + item.descAcr) }}
                            </template>
                          </v-data-table>
                        </v-flex>
                        <v-flex xs12 md3 v-if="parcelas.length > 0 && parcelas[0].id && direitoParcelas">
                          <v-icon color="blue lighten-2" title="Abrir no Financeiro" @click="visualizarParcelas">add_circle_outline</v-icon>
                        </v-flex>
                      </v-layout>
                    </v-container>
                    <v-alert v-if="validacao.parcelas" :value="true" color="error" icon="priority_high" outlined>{{ validacao.parcelas }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.anuncios ? 'red--text' : ''">
                <strong>Anúncios</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap>
                  <v-flex xs12 md12>
                    <v-card>
                      <v-toolbar flat v-if="direitoCriarAnuncio && id && parseFloat(vlrSaldo) > 0 && pacote.status == 'CORRENTE'">
                        <v-spacer></v-spacer>
                        <template>
                          <v-btn color="success" fab small @click="incluirAnuncio">
                            <v-icon color="white" title="Incluir">add</v-icon>
                          </v-btn>
                        </template>
                      </v-toolbar>
                      <v-data-table mobile-breakpoint="0" :headers="headerAnuncios" :items="anuncios" no-data-text no-results-text :footer-props="rodapeAnuncios">
                        <template v-slot:[getItemActions()]="{ item }">
                          <v-icon v-if="direitoEditarAnuncio && item.id && id != ''" color="yellow darken-2" title="Anúncio" @click="visualizarAnuncio(item)"
                            >chrome_reader_mode</v-icon
                          >
                        </template>
                        <template v-slot:[getItemDtCriacao()]="{ item }">{{ formatDateTime(item.dtCriacao) }}</template>
                        <template v-slot:[getItemVlrUtilizado()]="{ item }">{{ item.vlrUtilizado != null ? formatCurrency(item.vlrUtilizado) : "" }}</template>
                        <template v-slot:[getItemDtInicial()]="{ item }">{{ formatDate(item.dtInicial) }}</template>
                        <template v-slot:[getItemDtFinal()]="{ item }">{{ formatDate(item.dtFinal) }}</template>
                      </v-data-table>
                      <v-alert v-if="validacao.anuncios" :value="true" color="error" icon="priority_high" outlined>{{ validacao.anuncios }}</v-alert>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">{{ validacao.mensagemErro }}</v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-card-actions id="flex-wrap">
      <v-btn to="/comercial/pacotes" color="orange lighten-2 white" style="color: white" id="btn-rsn"> <v-icon left>keyboard_arrow_left</v-icon>Retornar </v-btn>
      <v-btn v-if="nfNaoEmitida && editar" :loading="isUpdating" :disabled="bloqueiaBotoes" color="blue white" style="color: white" id="btn-rsn" depressed @click="salvar('false')">
        <v-icon left>update</v-icon>Salvar
      </v-btn>

      <v-btn
        v-if="nfNaoEmitida && editar"
        :loading="isUpdating"
        :disabled="bloqueiaBotoes"
        color="orange white"
        style="color: white"
        id="btn-rsn"
        outlined
        @click="salvar('retornar')"
      >
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn
        v-if="direitoCriar && nfNaoEmitida && editar"
        :loading="isUpdating"
        :disabled="bloqueiaBotoes"
        color="blue white"
        style="color: white"
        id="btn-rsn"
        outlined
        @click="salvar('true')"
      >
        <v-icon left>autorenew</v-icon>Salvar e Novo
      </v-btn>
      <v-btn
        v-if="id != '' && direitoCancelar && editar"
        :loading="isUpdating"
        :disabled="bloqueiaBotoes"
        color="red lighten-1"
        style="color: white"
        id="btn-rsn"
        outlined
        @click="cancela()"
      >
        <v-icon left>cancel</v-icon>Cancelar
      </v-btn>
    </v-card-actions>

    <v-dialog v-model="dialogConfirm" persistent max-width="290">
      <v-card>
        <v-toolbar>
          <span class="headline">Confirmação</span>
        </v-toolbar>
        <br />
        <v-card-text><span v-html="`Tem certeza que deseja CANCELAR este pacote? <span style='color:red'>esta ação NÃO PODE ser desfeita</span>`"></span></v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="naoConfirmado">Não</v-btn>
          <v-btn color="green darken-1" text @click="confirmado">Sim</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackRefresh" color="info" :timeout="1" relative bottom left></v-snackbar>
  </v-card>
</template>
<script>
import Parcela from "@/views/fin/Parcela.vue";
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  components: {
    Parcela
  },
  data() {
    return {
      pacote: {
        id: "",
        idSite: this.$store.state.usuario.idSitePrincipal,
        tipo: 1,
        status: "CORRENTE",
        dtOperacao: null,
        dtOp: util.formatDateTime(new Date()),
        idUsuario: this.$store.state.usuario.id,
        usuario: this.$store.state.usuario.nome,
        dtCriacao: null,
        dtCancelamento: null,
        descricao: "",
        idCliente: null,
        idDeptoCliente: null,
        vlrBruto: 0,
        vlrLiquido: 0,
        vlrCredito: 0,
        nroParcelas: 0,
        //frete: 9,
        duplicatas: 1,
        situacao: 1,
        mensal: 0,
        confirmada: 1,
        autorizacao: null,
        online: 0,
        rede: 0,
        emailCobranca: 0,
        descComissao: 0
      },
      validacao: {
        idSite: false,
        tipo: false,
        status: false,
        dtOperacao: false,
        dtCriacao: false,
        dtCancelamento: false,
        descricao: false,
        idCliente: false,
        idDeptoCliente: false,
        vlrBruto: false,
        vlrLiquido: false,
        vlrCredito: false,
        nroParcelas: false,
        //frete: false,
        duplicatas: false,
        mensal: false,
        confirmada: false,
        situacao: false,
        autorizacao: false,
        online: false,
        rede: false,
        emailCobranca: false,
        descComissao: false,
        mensagemErro: false,
        dtVecto: false,
        idAgente: false,
        comissao: false,
        parcelas: false,
        anuncios: false
      },
      vlrUtilizado: 0,
      vlrSaldo: 0,

      idClienteEndereco: null,
      idClienteCartao: null,
      idClienteConta: null,
      idClienteCelesc: null,

      agentes: [],
      parcelas: [],

      clientes: [],
      departamentosCliente: [],
      agentesOrigem: [],
      formasPgto: [],
      formaPagamentoSelecionada: { id: null, nome: "", tipo: "" },
      clienteSelecionado: {},
      dtVecto: null,
      dtVectoUS: null,
      mnuDtVecto: false,

      //* Dados para o componente DialogParcela
      dialogParcela: false,
      idParcela: "",

      agenteSelecionado: {},
      dialogAgente: false,
      headerAgentes: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Agente/Agência", value: "agente" },
        { text: "Comissão(%)", value: "comissao", align: "end" }
      ],
      agenteValid: true,
      agenteIndex: -1,
      agenteItem: {
        id: null,
        idAgente: null,
        agente: "",
        comissao: 0
      },
      defaultAgenteItem: {
        id: null,
        idAgente: null,
        agente: "",
        comissao: 0
      },

      dialogAjuste: false,
      ajusteValid: true,
      ajusteIndex: -1,
      ajusteItem: {
        valor: 0,
        dtVectoOriginal: null,
        dtVectoO: null, //* para apresentação
        liquido: 1
      },

      ajusteItemDtVectoO: null,
      ajusteItemDtVectoOUS: null,
      mnuAjusteItemDtVectoO: false,

      headerParcelas: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Parcela", value: "numero" },
        { text: "Status", value: "status" },
        { text: "Forma de Pagamento", value: "formaPagamento" },
        { text: "Vencimento Original", value: "dtVectoOriginal" },
        { text: "Promessa", value: "dtPromessa" },
        { text: "Valor", value: "valor", align: "end" }
      ],

      headerAnuncios: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Status", value: "status" },
        { text: "Data Criação", value: "dtCriacao" },
        { text: "Produto", value: "nomeProduto" },
        { text: "Caderno", value: "nomeCaderno" },
        { text: "Formato", value: "nomeFormato" },
        { text: "Início", value: "dtInicial" },
        { text: "Fim", value: "dtFinal" },
        { text: "Utilizado", value: "vlrUtilizado", align: "end" }
      ],
      rodapeAnuncios: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50, 100]
      },
      anuncios: [],

      dialogConfirm: false,
      tipoConfirm: "",
      msgConfirm: "",

      idSitePrincipal: null,
      editar: true,
      emCobranca: false,
      painel: [0],
      search: "",
      nfNaoEmitida: true,
      loadingClientes: false,
      direitoCriar: false,
      direitoCancelar: false,
      direitoAnunciante: false,
      direitoCriarAnuncio: false,
      direitoEditarAnuncio: false,
      direitoParcelas: false,
      direitoDescComissao: false,
      direitoComissao: false,

      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,
      snackRefresh: false,

      isUpdating: false,
      bloqueiaBotoes: false
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    getItemActions() {
      return "item.actions";
    },
    getItemDtVecto() {
      return "item.dtVecto";
    },
    getItemDtVectoOriginal() {
      return "item.dtVectoOriginal";
    },
    getItemDtPromessa() {
      return "item.dtPromessa";
    },
    getItemValor() {
      return "item.valor";
    },
    getItemDtCriacao() {
      return "item.dtCriacao";
    },
    getItemVlrUtilizado() {
      return "item.vlrUtilizado";
    },
    getItemDtInicial() {
      return "item.dtInicial";
    },
    getItemDtFinal() {
      return "item.dtFinal";
    },

    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      var erro = false;
      if (this.agentes.length == 0) {
        erro = true;
        this.validacao.agentes = "Selecione pelo menos 1 agente/agência";
      }
      if (this.agentes.length > 2) {
        erro = true;
        this.validacao.agentes = "Máximo de agentes permitidos: 2";
      }

      if (this.parcelas.length == 0) {
        erro = true;
        this.validacao.parcelas = "Inclua pelo menos 1 parcela";
      }

      if (!this.pacote.vlrCredito || parseFloat(this.pacote.vlrCredito) == 0) {
        this.validacao.vlrCreditos = "Informe o valor de créditos";
        erro = true;
      }

      //* Prepara os agentes da pacote para envio a base
      var agentesCommit = [];
      var agentesVal = [];
      var agenteDuplicado = "";
      this.agentes.forEach((element) => {
        if (agentesVal.includes(element.agente)) {
          agenteDuplicado = element.agente;
        }
        agentesVal.push(element.agente);
        agentesCommit.push({
          id: element.id,
          idOperacao: this.pacote.idOperacao,
          idAgente: element.idAgente,
          comissao: element.comissao
        });
      });
      if (agenteDuplicado) {
        this.validacao.agentes = "Agente/Agência duplicado [" + agenteDuplicado + "]";
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      var vlrTotal = 0;
      this.parcelas.forEach((element) => {
        var vlr = parseFloat(element.valor);
        vlr = vlr.toFixed(2); //deixa vlr como string
        if (!util.isFormaPgtoValorZero(this.formaPagamentoSelecionada.tipo) && parseFloat(vlr) <= 0) {
          erro = true;
          this.validacao.parcelas = "Não pode haver parcela com valor zero";
        }
        vlrTotal += parseFloat(vlr); //converte vlr para float
        vlrTotal = vlrTotal.toFixed(2); //deixa só 2 decimais após a soma
        vlrTotal = parseFloat(vlrTotal); //converte novamente para float, pois toFixed deixa como string
      });
      if (vlrTotal != parseFloat(this.pacote.vlrLiquido)) {
        erro = true;
        this.validacao.parcelas = "Soma das parcelas deve ser igual ao valor líquido";
      }

      if (erro) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      this.isUpdating = true;
      try {
        var pacoteCommit = {
          idSite: this.idSitePrincipal,
          status: "CORRENTE",
          autorizacao: this.pacote.autorizacao,
          vlrCredito: this.pacote.vlrCredito,
          descComissao: this.pacote.descComissao
        };

        pacoteCommit.agentes = agentesCommit;
        pacoteCommit.parcelas = this.parcelas;

        var operacaoCommit = {};

        var retorno = "";
        if (this.id) {
          operacaoCommit = {
            id: this.pacote.idOperacao,
            tipo: 1,
            origem: "PAC",
            dtCriacao: this.pacote.dtCriacao,
            dtOperacao: this.pacote.dtOperacao,
            vlrBruto: this.pacote.vlrCredito ? this.pacote.vlrCredito : this.pacote.vlrLiquido, //para registrar a diferença como acréscimo ou desconto - se está vendendo pacote sem créditos, usa vlrLiquido como vlrBruto
            vlrTaxado: 0,
            vlrLiquido: this.pacote.vlrLiquido,
            //frete: this.pacote.frete,
            duplicatas: this.pacote.duplicatas,
            descricao: this.pacote.descricao,
            nroParcelas: this.parcelas.length,
            mensal: 0,
            confirmada: 1,
            formaPagamentoTipo: this.formaPagamentoSelecionada.tipo
          };
          pacoteCommit.operacao = operacaoCommit;

          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "com/pacote/principal/" + this.id, pacoteCommit);
        } else {
          operacaoCommit = {
            idSite: this.pacote.idSite,
            tipo: 1,
            idCliente: this.pacote.idCliente,
            idDeptoCliente: this.pacote.idDeptoCliente,
            origem: "PAC",
            vlrBruto: this.pacote.vlrCredito ? this.pacote.vlrCredito : this.pacote.vlrLiquido, //para registrar a diferença como acréscimo ou desconto - se está vendendo pacote sem créditos, usa vlrLiquido como vlrBruto
            vlrTaxado: 0,
            vlrLiquido: this.pacote.vlrLiquido,
            nroParcelas: this.parcelas.length,
            //frete: 9,
            duplicatas: 1,
            descricao: this.pacote.descricao,
            mensal: 0,
            confirmada: 1,
            idUsuario: this.$store.state.usuario.id
          };
          pacoteCommit.operacao = operacaoCommit;

          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "com/pacote/novo?idSite=" + this.idSitePrincipal, pacoteCommit);
          this.id = retorno.data.id;
          this.pacote.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "false") {
          this.$router.push("/comercial/pacote/" + this.id);
          this.$router.go("/comercial/pacote/" + this.id);
        } else if (novo == "true") {
          this.$router.push("/comercial/pacote");
          this.$router.go("/comercial/pacote");
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/comercial/pacotes");
          }, 2000);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },
    cancela() {
      this.dialogConfirm = true;
    },
    async cancelar() {
      try {
        await api.put(process.env.VUE_APP_URL_WEB_API + "com/pacote/cancelar/" + this.id);
        var timeout = 0;
        if (this.anuncios.length) {
          this.mensagem = "Pacote contém anúncios - Saldo foi zerado.";
          this.snackErro = true;
          timeout = 3000;
        }
        setTimeout(() => {
          this.$router.push("/comercial/pacote/" + this.id);
          this.$router.go("/comercial/pacote/" + this.id);
        }, timeout);
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },

    async gerarParcelas() {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }
      if (this.agentes.length == 0) {
        this.validacao.agentes = "Selecione pelo menos 1 agente/agência";
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }
      this.parcelas = [];

      try {
        var vlrTotal = parseFloat(this.pacote.vlrLiquido);
        var nroParcelas = parseInt(this.pacote.nroParcelas);
        var dtVecto = util.parseDate(this.dtVecto);
        const dia = dtVecto.getDate();

        if (util.isFormaPgtoValorZero(this.formaPagamentoSelecionada.tipo)) {
          if (vlrTotal != 0) {
            this.mensagem = "Valor líquido deve ser 0,00 para CORTESIA, BONIFICAÇÃO ou COMPENSAÇÃO";
            this.snackErro = true;
            return;
          }
          if (parseInt(this.pacote.nroParcelas) != 1) {
            this.mensagem = "Número de parcelas deve ser 1 para CORTESIA, BONIFICAÇÃO ou COMPENSAÇÃO";
            this.snackErro = true;
            return;
          }

          //* Cria a parcela para CORTESIA, BONIFICAÇÃO ou COMPENSAÇÃO
          var parcela = {
            id: null,
            idOperacao: null,
            indice: null,
            numero: 1,
            status: "QUITADA",
            idFormaPgto: this.formaPagamentoSelecionada.id,
            formaPagamento: this.formaPagamentoSelecionada.nome,
            formaPagamentoTipo: this.formaPagamentoSelecionada.tipo,
            dtVectoOriginal: dtVecto,
            dtVecto: dtVecto,
            dtQuitacao: dtVecto,
            idClienteCartao: this.idClienteCartao,
            idClienteConta: this.idClienteConta,
            idClienteEndereco: this.idClienteEndereco,
            idClienteCelesc: this.idClienteCelesc,
            valor: 0,
            descAcr: 0,
            obsCobranca: util.removeEspeciais(util.removeAcentos(this.pacote.descricao)),
            ajustada: 0
          };
          this.parcelas.push(parcela);
        } else {
          if (vlrTotal / nroParcelas < 0.01) {
            throw "O valor líquido é incompatível com o número de parcelas";
          }
          if (util.isFormaPgto1Parcela(this.formaPagamentoSelecionada.tipo)) {
            if (parseInt(this.pacote.nroParcelas) != 1) {
              this.mensagem = "Número de parcelas deve ser 1 para esta forma de pagamento";
              this.snackErro = true;
              return;
            }
            dtVecto = new Date();
          }

          var vlrRestante = vlrTotal;
          for (let index = 1; index <= nroParcelas; index++) {
            var vlr = vlrTotal / nroParcelas;
            vlr = vlr.toFixed(2);
            if (index == nroParcelas) {
              vlr = vlrRestante;
            } else {
              vlrRestante -= parseFloat(vlr);
              vlrRestante = vlrRestante.toFixed(2);
            }

            const dtVencimento = new Date(dtVecto.getFullYear(), dtVecto.getMonth(), dtVecto.getDate());
            var parcela2 = {
              id: null,
              idOperacao: null,
              indice: null,
              numero: index,
              status: "ABERTA",
              idFormaPgto: this.formaPagamentoSelecionada.id,
              formaPagamento: this.formaPagamentoSelecionada.nome,
              formaPagamentoTipo: this.formaPagamentoSelecionada.tipo,
              dtVectoOriginal: dtVencimento,
              dtVecto: dtVencimento,
              dtQuitacao: null,
              idClienteCartao: this.idClienteCartao,
              idClienteConta: this.idClienteConta,
              idClienteEndereco: this.idClienteEndereco,
              idClienteCelesc: this.idClienteCelesc,
              valor: vlr,
              descAcr: 0,
              obsCobranca: util.removeEspeciais(util.removeAcentos(this.pacote.descricao)),
              ajustada: 0
            };
            this.parcelas.push(parcela2);
            var dtUltDiaProxMes = new Date(dtVecto.getFullYear(), dtVecto.getMonth() + 2, 0);
            dtVecto.setMonth(dtVecto.getMonth() + 1);
            if (dia <= dtUltDiaProxMes.getDate()) {
              dtVecto.setDate(dia);
            }
            if (dia > dtUltDiaProxMes.getDate()) {
              dtVecto.setDate(dtVecto.getDate() - (dia - dtUltDiaProxMes.getDate()));
            }
          }

          //* Se houve desconto de comissão, aplica nas parcelas, reduzindo o líquido em x%
          var comissao = 0;
          if (this.pacote.descComissao) {
            comissao = this.agentes[0].comissao;
            if (!comissao) {
              this.mensagem = "Desconto de comissão não aplicado, pois não há comissão do primeiro agente";
              this.pacote.descComissao = 0;
              this.snackErro = true;
            }
          }
          var vlrP = 0;
          this.parcelas.forEach((element) => {
            element.formaPagamento = this.formaPagamentoSelecionada.nome;
            if (comissao) {
              element.vlrBruto = element.valor;
              element.valor = element.vlrBruto * (1 - comissao / 100);
              element.valor = parseFloat(element.valor.toFixed(2));
            }
            element.valorBruto = element.vlrBruto;
            vlrP += parseFloat(element.valor);
          });
          this.pacote.vlrLiquido = vlrP;
        }
      } catch (e) {
        this.mensagem = "Erro ao gerar as parcelas: " + e;
        this.snackErro = true;
      }
    },

    async incluirAnuncio() {
      if (this.direitoCriarAnuncio) {
        window.open("/comercial/anuncio?idCliente=" + this.pacote.idCliente, "_blank");
      }
    },

    async buscarCliente() {
      if (this.loadingClientes || !this.search) {
        return;
      }

      this.loadingClientes = true;

      var filtro = "";
      if (util.valida_cpf(this.search) || util.valida_cnpj(this.search)) {
        filtro = "identificacao=" + this.search;
      } else {
        filtro = "nome=" + this.search + "&nomeFantasia=" + this.search;
      }

      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente?" + filtro + "&tela=cliente" + "&ordenacao=nome&ordem=asc&pagina=1&tamanho=50");
      if (response.data.data) {
        this.clientes = response.data.data;
        if (this.clientes.length == 1) {
          this.pacote.idCliente = this.clientes[0].id;
          this.recarregarItensCliente(this.clientes[0]);
        }
      }

      this.loadingClientes = false;
    },

    async carregarDepartamentosCliente() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-departamento/cliente/" + this.pacote.idCliente + "?ativos=1");
        if (response.data) {
          this.departamentosCliente = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os departamentos do cliente";
        this.snackErro = true;
      }
    },

    recarregarItensCliente(item) {
      this.clienteSelecionado = item;
      this.pacote.idCliente = this.clienteSelecionado.id;
      this.carregarDepartamentosCliente();
      if (!this.id && this.formaPagamentoSelecionada.id) {
        const formaPgto = this.formaPagamentoSelecionada;
        this.formaPagamentoSelecionada = null;
        this.limpaParcelas();
        this.formaPagamentoSelecionada = formaPgto;
      }
    },

    async carregarFormasPgto() {
      try {
        //* Busca todas as formas de cobrança a receber ativas - para aplicação nas parcelas - alteração será feita na própria parcela depois
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/forma-pagamento/lista?idSite=" + this.idSitePrincipal + "&idUsuario=" + this.$store.state.usuario.id);
        if (response.data) {
          response.data.forEach((element) => {
            if (!["PERMUTA_PAGAR", "PERMUTA_RECEBER", "PACOTE"].includes(element.tipo)) {
              this.formasPgto.push(element);
            }
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de formas de pagamento";
        this.snackErro = true;
      }
    },

    async carregarAgentes() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-agente/lista?idSite=" + this.idSitePrincipal);
        if (response.data) {
          this.agentesOrigem = response.data;
          this.agentesOrigem.forEach((element) => {
            element.agente = element.nome;
            element.idAgente = element.id;
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de agentes de venda";
        this.snackErro = true;
      }
    },

    async carregarAgentesPacote() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/operacao-agente/operacao/" + this.pacote.idOperacao);
        if (response.data) {
          this.agentes = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os agentes de venda do pacote";
        this.snackErro = true;
      }
    },

    async carregarParcelasPacote() {
      try {
        this.parcelas = [];
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/parcela/pacote/" + this.pacote.id);
        if (response.data) {
          this.parcelas = response.data;
          if (this.parcelas.length > 0) {
            var parcela = this.parcelas[0];
            this.formaPagamentoSelecionada.id = parcela.idFormaPgto;
            this.formaPagamentoSelecionada.nome = parcela.formaPagamento;
            this.formaPagamentoSelecionada.tipo = parcela.formaPagamentoTipo;
            this.pacote.nroParcelas = this.parcelas.length;
            this.dtVectoUS = util.dateToStringUS(this.parcelas[this.parcelas.length - 1].dtVecto);
          }
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as parcelas do pacote";
        this.snackErro = true;
      }
    },

    async carregarAnunciosPacote() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "com/anuncio-pacote/pacote/" + this.pacote.id);
        if (response.data) {
          this.anuncios = response.data;
          this.vlrSaldo = this.pacote.vlrCredito;
          this.vlrUtilizado = 0;
          this.rodapeAnuncios.pageText = "";
          this.anuncios.forEach((element) => {
            this.vlrSaldo -= element.vlrUtilizado;
            this.vlrUtilizado += element.vlrUtilizado;
          });
          this.rodapeAnuncios.pageText = "Total Utilizado: " + util.formatCurrency(this.vlrUtilizado, this.$store.state.usuario.site_pais, true);
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os anúncios do pacote";
        this.snackErro = true;
      }
    },

    async carregarEnderecos() {
      try {
        //* Carrega os endereços de cobrança do cliente
        const responseEnderecosCliente = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-endereco/lista?idCliente=" + this.pacote.idCliente + "&cobranca=1");
        const enderecosCliente = responseEnderecosCliente.data;
        if (enderecosCliente.length > 0) {
          this.idClienteEndereco = enderecosCliente[0].id;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os endereços do cliente";
        this.snackErro = true;
      }
    },
    async carregarCartoes() {
      try {
        //* Carrega os cartões de crédito do cliente
        const responseCartoesCliente = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-cartao/lista?idCliente=" + this.pacote.idCliente);
        const cartoesCliente = responseCartoesCliente.data;
        if (cartoesCliente.length > 0) {
          this.idClienteCartao = cartoesCliente[0].id;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os cartões de crédito do cliente";
        this.snackErro = true;
      }
    },
    async carregarCelescs() {
      try {
        //* Carrega as unidades consumidoras do cliente
        const responseCelescsCliente = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-celesc/lista?idCliente=" + this.pacote.idCliente);
        const celescsCliente = responseCelescsCliente.data;
        if (celescsCliente.length > 0) {
          this.idClienteCelesc = celescsCliente[0].id;
        }
      } catch (e) {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as unidades consumidoras do cliente: " + e;
        this.snackErro = true;
      }
    },
    async carregarContas() {
      try {
        //* Carrega as contas correntes do cliente
        const responseContasCliente = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-conta/lista?idCliente=" + this.pacote.idCliente);
        const contasCliente = responseContasCliente.data;
        if (contasCliente.length > 0) {
          this.idClienteConta = contasCliente[0].id;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as contas corrente do cliente";
        this.snackErro = true;
      }
    },
    visualizarCliente() {
      if (this.direitoAnunciante) {
        window.open("/comercial/anunciante/" + this.pacote.idCliente, "_blank");
      }
    },
    visualizarAnuncio(item) {
      if (this.direitoEditarAnuncio) {
        window.open("/comercial/anuncio/" + item.id, "_blank");
      }
    },
    visualizarParcelas() {
      if (this.direitoParcelas) {
        window.open("/financeiro/parcelas/receber?idPacote=" + this.pacote.id, "_blank");
      }
    },

    async removerAgente(item) {
      if (!this.emCobranca) {
        this.agenteIndex = this.agentes.indexOf(item);
        this.agenteItem = Object.assign({}, item);
        this.agentes.splice(this.agenteIndex, 1);
        this.agenteItem = Object.assign({}, this.defaultAgenteItem);
        this.agenteIndex = -1;
        this.agenteSelecionado = {};
      } else {
        this.mensagem = "ATENÇÃO: Existe parcela em cobrança ou com nota fiscal emitida.";
        this.snackErro = true;
      }
    },
    closeAgente() {
      this.dialogAgente = false;
      setTimeout(() => {
        this.agenteItem = Object.assign({}, this.defaultAgenteItem);
        this.agenteIndex = -1;
        this.agenteSelecionado = {};
        this.$refs.formAgente.resetValidation();
      }, 300);
    },
    async saveAgente() {
      if (this.$refs.formAgente.validate()) {
        var incluido = false;
        this.agenteItem.idAgente = this.agenteSelecionado.idAgente;
        this.agenteItem.descMaximoCom = this.agenteSelecionado.descMaximoCom;
        this.agentes.forEach((element) => {
          if (this.agenteItem.idAgente == element.idAgente) {
            incluido = true;
            this.mensagem = "Agente/Agência já foi incluído";
            this.snackErro = true;
          }
        });
        if (this.agentes.length == 2) {
          this.validacao.idAgente = "Máximo 2 agentes de venda por operação (1º Agência, 2º Agente)";
          incluido = true;
        }

        if (this.emCobranca) {
          this.mensagem = "ATENÇÃO: Existe parcela em cobrança ou com nota fiscal emitida.";
          this.snackErro = true;
          this.closeAgente();
          return;
        }

        if (!incluido) {
          if (this.agenteIndex > -1) {
            Object.assign(this.agentes[this.agenteIndex], this.agenteItem);
          } else {
            if (this.agentes.length == 0 && this.agenteSelecionado.descComissao) {
              this.agenteItem.comissao = this.agenteSelecionado.comissaoPadraoCom;
              this.pacote.descComissao = 1;
              if (this.parcelas.length > 0) {
                this.limpaParcelas();
              }
            } else if (this.agenteSelecionado.comissaoPadraoCom) {
              this.agenteItem.comissao = this.agenteSelecionado.comissaoPadraoCom;
            }

            this.agentes.push(this.agenteItem);
          }
          this.closeAgente();
          this.agenteItem = Object.assign({}, this.defaultAgenteItem);
          this.agenteIndex = -1;
          this.agenteSelecionado = {};
          this.$refs.formAgente.resetValidation();
          this.validacao.agentes = false;
        }
      }
    },

    editarAjuste(item) {
      this.ajusteIndex = this.parcelas.indexOf(item);
      this.ajusteItem = Object.assign({}, item);

      this.ajusteItemDtVectoO = util.formatDate(this.ajusteItem.dtVectoOriginal);
      this.ajusteItem.liquido = 1;
      this.validacao.ajuste_valor = false;
      this.validacao.ajuste_vecto = false;
      this.dialogAjuste = true;
    },
    closeAjuste() {
      this.dialogAjuste = false;
    },
    saveAjuste() {
      this.validacao.ajuste_valor = false;
      this.validacao.ajuste_vecto = false;
      this.validacao.parcelas = false;

      var vlrOperacao = parseFloat(this.pacote.vlrLiquido);

      this.ajusteItem.dtVecto = util.parseDate(this.ajusteItemDtVectoO);
      this.ajusteItem.dtVectoOriginal = util.parseDate(this.ajusteItemDtVectoO);
      if (this.ajusteItem.liquido) {
        if (parseFloat(this.ajusteItem.valor) > vlrOperacao) {
          this.validacao.ajuste_valor = "Valor da parcela não pode ser superior ao valor da operação";
          return;
        }
      }
      if (this.ajusteIndex > 0) {
        var dtAnt = this.parcelas[this.ajusteIndex - 1].dtVectoOriginal;
        var dtV = this.ajusteItem.dtVectoOriginal;
        if (dtV < dtAnt) {
          this.validacao.ajuste_vecto = "Vencimento deve ser posterior ao vencimento da parcela anterior";
          return;
        }
      }
      if (this.$refs.formAjuste.validate()) {
        if (this.ajusteIndex > -1) {
          Object.assign(this.parcelas[this.ajusteIndex], this.ajusteItem);
        } else {
          this.parcelas.push(this.ajusteItem);
        }
      }

      var nroParcelas = this.parcelas.length - 1;
      var vlrRestante = vlrOperacao;
      var vlrAjuste = vlrOperacao;
      if (this.ajusteItem.liquido) {
        for (var x = 0; x <= nroParcelas; x++) {
          var vlr = 0;
          if (parseInt(this.ajusteIndex) >= x) {
            vlr = parseFloat(this.parcelas[x].valor);
            vlrRestante -= vlr;
          } else {
            if (x < nroParcelas) {
              vlr = vlrRestante / (nroParcelas - this.ajusteIndex);
              vlr = vlr.toFixed(2);
              this.parcelas[x].valor = vlr;
            } else {
              this.parcelas[x].valor = vlrAjuste;
            }
          }
          vlrAjuste -= parseFloat(vlr);
          vlrAjuste = vlrAjuste.toFixed(2);
        }
      }
      var vlrTotal = 0;
      this.parcelas.forEach((element) => {
        var vlr = parseFloat(element.valor);
        vlr = vlr.toFixed(2);
        if (parseFloat(vlr) <= 0) {
          this.validacao.parcelas = "Não pode haver parcela com valor zero";
        }
        dtAnt = element.dtVectoOriginal;
        vlrTotal += parseFloat(vlr);
        vlrTotal = vlrTotal.toFixed(2);
        vlrTotal = parseFloat(vlrTotal);
      });
      if (this.ajusteItem.liquido) {
        if (vlrTotal != vlrOperacao) {
          this.validacao.parcelas = "Soma das parcelas deve ser igual ao valor líquido";
        }
      } else {
        this.pacote.vlrLiquido = vlrTotal;
      }
      if (this.$refs.formAjuste.validate()) {
        this.closeAjuste();
        this.$refs.formAjuste.resetValidation();
      }
    },

    editarParcela(item) {
      this.idParcela = item.id + "";
      this.dialogParcela = true;
    },
    closeParcela() {
      this.dialogParcela = false;
      this.idParcela = "";
    },

    confirmado() {
      this.dialogConfirm = false;
      this.cancelar();
    },
    naoConfirmado() {
      this.dialogConfirm = false;
    },

    async limpaParcelas() {
      if (this.parcelas.length > 0) {
        if (!this.emCobranca) {
          this.mensagem = "Parcelas foram redefinidas!";
          this.snackErro = true;
          this.parcelas = [];
        } else {
          this.mensagem = "ATENÇÃO: Existe parcela em cobrança ou com nota fiscal emitida.";
          this.snackErro = true;
        }
      }
    },

    async existeParcelaEmCobranca() {
      try {
        //* Verifica se há alguma parcela da operação do pacote em cobrança
        if (this.pacote.idOperacao) {
          const responseCobranca = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/parcela/emCobranca/" + this.pacote.idOperacao);
          if (responseCobranca.data && responseCobranca.data.id) {
            this.editar = false;
            this.emCobranca = true;
          }
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao verificar as parcelas em cobrança";
        this.snackErro = true;
      }
    },

    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    filtrarObjetosIdentificacao(item, queryText, itemText) {
      return util.filtrarObjetosIdentificacao(item, queryText, itemText);
    },
    pLetra(text) {
      return util.pLetra(text);
    },
    valida_nome(text) {
      return util.valida_nome(text);
    },
    valida_credito(valor) {
      var retorno = true;
      if (valor && this.vlrUtilizado) {
        const vlr = parseFloat(valor.replace(".", "").replace(" ", "").replace(",", "."));
        if (vlr < this.vlrUtilizado) {
          retorno = "Informe um valor superior ao Utilizado";
        } else {
          this.vlrSaldo = this.vlrUtilizado - vlr;
        }
      } else if (valor) {
        const vlr = parseFloat(valor.replace(".", "").replace(" ", "").replace(",", "."));
        if (!vlr) {
          retorno = "Informe o valor do crédito";
        }
      } else {
        retorno = "Informe o valor do crédito";
      }
      return retorno;
    },
    formatDate(date) {
      return util.formatDate(date);
    },
    formatDateTime(datetime) {
      return util.formatDateTime(datetime);
    },
    formatCurrency(valor) {
      return util.formatCurrency(valor);
    },
    valida_data(date) {
      if (date) {
        return util.valida_data_BR(date);
      } else {
        return true;
      }
    },
    formatDateUS(date) {
      if (date && date.length == 10) {
        return util.formatDateUS(date);
      }
    },
    valida_data_vecto(date) {
      if (date) {
        var retorno = util.valida_data_BR(date);
        if (!retorno) {
          return false;
        }
        const dtOp = this.pacote.dtOperacao ? util.parseDateUS(this.pacote.dtOperacao) : new Date();
        const dtV = util.parseDateTime(date + " 23:59:59");
        if (dtV < dtOp) {
          return false;
        }
        return true;
      } else {
        return true;
      }
    }
  },
  watch: {
    clienteSelecionado(val) {
      if (val) {
        this.recarregarItensCliente(val);
      } else {
        this.clientes = [];
        this.pacote.idCliente = null;
      }
    },
    agenteSelecionado(val) {
      this.validacao.idAgente = "";
      this.validacao.comissao = "";
      this.agenteItem.idAgente = val.idAgente;
      this.agenteItem.agente = val.agente;
    },
    dialogParcela(val) {
      if (val == null) {
        this.carregarParcelasPacote();
      }
      val || this.closeParcela();
    },
    dialogCentroCusto(val) {
      val || this.closeCentroCusto();
    },
    dtVectoUS() {
      this.dtVecto = util.formatDate(this.dtVectoUS);
      this.mnuDtVecto = false;
    },
    ajusteItemDtVectoOUS() {
      this.ajusteItemDtVectoO = util.formatDate(this.ajusteItemDtVectoOUS);
      this.mnuAjusteItemDtVectoO = false;
    },
    formaPagamentoSelecionada(val) {
      this.idClienteEndereco = null;
      this.idClienteCartao = null;
      this.idClienteConta = null;
      this.idClienteCelesc = null;
      if (val.tipo == "BOLETO" || val.tipo == "CARTEIRA") {
        this.carregarEnderecos();
      }
      if (val.tipo == "CARTAO_CREDITO") {
        this.carregarCartoes();
      }
      if (val.tipo == "CELESC" || val.tipo == "SAMAE") {
        this.carregarCelescs();
      }
      if (val.tipo == "DEBITO_CONTA") {
        this.carregarContas();
      }
    }
  },
  async created() {
    this.isUpdating = false;
    this.bloqueiaBotoes = true;
    this.id = this.$route.params.id ? this.$route.params.id : "";

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach((element) => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_PACOTES_CRIAR") {
        this.direitoCriar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_PACOTES_CANCELAR") {
        this.direitoCancelar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_ANUNCIANTES_EDITAR") {
        this.direitoAnunciante = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_ANÚNCIOS_CRIAR") {
        this.direitoCriarAnuncio = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_ANÚNCIOS_EDITAR") {
        this.direitoEditarAnuncio = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_ACESSO") {
        this.direitoParcelas = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_COMERCIAL_DEFINIR_COMISSÃO") {
        this.direitoComissao = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_COMERCIAL_EDITAR_DESCONTO_COMISSÃO") {
        this.direitoDescComissao = true;
      }
    });

    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

    await this.carregarFormasPgto();
    await this.carregarAgentes();

    //* Se é edição, carrega o item
    if (this.id == "") {
      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "COMERCIAL_PACOTES_CRIAR"]);
      this.dtVectoUS = util.dateToStringUS(new Date());
    } else {
      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "COMERCIAL_PACOTES_EDITAR"]);
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "com/pacote/" + this.id + "?idSite=" + this.idSitePrincipal);
        if (!response.data) {
          // || response.data.idRenovacao
          this.$router.push("/comercial/pacotes");
        } else {
          this.pacote = response.data;
          if (this.pacote.status != "CORRENTE") {
            this.editar = false;
          } else {
            //* Se há parcela em cobrança, não permite alterar o pacote
            await this.existeParcelaEmCobranca();
          }

          this.pacote.dtOp = util.formatDateTime(this.pacote.dtCriacao);

          await this.carregarParcelasPacote();
          await this.carregarAgentesPacote();
          await this.carregarAnunciosPacote();

          this.clientes.push({
            id: this.pacote.idCliente,
            nome: this.pacote.cliente,
            diaVencimento: this.pacote.diaVencimento
          });
          this.clienteSelecionado = {
            id: this.pacote.idCliente,
            nome: this.pacote.cliente,
            diaVencimento: this.pacote.diaVencimento
          };

          //* Se NF de parcela desta operação foi emitida, não permitir alterar os campos que estavam liberados para alteração (inclusive agentes)
          const responseNF = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/parcela/comNF/" + this.pacote.idOperacao + "?idSite=" + this.idSitePrincipal);
          if (responseNF.data && responseNF.data.idOperacao) {
            this.nfNaoEmitida = false;
          }
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os dados do pacote";
        this.snackErro = true;
      }
    }
    setTimeout(() => {
      this.bloqueiaBotoes = false;
    }, 1000);
  }
};
</script>
