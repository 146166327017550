<template>
  <div id="inspire">
    <div class="fundo">
      <v-layout class="justify-center" justify-center>
        <v-card outlined color="rgba(0, 244, 255, 0.7)" class="elevation-12 pt-6 card-login">
          <v-card-text>
            <h1 style="color: white" class="display-2 font-weight-thin mb-2">
              Suita S.I.M <span style="font-size: 16px">{{ $store.state.versao }}</span>
            </h1>
            <h4 style="color: white" class="subheading mb-6">Entre com as informações para fazer o login!</h4>
            <v-form ref="formLogin" v-model="loginValid" lazy-validation>
              <v-text-field
                background-color="white"
                v-model="usuario.email"
                prepend-icon="person"
                maxlength="35"
                label="Login"
                filled
                hint="Obrigatório"
                :rules="[validacao.usuario == false || validacao.usuario, (value) => !!value || 'Obrigatório.']"
              ></v-text-field>
              <v-text-field
                background-color="white"
                v-model="usuario.senha"
                prepend-icon="lock"
                label="Senha"
                maxlength="35"
                filled
                type="password"
                hint="Obrigatório"
                :rules="[validacao.senha == false || validacao.senha, (value) => !!value || 'Obrigatório.']"
              ></v-text-field>

              <v-sheet class="ml-1 red--text" :value="true">{{ mensagemErro }}</v-sheet>
              <v-sheet class="ml-1 red--text" :value="true" style="padding: 20px" v-if="siteBloqueado"
                ><h3>{{ siteBloqueado }}</h3></v-sheet
              >
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-container>
              <v-layout wrap>
                <v-flex offset-xs1 xs12 sm3>
                  <v-btn class="btn-login" color="white lighten-2" block large @click="logar">Login</v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-layout>
    </div>
  </div>
</template>
<style scoped>
.fundo {
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: url("https://suita.com.br/thiago/fundo1.png");
}
.card-login {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  width: 400px;
}

@media screen and (max-width: 600px) {
  .card-login {
    width: 290px;
  }
  .btn-login {
    width: 90%;
    margin: 0 auto;
  }
}
</style>
<script>
import util from "../util";
import VueCookies from "vue-cookies";
export default {
  data() {
    return {
      usuario: {
        email: "",
        senha: ""
      },
      validacao: {
        usuario: false,
        senha: false
      },
      esqueci: false,
      dialogLogin: true,
      loginValid: true,
      mensagemErro: "",
      siteBloqueado: ""
    };
  },

  methods: {
    async logar() {
      var senha = this.usuario.senha;
      try {
        await util.limparObjeto(this.validacao, false);
        this.siteBloqueado = "";
        await this.$store.dispatch("logarUsuario", this.usuario); //loga o usuario
        await this.$store.dispatch("getUsuario", this.usuario.email); //se deu certo pega informações do usuário
        if (!util.somenteNumeros(senha) || senha == util.somenteNumeros(senha) || senha == senha.toUpperCase() || senha == senha.toLowerCase() || util.valida_nome(senha)) {
          this.mensagemErro = "Sua senha deve ser atualizada! Carregando seus dados...";

          setTimeout(() => {
            this.$router.push("/administrativo/usuario/" + this.$store.state.usuario.id);
          }, 6000);
          return;
        }

        try {
          if (this.$route.query && this.$route.query.redirect) {
            if (this.$route.query.redirect.startsWith("/")) {
              //* Retorna para a mesma página onde estava antes de fazer o logoff (se não tiver acesso irá para a página acesso negado)
              await this.$router.push(this.$route.query.redirect);
            } else {
              //* Envia para página externa
              window.location.href = this.$route.query.redirect;
            }
          } else {
            //* Se usuário só possui 1 módulo, vai para seu módulo
            var modulos = [];
            this.$store.state.usuario.direitos.forEach((element) => {
              if (element.modulo != "WEBSUITA") {
                if (modulos.length == 0) {
                  modulos.push(element.modulo);
                } else if (!modulos.includes(element.modulo)) {
                  modulos.push(element.modulo);
                }
              }
            });
            if (modulos.length == 1) {
              await this.$router.push("/" + modulos[0].toLowerCase() + "/desktop");
            } else {
              await this.$router.push("/");
            }
          }
        } catch {
          //* Se deu erro na lógica acima, como o usuário já está logado, vai para a raiz do sistema
          await this.$router.push("/");
        }
      } catch (e) {
        this.usuario.senha = senha;
        //valida se tem mensagem de retorno
        if (e.response && e.response.data) {
          if (e.response.data.length && e.response.data[0].campo == "siteBloqueado") {
            this.siteBloqueado = e.response.data[0].mensagem;
          } else {
            //função realiza todas as validações de erro
            util.validaErros(this.validacao, e.response.data);
          }
        }
        //realiza o dispatch para as mensagens
        this.$refs.formLogin.validate();
      }
    }
  },
  beforeMount() {
    if (VueCookies.get("erros") == "true") {
      this.validacao.usuario = "Usuário não possui acesso ao sistema";
    }
    VueCookies.set("erros", false);
  }
};
</script>
