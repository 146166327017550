<template>
  <form @submit.prevent="onSubmit" enctype="multipart/form-data">
    <v-container fluid grid-list-md>
      <v-layout wrap>
        <v-flex xs12 v-if="retorno && cedentes.length">
          <v-autocomplete
            v-model="cedente"
            :items="cedentes"
            :filter="filtrarObjetos"
            label="Selecione o Cedente"
            item-text="nome"
            item-value="id"
            no-data-text
            autocomplete="disabled"
            :name="Math.random()"
            return-object
          ></v-autocomplete>
        </v-flex>
        <v-flex xs12>
          <v-file-input v-model="file" ref="file" label="Selecione o arquivo"></v-file-input>
        </v-flex>
        <v-flex xs12>
          <v-btn color="success" @click="onSubmit">Enviar</v-btn>
        </v-flex>
        <v-flex xs12 v-if="mensagem">
          <v-sheet :value="true" :class="cor">{{ mensagem }}</v-sheet>
        </v-flex>
      </v-layout>
    </v-container>
  </form>
</template>
<script>
import { api } from "@/services/api.js";
import util from "../../util.js";
export default {
  props: {
    retorno: { type: Boolean, default: false },
    receber: { type: Boolean, default: false },
    extrato: { type: Boolean, default: false },
    beaudio: { type: Boolean, default: false },
    promocao: { type: Boolean, default: false },
    celesc: { type: Boolean, default: false },
    help: { type: Boolean, default: false }
  },
  data() {
    return {
      file: null,
      mensagem: "",
      cedente: null,
      cedentes: [],
      idSitePrincipal: null,
      cor: "blue--text"
    };
  },
  methods: {
    async onSubmit() {
      this.mensagem = "";
      if (this.file && (this.retorno || this.cedente || this.beaudio || this.promocao || this.help)) {
        const formData = new FormData();
        formData.append("file", this.file);

        var forma =
          this.beaudio || this.promocao || this.celesc || this.help
            ? ""
            : this.cedente.forma == "DEBITO_CONTA"
            ? "debitos-conta"
            : this.cedente.forma == "CELESC_OLD"
            ? "celescs"
            : this.cedente.forma == "SAMAE"
            ? "samaes"
            : this.cedente.forma == "BRR"
            ? "brr"
            : this.cedente.forma == "EXTRATO"
            ? "extratos"
            : "boletos";
        var caminhoRetorno = this.celesc
          ? `/${this.idSitePrincipal}/celescs/retornos/`
          : this.retorno
          ? `/${this.idSitePrincipal}/${forma}/${this.cedente.bancoNumero}/retornos/`
          : // +(this.cedente.forma == "BOLETO" && this.$store.state.usuario.site_multiCedenteBoleto ? this.cedente.id + "/" : "")
          this.beaudio
          ? `/${this.idSitePrincipal}/beaudio/`
          : this.promocao
          ? `/${this.idSitePrincipal}/promocoes/`
          : this.help
          ? `/${this.idSitePrincipal}/helps/`
          : "";
        try {
          await api.post(process.env.VUE_APP_URL_WEB_API + "upload?caminho=" + caminhoRetorno + "&idSite=" + this.idSitePrincipal, formData);
          this.cor = "blue--text";
          this.mensagem = "Arquivo enviado com sucesso!";
          this.file = null;
          if (this.promocao) {
            this.$emit("update:imagem", true);
          }
        } catch (e) {
          this.cor = "red--text";
          this.mensagem =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
        }
      } else {
        this.cor = "red--text";
        var msg1 = !this.cedente ? "um cedente" : "";
        var msg2 = !this.file ? "um arquivo" : "";
        this.mensagem = "Selecione " + msg1 + (msg1 && msg2 ? " e " : "") + msg2;
      }
    },
    async carregarCedentes() {
      this.mensagem = "";
      this.cedentes = [];
      if (this.extrato) {
        const responseExt = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/cedente/extrato?idSite=" + this.idSitePrincipal);
        if (responseExt.data && responseExt.data.length > 0) {
          this.cedentes = responseExt.data;
        }
      } else {
        if (this.receber) {
          const responseBoleto = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/cedente/boleto?idSite=" + this.idSitePrincipal);
          if (responseBoleto.data && responseBoleto.data.length > 0) {
            this.cedentes = responseBoleto.data;
          }

          const responseDebito = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/cedente/debito?idSite=" + this.idSitePrincipal);
          if (responseDebito.data && responseDebito.data.length > 0) {
            responseDebito.data.forEach((element) => {
              this.cedentes.push(element);
            });
          }
        } else if (!this.celesc) {
          const responseBRR = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/cedente/brr?idSite=" + this.idSitePrincipal);
          if (responseBRR.data && responseBRR.data.length > 0) {
            this.cedentes = responseBRR.data;
          }
        }
      }
      if (this.cedentes.length == 1) {
        this.cedente = this.cedentes[0];
      }
    },

    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    }
  },
  beforeMount() {
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
    this.carregarCedentes();
  },
  watch: {
    retorno() {
      this.carregarCedentes();
    },
    receber() {
      this.carregarCedentes();
    },
    extrato() {
      this.carregarCedentes();
    }
  }
};
</script>
