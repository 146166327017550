<template>
    <div id="app" class="fullscreen">
      <iframe 
        :src="iframeSrc" 
        frameborder="0" 
        class="iframe-fullscreen">
      </iframe>
    </div>
  </template>
  
  <script>
  import { mapMutations } from "vuex";
  export default {
    data() {
    return {
    name: "FullScreenIframe",
    iframeSrc:""
    }
  },
  methods: {
    ...mapMutations(["OCULTAR_MENU"]),
  },
  beforeMount() {
    this.OCULTAR_MENU();

    var urlApi = (window.location.host.includes("localhost") ? "http://localhost" : window.location.host) + "/";
    if (!urlApi.includes("http")) {
      urlApi = "https://" + urlApi;
    }
    this.iframeSrc = urlApi + "arqmidia/galeria.html#";


  }
  };


  </script>
  
  <style scoped>
  .fullscreen {
    height: 100vh; /* Altura de 100% da viewport */
    width: 100vw;  /* Largura de 100% da viewport */
    margin: 0;
    padding: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .iframe-fullscreen {
    height: 100%;
    width: 100%;
    border: none;
  }
  </style>
  