<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">people</v-icon>
      <v-toolbar-title style="color: white" class="font-weight-light">
        {{ rotaAssinante ? "Assinante" : rotaBanca ? "Banca" : rotaFornecedor ? "Fornecedor" : rotaAgente ? "Agente/Agência" : rotaAnunciante ? "Anunciante" : "Cliente" }}
      </v-toolbar-title>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12 md6 v-if="siteGrupo.length > 0">
            <v-autocomplete
              :disabled="isUpdating"
              v-model="cliente.idSite"
              :items="siteGrupo"
              :filter="filtrarObjetos"
              label="Restrito ao Site"
              item-text="nome"
              item-value="id"
              autocomplete="disabled"
              :name="Math.random()"
              clearable
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md6 v-if="$store.state.usuario.site_pais == 'BR'">
            <v-text-field
              v-model="cliente.identificacao"
              :label="'Identificação (' + tipoIdent(cliente.identificacao) + ')'"
              v-mask="'##############'"
              ref="focusIdentificacao"
              hint="Obrigatório"
              :rules="[validacao.identificacao == false || validacao.identificacao, (value) => !!value || 'Obrigatório.', (value) => valida_identificacao(value, true)]"
              :disabled="isUpdating || id != ''"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="isClienteExiste()"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 v-if="$store.state.usuario.site_pais != 'BR'">
            <v-text-field
              v-model="cliente.identificacao"
              :label="'Identificação (' + tipoIdent(cliente.identificacao) + ')'"
              v-mask="'XXXXXXXXXXXXXX'"
              maxlength="14"
              ref="focusIdentificacao"
              hint="Obrigatório"
              :rules="[validacao.identificacao == false || validacao.identificacao, (value) => !!value || 'Obrigatório.', (value) => valida_identificacao(value, true)]"
              :disabled="isUpdating || id != ''"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @keyup="cliente.identificacao = cliente.identificacao.toUpperCase()"
              @blur="erroIdentificacao = ''"
              @change="isClienteExiste()"
              :error-messages="erroIdentificacao"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md12>
            <v-text-field
              v-model="cliente.nome"
              :label="(pessoaFisica ? 'Nome' : 'Razão Social') + ' do Cliente'"
              maxlength="100"
              autofocus
              ref="focusNome"
              hint="Obrigatório"
              :rules="[
                validacao.nome == false || validacao.nome,
                (value) => !!value || 'Obrigatório.',
                (value) => value.length > 2 || 'Preencha ' + (pessoaFisica ? 'o nome' : 'a razão social') + ' do cliente'
              ]"
              :disabled="isUpdating || (id != '' && !direitoAvancado)"
              color="blue-grey lighten-2"
              @change="
                cliente.nome = pLetra(cliente.nome);
                validacao.nome = false;
              "
              autocomplete="disabled"
              :name="Math.random()"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md12>
            <v-text-field
              v-model="cliente.nomeFantasia"
              label="Nome Fantasia do Cliente"
              maxlength="100"
              hint="Obrigatório"
              :rules="
                pessoaFisica && $store.state.usuario.site_pais == 'BR'
                  ? []
                  : [
                      validacao.nomeFantasia == false || validacao.nomeFantasia,
                      (value) => !!value || 'Obrigatório.',
                      (value) => (value != null && value.length > 2) || 'Preencha o nome fantasia do cliente'
                    ]
              "
              :disabled="isUpdating || (pessoaFisica && $store.state.usuario.site_pais == 'BR')"
              color="blue-grey lighten-2"
              @change="
                cliente.nomeFantasia = pLetra(cliente.nomeFantasia);
                validacao.nomeFantasia = false;
              "
              autocomplete="disabled"
              :name="Math.random()"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md6>
            <v-autocomplete
              v-model="cliente.idRamo"
              :disabled="isUpdating || (id != '' && !direitoAvancado)"
              :items="ramos"
              :filter="filtrarObjetos"
              :rules="[validacao.idRamo == false || validacao.idRamo, (value) => !!value || 'Obrigatório.']"
              hint="Obrigatório"
              label="Selecione o Ramo de Atividade"
              item-text="nome"
              item-value="id"
              autocomplete="disabled"
              :name="Math.random()"
              no-data-text
              @change="validacao.idRamo = false"
            >
              <template v-slot:append-outer v-if="direitoRamos">
                <v-dialog persistent v-model="dialogRamo" max-width="600px">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" color="blue" fab small>
                      <v-icon color="white" title="Adicionar Ramo" style="position: relative">add</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <RamoAtividade :dialogRamo.sync="dialogRamo" />
                  </v-card>
                </v-dialog>
              </template>
            </v-autocomplete>
          </v-flex>

          <v-flex xs12 md6>
            <v-autocomplete
              v-model="cliente.statusCredito"
              :disabled="isUpdating || (id != '' && !direitoAlterarCredito)"
              :items="statusCreditos"
              :filter="filtrarObjetos"
              hint="Obrigatório"
              :rules="[validacao.statusCredito == false || validacao.statusCredito, (value) => value >= 0 || 'Obrigatório']"
              label="Selecione o Status de Crédito"
              item-text="nome"
              item-value="id"
              autocomplete="disabled"
              :name="Math.random()"
              no-data-text
              @change="validacao.statusCredito = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md6>
            <v-text-field
              v-model="cliente.diaVencimento"
              label="Melhor Dia de Vencimento"
              v-mask="'##'"
              hint="Obrigatório"
              :rules="[validacao.diaVencimento == false || validacao.diaVencimento, (value) => value == 0 || value < 32 || 'Dia deve ser de 1 a 31']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.diaVencimento = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md6>
            <v-text-field
              v-model="cliente.email"
              label="E-mail"
              maxlength="100"
              :disabled="isUpdating || (id != '' && !direitoAvancado)"
              color="blue-grey lighten-2"
              :rules="[validacao.email == false || validacao.email, (value) => !!value || 'Obrigatório.', (value) => valida_email_identificacao(value)]"
              @change="
                cliente.email = cliente.email.toLowerCase();
                isEmailExiste();
              "
              @blur="erroEmail = ''"
              autocomplete="disabled"
              :name="Math.random()"
              :error-messages="erroEmail"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md6>
            <v-text-field
              v-model="cliente.inscricaoEstadual"
              label="Inscrição Estadual"
              v-mask="'##############'"
              :rules="[validacao.inscricaoEstadual == false || validacao.inscricaoEstadual]"
              :disabled="isUpdating || pessoaFisica"
              color="blue-grey lighten-2"
              hint="Use 000 para ISENTO"
              @change="validacao.inscricaoEstadual = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md6>
            <v-text-field
              v-model="cliente.inscricaoMunicipal"
              label="Inscrição Municipal"
              v-mask="'####################'"
              :rules="[validacao.inscricaoMunicipal == false || validacao.inscricaoMunicipal]"
              :disabled="isUpdating || pessoaFisica"
              color="blue-grey lighten-2"
              @change="validacao.inscricaoMunicipal = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md6>
            <v-text-field
              v-model="dtCadastro"
              label="Data de Cadastro"
              v-mask="'##/##/####'"
              :rules="[validacao.dtCadastro == false || validacao.dtCadastro, (value) => valida_data_passada(value) || 'Data inválida']"
              :disabled="isUpdating || !direitoAvancado"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.dtCadastro = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md6>
            <v-text-field
              v-model="dtNascimento"
              :label="pessoaFisica ? 'Data de Nascimento' : 'Data de Fundação'"
              v-mask="'##/##/####'"
              :rules="[validacao.dtNascimento == false || validacao.dtNascimento, (value) => valida_data_nasc(value) || 'Data inválida']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.dtNascimento = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md6>
            <v-autocomplete
              v-model="cliente.sexo"
              :disabled="isUpdating || !pessoaFisica"
              :items="sexos"
              :filter="filtrarObjetos"
              hint="Obrigatório"
              :rules="pessoaFisica ? [validacao.sexo == false || validacao.sexo, (value) => value != null || 'Obrigatório'] : []"
              label="Selecione o Sexo do Cliente"
              item-text="nome"
              item-value="id"
              autocomplete="disabled"
              :name="Math.random()"
              no-data-text
              @change="validacao.sexo = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex md6>
            <v-text-field v-if="direitoVerSenha" v-model="cliente.senha" label="Senha" disabled color="blue-grey lighten-2"></v-text-field>
          </v-flex>

          <v-flex xs6 md3 v-if="cliente.codAntigoComercial && cliente.codAntigoComercial != '0'">
            <v-text-field v-model="cliente.codAntigoComercial" label="Código Comercial®" disabled color="blue-grey lighten-2"></v-text-field>
          </v-flex>

          <v-flex xs6 md3 v-if="cliente.codAntigoCirculacao && cliente.codAntigoCirculacao != '0'">
            <v-text-field v-model="cliente.codAntigoCirculacao" label="Código Circulação®" disabled color="blue-grey lighten-2"></v-text-field>
          </v-flex>

          <v-flex xs12> </v-flex>

          <v-flex xs12 md1>
            <v-switch
              v-model="cliente.situacao"
              :disabled="isUpdating || (id != '' && cliente.situacao == 1 && !direitoAvancado)"
              :label="cliente.situacao == 1 ? 'Ativo' : 'Inativo'"
            ></v-switch>
          </v-flex>
          <v-flex xs12 md3>
            <v-switch
              v-model="cliente.renovacaoAutomatica"
              :label="cliente.renovacaoAutomatica == 1 ? 'Renovação Automática (se plano idem)' : 'Renovação Manual'"
              :disabled="isUpdating || !direitoRenovacaoAutomatica"
            ></v-switch>
          </v-flex>
          <v-flex xs12 md4>
            <v-switch
              v-model="cliente.cartaoIntegral"
              :label="cliente.cartaoIntegral == 1 ? 'Cobrar TODAS as parcelas no cartão (se plano idem)' : 'Cobrar apenas a próxima parcela no cartão'"
              :disabled="isUpdating || !direitoAvancado"
            ></v-switch>
          </v-flex>
          <v-flex xs12 md2>
            <v-switch v-model="cliente.lgpd" :label="cliente.lgpd == 1 ? 'LGPD: ocultar dados' : 'LGPD: uso autorizado'" :disabled="isUpdating || !direitoAvancado"></v-switch>
          </v-flex>
          <v-flex xs12 md2 $store.state.usuario.site_asaas>
            <v-switch
              v-model="cliente.correiosAsaas"
              :label="cliente.correiosAsaas == 1 ? 'Asaas: enviar boleto pelos Correios' : 'Asaas: não enviar boleto pelos Correios'"
              :disabled="isUpdating || !direitoAvancado"
            ></v-switch>
          </v-flex>

          <v-flex xs12 md2>
            <v-switch
              v-model="cliente.cliente"
              :disabled="isUpdating || (id != '' && cliente.cliente == 1 && !direitoAvancado) || rotaCliente || rotaAssinante || rotaAnunciante"
              :label="cliente.cliente == 1 ? 'É Cliente' : 'Não é cliente'"
            ></v-switch>
          </v-flex>
          <v-flex xs12 md2>
            <v-switch
              v-model="cliente.fornecedor"
              :disabled="isUpdating || (id != '' && cliente.fornecedor == 1 && !direitoAvancado) || rotaFornecedor"
              :label="cliente.fornecedor == 1 ? 'É Fornecedor' : 'Não é fornecedor'"
            ></v-switch>
          </v-flex>
          <v-flex xs12 md2>
            <v-switch
              v-model="cliente.agente"
              :disabled="isUpdating || (id != '' && cliente.agente == 1 && !direitoAvancado) || rotaAgente"
              :label="cliente.agente == 1 ? 'É Agente/Agência' : 'Não é agente/agência'"
            ></v-switch>
          </v-flex>
          <v-flex xs12 md2>
            <v-switch
              v-model="cliente.banca"
              :disabled="isUpdating || (id != '' && cliente.banca == 1 && !direitoAvancado) || rotaBanca"
              :label="cliente.banca == 1 ? 'É Banca' : 'Não é banca'"
            ></v-switch>
          </v-flex>
          <v-flex xs12 md2 v-if="!cliente.tipoProtheus">
            <v-switch
              v-model="cliente.funcionario"
              :disabled="isUpdating || (id != '' && cliente.funcionario == 1 && !direitoAvancado)"
              :label="cliente.funcionario == 1 ? 'É Funcionário' : 'Não é funcionário'"
            ></v-switch>
          </v-flex>
          <v-flex xs12 md2 v-if="!cliente.funcionario && $store.state.usuario.site_protheus">
            <v-switch
              v-model="cliente.tipoProtheus"
              :disabled="isUpdating || (id != '' && cliente.tipoProtheus > 0 && !direitoAvancado)"
              :label="cliente.tipoProtheus == 1 ? 'Faturamento por Parcela' : 'Faturamento por Contrato'"
            ></v-switch>
          </v-flex>

          <v-flex xs12 md12 v-if="cliente.rede != 0">
            <v-alert :value="true" color="error" icon="check_circle" outlined>Este cliente é administrado pela REDE.</v-alert>
          </v-flex>
          <v-flex xs12 md12 v-if="cliente.online != 0">
            <v-alert :value="true" color="success" icon="check_circle" outlined
              >Este cliente foi cadastrado a partir do site (Paywall) - Módulo
              {{ cliente.origem == "ASS" ? "Assinaturas" : cliente.origem == "ANU" ? "Comercial" : cliente.origem == "EXT" ? "Externo" : "Financeiro" }}
              em {{ formatDateTime(dtCriacao) }}.</v-alert
            >
          </v-flex>
          <v-flex xs12 md12 v-if="id && cliente.online == 0">
            <v-alert :value="true" color="orange" icon="check_circle" outlined
              >Este cliente foi cadastrado a partir do Módulo
              {{ cliente.origem == "ASS" ? "Assinaturas" : cliente.origem == "ANU" ? "Comercial" : cliente.origem == "EXT" ? "Externo" : "Financeiro" }}
              em {{ formatDateTime(dtCriacao) }}</v-alert
            >
          </v-flex>
          <v-flex xs12 md12 v-if="cliente.fbUsuarioId != null">
            <v-alert :value="true" color="info" icon="info" outlined>Este cliente está vinculado ao Facebook.</v-alert>
          </v-flex>
          <v-flex xs12 md12 v-if="cliente.idUsuarioCall != null">
            <v-alert :value="true" color="error" icon="priority_high" outlined> Este cliente está sendo atendido pelo call-center neste momento. </v-alert>
          </v-flex>

          <v-expansion-panels v-model="painel" focusable>
            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.enderecos ? 'red--text' : ''">
                <strong>Endereços</strong>
                <v-progress-linear class="ma-4" v-if="dialogRecalculandoEdicoes" indeterminate color="cyan" height="40" striped
                  >Recalculando edições...
                  <span v-if="$vuetify.breakpoint.mdAndUp"
                    >Redirecionando as entregas futuras de todas as edições relacionadas as assinaturas deste cliente para o endereço de entrega ativo mais recente.</span
                  ></v-progress-linear
                >
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar flat>
                      <v-spacer></v-spacer>
                      <v-dialog v-if="!dialogRecalculandoEdicoes" persistent v-model="dialogEndereco" max-width="800px">
                        <template v-slot:activator="{ on }">
                          <v-btn color="success" fab small v-on="on">
                            <v-icon color="white" title="Incluir">add</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form ref="formEndereco" v-model="enderecoValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">Endereço - {{ enderecoItem.id }}</span>
                              <v-spacer></v-spacer>
                              <v-btn icon @click="closeEndereco">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container fluid grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs4 v-if="!enderecoItem.edicao && moduloAssinaturas">
                                    <v-btn fab @click="clickEndereco(enderecoItem, 'cobranca')">
                                      <v-icon
                                        :color="enderecoItem.cobranca ? 'blue lighten-1' : 'blue-grey lighten-3'"
                                        :title="(enderecoItem.cobranca ? 'Desmarcar' : 'Marcar') + ' como Endereço de Cobrança'"
                                        >monetization_on</v-icon
                                      >
                                    </v-btn>
                                  </v-flex>
                                  <v-flex xs4 v-if="!enderecoItem.edicao && moduloAssinaturas">
                                    <v-btn fab @click="clickEndereco(enderecoItem, 'entrega')">
                                      <v-icon
                                        :color="enderecoItem.entrega ? 'blue lighten-1' : 'blue-grey lighten-3'"
                                        :title="(enderecoItem.entrega ? 'Desmarcar' : 'Marcar') + ' como Endereço de Entrega'"
                                        >local_shipping</v-icon
                                      >
                                    </v-btn>
                                  </v-flex>
                                  <v-flex xs4 v-if="!enderecoItem.edicao && moduloAssinaturas && rotaBanca">
                                    <v-btn fab @click="clickEndereco(enderecoItem, 'entregaVA')">
                                      <v-icon
                                        :color="enderecoItem.entregaVA ? 'green' : 'green lighten-3'"
                                        :title="(enderecoItem.entregaVA ? 'Desmarcar' : 'Marcar') + ' como Endereço de Entrega de Venda Avulsa'"
                                        >local_shipping</v-icon
                                      >
                                    </v-btn>
                                  </v-flex>
                                  <v-flex xs12 md12 v-if="!enderecoItem.edicao">
                                    <v-text-field
                                      ref="focusCep"
                                      v-model="cep"
                                      label="CEP"
                                      v-mask="$store.state.usuario.site_pais == 'BR' ? '#####-###' : '####-###'"
                                      color="blue-grey lighten-2"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @keyup="buscarCep"
                                      @keyup.enter="buscarCep"
                                      @click:append="buscarCep"
                                      append-icon="search"
                                      hint="Informe o CEP e clique na
                                      lupa ou tecle ENTER"
                                      persistent-hint
                                      :rules="[validacao.cep == false || validacao.cep]"
                                      @change="validacao.cep = false"
                                    >
                                      <template v-slot:append-outer v-if="direitoCEPs">
                                        <v-btn color="blue" fab small @click="incluirCEP()">
                                          <v-icon color="white" title="Adicionar Logradouro (CEP)">add</v-icon>
                                        </v-btn>

                                        <v-dialog persistent v-model="dialogCEP" max-width="600px">
                                          <v-card>
                                            <CEP :dialogCEP.sync="dialogCEP" :id="idCEP" />
                                          </v-card>
                                        </v-dialog>
                                      </template>
                                    </v-text-field>
                                  </v-flex>
                                  <v-flex xs12 md12 v-if="!enderecoItem.edicao">
                                    <v-autocomplete
                                      v-model="ufEndereco"
                                      :items="ufs"
                                      :filter="filtrarUFs"
                                      hint="Obrigatório"
                                      :rules="[(value) => (!!value && !!value.id) || 'Obrigatório.']"
                                      :label="'Selecione o ' + ($store.state.usuario.site_pais == 'BR' ? 'Estado' : 'Distrito')"
                                      item-text="nome"
                                      item-value="id"
                                      return-object
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      no-data-text
                                      @change="filtrarMunicipios"
                                    ></v-autocomplete>
                                  </v-flex>

                                  <v-flex xs12 md12 v-if="!enderecoItem.edicao">
                                    <v-autocomplete
                                      v-model="municipioEndereco"
                                      :items="municipios"
                                      :filter="filtrarObjetos"
                                      hint="Obrigatório"
                                      :rules="[(value) => (!!value && !!value.id) || 'Obrigatório.']"
                                      :label="'Selecione o ' + ($store.state.usuario.site_pais == 'BR' ? 'Município' : 'Concelho')"
                                      item-text="nome"
                                      item-value="id"
                                      return-object
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      :no-data-text="'Selecione um ' + ($store.state.usuario.site_pais == 'BR' ? 'Estado' : 'Distrito')"
                                      @change="filtrarCEPs"
                                    ></v-autocomplete>
                                  </v-flex>

                                  <v-flex xs12 md12 v-if="!enderecoItem.edicao">
                                    <v-autocomplete
                                      v-model="cepSelecionado"
                                      :items="ceps"
                                      :filter="filtrarObjetos"
                                      hint="Obrigatório"
                                      :rules="[(value) => !!value || 'Obrigatório.', (value) => (!!value && !!value.cep) || 'Obrigatório']"
                                      label="Selecione o Logradouro"
                                      item-text="nome"
                                      item-value="id"
                                      return-object
                                      :no-data-text="
                                        municipioEndereco.id
                                          ? `Não há logradouro${enderecoItem.entrega ? ' de entrega' : ''} cadastrado para este ${
                                              $store.state.usuario.site_pais == 'BR' ? 'município' : 'concelho'
                                            }`
                                          : `Selecione um ${$store.state.usuario.site_pais == 'BR' ? 'Município' : 'Concelho'}`
                                      "
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                    ></v-autocomplete>
                                  </v-flex>

                                  <v-flex xs12 md12 v-if="!enderecoItem.edicao">
                                    <v-text-field
                                      v-model="enderecoItem.numero"
                                      label="Número"
                                      v-mask="'#####'"
                                      ref="focusNumero"
                                      hint="Obrigatório"
                                      :rules="[(value) => !!value || 'Obrigatório.', (value) => (value >= 0 && value <= 99999) || 'Obrigatório']"
                                      color="blue-grey lighten-2"
                                    >
                                      <template slot="append-outer">
                                        <v-tooltip right>
                                          <template v-slot:activator="{ on }">
                                            <v-icon v-on="on" @click="buscarCep">search</v-icon>
                                          </template>
                                          <span>Filtrar logradouro pela faixa</span>
                                        </v-tooltip>
                                      </template></v-text-field
                                    >
                                  </v-flex>

                                  <v-flex xs12 md12>
                                    <v-text-field
                                      v-model="enderecoItem.complemento"
                                      ref="focusComplemento"
                                      label="Complemento"
                                      maxlength="100"
                                      color="blue-grey lighten-2"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @change="enderecoItem.complemento = pLetra(enderecoItem.complemento)"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-expansion-panels>
                                    <v-expansion-panel>
                                      <v-expansion-panel-header>
                                        <strong>Complemento Adicional do Endereço</strong>
                                      </v-expansion-panel-header>
                                      <v-expansion-panel-content :v-model="true">
                                        <v-flex xs12 md12>
                                          <v-text-field
                                            v-model="enderecoItem.complementoTipo"
                                            label="Tipo de Complemento"
                                            maxlength="20"
                                            color="blue-grey lighten-2"
                                            autocomplete="disabled"
                                            :name="Math.random()"
                                            @change="enderecoItem.complementoTipo = pLetra(enderecoItem.complementoTipo)"
                                          ></v-text-field>
                                        </v-flex>
                                        <v-flex xs12 md12>
                                          <v-text-field v-model="enderecoItem.complementoNumero" label="Número" maxlength="7" color="blue-grey lighten-2"></v-text-field>
                                        </v-flex>
                                        <v-flex xs12 md12>
                                          <v-text-field
                                            v-model="enderecoItem.complementoBloco"
                                            label="Bloco"
                                            maxlength="7"
                                            color="blue-grey lighten-2"
                                            @change="enderecoItem.complementoBloco = pLetra(enderecoItem.complementoBloco)"
                                          ></v-text-field>
                                        </v-flex>
                                        <v-flex xs12 md12>
                                          <v-text-field
                                            v-model="enderecoItem.complementoEdificio"
                                            label="Edifício"
                                            maxlength="50"
                                            color="blue-grey lighten-2"
                                            @change="enderecoItem.complementoEdificio = pLetra(enderecoItem.complementoEdificio)"
                                          ></v-text-field>
                                        </v-flex>
                                        <v-flex xs12 md12 v-if="enderecoItem.entrega || enderecoItem.entregaVA">
                                          <v-autocomplete
                                            v-model="enderecoItem.idModoEntrega"
                                            :items="modosEntrega"
                                            :filter="filtrarObjetos"
                                            label="Selecione o Modo de Entrega"
                                            item-text="nome"
                                            item-value="id"
                                            autocomplete="disabled"
                                            :name="Math.random()"
                                            no-data-text
                                          ></v-autocomplete>
                                        </v-flex>
                                      </v-expansion-panel-content>
                                    </v-expansion-panel>
                                  </v-expansion-panels>

                                  <v-flex xs3 md2>
                                    <v-text-field v-model="enderecoItem.ordem" label="Ordem" color="blue-grey lighten-2" disabled></v-text-field>
                                  </v-flex>
                                  <v-flex xs4 offset-md1 md4>
                                    <v-text-field v-model="enderecoItem.latitude" label="Latitude" color="blue-grey lighten-2" disabled></v-text-field>
                                  </v-flex>
                                  <v-flex xs4 md4>
                                    <v-text-field v-model="enderecoItem.longitude" label="Longitude" color="blue-grey lighten-2" disabled></v-text-field>
                                  </v-flex>

                                  <v-flex xs1 class="text-right" v-if="enderecoItem.latitude">
                                    <a
                                      v-bind:href="`https://www.google.com.br/maps/place/${enderecoItem.tipo} ${enderecoItem.logradouro}, ${enderecoItem.numero} - ${enderecoItem.bairro}, ${enderecoItem.municipio},${enderecoItem.cep}/@${enderecoItem.latitude},${enderecoItem.longitude}`"
                                      target="_blank"
                                    >
                                      <v-icon class="mt-4" color="green" title="Google Maps">location_on</v-icon>
                                    </a>
                                  </v-flex>
                                  <v-alert v-if="validacao.endereco" :value="true" color="error" icon="priority_high" outlined>{{ validacao.endereco }}</v-alert>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closeEndereco">Retornar</v-btn>
                              <v-btn color="blue darken-1" @click="saveEndereco">Atualizar</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="isBanca ? headerEnderecosVA : !moduloAssinaturas ? headerEnderecosCom : headerEnderecos"
                      :items="enderecosCliente"
                      no-data-text
                      :footer-props="rodape"
                    >
                      <template v-slot:item.actions="{ item }">
                        <v-icon v-if="!dialogRecalculandoEdicoes" class="mr-2" color="blue lighten-2" title="Editar Complemento" @click="editarComplemento(item)">edit</v-icon>
                        <v-icon
                          v-if="direitoCEPs && item.idCEP"
                          class="mr-2"
                          :color="item.situacaoCEP ? 'blue lighten-2' : 'blue-grey lighten-3'"
                          title="Editar Logradouro (CEP)"
                          @click="editarCEP(item)"
                          >location_on</v-icon
                        >
                        <v-icon v-if="direitoRoteiro && item.idCEP" class="mr-2" color="blue lighten-2" title="Editar Roteiro" @click="editarRoteiro(item)">inventory</v-icon>
                        <v-icon
                          v-if="!dialogRecalculandoEdicoes && (!item.id || direitoAvancado)"
                          :color="item.situacao ? 'blue lighten-1' : 'blue-grey lighten-3'"
                          :title="item.situacao ? 'Desativar' : 'Ativar'"
                          @click="ativarDesativarEndereco(item, false)"
                        >
                          {{ item.situacao ? "radio_button_checked" : "radio_button_unchecked" }}
                        </v-icon>
                        <v-icon
                          v-if="!dialogRecalculandoEdicoes && moduloAssinaturas && (!item.id || direitoAvancado)"
                          :color="item.cobranca ? 'blue lighten-1' : 'blue-grey lighten-3'"
                          class="mr-2"
                          :title="(item.cobranca ? 'Desmarcar' : 'Marcar') + ' como Endereço de Cobrança'"
                          @click="marcarDesmarcarEndereco(item, 'cobranca')"
                          >monetization_on</v-icon
                        >
                        <v-icon
                          v-if="!dialogRecalculandoEdicoes && moduloAssinaturas && (!item.id || direitoAvancado)"
                          :color="item.entrega ? 'blue lighten-1' : 'blue-grey lighten-3'"
                          :title="(item.entrega ? 'Desmarcar' : 'Marcar') + ' como Endereço de Entrega'"
                          @click="marcarDesmarcarEndereco(item, 'entrega')"
                          >local_shipping</v-icon
                        >
                        <v-icon
                          v-if="!dialogRecalculandoEdicoes && moduloAssinaturas && (!item.id || direitoAvancado) && rotaBanca"
                          :color="item.entregaVA ? 'green' : 'green lighten-4'"
                          :title="(item.entregaVA ? 'Desmarcar' : 'Marcar') + ' como Endereço de Entrega de Venda Avulsa'"
                          @click="marcarDesmarcarEndereco(item, 'entregaVA')"
                          >local_shipping</v-icon
                        >
                        <v-icon v-if="direitoAvancado" color="blue lighten-3" title="Visualizar Log" @click="visualizarLog(item)">info</v-icon>
                        <v-icon v-if="!dialogRecalculandoEdicoes && item.id && direitoAvancado" color="red" title="Excluir" @click="excluirEndereco(item)">delete </v-icon>
                      </template>
                      <template v-slot:item.logradouro="{ item }">
                        {{ item.tipo + " " + item.logradouro + (item.faixa ? " - " + item.faixa : "") }}
                      </template>
                      <template v-slot:item.complemento="{ item }">
                        {{
                          item.complemento +
                          (item.complementoTipo || item.complementoNumero || item.complementoBloco || item.complementoEdificio ? " | " : "") +
                          item.complementoTipo +
                          " " +
                          item.complementoNumero +
                          (item.complementoBloco ? " - Bloco " + item.complementoBloco : "") +
                          (item.complementoEdificio ? " - " + item.complementoEdificio : "")
                        }}
                      </template>
                      <template v-slot:item.online="{ item }">
                        <span v-html="item.online == 1 ? 'Sim' : ''"></span>
                      </template>
                      <template v-slot:item.dtRegistro="{ item }">
                        {{ formatDateTime(item.dtRegistro) }}
                      </template>
                      <template v-slot:item.dtExclusao="{ item }">
                        {{ item.situacao ? "" : formatDateTime(item.dtExclusao) }}
                      </template>
                    </v-data-table>
                    <v-snackbar v-model="snackEnderecos" :timeout="2000" absolute bottom left>{{ msgEnderecos }}</v-snackbar>
                    <v-alert v-if="validacao.enderecos" :value="true" color="error" icon="priority_high" outlined>{{ validacao.enderecos }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.telefones ? 'red--text' : ''">
                <strong>Telefones</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar flat>
                      <v-spacer></v-spacer>
                      <v-dialog persistent v-model="dialogTelefone" max-width="600px">
                        <template v-slot:activator="{ on }">
                          <v-btn color="success" fab small v-on="on">
                            <v-icon color="white" title="Incluir">add</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form ref="formTelefone" v-model="telefoneValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">Telefone</span>
                              <v-spacer></v-spacer>
                              <v-btn icon @click="closeTelefone">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container fluid grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs4>
                                    <v-btn fab @click="clickTelefone(telefoneItem, 'comercial')">
                                      <v-icon
                                        :color="telefoneItem.comercial ? 'blue lighten-1' : 'blue-grey lighten-3'"
                                        :title="(telefoneItem.comercial ? 'Desmarcar' : 'Marcar') + ' como Telefone Comercial'"
                                        >contact_phone</v-icon
                                      >
                                    </v-btn>
                                  </v-flex>
                                  <v-flex xs4>
                                    <v-btn fab @click="clickTelefone(telefoneItem, 'residencial')">
                                      <v-icon
                                        :color="telefoneItem.residencial ? 'blue lighten-1' : 'blue-grey lighten-3'"
                                        :title="(telefoneItem.residencial ? 'Desmarcar' : 'Marcar') + ' como Telefone Residencial'"
                                        >home</v-icon
                                      >
                                    </v-btn>
                                  </v-flex>
                                  <v-flex xs4>
                                    <v-btn fab @click="clickTelefone(telefoneItem, 'celular')">
                                      <v-icon
                                        :color="telefoneItem.celular ? 'blue lighten-1' : 'blue-grey lighten-3'"
                                        :title="(telefoneItem.celular ? 'Desmarcar' : 'Marcar') + ' como Telefone Celular'"
                                        >smartphone</v-icon
                                      >
                                    </v-btn>
                                  </v-flex>
                                  <v-flex xs12 md12 v-if="$store.state.usuario.site_pais == 'BR'">
                                    <v-text-field
                                      ref="focusTelefone"
                                      v-model="telefoneItem.ddd"
                                      label="DDD"
                                      v-mask="'##'"
                                      hint="Obrigatório"
                                      :rules="[(value) => !!value || 'Obrigatório.', (value) => isDDDvalido(value) || 'DDD inválido']"
                                      color="blue-grey lighten-2"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 md12>
                                    <v-text-field
                                      v-model="telefoneItem.numero"
                                      label="Número"
                                      v-mask="'###########'"
                                      hint="Obrigatório"
                                      :rules="[(value) => !!value || 'Obrigatório.', (value) => (value != null && value.length > 7) || 'Número inválido']"
                                      color="blue-grey lighten-2"
                                      @keyup="
                                        telefoneItem.numero.length > 0 ? (telefoneItem.numero.substring(0, 1) > 5 ? (telefoneItem.celular = 1) : (telefoneItem.celular = 0)) : true
                                      "
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12>
                                    <v-text-field
                                      v-model="telefoneItem.contato"
                                      label="Contato"
                                      maxlength="30"
                                      :rules="[(value) => !value || (value != null && value.length >= 3 && value.length <= 100) || 'Contato inválido']"
                                      @change="telefoneItem.contato = pLetra(telefoneItem.contato)"
                                      color="blue-grey lighten-2"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 md12>
                                    <v-btn fab @click="clickTelefone(telefoneItem, 'naoIncomodar')">
                                      <v-icon
                                        :color="telefoneItem.naoIncomodar ? 'blue lighten-1' : 'blue-grey lighten-3'"
                                        :title="telefoneItem.naoIncomodar ? 'Permitir ligação para este telefone' : 'Bloquear ligação para este telefone'"
                                      >
                                        {{ telefoneItem.naoIncomodar == 1 ? "phone_locked" : "phone" }}
                                      </v-icon>
                                    </v-btn>
                                    <span class="ml-1">
                                      {{ telefoneItem.naoIncomodar ? "Não incomodar" : "Desbloqueado" }}
                                    </span>
                                  </v-flex>
                                  <v-flex xs12 md12>
                                    <v-btn fab v-if="telefoneItem.celular" @click="clickTelefone(telefoneItem, 'whatsapp')">
                                      <v-icon
                                        :color="telefoneItem.whatsapp ? 'blue lighten-1' : 'blue-grey lighten-3'"
                                        :title="telefoneItem.whatsapp ? 'Desmarcar telefone WhatsApp' : 'Marcar telefone como WhatsApp'"
                                        >mdi-whatsapp
                                      </v-icon>
                                    </v-btn>
                                    <span v-if="telefoneItem.celular" class="ml-1">
                                      {{ telefoneItem.whatsapp ? "É WhatsApp" : "Não é WhatsApp" }}
                                    </span>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closeTelefone">Retornar</v-btn>
                              <v-btn color="blue darken-1" @click="saveTelefone">Atualizar</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table mobile-breakpoint="0" :headers="headerTelefones" :items="telefonesCliente" no-data-text :footer-props="rodape">
                      <template v-slot:item.actions="{ item }">
                        <a v-bind:href="'tel:' + item.ddd + item.numero">
                          <v-icon color="blue" title="Discagem">phone_in_talk</v-icon>
                        </a>

                        <v-icon
                          class="mr-2"
                          :color="item.naoIncomodar == 1 ? 'red' : 'blue lighten-1'"
                          :title="item.naoIncomodar == 1 ? 'Permitir ligação para este telefone' : 'Bloquear ligação para este telefone'"
                          @click="ativarDesativarNaoIncomodar(item)"
                        >
                          {{ item.naoIncomodar == 1 ? "phone_locked" : "phone" }}
                        </v-icon>
                        <v-icon
                          :color="item.situacao == 1 ? 'blue lighten-1' : 'blue-grey lighten-3'"
                          :title="item.situacao == 1 ? 'Desativar' : 'Ativar'"
                          @click="ativarDesativarTelefone(item)"
                        >
                          {{ item.situacao == 1 ? "radio_button_checked" : "radio_button_unchecked" }}
                        </v-icon>
                        <v-icon
                          class="mr-2"
                          :color="item.comercial == 1 ? 'blue lighten-1' : 'blue-grey lighten-3'"
                          :title="item.comercial == 1 ? 'Desmarcar Comercial' : 'Marcar como Comercial'"
                          @click="ativarDesativarComercial(item)"
                          >contact_phone</v-icon
                        >
                        <v-icon
                          class="mr-2"
                          :color="item.residencial == 1 ? 'blue lighten-1' : 'blue-grey lighten-3'"
                          :title="item.residencial == 1 ? 'Desmarcar Residencial' : 'Marcar como Residencial'"
                          @click="ativarDesativarResidencial(item)"
                          >home</v-icon
                        >
                        <v-icon color="blue lighten-2" v-if="item.celular == 1" title="Telefone Celular">smartphone</v-icon>
                        <v-icon
                          v-if="item.celular"
                          class="mr-2"
                          :color="item.whatsapp == 1 ? 'blue lighten-1' : 'blue-grey lighten-3'"
                          :title="item.whatsapp == 1 ? 'Desmarcar WhatsApp' : 'Marcar como WhatsApp'"
                          @click="ativarDesativarWhatsApp(item)"
                          >mdi-whatsapp</v-icon
                        >
                        <v-icon v-if="item.id && direitoAvancado" color="red" title="Excluir" @click="excluirTelefone(item)">delete </v-icon>
                      </template>

                      <template v-slot:item.dtRegistro="{ item }">
                        {{ formatDateTime(item.dtRegistro) }}
                      </template>
                      <template v-slot:item.dtExclusao="{ item }">
                        {{ item.situacao ? "" : formatDateTime(item.dtExclusao) }}
                      </template>

                      <template v-slot:item.contato="props">
                        <v-edit-dialog v-if="direitoAvancado" :return-value.sync="props.item.contato" @save="saveContatoFone(props.item)">
                          <v-chip small>{{ props.item.contato }}</v-chip>
                          <template v-slot:input>
                            <v-text-field
                              v-model="props.item.contato"
                              maxlength="100"
                              label="Contato"
                              single-line
                              :rules="[(v) => v != '']"
                              @change="props.item.contato = pLetra(props.item.contato)"
                            ></v-text-field>
                          </template>
                        </v-edit-dialog>
                        <span v-if="!direitoAvancado">{{ props.item.contato }}</span>
                      </template>
                    </v-data-table>
                    <v-snackbar v-model="snackTelefones" :timeout="2000" absolute bottom left>{{ msgTelefones }}</v-snackbar>
                    <v-alert v-if="validacao.telefones" :value="true" color="error" icon="priority_high" outlined>{{ validacao.telefones }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.emails ? 'red--text' : ''">
                <strong>E-mails</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar>
                      <v-spacer></v-spacer>
                      <v-dialog persistent v-model="dialogEmail" max-width="600px">
                        <template v-slot:activator="{ on }">
                          <v-btn color="success" fab small v-on="on">
                            <v-icon color="white" title="Incluir">add</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form ref="formEmail" v-model="emailValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">E-mail</span>
                              <v-spacer></v-spacer>
                              <v-btn icon @click="closeEmail">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container fluid grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs12 md12>
                                    <v-text-field
                                      ref="focusEmail"
                                      v-model="emailItem.email"
                                      label="E-mail"
                                      maxlength="100"
                                      hint="Obrigatório"
                                      :rules="[
                                        validacao.emails_email == false || validacao.emails_email,
                                        (value) => !!value || 'Obrigatório.',
                                        (value) => (value != null && value.length > 2) || 'Preencha o e-mail do cliente',
                                        (value) => valida_email_email(value)
                                      ]"
                                      @change="
                                        emailItem.email = emailItem.email.toLowerCase();
                                        validacao.emails_email = false;
                                      "
                                      color="blue-grey lighten-2"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 md12>
                                    <v-btn fab @click="clickEmail(emailItem)">
                                      <v-icon
                                        :color="emailItem.fatura ? 'blue lighten-1' : 'blue-grey lighten-3'"
                                        :title="(emailItem.fatura ? 'Desmarcar' : 'Marcar') + ' como Fatura'"
                                      >
                                        {{ emailItem.fatura == 1 ? "speaker_notes" : "speaker_notes_off" }}
                                      </v-icon>
                                    </v-btn>
                                    <span class="ml-1">
                                      {{ emailItem.fatura ? "E-mail para envio de notas fiscais, boletos e cobrança" : "E-mail não é para envio de fatura" }}
                                    </span>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closeEmail">Retornar</v-btn>
                              <v-btn color="blue darken-1" @click="saveEmail">Atualizar</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table mobile-breakpoint="0" :headers="headerEmails" :items="emailsCliente" no-data-text :footer-props="rodape">
                      <template v-slot:item.actions="{ item }">
                        <v-icon
                          class="mr-2"
                          :color="item.fatura == 1 ? 'blue lighten-1' : 'blue-grey lighten-3'"
                          :title="(item.fatura == 1 ? 'Desativar ' : 'Ativar ') + 'envio de fatura neste e-mail'"
                          @click="ativarDesativarFatura(item)"
                        >
                          {{ item.fatura == 1 ? "speaker_notes" : "speaker_notes_off" }}
                        </v-icon>
                        <v-icon
                          :color="item.situacao == 1 ? 'blue lighten-1' : 'blue-grey lighten-3'"
                          :title="item.situacao == 1 ? 'Desativar' : 'Ativar'"
                          @click="ativarDesativarEmail(item)"
                        >
                          {{ item.situacao == 1 ? "radio_button_checked" : "radio_button_unchecked" }}
                        </v-icon>
                        <v-icon v-if="item.id && direitoAvancado" color="red" title="Excluir" @click="excluirEmail(item)">delete </v-icon>
                      </template>

                      <template v-slot:item.dtRegistro="{ item }">
                        {{ formatDateTime(item.dtRegistro) }}
                      </template>
                      <template v-slot:item.dtExclusao="{ item }">
                        {{ item.situacao ? "" : formatDateTime(item.dtExclusao) }}
                      </template>
                    </v-data-table>
                    <v-snackbar v-model="snackEmails" :timeout="2000" absolute bottom left>{{ msgEmails }}</v-snackbar>
                    <v-alert v-if="validacao.emails" :value="true" color="error" icon="priority_high" outlined>{{ validacao.emails }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.cartoes ? 'red--text' : ''">
                <strong>Cartões de Crédito</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-data-table mobile-breakpoint="0" :headers="headerCartoes" :items="cartoesCliente" no-data-text :footer-props="rodape">
                      <template v-slot:item.actions="{ item }">
                        <v-icon
                          v-if="!item.id || direitoAvancado"
                          :color="item.situacao == 1 ? 'blue lighten-1' : 'blue-grey lighten-3'"
                          :title="item.situacao == 1 ? 'Desativar' : 'Ativar'"
                          @click="ativarDesativarCartao(item)"
                        >
                          {{ item.situacao == 1 ? "radio_button_checked" : "radio_button_unchecked" }}
                        </v-icon>
                      </template>

                      <template v-slot:item.dtRegistro="{ item }">
                        {{ formatDateTime(item.dtRegistro) }}
                      </template>
                      <template v-slot:item.dtExclusao="{ item }">
                        {{ item.situacao ? "" : formatDateTime(item.dtExclusao) }}
                      </template>
                    </v-data-table>
                    <v-snackbar v-model="snackCartoes" :timeout="2000" absolute bottom left>{{ msgCartoes }}</v-snackbar>
                    <v-alert v-if="validacao.cartoes" :value="true" color="error" icon="priority_high" outlined>{{ validacao.cartoes }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.contas ? 'red--text' : ''">
                <strong>Contas Correntes</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar flat>
                      <v-spacer></v-spacer>
                      <v-dialog persistent v-model="dialogConta" max-width="600px">
                        <template v-slot:activator="{ on }">
                          <v-btn color="success" fab small v-on="on">
                            <v-icon color="white" title="Incluir">add</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form ref="formConta" v-model="contaValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">Conta Corrente</span>
                              <v-spacer></v-spacer>
                              <v-btn icon @click="closeConta">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container fluid grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs12 md12>
                                    <v-autocomplete
                                      ref="focusConta"
                                      v-model="bancoSelecionado"
                                      :items="bancosDebitoConta"
                                      :filter="filtrarObjetos"
                                      :rules="[(value) => (!!value && !!value.id) || 'Obrigatório.']"
                                      hint="Obrigatório"
                                      label="Selecione o Banco"
                                      item-text="nome"
                                      item-value="id"
                                      no-data-text
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      return-object
                                    ></v-autocomplete>
                                  </v-flex>

                                  <v-flex xs12 md12>
                                    <v-text-field
                                      v-model="contaItem.identificacao"
                                      :label="'Identificação (' + tipoIdent(contaItem.identificacao) + ')'"
                                      v-mask="'##############'"
                                      hint="Obrigatório"
                                      :rules="[(value) => !!value || 'Obrigatório.', (value) => valida_identificacao(value, true)]"
                                      color="blue-grey lighten-2"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 md12>
                                    <v-text-field
                                      v-model="contaItem.nome"
                                      label="Nome do Titular da Conta"
                                      maxlength="100"
                                      hint="Obrigatório"
                                      :rules="[(value) => !!value || 'Obrigatório.', (value) => (value != null && value.length > 2) || 'Preencha o nome do titular da conta']"
                                      color="blue-grey lighten-2"
                                      @change="contaItem.nome = contaItem.nome.toUpperCase()"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 md12>
                                    <v-text-field
                                      v-model="contaItem.agencia"
                                      label="Agência"
                                      v-mask="'####'"
                                      hint="Obrigatório"
                                      :rules="[
                                        (value) => !!value || 'Obrigatório.',
                                        (value) => value.length == 4 || 'Deve conter 4 dígitos numéricos: somente número da agência (não incluir o dígito de controle)'
                                      ]"
                                      color="blue-grey lighten-2"
                                      :disabled="!bancoSelecionado || !bancoSelecionado.bancoNumero"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 md12>
                                    <v-text-field
                                      v-model="contaItem.numero"
                                      label="Número"
                                      v-mask="'################'"
                                      hint="Obrigatório"
                                      :rules="[
                                        (value) => !!value || 'Obrigatório.',
                                        (value) =>
                                          bancoSelecionado.bancoNumero == '001'
                                            ? value.length <= 14 || 'Deve conter até 14 dígitos numéricos'
                                            : bancoSelecionado.bancoNumero == '104'
                                            ? value.length >= 12 || 'Deve conter pelo menos 12 dígitos numéricos'
                                            : bancoSelecionado.bancoNumero == '033'
                                            ? value.length == 9 || 'Deve conter 9 dígitos numéricos'
                                            : bancoSelecionado.bancoNumero == '041'
                                            ? value.length == 10 || 'Deve conter 10 dígitos numéricos'
                                            : bancoSelecionado.bancoNumero == '237'
                                            ? (value.length >= 6 && value.length <= 8) || 'Deve conter de 6 a 8 dígitos numéricos'
                                            : bancoSelecionado.bancoNumero == '748'
                                            ? value.length == 6 || 'Deve conter 6 dígitos numéricos'
                                            : value.length > 2 || 'Deve conter até 16 dígitos numéricos'
                                      ]"
                                      color="blue-grey lighten-2"
                                      :disabled="!bancoSelecionado || !bancoSelecionado.bancoNumero"
                                    ></v-text-field>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closeConta">Retornar</v-btn>
                              <v-btn color="blue darken-1" @click="saveConta">Atualizar</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table mobile-breakpoint="0" :headers="headerContas" :items="contasCliente" no-data-text :footer-props="rodape">
                      <template v-slot:item.actions="{ item }">
                        <v-icon
                          v-if="!item.id || direitoAvancado"
                          :color="item.situacao == 1 ? 'blue lighten-1' : 'blue-grey lighten-3'"
                          :title="item.situacao == 1 ? 'Desativar' : 'Ativar'"
                          @click="ativarDesativarConta(item)"
                        >
                          {{ item.situacao == 1 ? "radio_button_checked" : "radio_button_unchecked" }}
                        </v-icon>
                      </template>

                      <template v-slot:item.bancoNumero="{ item }">
                        {{ item.bancoNumero + " - " + item.bancoNome }}
                      </template>

                      <template v-slot:item.codFinanceiro="props">
                        <v-edit-dialog v-if="direitoAvancado" :return-value.sync="props.item.codFinanceiro" @save="saveCodFinanceiro(props.item)">
                          <v-chip small>{{ props.item.codFinanceiro }}</v-chip>
                          <template v-slot:input>
                            <v-text-field v-model="props.item.codFinanceiro" maxlength="20" label="Financeiro" single-line></v-text-field>
                          </template>
                        </v-edit-dialog>
                        <span v-if="!direitoAvancado">{{ props.item.codFinanceiro }}</span>
                      </template>

                      <template v-slot:item.dtRegistro="{ item }">
                        {{ formatDateTime(item.dtRegistro) }}
                      </template>
                      <template v-slot:item.dtExclusao="{ item }">
                        {{ item.situacao ? "" : formatDateTime(item.dtExclusao) }}
                      </template>
                    </v-data-table>
                    <v-snackbar v-model="snackContas" :timeout="2000" absolute bottom left>{{ msgContas }}</v-snackbar>
                    <v-alert v-if="validacao.contas" :value="true" color="error" icon="priority_high" outlined>{{ validacao.contas }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.celescs ? 'red--text' : ''">
                <strong>Unidades Consumidoras</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar flat>
                      <v-spacer></v-spacer>
                      <v-dialog persistent v-model="dialogCelesc" max-width="600px">
                        <template v-slot:activator="{ on }">
                          <v-btn color="success" fab small v-on="on">
                            <v-icon color="white" title="Incluir">add</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form ref="formCelesc" v-model="celescValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">Unidade Consumidora</span>

                              <v-spacer></v-spacer>
                              <v-btn icon @click="closeCelesc">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container fluid grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs12 md12>
                                    <v-text-field
                                      ref="focusCelesc"
                                      v-model="celescItem.identificacao"
                                      :label="'Identificação (' + tipoIdent(celescItem.identificacao) + ')'"
                                      v-mask="'##############'"
                                      hint="Obrigatório"
                                      :rules="[(value) => !!value || 'Obrigatório.', (value) => valida_identificacao(value, true)]"
                                      color="blue-grey lighten-2"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 md12>
                                    <v-text-field
                                      v-model="celescItem.unidadeConsumidora"
                                      label="Unidade Consumidora"
                                      v-mask="'#############'"
                                      hint="Obrigatório"
                                      :rules="[(value) => !!value || 'Obrigatório.', (value) => value.length > 2 || 'Deve conter até 13 dígitos numéricos']"
                                      color="blue-grey lighten-2"
                                    ></v-text-field>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closeCelesc">Retornar</v-btn>
                              <v-btn color="blue darken-1" @click="saveCelesc">Atualizar</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table mobile-breakpoint="0" :headers="headerCelescs" :items="celescsCliente" no-data-text :footer-props="rodape">
                      <template v-slot:item.actions="{ item }">
                        <v-icon
                          v-if="!item.id || direitoAvancado"
                          :color="item.situacao == 1 ? 'blue lighten-1' : 'blue-grey lighten-3'"
                          :title="item.situacao == 1 ? 'Desativar' : 'Ativar'"
                          @click="ativarDesativarCelesc(item)"
                        >
                          {{ item.situacao == 1 ? "radio_button_checked" : "radio_button_unchecked" }}
                        </v-icon>
                      </template>

                      <template v-slot:item.dtRegistro="{ item }">
                        {{ formatDateTime(item.dtRegistro) }}
                      </template>
                      <template v-slot:item.dtExclusao="{ item }">
                        {{ item.situacao ? "" : formatDateTime(item.dtExclusao) }}
                      </template>
                    </v-data-table>
                    <v-snackbar v-model="snackCelescs" :timeout="2000" absolute bottom left>{{ msgCelescs }}</v-snackbar>
                    <v-alert v-if="validacao.celescs" :value="true" color="error" icon="priority_high" outlined>{{ validacao.celescs }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating" v-if="rotaFornecedor">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.pix ? 'red--text' : ''">
                <strong>Chaves PIX</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar flat>
                      <v-spacer></v-spacer>
                      <v-dialog persistent v-model="dialogPix" max-width="600px">
                        <template v-slot:activator="{ on }">
                          <v-btn color="success" fab small v-on="on">
                            <v-icon color="white" title="Incluir">add</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form ref="formPix" v-model="pixValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">Chave PIX</span>

                              <v-spacer></v-spacer>
                              <v-btn icon @click="closePix">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container fluid grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs12 md12>
                                    <v-autocomplete
                                      ref="focusPix"
                                      v-model="tipoChaveSelecionado"
                                      :items="tiposChave"
                                      :filter="filtrarObjetos"
                                      :rules="[(value) => !!value || 'Obrigatório.']"
                                      hint="Obrigatório"
                                      label="Selecione o Tipo de Chave PIX"
                                      item-text="nome"
                                      item-value="id"
                                      no-data-text
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                    ></v-autocomplete>
                                  </v-flex>
                                  <v-flex xs12 md12 v-if="pixItem.tipoChave == '05'">
                                    <v-autocomplete
                                      v-model="pixItem.tipoConta"
                                      :items="tiposConta"
                                      :filter="filtrarObjetos"
                                      :rules="[(value) => !!value || 'Obrigatório.']"
                                      hint="Obrigatório"
                                      label="Selecione o Tipo de Conta PIX"
                                      item-text="nome"
                                      item-value="id"
                                      no-data-text
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                    ></v-autocomplete>
                                  </v-flex>

                                  <v-flex xs12 md12>
                                    <v-text-field
                                      v-model="pixItem.chave"
                                      label="Chave PIX"
                                      hint="Obrigatório"
                                      :rules="[(value) => valida_chave(value)]"
                                      color="blue-grey lighten-2"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @blur="pixItem.chave = trataChave(pixItem.chave)"
                                    ></v-text-field>
                                  </v-flex>

                                  <v-flex xs12 md12>
                                    <v-text-field
                                      v-model="pixItem.identificacao"
                                      :label="'Identificação (' + tipoIdent(pixItem.identificacao) + ')'"
                                      v-mask="'##############'"
                                      hint="Obrigatório"
                                      :rules="[(value) => !!value || 'Obrigatório.', (value) => valida_identificacao(value, true)]"
                                      color="blue-grey lighten-2"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                    ></v-text-field>
                                  </v-flex>

                                  <v-flex xs12 md12>
                                    <v-text-field
                                      v-model="pixItem.nome"
                                      label="Nome no Cartão"
                                      maxlength="100"
                                      hint="Obrigatório"
                                      :rules="[(value) => !!value || 'Obrigatório.', (value) => (value != null && value.length > 2) || 'Preencha o nome do titular do pix']"
                                      color="blue-grey lighten-2"
                                      @change="pixItem.nome = removeEspeciais(pixItem.nome)"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                    ></v-text-field>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closePix">Retornar</v-btn>
                              <v-btn color="blue darken-1" @click="savePix">Atualizar</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table mobile-breakpoint="0" :headers="headerPix" :items="pixsCliente" no-data-text :footer-props="rodape">
                      <template v-slot:item.actions="{ item }">
                        <v-icon
                          v-if="!item.id || direitoAvancado"
                          :color="item.situacao == 1 ? 'blue lighten-1' : 'blue-grey lighten-3'"
                          :title="item.situacao == 1 ? 'Desativar' : 'Ativar'"
                          @click="ativarDesativarPix(item)"
                        >
                          {{ item.situacao == 1 ? "radio_button_checked" : "radio_button_unchecked" }}
                        </v-icon>
                        <v-icon v-if="item.id && direitoAvancado" color="red" title="Excluir" @click="excluirPix(item)">delete </v-icon>
                      </template>

                      <template v-slot:item.tipoChave="{ item }">
                        {{
                          item.tipoChave == "01"
                            ? "Telefone"
                            : item.tipoChave == "02"
                            ? "E-mail"
                            : item.tipoChave == "03"
                            ? "CPF/CNPJ"
                            : item.tipoChave == "04"
                            ? "Aleatória"
                            : "Dados Bancários"
                        }}
                      </template>

                      <template v-slot:item.tipoConta="{ item }">
                        {{ item.tipoConta == "01" ? "Corrente" : item.tipoConta == "02" ? "Pagamento" : item.tipoConta == "03" ? "Poupança" : "" }}
                      </template>

                      <template v-slot:item.dtRegistro="{ item }">
                        {{ formatDateTime(item.dtRegistro) }}
                      </template>
                      <template v-slot:item.dtExclusao="{ item }">
                        {{ item.situacao ? "" : formatDateTime(item.dtExclusao) }}
                      </template>
                    </v-data-table>
                    <v-snackbar v-model="snackPix" :timeout="6000" absolute bottom left>{{ msgPix }}</v-snackbar>
                    <v-alert v-if="validacao.pix" :value="true" color="error" icon="priority_high" outlined>{{ validacao.pix }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" v-if="moduloAssinaturas" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.dependentes ? 'red--text' : ''">
                <strong>Dependentes/Integrantes</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar flat>
                      <v-spacer></v-spacer>
                      <v-dialog persistent v-model="dialogDependente" max-width="600px">
                        <template v-slot:activator="{ on }">
                          <v-btn color="success" fab small v-on="on">
                            <v-icon color="white" title="Incluir">add</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form ref="formDependente" v-model="dependenteValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">Dependente/Integrante</span>
                              <v-spacer></v-spacer>
                              <v-btn icon @click="closeDependente">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container fluid grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs12 md12>
                                    <v-text-field
                                      ref="focusDependente"
                                      :disabled="dependenteItem.id != null && !dependenteItem.editar"
                                      v-model="dependenteItem.identificacao"
                                      :label="'Identificação (' + tipoIdent(dependenteItem.identificacao) + ')'"
                                      v-mask="'##############'"
                                      hint="Obrigatório"
                                      :rules="[validacao.identificacaoDependente == false || validacao.identificacaoDependente, (value) => valida_identificacao(value, false)]"
                                      color="blue-grey lighten-2"
                                      @change="isDependenteExiste"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 md12>
                                    <v-text-field
                                      :disabled="dependenteItem.id && !direitoAvancado"
                                      v-model="dependenteItem.nome"
                                      label="Nome"
                                      maxlength="100"
                                      hint="Obrigatório"
                                      :rules="[(value) => !!value || 'Obrigatório.', (value) => value.length > 2 || 'Preencha o nome do dependente/integrante']"
                                      color="blue-grey lighten-2"
                                      @change="dependenteItem.nome = pLetra(dependenteItem.nome)"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 md12>
                                    <v-text-field
                                      v-model="dependenteItem.associacao"
                                      label="Associação"
                                      maxlength="30"
                                      hint="Obrigatório"
                                      :rules="[
                                        (value) => !!value || 'Obrigatório.',
                                        (value) => (value != null && value.length > 2) || 'Preencha a associação (parentesco, etc) do dependente/integrante com o cliente titular'
                                      ]"
                                      color="blue-grey lighten-2"
                                      @change="dependenteItem.associacao = pLetra(dependenteItem.associacao)"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 md12>
                                    <v-text-field
                                      :disabled="dependenteItem.id && !direitoAvancado"
                                      v-model="dependenteItem.email"
                                      label="E-mail"
                                      maxlength="100"
                                      :rules="[validacao.dependentes_email == false || validacao.dependentes_email, (value) => valida_email_identificacao(value)]"
                                      color="blue-grey lighten-2"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      @change="
                                        dependenteItem.email = dependenteItem.email.toLowerCase();
                                        validacao.dependentes_email = false;
                                      "
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 md12 v-if="$store.state.usuario.site_pais == 'BR'">
                                    <v-text-field
                                      v-model="dependenteItem.foneDDD"
                                      label="DDD"
                                      v-mask="'##'"
                                      :rules="[(value) => value == '' || isDDDvalido(value) || 'DDD inválido']"
                                      color="blue-grey lighten-2"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 md12>
                                    <v-text-field v-model="dependenteItem.foneNumero" label="Telefone" v-mask="'###########'" color="blue-grey lighten-2"></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 md12>
                                    <v-text-field
                                      v-model="dependenteItem.dtNasc"
                                      label="Data de Nascimento"
                                      v-mask="'##/##/####'"
                                      :rules="[(value) => valida_data_nasc(value) || 'Data inválida']"
                                      color="blue-grey lighten-2"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 md6>
                                    <v-autocomplete
                                      v-model="dependenteItem.sexo"
                                      :disabled="isUpdating"
                                      :items="sexos"
                                      :filter="filtrarObjetos"
                                      hint="Obrigatório"
                                      :rules="[validacao.dependentes_sexo == false || validacao.dependentes_sexo, (value) => value != null || 'Obrigatório']"
                                      label="Sexo"
                                      item-text="nome"
                                      item-value="id"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      no-data-text
                                      @change="validacao.dependentes_sexo = false"
                                    ></v-autocomplete>
                                  </v-flex>
                                  <v-flex xs12>
                                    <v-autocomplete
                                      v-if="produtosClube.length > 0"
                                      v-model="dependenteItem.produtosClube"
                                      :items="produtosClube"
                                      :filter="filtrarObjetos"
                                      label="Produtos do Clube"
                                      item-text="nome"
                                      item-value="id"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      no-data-text
                                      return-object
                                      multiple
                                    ></v-autocomplete>
                                  </v-flex>
                                  <v-flex md6>
                                    <v-btn fab @click="clickCartao(dependenteItem)">
                                      <v-icon
                                        :color="dependenteItem.clubeCortesia ? 'blue lighten-1' : 'blue-grey lighten-3'"
                                        :title="(dependenteItem.clubeCortesia ? 'Desmarcar' : 'Marcar') + ' como Clube Cortesia'"
                                      >
                                        {{ dependenteItem.clubeCortesia == 1 ? "money_off" : "attach_money" }}
                                      </v-icon>
                                    </v-btn>
                                    <span class="ml-1">
                                      {{ dependenteItem.clubeCortesia ? "Clube do assinante cortesia" : "Clube do assinante pago" }}
                                    </span>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closeDependente">Retornar</v-btn>
                              <v-btn color="blue darken-1" @click="saveDependente">Atualizar</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table mobile-breakpoint="0" :headers="headerDependentes" :items="dependentesCliente" no-data-text :footer-props="rodape">
                      <template v-slot:item.actions="{ item }">
                        <v-icon class="mr-2" color="blue lighten-2" title="Editar Dependente" @click="editarDependente(item)">edit</v-icon>
                        <v-icon
                          class="mr-2"
                          :color="item.clubeCortesia == 1 ? 'red' : 'blue lighten-1'"
                          :title="item.clubeCortesia == 1 ? 'Desativar Cortesia para o Clube' : 'Ativar Cortesia para o Clube'"
                          @click="ativarDesativarCortesiaClube(item)"
                        >
                          {{ item.clubeCortesia == 1 ? "money_off" : "attach_money" }}
                        </v-icon>
                        <v-icon
                          v-if="!item.id || direitoAvancado"
                          :color="item.situacao == 1 ? 'blue lighten-1' : 'blue-grey lighten-3'"
                          :title="item.situacao == 1 ? 'Desativar' : 'Ativar'"
                          @click="ativarDesativarDependente(item)"
                        >
                          {{ item.situacao == 1 ? "radio_button_checked" : "radio_button_unchecked" }}
                        </v-icon>
                      </template>

                      <template v-slot:item.id="{ item }">
                        {{ item.codAntigoCirculacao ? item.id + " '" + item.codAntigoCirculacao + "'" : item.id }}
                      </template>

                      <template v-slot:item.senha="{ item }">
                        <v-icon v-if="direitoVerSenha && !item.verSenha" @click="verSenha(item)">visibility</v-icon>
                        <span v-if="direitoVerSenha && item.verSenha">{{ item.senha }}</span>
                      </template>

                      <template v-slot:item.dtRegistro="{ item }">
                        {{ formatDateTime(item.dtRegistro) }}
                      </template>
                      <template v-slot:item.dtExclusao="{ item }">
                        {{ item.situacao ? "" : formatDateTime(item.dtExclusao) }}
                      </template>

                      <template v-slot:item.dtNascimento="{ item }">
                        {{ formatDate(item.dtNascimento) }}
                      </template>
                    </v-data-table>
                    <v-snackbar v-model="snackDependentes" :timeout="2000" absolute bottom left>{{ msgDependentes }}</v-snackbar>
                    <v-alert v-if="validacao.dependentes" :value="true" color="error" icon="priority_high" outlined>{{ validacao.dependentes }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-if="!pessoaFisica" dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.departamentos ? 'red--text' : ''">
                <strong>Departamentos</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar flat>
                      <v-spacer></v-spacer>
                      <v-dialog persistent v-model="dialogDepartamento" max-width="600px">
                        <template v-slot:activator="{ on }">
                          <v-btn color="success" fab small v-on="on">
                            <v-icon color="white" title="Incluir">add</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form ref="formDepartamento" v-model="departamentoValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">Departamento</span>
                              <v-spacer></v-spacer>
                              <v-btn icon @click="closeDepartamento">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container fluid grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs12 md12>
                                    <v-text-field
                                      ref="focusDepartamento"
                                      v-model="departamentoItem.departamento"
                                      label="Nome do Departamento"
                                      maxlength="30"
                                      hint="Obrigatório"
                                      :rules="[(value) => !!value || 'Obrigatório.', (value) => value.length > 2 || 'Preencha o nome do departamento']"
                                      color="blue-grey lighten-2"
                                      @change="departamentoItem.departamento = pLetra(departamentoItem.departamento)"
                                    ></v-text-field>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closeDepartamento">Retornar</v-btn>
                              <v-btn color="blue darken-1" @click="saveDepartamento">Atualizar</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table mobile-breakpoint="0" :headers="headerDepartamentos" :items="departamentosCliente" no-data-text :footer-props="rodape">
                      <template v-slot:item.actions="{ item }">
                        <v-icon
                          :color="item.situacao == 1 ? 'blue lighten-1' : 'blue-grey lighten-3'"
                          :title="item.situacao == 1 ? 'Desativar' : 'Ativar'"
                          @click="ativarDesativarDepartamento(item)"
                        >
                          {{ item.situacao == 1 ? "radio_button_checked" : "radio_button_unchecked" }}
                        </v-icon>
                      </template>

                      <template v-slot:item.dtRegistro="{ item }">
                        {{ formatDateTime(item.dtRegistro) }}
                      </template>
                      <template v-slot:item.dtExclusao="{ item }">
                        {{ item.situacao ? "" : formatDateTime(item.dtExclusao) }}
                      </template>
                    </v-data-table>
                    <v-snackbar v-model="snackDepartamentos" :timeout="2000" absolute bottom left>{{ msgDepartamentos }}</v-snackbar>
                    <v-alert v-if="validacao.departamentos" :value="true" color="error" icon="priority_high" outlined>{{ validacao.departamentos }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.observacoes ? 'red--text' : ''">
                <strong>Observações</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar flat>
                      <v-spacer></v-spacer>
                      <v-dialog persistent v-model="dialogObservacao" max-width="600px">
                        <template v-slot:activator="{ on }">
                          <v-btn color="success" fab small v-on="on">
                            <v-icon color="white" title="Incluir">add</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form ref="formObservacao" v-model="observacaoValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">Observação</span>
                              <v-spacer></v-spacer>
                              <v-btn icon @click="closeObservacao">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container fluid grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs12 md12>
                                    <v-textarea
                                      ref="focusObservacao"
                                      v-model="observacaoItem.observacao"
                                      label="Texto da Observação"
                                      maxlength="1000"
                                      counter="1000"
                                      rows="10"
                                      hint="Obrigatório"
                                      :rules="[
                                        (value) => !!value || 'Obrigatório.',
                                        (value) => (value != null && value.length > 10 && value.length < 1000) || 'Texto da observação deve ter entre 10 e 1000 caracteres'
                                      ]"
                                      color="blue-grey lighten-2"
                                    ></v-textarea>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closeObservacao">Retornar</v-btn>
                              <v-btn color="blue darken-1" @click="saveObservacao">Atualizar</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table mobile-breakpoint="0" :headers="headerObservacoes" :items="observacoesCliente" no-data-text :footer-props="rodape">
                      <template v-slot:item.actions="{ item }">
                        <v-icon
                          :color="item.situacao == 1 ? 'blue lighten-1' : 'blue-grey lighten-3'"
                          :title="item.situacao == 1 ? 'Desativar' : 'Ativar'"
                          @click="ativarDesativarObservacao(item)"
                        >
                          {{ item.situacao == 1 ? "radio_button_checked" : "radio_button_unchecked" }}
                        </v-icon>
                      </template>

                      <template v-slot:item.dtRegistro="{ item }">
                        {{ formatDateTime(item.dtRegistro) }}
                      </template>
                      <template v-slot:item.dtExclusao="{ item }">
                        {{ item.situacao ? "" : formatDateTime(item.dtExclusao) }}
                      </template>
                    </v-data-table>
                    <v-snackbar v-model="snackObservacoes" :timeout="2000" absolute bottom left>{{ msgObservacoes }}</v-snackbar>
                    <v-alert v-if="validacao.observacoes" :value="true" color="error" icon="priority_high" outlined>{{ validacao.observacoes }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.contatos ? 'red--text' : ''">
                <strong>Contatos</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar>
                      <v-spacer></v-spacer>
                      <v-dialog persistent v-model="dialogContato" max-width="600px">
                        <template v-slot:activator="{ on }">
                          <v-btn color="success" fab small v-on="on">
                            <v-icon color="white" title="Incluir">add</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form ref="formContato" v-model="contatoValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">Contato</span>
                              <v-spacer></v-spacer>
                              <v-btn icon @click="closeContato">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs12 md12>
                                    <v-text-field v-model="contatoItem.status" label="Status" readonly color="blue-grey lighten-2"></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 md12 v-if="!contatoItem.id">
                                    <v-autocomplete
                                      ref="focusAssunto"
                                      v-model="assuntoSelecionado"
                                      :items="assuntos"
                                      :filter="filtrarObjetos"
                                      :rules="[(value) => (!!value && !!value.id) || 'Obrigatório.']"
                                      hint="Obrigatório"
                                      label="Selecione o Assunto"
                                      item-text="nome"
                                      item-value="id"
                                      no-data-text
                                      return-object
                                    ></v-autocomplete>
                                  </v-flex>
                                  <v-flex xs12 md12 v-if="!contatoItem.id">
                                    <v-autocomplete
                                      v-model="motivoSelecionado"
                                      :items="motivos"
                                      :filter="filtrarObjetos"
                                      label="Selecione o Motivo"
                                      item-text="nome"
                                      item-value="id"
                                      no-data-text
                                      return-object
                                    ></v-autocomplete>
                                  </v-flex>
                                  <v-flex xs12 md12 v-if="!contatoItem.id">
                                    <v-autocomplete
                                      v-model="procedimentoSelecionado"
                                      :items="procedimentos"
                                      :filter="filtrarObjetos"
                                      label="Selecione o Procedimento"
                                      item-text="nome"
                                      item-value="id"
                                      no-data-text
                                      return-object
                                    ></v-autocomplete>
                                  </v-flex>
                                  <v-flex xs12 md12>
                                    <v-textarea
                                      v-model="contatoItem.contato"
                                      label="Descrição do Contato"
                                      maxlength="1000"
                                      counter="1000"
                                      rows="5"
                                      hint="Obrigatório"
                                      :rules="[
                                        (value) => !!value || 'Obrigatório.',
                                        (value) => (value != null && value.length >= 10 && value.length <= 1000) || 'Texto da reclamação deve ter entre 10 e 1000 caracteres'
                                      ]"
                                      color="blue-grey lighten-2"
                                      :readonly="contatoItem.id != null"
                                    ></v-textarea>
                                  </v-flex>
                                  <v-flex xs12 md12 v-if="contatoItem.execucao">
                                    <v-textarea
                                      v-model="contatoItem.execucao"
                                      label="Execução do Contato"
                                      rows="5"
                                      maxlength="500"
                                      counter="500"
                                      readonly
                                      color="blue-grey lighten-2"
                                    ></v-textarea>
                                  </v-flex>
                                  <v-flex xs12 md12 v-if="contatoItem.solucao">
                                    <v-textarea
                                      v-model="contatoItem.solucao"
                                      label="Resolução do Contato"
                                      rows="5"
                                      maxlength="500"
                                      counter="500"
                                      readonly
                                      color="blue-grey lighten-2"
                                    ></v-textarea>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closeContato">Retornar</v-btn>
                              <v-btn v-if="contatoItem.id == null" color="blue darken-1" @click="saveContato">Atualizar</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headerContatos"
                      :items="contatosCliente"
                      no-data-text
                      no-results-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [10, 25, 50]
                      }"
                    >
                      <template v-slot:item.actions="{ item }">
                        <v-icon v-if="item.id == null" class="mr-2" color="red" title="Excluir Contato" @click="excluirContato(item)">delete</v-icon>
                        <v-icon class="mr-2" color="blue lighten-2" title="Visualizar" @click="visualizarContato(item)">loupe</v-icon>
                      </template>
                      <template v-slot:item.status="{ item }">
                        <v-icon color="blue lighten-2" :title="item.status">
                          {{ item.status == "ABERTO" ? "lock_open" : item.status == "EXECUÇÃO" ? "hourglass_empty" : "lock" }}
                        </v-icon>
                      </template>
                      <template v-slot:item.dtRegistro="{ item }">
                        {{ formatDateTime(item.dtRegistro) }}
                      </template>
                      <template v-slot:item.dtSolucao="{ item }">
                        {{ formatDateTime(item.dtSolucao) }}
                      </template>
                    </v-data-table>
                    <v-snackbar v-model="snackContatos" :timeout="2000" absolute bottom left>{{ msgContatos }}</v-snackbar>
                    <v-alert v-if="validacao.contatos" :value="true" color="error" icon="priority_high" outlined>{{ validacao.contatos }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" v-if="moduloAssinaturas" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.promocoes ? 'red--text' : ''">
                <strong>Promoções</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-dialog persistent v-model="dialogPromocao" max-width="600px">
                      <v-card>
                        <v-form ref="formPromocao" v-model="observacaoValid" lazy-validation>
                          <v-toolbar>
                            <span class="headline">Cupons da promoção</span>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="closePromocao">
                              <v-icon color="darken-1">cancel</v-icon>
                            </v-btn>
                          </v-toolbar>
                          <v-card-text>
                            <v-container fluid grid-list-md>
                              <v-layout wrap>
                                <v-flex xs12 md12 class="text-xs-center">
                                  <p class="warning" v-if="this.mensagemPromocaoCupons !== ''" style="margin-bottom: 0px">
                                    {{ this.mensagemPromocaoCupons }}
                                  </p>
                                </v-flex>
                                <v-flex xs12 md12>
                                  <v-data-table
                                    mobile-breakpoint="0"
                                    :headers="headerPromocoesCupons"
                                    :items="promocaoItem.cupons"
                                    no-data-text
                                    :footer-props="rodape"
                                    height="300px"
                                    fixed-header
                                  ></v-data-table>
                                </v-flex>
                              </v-layout>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" @click="enviarEmailPromocao">Enviar e-mail</v-btn>
                            <v-btn color="blue darken-1" @click="downloadPromocao">Download</v-btn>
                            <v-btn color="blue darken-1" @click="closePromocao">Fechar</v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>

                    <v-data-table mobile-breakpoint="0" :headers="headerPromocoes" :items="promocoesCliente" no-data-text :footer-props="rodape">
                      <template v-slot:item.actions="{ item }">
                        <v-icon class="mr-2" color="blue lighten-2" title="Cupons" @click="editarPromocao(item)">info</v-icon>
                      </template>
                      <template v-slot:item.dtInicio="{ item }">
                        {{ formatDate(item.dtInicio) }}
                      </template>
                    </v-data-table>
                    <v-alert v-if="validacao.promocoes" :value="true" color="error" icon="priority_high" outlined>{{ validacao.promocoes }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.premios ? 'red--text' : ''">
                <strong>Prêmios</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <!-- <v-dialog persistent v-model="dialogPromocao" max-width="600px">
                      <v-card>
                        <v-form ref="formPromocao" v-model="observacaoValid" lazy-validation>
                          <v-toolbar>
                            <span class="headline">Cupons da promoção</span>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="closePromocao">
                              <v-icon color="darken-1">cancel</v-icon>
                            </v-btn>
                          </v-toolbar>
                          <v-card-text>
                            <v-container fluid grid-list-md>
                              <v-layout wrap>
                                <v-flex xs12 md12 class="text-xs-center">
                                  <p class="warning" v-if="this.mensagemPromocaoCupons !== ''" style="margin-bottom:0px">
                                    {{ this.mensagemPromocaoCupons }}
                                  </p>
                                </v-flex>
                                <v-flex xs12 md12>
                                  <v-data-table
                                    mobile-breakpoint="0"
                                    :headers="headerPromocoesCupons"
                                    :items="promocaoItem.cupons"
                                    no-data-text
                                    :footer-props="rodape"
                                    height="300px"
                                    fixed-header
                                  ></v-data-table>
                                </v-flex>
                              </v-layout>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" @click="enviarEmailPromocao">Enviar e-mail</v-btn>
                            <v-btn color="blue darken-1" @click="downloadPromocao">Download</v-btn>
                            <v-btn color="blue darken-1" @click="closePromocao">Fechar</v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog> -->

                    <v-data-table mobile-breakpoint="0" :headers="headerPremios" :items="premiosCliente" no-data-text :footer-props="rodape">
                      <!-- <template v-slot:item.actions="{ item }">
                        <v-icon class="mr-2" color="blue lighten-2" title="Cupons" @click="editarPromocao(item)">info</v-icon>
                      </template> -->
                      <template v-slot:item.dtPremio="{ item }">
                        {{ formatDate(item.dtPremio) }}
                      </template>
                      <template v-slot:item.dtEntrega="{ item }">
                        {{ formatDateTime(item.dtEntrega) }}
                      </template>
                    </v-data-table>
                    <v-alert v-if="validacao.premios" :value="true" color="error" icon="priority_high" outlined>{{ validacao.premios }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-if="!pessoaFisica && moduloAssinaturas" dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.cortesias ? 'red--text' : ''">
                <strong>Cortesias</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar flat>
                      <v-spacer></v-spacer>
                      <v-dialog persistent v-model="dialogCortesia" max-width="600px">
                        <template v-slot:activator="{ on }">
                          <v-btn color="success" fab small v-on="on">
                            <v-icon color="white" title="Incluir">add</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form ref="formCortesia" v-model="cortesiaValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">Cortesias</span>

                              <v-spacer></v-spacer>
                              <v-btn icon @click="closeCortesia">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container fluid grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs12 md12 v-if="$store.state.usuario.site_codPromoUnico == 0">
                                    <v-text-field
                                      ref="focusCortesias"
                                      v-model="codigosPromocionais"
                                      label="Número de Códigos Promocionais"
                                      v-mask="'###'"
                                      hint="Obrigatório"
                                      :rules="[(value) => !!value || 'Obrigatório.', (value) => (value >= 0 && value <= 999) || 'Obrigatório']"
                                      color="blue-grey lighten-2"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12 md12 v-if="$store.state.usuario.site_codPromoUnico == 1">
                                    <v-text-field
                                      ref="focusCortesia"
                                      v-model="codigosPromocionais"
                                      label="Código Promocional"
                                      hint="Obrigatório"
                                      :rules="[(value) => !!value || 'Obrigatório.']"
                                      maxLength="20"
                                      color="blue-grey lighten-2"
                                    ></v-text-field>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closeCortesia">Retornar</v-btn>
                              <v-btn color="blue darken-1" @click="saveCortesia">Incluir</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table mobile-breakpoint="0" :headers="headerCortesias" :items="cortesiasCliente" no-data-text :footer-props="rodape">
                      <template v-slot:item.actions="{ item }">
                        <v-icon
                          v-if="!item.idAssinatura"
                          :color="item.situacao == 1 ? 'blue lighten-1' : 'blue-grey lighten-3'"
                          :title="item.situacao == 1 ? 'Desativar' : 'Ativar'"
                          @click="ativarDesativarCortesia(item)"
                        >
                          {{ item.situacao == 1 ? "radio_button_checked" : "radio_button_unchecked" }}
                        </v-icon>
                      </template>

                      <template v-slot:item.dtRegistro="{ item }">
                        {{ formatDateTime(item.dtRegistro) }}
                      </template>
                      <template v-slot:item.dtExclusao="{ item }">
                        {{ item.situacao ? "" : formatDateTime(item.dtExclusao) }}
                      </template>
                    </v-data-table>
                    <v-snackbar v-model="snackCortesias" :timeout="2000" absolute bottom left>{{ msgCortesias }}</v-snackbar>
                    <v-alert v-if="validacao.cortesias" :value="true" color="error" icon="priority_high" outlined>{{ validacao.cortesias }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark elevation-0 v-if="moduloAssinaturas" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Assinaturas</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar flat v-if="id">
                      <v-spacer></v-spacer>
                      <template>
                        <v-btn color="success" fab small @click="incluirAssinatura" v-if="direitoAssinaturas && cliente.situacao && cliente.statusCredito">
                          <v-icon color="white" title="Incluir">add</v-icon>
                        </v-btn>
                      </template>
                    </v-toolbar>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headerAssinaturas"
                      :items="assinaturasCliente"
                      no-data-text
                      :footer-props="rodape"
                      :loading="loadingAss"
                      loading-text="Carregando..."
                    >
                      <template v-slot:item.actions="{ item }">
                        <v-icon v-if="direitoAssinaturas && item.idSite == idSitePrincipal" color="red" title="Assinatura" @click="visualizarAssinatura(item)">assignment</v-icon>
                      </template>
                      <template v-slot:item.dtCriacao="{ item }">
                        {{ formatDateTime(item.dtCriacao) }}
                      </template>
                      <template v-slot:item.dtInicio="{ item }">
                        {{ formatDate(item.dtInicio) }}
                      </template>
                      <template v-slot:item.dtFim="{ item }">
                        {{ formatDate(item.dtFim) }}
                      </template>
                      <template v-slot:item.online="{ item }">
                        <span v-html="item.online == 1 ? 'Sim' : ''"></span>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark elevation-0 v-if="moduloComercial" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Anúncios</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap>
                  <v-flex xs12 md12>
                    <v-card>
                      <v-toolbar flat v-if="direitoCriarAnuncio && id">
                        <v-spacer></v-spacer>
                        <template>
                          <v-btn color="success" fab small @click="incluirAnuncio" v-if="id && direitoCriarAnuncio && cliente.situacao && cliente.statusCredito">
                            <v-icon color="white" title="Incluir">add</v-icon>
                          </v-btn>
                        </template>
                      </v-toolbar>
                      <v-data-table
                        mobile-breakpoint="0"
                        :headers="headerAnuncios"
                        :items="anunciosCliente"
                        no-data-text
                        :footer-props="rodape"
                        :loading="loadingAnu"
                        loading-text="Carregando..."
                      >
                        <template v-slot:item.actions="{ item }">
                          <v-icon v-if="item.idSite == idSitePrincipal" color="orange" title="Selecionar para Recibo" @click="ativarDesativarItem(item)">
                            {{ item.selecionado == 1 ? "check_box" : "check_box_outline_blank" }}
                          </v-icon>
                          <v-icon v-if="direitoEditarAnuncio && item.idSite == idSitePrincipal" color="yellow darken-2" title="Anúncio" @click="visualizarAnuncio(item)"
                            >chrome_reader_mode</v-icon
                          >
                        </template>
                        <template v-slot:item.dtCriacao="{ item }">
                          {{ formatDateTime(item.dtCriacao) }}
                        </template>
                        <template v-slot:item.vlrLiquido="{ item }">
                          {{ formatCurrency(item.vlrLiquido) }}
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-if="rotaBanca" dark class="elevation-0" :disabled="isUpdating" @click="ajustaBanca">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.banca ? 'red--text' : ''">
                <strong>Dados Adicionais da Banca</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container fluid grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 md2>
                      <v-switch
                        v-model="banca.distribuidora"
                        :label="banca.distribuidora == 1 ? 'Banca é Distribuidora' : 'Banca não é distribuidora'"
                        @change="banca.idBancaDist = null"
                      ></v-switch>
                    </v-flex>
                    <v-flex xs12 md10 v-if="banca.distribuidora == 1"></v-flex>
                    <v-flex xs12 md10 v-if="banca.distribuidora == 0">
                      <v-autocomplete
                        :disabled="isUpdating"
                        v-model="banca.idBancaDist"
                        :items="distribuidoras"
                        :filter="filtrarObjetos"
                        label="Banca Distribuidora"
                        item-text="nome"
                        item-value="id"
                        autocomplete="disabled"
                        :name="Math.random()"
                        no-data-text
                        clearable
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs6 md6>
                      <v-currency-field
                        :locale="`pt-${$store.state.usuario.site_pais}`"
                        v-model="banca.comissao"
                        label="Comissão da Banca (%)"
                        :allowNegative="false"
                        :max="99"
                        :rules="[validacao.comissao == false || validacao.comissao, (value) => !!value || 'Obrigatório.', (value) => value.length < 6 || 'Comissão inválida']"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        @change="validacao.comissao = false"
                      ></v-currency-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        v-model="banca.reparteMinimo"
                        label="Reparte Mínimo"
                        v-mask="'###'"
                        :rules="[validacao.reparteMinimo == false || validacao.reparteMinimo]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        @change="validacao.reparteMinimo = false"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        v-model="banca.reparteMaximo"
                        label="Reparte Máximo"
                        v-mask="'###'"
                        :rules="[validacao.reparteMaximo == false || validacao.reparteMaximo]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-text-field
                        v-model="banca.atividade"
                        label="Atividade"
                        maxlength="20"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        :rules="[validacao.atividade == false || validacao.atividade]"
                        @change="
                          banca.atividade = pLetra(banca.atividade);
                          validacao.atividade = false;
                        "
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-autocomplete
                        :disabled="isUpdating"
                        v-model="banca.idTipoBanca"
                        :items="tiposBanca"
                        :filter="filtrarObjetos"
                        hint="Obrigatório"
                        :rules="[validacao.idTipoBanca == false || validacao.idTipoBanca, (value) => value != null || 'Obrigatório']"
                        label="Selecione o Tipo de Banca"
                        item-text="nome"
                        item-value="id"
                        autocomplete="disabled"
                        :name="Math.random()"
                        no-data-text
                        @change="validacao.idTipoBanca = false"
                      >
                        <template v-slot:item="data">
                          <v-list-item-content>
                            <v-list-item-title v-html="data.item.nome"></v-list-item-title>
                            <v-list-item-subtitle v-html="data.item.descricao"></v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-autocomplete
                        :disabled="isUpdating"
                        v-model="banca.idFormaPgto"
                        :items="formasPgto"
                        :filter="filtrarObjetos"
                        hint="Obrigatório"
                        :rules="[validacao.idFormaPgto == false || validacao.idFormaPgto, (value) => value != null || 'Obrigatório']"
                        label="Selecione a Forma de Pagamento Padrão"
                        item-text="nome"
                        item-value="id"
                        autocomplete="disabled"
                        :name="Math.random()"
                        no-data-text
                        @change="validacao.idFormaPgto = false"
                      >
                      </v-autocomplete>
                    </v-flex>
                    <v-flex xs12 md12>
                      <v-subheader>
                        <u>Banca abre nos dias:</u>
                      </v-subheader>
                    </v-flex>

                    <v-flex xs6 md3>
                      <v-switch v-model="banca.segunda" label="SEGUNDA-FEIRA" @change="banca.reparteSeg = 0"></v-switch>
                    </v-flex>
                    <v-flex xs6 md3 v-if="!banca.segunda"></v-flex>
                    <v-flex xs6 md3 v-if="banca.segunda">
                      <v-text-field
                        v-model="banca.reparteSeg"
                        label="Reparte Segunda-Feira"
                        v-mask="'#####'"
                        :rules="[validacao.reparteSeg == false || validacao.reparteSeg]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        @change="validacao.reparteSeg = false"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs6 md3>
                      <v-switch v-model="banca.terca" label="TERÇA-FEIRA" @change="banca.reparteTer = 0"></v-switch>
                    </v-flex>
                    <v-flex xs6 md3 v-if="!banca.terca"></v-flex>
                    <v-flex xs6 md3 v-if="banca.terca">
                      <v-text-field
                        v-model="banca.reparteTer"
                        label="Reparte Terça-Feira"
                        v-mask="'#####'"
                        :rules="[validacao.reparteTer == false || validacao.reparteTer]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        @change="validacao.reparteTer = false"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs6 md3>
                      <v-switch v-model="banca.quarta" label="QUARTA-FEIRA" @change="banca.reparteQua = 0"></v-switch>
                    </v-flex>
                    <v-flex xs6 md3 v-if="!banca.quarta"></v-flex>
                    <v-flex xs6 md3 v-if="banca.quarta">
                      <v-text-field
                        v-model="banca.reparteQua"
                        label="Reparte Quarta-Feira"
                        v-mask="'#####'"
                        :rules="[validacao.reparteQua == false || validacao.reparteQua]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        @change="validacao.reparteQua = false"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs6 md3>
                      <v-switch v-model="banca.quinta" label="QUINTA-FEIRA" @change="banca.reparteQui = 0"></v-switch>
                    </v-flex>
                    <v-flex xs6 md3 v-if="!banca.quinta"></v-flex>
                    <v-flex xs6 md3 v-if="banca.quinta">
                      <v-text-field
                        v-model="banca.reparteQui"
                        label="Reparte Quinta-Feira"
                        v-mask="'#####'"
                        :rules="[validacao.reparteQui == false || validacao.reparteQui]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        @change="validacao.reparteQui = false"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs6 md3>
                      <v-switch v-model="banca.sexta" label="SEXTA-FEIRA" @change="banca.reparteSex = 0"></v-switch>
                    </v-flex>
                    <v-flex xs6 md3 v-if="!banca.sexta"></v-flex>
                    <v-flex xs6 md3 v-if="banca.sexta">
                      <v-text-field
                        v-model="banca.reparteSex"
                        label="Reparte Sexta-Feira"
                        v-mask="'#####'"
                        :rules="[validacao.reparteSex == false || validacao.reparteSex]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        @change="validacao.reparteSex = false"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs6 md3>
                      <v-switch v-model="banca.sabado" label="SÁBADO" @change="banca.reparteSab = 0"></v-switch>
                    </v-flex>
                    <v-flex xs6 md3 v-if="!banca.sabado"></v-flex>
                    <v-flex xs6 md3 v-if="banca.sabado">
                      <v-text-field
                        v-model="banca.reparteSab"
                        label="Reparte Sábado"
                        v-mask="'#####'"
                        :rules="[validacao.reparteSab == false || validacao.reparteSab]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        @change="validacao.reparteSab = false"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs6 md3>
                      <v-switch v-model="banca.domingo" label="DOMINGO" @change="banca.reparteDom = 0"></v-switch>
                    </v-flex>
                    <v-flex xs6 md3 v-if="!banca.domingo"></v-flex>
                    <v-flex xs6 md3 v-if="banca.domingo">
                      <v-text-field
                        v-model="banca.reparteDom"
                        label="Reparte Domingo"
                        v-mask="'#####'"
                        :rules="[validacao.reparteDom == false || validacao.reparteDom]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        @change="validacao.reparteDom = false"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs6 md3>
                      <v-switch v-model="banca.feriado" label="FERIADO"></v-switch>
                    </v-flex>
                    <v-flex xs6 md3 v-if="!banca.feriado"></v-flex>
                    <v-flex xs6 md3 v-if="banca.feriado">
                      <v-text-field
                        v-model="banca.reparteFer"
                        label="Reparte Feriado*"
                        v-mask="'#####'"
                        :rules="[validacao.reparteFer == false || validacao.reparteFer]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        @change="validacao.reparteFer = false"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs6 md3>
                      <v-text-field
                        v-model="banca.horaInicio"
                        label="Horário de Abertura"
                        v-mask="'##:##'"
                        :rules="[validacao.horaInicio == false || validacao.horaInicio, (value) => valida_hora(value)]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        @change="validacao.horaInicio = false"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs6 md3>
                      <v-text-field
                        v-model="banca.horaFim"
                        label="Horário de Fechamento"
                        v-mask="'##:##'"
                        :rules="[validacao.horaFim == false || validacao.horaFim, (value) => valida_hora(value)]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        @change="validacao.horaFim = false"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs6 md2>
                      <v-text-field
                        v-model="banca.codAntigoBanca"
                        label="Código Antigo"
                        v-mask="'#######'"
                        :rules="[validacao.codAntigoBanca == false || validacao.codAntigoBanca]"
                        :disabled="isUpdating"
                        color="blue-grey lighten-2"
                        @change="validacao.codAntigoBanca = false"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs6 md1>
                      <v-text-field v-model="banca.id" label="ID" disabled color="blue-grey lighten-2"></v-text-field>
                    </v-flex>
                    <v-flex xs6 md3>
                      <v-switch
                        v-model="banca.situacao"
                        :disabled="isUpdating || (id != '' && banca.situacao == 1 && !direitoAvancado)"
                        :label="banca.situacao == 1 ? 'Banca Ativa' : 'Banca Inativa'"
                      ></v-switch>
                    </v-flex>
                    <v-alert v-if="validacao.banca" :value="true" color="error" icon="priority_high" outlined>{{ validacao.banca }}</v-alert>
                  </v-layout>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-if="rotaAgente" dark class="elevation-0" :disabled="isUpdating" @click="ajustaAgente">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.agente ? 'red--text' : ''">
                <strong>Dados Adicionais do Agente/Agência</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container fluid grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 md3>
                      <v-text-field
                        v-model="agente.origem"
                        label="Área/Origem de Venda"
                        maxlength="20"
                        :disabled="isUpdating"
                        hint="Preencha o nome da área/origem de venda"
                        :rules="[validacao.origem == false || validacao.origem]"
                        color="blue-grey lighten-2"
                        @change="
                          agente.origem = pLetra(agente.origem);
                          validacao.origem = false;
                        "
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 md3 v-if="agente.codAntigoCirculacao">
                      <v-text-field v-model="agente.codAntigoCirculacao" label="Código Circulação®" disabled color="blue-grey lighten-2"></v-text-field>
                    </v-flex>

                    <v-flex xs12 md3 v-if="id == '' || agente.codAntigoComercial">
                      <v-text-field v-model="agente.codAntigoComercial" maxlength="10" label="Código Comercial®" :disabled="id != ''" color="blue-grey lighten-2"></v-text-field>
                    </v-flex>
                    <v-flex xs6 md1>
                      <v-text-field v-model="agente.id" label="ID" disabled color="blue-grey lighten-2"></v-text-field>
                    </v-flex>

                    <v-flex xs6 md3>
                      <v-switch
                        v-model="agente.situacao"
                        :disabled="isUpdating || (id != '' && agente.situacao == 1 && !direitoAvancado)"
                        :label="agente.situacao == 1 ? 'Agente Ativo' : 'Agente Inativo'"
                      ></v-switch>
                    </v-flex>

                    <v-flex xs12 md12 v-if="agente.rede != 0">
                      <v-alert :value="true" color="error" icon="check_circle" outlined>Este é o Agente REDE.</v-alert>
                    </v-flex>

                    <v-flex xs12>
                      <v-card v-if="moduloComercial">
                        <v-toolbar>
                          <span><strong>Comercial</strong></span>
                          <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                          <v-container fluid grid-list-md>
                            <v-layout wrap>
                              <v-flex xs6 md3>
                                <v-currency-field
                                  :locale="`pt-${$store.state.usuario.site_pais}`"
                                  v-model="agente.descMaximoCom"
                                  label="Desconto Máximo Anúncio (%)"
                                  :allowNegative="false"
                                  :max="100"
                                  :rules="[
                                    validacao.descMaximoCom == false || validacao.descMaximoCom,
                                    (value) => !!value || 'Obrigatório.',
                                    (value) => (value && parseFloat(value.toString().replace('.', '').replace(',', '.')) <= 100) || 'Desconto inválido'
                                  ]"
                                  :disabled="isUpdating"
                                  color="blue-grey lighten-2"
                                  @change="validacao.descMaximoCom = false"
                                ></v-currency-field>
                              </v-flex>
                              <v-flex xs6 md3>
                                <v-currency-field
                                  :locale="`pt-${$store.state.usuario.site_pais}`"
                                  v-model="agente.comissaoPadraoCom"
                                  label="Comissão Padrão Anúncio (%)"
                                  :allowNegative="false"
                                  :max="100"
                                  :rules="[
                                    validacao.comissaoPadraoCom == false || validacao.comissaoPadraoCom,
                                    (value) => !!value || 'Obrigatório.',
                                    (value) => (value && parseFloat(value.toString().replace('.', '').replace(',', '.')) <= 100) || 'Comissão inválida'
                                  ]"
                                  :disabled="isUpdating"
                                  color="blue-grey lighten-2"
                                  @change="validacao.comissaoPadraoCom = false"
                                ></v-currency-field>
                              </v-flex>

                              <v-flex xs6 md6>
                                <v-switch v-model="agente.descComissao" :label="`${agente.descComissao == 1 ? 'Descontar Comissão' : 'Sem Desconto de Comissão'}`"></v-switch>
                              </v-flex>

                              <v-flex xs12 class="text-md-left" v-if="id">
                                <h4 class="overline font-weight-black font-italic">Relatório de Comissionamento de Anúncios</h4>
                                <v-divider></v-divider>
                              </v-flex>

                              <v-flex xs12 md3 v-if="id != ''">
                                <v-menu v-model="mnuQuitacao" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="intervaloQuitacao"
                                      label="Período de Quitação"
                                      prepend-icon="event"
                                      v-on="on"
                                      color="blue-grey lighten-2"
                                      clearable
                                      @click:clear="intervaloQuitacaoUS = ['', '']"
                                      readonly
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker v-model="intervaloQuitacaoUS" locale="pt-br" no-title range
                                    ><v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="mnuQuitacao = false"> OK </v-btn>
                                  </v-date-picker>
                                </v-menu>
                              </v-flex>

                              <v-flex xs1 v-if="id">
                                <v-btn color="blue" fab small :loading="loading">
                                  <v-icon color="white" title="Imprimir" @click="relatorio()">print</v-icon>
                                </v-btn>
                              </v-flex>
                            </v-layout>
                          </v-container>
                        </v-card-text>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">{{ validacao.mensagemErro }}</v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn
        :to="
          rotaAssinante
            ? '/assinaturas/assinantes'
            : rotaBanca
            ? '/assinaturas/bancas'
            : rotaFornecedor
            ? '/financeiro/fornecedores'
            : rotaAgente
            ? '/administrativo/agentes'
            : rotaAnunciante
            ? '/comercial/anunciantes'
            : '/financeiro/clientes'
        "
        color="orange lighten-2 white"
        style="color: white"
        id="btn-rsn"
      >
        <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
      </v-btn>
      <v-btn v-if="cliente.rede == 0" :loading="isUpdating" :disabled="bloqueiaBotoes" color="blue white" style="color: white" id="btn-rsn" depressed @click="salvar('false')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar
      </v-btn>
      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="orange white" style="color: white" id="btn-rsn" outlined @click="salvar('retornar')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn
        :loading="isUpdating"
        :disabled="bloqueiaBotoes"
        color="blue white"
        style="color: white"
        id="btn-rsn"
        outlined
        @click="salvar('true')"
        v-if="direitoCriar && cliente.rede == 0"
      >
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Novo
      </v-btn>
      <v-btn v-if="anunciosCliente.length > 0 && direitoRecibo" :loading="isUpdating" color="cyan" id="btn-rsn" style="color: white" outlined @click="emitirRecibo">
        <v-icon id="icon-rsn" left>insert_drive_file</v-icon>Recibo
      </v-btn>
    </v-card-actions>

    <v-snackbar v-model="snackbar" color="info" :timeout="5000" absolute bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackBancaAgente" color="blue lighten-2" :timeout="5000" absolute bottom right>{{ msgBancaAgente }}</v-snackbar>

    <v-dialog persistent v-model="dialogConfirm" max-width="600">
      <v-card>
        <v-toolbar>
          <span class="headline">Confirmação</span>
        </v-toolbar>
        <v-card-text class="mt-4" v-html="'<b>Tem certeza que deseja excluir?</b>'"></v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="naoConfirmado">Não</v-btn>
          <v-btn color="green darken-1" text @click="confirmado">Sim</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogLogAlteracao" max-width="1200px">
      <v-card>
        <LogAlteracao :idClienteEndereco="idClienteEndereco" :dialogLogAlteracao.sync="dialogLogAlteracao" />
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import axios from "axios";
import RamoAtividade from "@/views/adm/RamoAtividade.vue";
import CEP from "@/views/adm/CEP.vue";
import LogAlteracao from "@/views/components/LogAlteracao.vue";
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
import download from "downloadjs";
export default {
  components: {
    RamoAtividade,
    CEP,
    LogAlteracao
  },
  data() {
    return {
      cliente: {
        id: "",
        idSite: null,
        identificacao: "",
        inscricaoEstadual: null,
        inscricaoMunicipal: null,
        nome: "",
        nomeFantasia: "",
        idRamo: null,
        email: "",
        dtCadastro: null,
        dtNascimento: null,
        sexo: null,
        statusCredito: 1,
        diaVencimento: 0,
        idUsuarioCall: null,
        codAntigoComercial: null,
        codAntigoCirculacao: null,
        fbUsuarioId: null,
        rede: 0,
        online: 0,
        origem: "",
        cliente: 0,
        fornecedor: 0,
        agente: 0,
        banca: 0,
        funcionario: 0,
        tipoProtheus: 0,
        renovacaoAutomatica: 1,
        cartaoIntegral: 1,
        lgpd: 0,
        correiosAsaas: 0,
        situacao: 1
      },
      validacao: {
        id: false,
        idSite: false,
        identificacao: false,
        inscricaoEstadual: false,
        inscricaoMunicipal: false,
        nome: false,
        nomeFantasia: false,
        idRamo: false,
        email: false,
        dtCadastro: false,
        dtNascimento: false,
        sexo: false,
        statusCredito: false,
        diaVencimento: false,
        idUsuarioCall: false,
        codAntigoComercial: false,
        codAntigoCirculacao: false,
        fbUsuarioId: false,
        rede: false,
        online: false,
        origem: false,
        cliente: false,
        fornecedor: false,
        agente: false,
        banca: false,
        funcionario: false,
        tipoProtheus: false,
        renovacaoAutomatica: false,
        cartaoIntegral: false,
        lgpd: false,
        correiosAsaas: false,
        situacao: false,
        endereco: false,
        cep: false,
        enderecos: false,
        telefones: false,
        emails: false,
        cartoes: false,
        contas: false,
        celescs: false,
        pix: false,
        dependentes: false,
        departamentos: false,
        observacoes: false,
        contatos: false,
        promocoes: false,
        premios: false,
        cortesias: false,
        emails_email: false,
        cartoes_email: false,
        cartoes_validade: false,
        dependentes_email: false,
        dependentes_sexo: false,
        identificacaoDependente: false,
        //dados da banca:
        codAntigoBanca: false,
        comissao: false,
        reparteMinimo: false,
        reparteMaximo: false,
        atividade: false,
        idTipoBanca: false,
        idFormaPgto: false,
        domingo: false,
        segunda: false,
        terca: false,
        quarta: false,
        quinta: false,
        sexta: false,
        sabado: false,
        feriado: false,
        horaInicio: false,
        horaFim: false,
        idBancaDist: false,
        distribuidora: false,
        reparteDom: false,
        reparteSeg: false,
        reparteTer: false,
        reparteQua: false,
        reparteQui: false,
        reparteSex: false,
        reparteSab: false,
        reparteFer: false,
        //dados do agente:
        descMaximoCom: false,
        descComissao: false,
        comissaoPadraoCom: false,

        mensagemErro: false
      },

      rotaCliente: false,
      rotaAssinante: false,
      rotaAnunciante: false,
      rotaBanca: false,
      rotaAgente: false,
      rotaFornecedor: false,

      erroIdentificacao: "",
      erroEmail: "",
      dtNascimento: null, //* separado do objeto devido as conversões para apresentação
      dtCadastro: util.formatDate(new Date()), //* separado do objeto devido as conversões para apresentação
      dtCriacao: null,
      enderecosCliente: [],
      telefonesCliente: [],
      emailsCliente: [],
      cartoesCliente: [],
      contasCliente: [],
      celescsCliente: [],
      pixsCliente: [],
      dependentesCliente: [],
      departamentosCliente: [],
      observacoesCliente: [],
      contatosCliente: [],
      promocoesCliente: [],
      premiosCliente: [],
      cortesiasCliente: [],
      assinaturasCliente: [],
      loadingAss: true,
      anunciosCliente: [],
      loadingAnu: true,
      banca: {
        id: "",
        codAntigoBanca: null,
        comissao: 0,
        reparteMinimo: 0,
        reparteMaximo: 0,
        atividade: "",
        idTipoBanca: null,
        idFormaPgto: null,
        domingo: 0,
        segunda: 0,
        terca: 0,
        quarta: 0,
        quinta: 0,
        sexta: 0,
        sabado: 0,
        feriado: 0,
        horaInicio: "",
        horaFim: "",
        situacao: 1,
        idBancaDist: null,
        distribuidora: 0,
        reparteDom: 0,
        reparteSeg: 0,
        reparteTer: 0,
        reparteQua: 0,
        reparteQui: 0,
        reparteSex: 0,
        reparteSab: 0,
        reparteFer: 0
      },
      isBanca: false,

      agente: {
        id: "",
        rede: 0,
        origem: "",
        situacao: 1,
        codAntigoCirculacao: null,
        codAntigoComercial: null,
        descMaximoCom: 100,
        descComissao: 0,
        comissaoPadraoCom: 0
      },

      ufEndereco: {},
      municipioEndereco: {},
      cepSelecionado: { id: null, cep: null },

      cep: null,

      siteGrupo: [],
      ramos: [],
      distribuidoras: [],
      sexos: [
        { id: "M", nome: "Masculino" },
        { id: "F", nome: "Feminino" },
        { id: "O", nome: "Outros" },
        { id: "N", nome: "Não Informado" }
      ],
      statusCreditos: [
        { id: 1, nome: "LIBERADO" },
        { id: 0, nome: "BLOQUEADO" },
        { id: 2, nome: "EM ANÁLISE" }
      ],
      bancosDebitoConta: [],
      modosEntrega: [],
      tiposLogradouros: [],
      assuntos: [],
      motivos: [],
      procedimentos: [],
      tiposBanca: [],
      formasPgto: [],
      produtosClube: [],

      mnuQuitacao: false,
      intervaloQuitacaoUS: ["", ""],
      loader: null,
      loading: false,

      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50]
      },
      headerEnderecos: [
        { text: "Ações", value: "actions", width: "260" },
        { text: "ID", value: "id" },
        {
          text: this.$store.state.usuario.site_pais == "BR" ? "Município" : "Concelho",
          value: "municipio"
        },
        { text: "CEP", value: "cep" },
        {
          text: this.$store.state.usuario.site_pais == "BR" ? "Bairro" : "Freguesia/Localidade",
          value: "bairro"
        },
        { text: "Logradouro", value: "logradouro" },
        { text: "Número", value: "numero" },
        { text: "Complemento", value: "complemento" },
        { text: "Entregador", value: "entregador" },
        { text: "Rota", value: "rota" },
        { text: "Registro", value: "dtRegistro" },
        { text: "Desativado em", value: "dtExclusao" },
        { text: "Online", value: "online" }
      ],
      headerEnderecosCom: [
        { text: "Ações", value: "actions", width: "180" },
        { text: "ID", value: "id" },
        {
          text: this.$store.state.usuario.site_pais == "BR" ? "Município" : "Concelho",
          value: "municipio"
        },
        { text: "CEP", value: "cep" },
        {
          text: this.$store.state.usuario.site_pais == "BR" ? "Bairro" : "Freguesia/Localidade",
          value: "bairro"
        },
        { text: "Logradouro", value: "logradouro" },
        { text: "Número", value: "numero" },
        { text: "Complemento", value: "complemento" },
        { text: "Registro", value: "dtRegistro" },
        { text: "Desativado em", value: "dtExclusao" }
      ],
      headerEnderecosVA: [
        { text: "Ações", value: "actions", width: "180" },
        { text: "ID", value: "id" },
        {
          text: this.$store.state.usuario.site_pais == "BR" ? "Município" : "Concelho",
          value: "municipio"
        },
        { text: "CEP", value: "cep" },
        {
          text: this.$store.state.usuario.site_pais == "BR" ? "Bairro" : "Freguesia/Localidade",
          value: "bairro"
        },
        { text: "Logradouro", value: "logradouro" },
        { text: "Número", value: "numero" },
        { text: "Complemento", value: "complemento" },
        { text: "Entregador VA", value: "entregadorVA" },
        { text: "Rota VA", value: "rotaVA" },
        { text: "Registro", value: "dtRegistro" },
        { text: "Desativado em", value: "dtExclusao" }
      ],
      headerTelefones: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "DDD", value: "ddd" },
        { text: "Número", value: "numero" },
        { text: "Contato", value: "contato" },
        { text: "Registro", value: "dtRegistro" },
        { text: "Desativado em", value: "dtExclusao" }
      ],
      headerEmails: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "E-mail", value: "email" },
        { text: "Registro", value: "dtRegistro" },
        { text: "Desativado em", value: "dtExclusao" }
      ],
      headerCartoes: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Identificação", value: "identificacao" },
        { text: "Nome", value: "nome" },
        { text: "E-mail", value: "email" },
        { text: "Número", value: "numero" },
        { text: "Validade", value: "validade" },
        { text: "Registro", value: "dtRegistro" },
        { text: "Desativado em", value: "dtExclusao" }
      ],
      headerContas: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Identificação", value: "identificacao" },
        { text: "Titular", value: "nome" },
        { text: "Banco", value: "bancoNumero" },
        { text: "Agência", value: "agencia" },
        { text: "Número", value: "numero" },
        { text: "Financeiro", value: "codFinanceiro" },
        { text: "Registro", value: "dtRegistro" },
        { text: "Desativado em", value: "dtExclusao" }
      ],
      headerCelescs: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Identificação", value: "identificacao" },
        { text: "Unidade Consumidora", value: "unidadeConsumidora" },
        { text: "Registro", value: "dtRegistro" },
        { text: "Desativado em", value: "dtExclusao" }
      ],
      headerPix: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Tipo Chave", value: "tipoChave" },
        { text: "Tipo Conta", value: "tipoConta" },
        { text: "Chave", value: "chave" },
        { text: "Identificação", value: "identificacao" },
        { text: "Nome", value: "nome" },
        { text: "Registro", value: "dtRegistro" },
        { text: "Desativado em", value: "dtExclusao" }
      ],
      headerDependentes: [
        { text: "Ações", value: "actions", width: "160" },
        { text: "ID", value: "id" },
        { text: "Identificação", value: "identificacao" },
        { text: "Nome", value: "nome" },
        { text: "Data Nascimento", value: "dtNascimento" },
        { text: "Associação", value: "associacao" },
        { text: "E-mail", value: "email" },
        { text: "Telefone", value: "foneNumeroV" },
        { text: "Registro", value: "dtRegistro" },
        { text: "Desativado em", value: "dtExclusao" },
        { text: "Produtos", value: "nomesProdutos" },
        { text: "Senha", value: "senha" }
      ],
      headerDepartamentos: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Nome", value: "departamento" },
        { text: "Registro", value: "dtRegistro" },
        { text: "Desativado em", value: "dtExclusao" }
      ],
      headerObservacoes: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Observação", value: "observacao" },
        { text: "Usuário", value: "usuario" },
        { text: "Registro", value: "dtRegistro" },
        { text: "Desativado em", value: "dtExclusao" }
      ],
      headerContatos: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Status", value: "status" },
        { text: "Assunto", value: "assunto" },
        { text: "Motivo", value: "motivo" },
        { text: "Procedimento", value: "procedimento" },
        { text: "Usuário", value: "usuario" },
        { text: "Registro", value: "dtRegistro" },
        { text: "Usuário Solução", value: "usuarioSolucao" },
        { text: "Registro Solução", value: "dtSolucao" }
      ],
      headerPromocoes: [
        { text: "Ações", value: "actions" },
        { text: "Assinatura", value: "idAssinatura" },
        { text: "Promoção", value: "promocao" },
        { text: "Plano", value: "plano" },
        { text: "Inicio", value: "dtInicio" },
        { text: "Cupons", value: "cupons" }
      ],
      headerPromocoesCupons: [
        { text: "Número", value: "numero" },
        { text: "Mês", value: "mesAno" }
      ],
      headerPremios: [
        { text: "Promoção", value: "promocao" },
        { text: "Dependente", value: "dependente" },
        { text: "Data Prêmio", value: "dtPremio" },
        { text: "Entrega", value: "dtEntrega" },
        { text: "Prêmios", value: "premios" }
      ],
      headerCortesias: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Assinatura", value: "idAssinatura" },
        { text: "Código Promocional", value: "promocional" },
        { text: "Usuário", value: "usuario" },
        { text: "Registro", value: "dtRegistro" },
        { text: "Desativado em", value: "dtExclusao" }
      ],
      headerAssinaturas: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Plano", value: "plano" },
        { text: "Produtos", value: "produtos" },
        { text: "Status", value: "status" },
        { text: "Data de Criação", value: "dtCriacao", divider: "true" },
        { text: "Início", value: "dtInicio" },
        { text: "Fim", value: "dtFim", divider: "true" },
        { text: "Online", value: "online" }
        // { text: "Rede", value: "rede" }
      ],
      headerAnuncios: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Status", value: "status" },
        { text: "Cobrado", value: "vlrLiquido", align: "end" },
        { text: "Data Criação", value: "dtCriacao" },
        { text: "Produto", value: "nomeProduto" },
        { text: "Caderno", value: "nomeCaderno" },
        { text: "Formato", value: "nomeFormato" }
      ],

      ufs: [],
      municipios: [],
      ceps: [],

      //* Parâmetros dos modais
      dialogRamo: false,
      dialogCEP: false,
      idCEP: "",

      dialogEndereco: false,
      enderecoValid: true,
      enderecoIndex: -1,
      enderecoItem: {
        id: null,
        cobranca: 1,
        entrega: 1,
        entregaVA: 0,
        idUF: null,
        idMunicipio: null,
        idCEP: null,
        numero: null,
        complemento: "",
        idModoEntrega: null,
        complementoTipo: "",
        complementoNumero: "",
        complementoBloco: "",
        complementoEdificio: "",
        online: 0,
        situacao: 1
      },
      defaultEnderecoItem: {
        id: null,
        cobranca: 1,
        entrega: 1,
        entregaVA: 0,
        idUF: null,
        idMunicipio: null,
        idCEP: null,
        numero: null,
        complemento: "",
        idModoEntrega: null,
        complementoTipo: "",
        complementoNumero: "",
        complementoBloco: "",
        complementoEdificio: "",
        online: 0,
        situacao: 1,
        visualizacao: false
      },

      dialogTelefone: false,
      telefoneValid: true,
      telefoneIndex: -1,
      telefoneItem: {
        id: null,
        comercial: 1,
        residencial: 1,
        celular: 0,
        ddd: "",
        numero: "",
        contato: "",
        naoIncomodar: 0,
        whatsapp: 0,
        situacao: 1
      },
      defaultTelefoneItem: {
        id: null,
        comercial: 1,
        residencial: 1,
        celular: 0,
        ddd: "",
        numero: "",
        contato: "",
        naoIncomodar: 0,
        whatsapp: 0,
        situacao: 1
      },

      dialogEmail: false,
      emailValid: true,
      emailIndex: -1,
      emailItem: {
        id: null,
        email: "",
        fatura: 0,
        situacao: 1
      },
      defaultEmailItem: {
        id: null,
        email: "",
        fatura: 0,
        situacao: 1
      },

      cartaoIndex: -1,
      cartaoItem: {
        id: null,
        identificacao: "",
        nome: "",
        email: "",
        numero: "",
        cvv: "",
        validade: "",
        situacao: 1
      },

      dialogConta: false,
      contaValid: true,
      contaIndex: -1,
      contaItem: {
        id: null,
        identificacao: "",
        nome: "",
        banco: "",
        agencia: "",
        numero: "",
        situacao: 1
      },

      dialogCelesc: false,
      celescValid: true,
      celescIndex: -1,
      celescItem: {
        id: null,
        identificacao: "",
        unidadeConsumidora: "",
        situacao: 1
      },

      dialogPix: false,
      pixValid: true,
      pixIndex: -1,
      pixItem: {
        id: null,
        tipoChave: "",
        tipoConta: null,
        chave: "",
        identificacao: "",
        nome: "",
        situacao: 1
      },
      defaultPixItem: {
        id: null,
        tipoChave: "",
        tipoConta: null,
        chave: "",
        identificacao: "",
        nome: "",
        situacao: 1
      },
      tipoChaveSelecionado: null,
      tiposChave: [
        { id: "01", nome: "Telefone" },
        { id: "02", nome: "E-mail" },
        { id: "03", nome: "CPF/CNPJ" },
        { id: "04", nome: "Aleatória" },
        { id: "05", nome: "Dados Bancários" }
      ],
      tiposConta: [
        { id: "01", nome: "Conta Corrente" },
        { id: "02", nome: "Conta Pagamnto" },
        { id: "03", nome: "Poupança" }
      ],

      dialogDependente: false,
      dependenteValid: true,
      dependenteIndex: -1,
      dependenteItem: {
        id: null,
        identificacao: "",
        nome: "",
        dtNascimento: null,
        dtNasc: null, //* para apresentação
        clubeCortesia: 0,
        associacao: "",
        email: "",
        foneDDD: "",
        foneNumero: "",
        foneNumeroV: "",
        produtosClube: [],
        sexo: null,
        situacao: 1
      },
      defaultDependenteItem: {
        id: null,
        identificacao: "",
        nome: "",
        dtNascimento: null,
        dtNasc: null, //* para apresentação
        clubeCortesia: 0,
        associacao: "",
        email: "",
        foneDDD: "",
        foneNumero: "",
        foneNumeroV: "",
        produtosClube: [],
        editar: true,
        situacao: 1
      },

      dialogDepartamento: false,
      departamentoValid: true,
      departamentoIndex: -1,
      departamentoItem: {
        id: null,
        departamento: "",
        situacao: 1
      },
      defaultDepartamentoItem: {
        id: null,
        departamento: "",
        situacao: 1
      },

      dialogObservacao: false,
      dialogPromocao: false,
      observacaoValid: true,
      observacaoIndex: -1,
      observacaoItem: {
        id: null,
        observacao: "",
        situacao: 1
      },
      defaultObservacaoItem: {
        id: null,
        observacao: "",
        situacao: 1
      },

      assuntoSelecionado: {},
      motivoSelecionado: {},
      procedimentoSelecionado: {},
      dialogContato: false,
      contatoValid: true,
      contatoIndex: -1,
      contatoItem: {
        id: null,
        status: "ABERTO",
        idAssunto: null,
        idMotivo: null,
        idProcedimento: null,
        assunto: "",
        motivo: "",
        procedimento: "",
        execucao: "",
        solucao: ""
      },
      defaultContatoItem: {
        id: null,
        status: "ABERTO",
        idAssunto: null,
        idMotivo: null,
        idProcedimento: null,
        assunto: "",
        motivo: "",
        procedimento: "",
        execucao: "",
        solucao: ""
      },

      promocaoIndex: -1,
      promocaoItem: {
        idAssinatura: null,
        idPromocao: null,
        idRenovacao: null,
        promocao: null,
        cupons: []
      },
      defaultPromocaoItem: {
        idAssinatura: null,
        idPromocao: null,
        idRenovacao: null,
        promocao: null,
        cupons: []
      },
      bancoSelecionado: {
        bancoNumero: ""
      },

      dialogCortesia: false,
      cortesiaValid: true,
      codigosPromocionais: 0,
      cortesiaIndex: -1,
      cortesiaItem: {
        id: null,
        idAssinatura: "",
        promocional: "",
        situacao: 1
      },

      idSitePrincipal: null,
      dialogRecalculandoEdicoes: false,
      dialogConfirm: false,
      tipoConfirm: "",

      direitoAssinaturas: false,
      direitoCriarAnuncio: false,
      direitoEditarAnuncio: false,
      direitoCEPs: false,
      direitoRoteiro: false,
      direitoRamos: false,
      direitoCriar: false,
      direitoVerSenha: false,
      direitoRecibo: false,
      direitoRenovacaoAutomatica: false,
      direitoAlterarCredito: false,
      direitoAvancado: false,

      dialogLogAlteracao: false,
      idClienteEndereco: "",

      elementoValid: true,
      id: "",
      painel: null,
      mensagem: "",
      mensagemPromocaoCupons: "",
      msgBancaAgente: "",
      snackbar: false,
      snackErro: false,
      snackBancaAgente: false,
      msgEnderecos: "",
      snackEnderecos: false,
      msgTelefones: "",
      snackTelefones: false,
      msgEmails: "",
      snackEmails: false,
      msgCartoes: "",
      snackCartoes: false,
      msgContas: "",
      snackContas: false,
      msgCelescs: "",
      snackCelescs: false,
      msgPix: "",
      snackPix: false,
      msgDependentes: "",
      snackDependentes: false,
      msgDepartamentos: "",
      snackDepartamentos: false,
      msgObservacoes: "",
      snackObservacoes: false,
      msgContatos: "",
      snackContatos: false,
      msgCortesias: "",
      snackCortesias: false,

      isUpdating: false,
      bloqueiaBotoes: false
    };
  },

  computed: {
    rota() {
      return this.$route.path;
    },
    defaultCartaoItem() {
      return {
        id: null,
        identificacao: this.cliente.identificacao,
        nome: this.cliente.nome.toUpperCase(),
        email: this.cliente.email,
        numero: "",
        cvv: "",
        validade: "",
        situacao: 1
      };
    },
    defaultContaItem() {
      return {
        id: null,
        identificacao: this.cliente.identificacao,
        nome: this.cliente.nome.toUpperCase(),
        banco: "",
        agencia: "",
        numero: "",
        situacao: 1
      };
    },
    defaultCelescItem() {
      return {
        id: null,
        identificacao: this.cliente.identificacao,
        unidadeConsumidora: "",
        situacao: 1
      };
    },
    moduloAssinaturas() {
      return this.$store.state.usuario.site_assinaturas;
    },
    moduloComercial() {
      return this.$store.state.usuario.site_comercial;
    },
    pessoaFisica() {
      var pf = true;
      if (this.$store.state.usuario.site_pais == "BR") {
        if (this.cliente.identificacao.length == 14) {
          pf = false;
        }
      } else {
        if (
          this.cliente.identificacao &&
          this.cliente.identificacao.substring(0, 1) != "1" &&
          this.cliente.identificacao.substring(0, 1) != "2" &&
          this.cliente.identificacao != "999999990"
        ) {
          if (this.id == "") {
            if (util.somenteNumeros(this.cliente.identificacao.substring(0, 1))) {
              //* Se for letra, mantém como PF
              pf = false;
            }
          } else {
            pf = false;
          }
        }
      }
      return pf;
    },
    intervaloQuitacao() {
      var retorno = "";
      if (this.intervaloQuitacaoUS && this.intervaloQuitacaoUS.length > 1) {
        this.intervaloQuitacaoUS.forEach((element) => {
          if (element) {
            retorno += (retorno ? " - " : "") + util.formatDate(element);
          }
        });
      }
      return retorno;
    }
  },

  watch: {
    cepSelecionado(val) {
      if (val && val.cep != null && !this.enderecoItem.edicao) {
        setTimeout(() => {
          this.$refs.focusNumero.focus();
        }, 300);
      }
    },
    tipoChaveSelecionado(val) {
      if (val) {
        this.pixItem.tipoChave = val;
      }
      if (val == "01") {
        for (let index = 0; index < this.telefonesCliente.length; index++) {
          const element = this.telefonesCliente[index];
          if (element.situacao) {
            this.pixItem.chave = "+55" + element.ddd + element.numero;
            break;
          }
        }
      } else if (val == "02") {
        if (util.valida_email(this.cliente.email)) {
          this.pixItem.chave = this.cliente.email.toLowerCase();
        } else {
          for (let index = 0; index < this.emailsCliente.length; index++) {
            const element = this.emailsCliente[index];
            if (element.situacao) {
              this.pixItem.chave = element.email.toLowerCase();
              break;
            }
          }
        }
      } else if (val == "03") {
        this.pixItem.chave = this.cliente.identificacao;
      } else if (val == "05") {
        for (let index = 0; index < this.contasCliente.length; index++) {
          const element = this.contasCliente[index];
          if (element.situacao) {
            var agencia = element.agencia;
            agencia = agencia.padStart(5, "0");
            agencia = agencia.substr(0, 5);

            var numero = element.numero;
            numero = numero.padStart(13, "0");
            numero = numero.substr(0, 13);

            this.pixItem.chave = element.bancoNumero + agencia + numero;
            break;
          }
        }
      }
    },
    assuntoSelecionado(val) {
      this.contatoItem.idAssunto = val.id;
      this.contatoItem.assunto = val.nome;
    },
    motivoSelecionado(val) {
      this.contatoItem.idMotivo = val.id;
      this.contatoItem.motivo = val.nome;
    },
    procedimentoSelecionado(val) {
      this.contatoItem.idProcedimento = val.id;
      this.contatoItem.procedimento = val.nome;
    },
    dialogRamo(val) {
      val || this.closeRamo();
    },
    dialogCEP(val) {
      val || this.closeCEP();
    },
    dialogEndereco(val) {
      if (val) {
        setTimeout(() => {
          if (this.enderecoItem.edicao) {
            this.$refs.focusComplemento.focus();
          } else {
            this.$refs.focusCep.focus();
          }
        }, 300);
      }
      val || this.closeEndereco();
    },
    dialogTelefone(val) {
      if (val) {
        setTimeout(() => {
          this.$refs.focusTelefone.focus();
        }, 300);
      }
      val || this.closeTelefone();
    },
    dialogEmail(val) {
      if (val) {
        setTimeout(() => {
          this.$refs.focusEmail.focus();
        }, 300);
      }
      val || this.closeEmail();
    },
    dialogConta(val) {
      if (val) {
        setTimeout(() => {
          this.$refs.focusConta.focus();
        }, 300);
        this.contaItem.identificacao = this.cliente.identificacao;
        this.contaItem.nome = this.cliente.nome.toUpperCase();
      }
      val || this.closeConta();
    },
    dialogCelesc(val) {
      if (val) {
        setTimeout(() => {
          this.$refs.focusCelesc.focus();
        }, 300);
        this.celescItem.identificacao = this.cliente.identificacao;
      }
      val || this.closeCelesc();
    },
    dialogPix(val) {
      if (val) {
        setTimeout(() => {
          this.$refs.focusPix.focus();
        }, 300);
        this.pixItem.identificacao = this.cliente.identificacao;
        this.pixItem.nome = this.cliente.nome.toUpperCase();
      }
      val || this.closePix();
    },
    dialogDependente(val) {
      if (val) {
        setTimeout(() => {
          this.$refs.focusDependente.focus();
        }, 300);
      }
      val || this.closeDependente();
    },
    dialogDepartamento(val) {
      if (val) {
        setTimeout(() => {
          this.$refs.focusDepartamento.focus();
        }, 300);
      }
      val || this.closeDepartamento();
    },
    dialogObservacao(val) {
      if (val) {
        setTimeout(() => {
          this.$refs.focusObservacao.focus();
        }, 300);
      }
      val || this.closeObservacao();
    },
    dialogContato(val) {
      if (val && !this.contatoItem.id) {
        setTimeout(() => {
          this.$refs.focusAssunto.focus();
        }, 300);
      }
      val || this.closeContato();
    },
    dialogPromocao(val) {
      val || this.closePromocao();
    },
    dialogCortesia(val) {
      if (val) {
        setTimeout(() => {
          if (this.$store.state.usuario.site_codPromoUnico) {
            this.$refs.focusCortesia.focus();
          } else {
            this.$refs.focusCortesias.focus();
          }
        }, 300);
      }
      val || this.closeCortesia();
    },
    dialogLogAlteracao(val) {
      val || this.closeLogAlteracao();
    }
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      this.erroIdentificacao = "";
      this.erroEmail = "";

      var erro = false;
      if (this.enderecosCliente.length == 0) {
        erro = true;
        this.validacao.enderecos = "Você deve cadastrar pelo menos 1 endereço para o cliente";
      }
      if (!this.cliente.cliente && !this.cliente.fornecedor && !this.cliente.agente && !this.cliente.banca) {
        erro = true;
        this.validacao.mensagemErro = "Favor marcar a(s) respectiva(s) caixa(s): Cliente e/ou Fornecedor e/ou Agente/Agência e/ou Banca";
      }

      if (this.$store.state.usuario.site_protheus) {
        if (this.telefonesCliente.length == 0) {
          erro = true;
          this.validacao.telefones = "Devido a integração com o Protheus, você deve cadastrar pelo menos 1 telefone para o cliente";
        }
      }
      if (!this.id) {
        await this.isClienteExiste();
        await this.isEmailExiste();
      }

      if (!this.$refs.formElemento.validate() || erro) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      if (this.rotaBanca && !this.banca.idTipoBanca) {
        this.validacao.banca = "Preencha os dados da banca";
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }
      if (this.rotaBanca && this.cliente.banca && !this.banca.idTipoBanca) {
        this.validacao.mensagemErro = "Atenção: Acesse o cadastro da banca e complemente seus dados";
      }
      if (this.rotaAgente && this.cliente.agente && !this.agente.id) {
        this.validacao.mensagemErro = "Atenção: Acesse o cadastro do agente e complemente seus dados";
      }

      if (this.dtNascimento != null) {
        this.cliente.dtNascimento = util.formatDateUS(this.dtNascimento);
      } else {
        this.cliente.dtNascimento = null;
      }
      if (!this.cliente.senha) {
        this.cliente.senha = this.cliente.identificacao;
      }
      this.cliente.dtCadastro = util.formatDateUS(this.dtCadastro);

      var erroCep = false;
      var erroCep2 = false;
      //* Prepara os enderecos do cliente para envio a base
      var enderecosClienteCommit = [];
      for (let index = 0; index < this.enderecosCliente.length; index++) {
        const element = this.enderecosCliente[index];

        var enderecoElement = {
          id: element.id,
          idCliente: this.id,
          cobranca: element.cobranca,
          entrega: element.entrega,
          entregaVA: element.entregaVA,
          idCEP: element.idCEP,
          numero: element.numero,
          complemento: element.complemento,
          idModoEntrega: element.idModoEntrega,
          complementoNumero: element.complementoNumero,
          complementoTipo: element.complementoTipo,
          complementoBloco: element.complementoBloco,
          complementoEdificio: element.complementoEdificio,
          online: 0,
          latitude: element.latitude,
          longitude: element.longitude,
          situacao: element.situacao
        };

        if (!element.idCEP) {
          //* Se CEP foi incluído diretamente pela tela de endereços do cliente, inclui para pegar o id
          var cepCommit = {
            idMunicipio: element.idMunicipio,
            cep: element.cep,
            bairro: element.bairro,
            idTipo: element.idTipo,
            logradouro: element.logradouro,
            faixa: "",
            idEntregador: null,
            idRota: null,
            entrega: element.entrega,
            roteiro: this.moduloAssinaturas ? 0 : null,
            idEntregadorVA: null,
            idRotaVA: null,
            situacao: 1
          };
          try {
            var retornoCEP = await api.post(process.env.VUE_APP_URL_WEB_API + "adm/cep?idSite=" + this.idSitePrincipal, cepCommit);
            enderecoElement.idCEP = retornoCEP.data.id;
          } catch (e) {
            if (e.toString().includes("status code 400")) {
              this.mensagem = "SESSÃO EXPIRADA";
              this.snackErro = true;
              erroCep2 = true;
            } else {
              erroCep = true;
              this.validacao.enderecos =
                "ERRO: " + (e.response && e.response.data && e.response.data.length > 0 ? e.response.data[0].mensagem : "Indeterminado, verifique sua conexão");
            }
          }
        }

        enderecosClienteCommit.push(enderecoElement);
      }

      if (erroCep && !erroCep2) {
        this.mensagem = "Ocorreu um erro ao incluir o novo logradouro";
        this.snackErro = true;
        return;
      }
      if (erroCep2) {
        return;
      }

      if (this.cliente.funcionario) {
        this.cliente.tipoProtheus = 0;
      }
      if (this.cliente.tipoProtheus) {
        this.cliente.funcionario = 0;
      }
      //* Prepara os telefones do cliente para envio a base
      var telefonesClienteCommit = [];
      this.telefonesCliente.forEach((element) => {
        telefonesClienteCommit.push({
          id: element.id,
          idCliente: this.id,
          comercial: element.comercial,
          residencial: element.residencial,
          celular: element.celular,
          ddd: element.ddd,
          contato: element.contato,
          numero: element.numero,
          naoIncomodar: element.naoIncomodar,
          whatsapp: element.whatsapp && element.celular,
          situacao: element.situacao
        });
      });

      //* Prepara os emails do cliente para envio a base
      var emailsClienteCommit = [];
      this.emailsCliente.forEach((element) => {
        emailsClienteCommit.push({
          id: element.id,
          idCliente: this.id,
          email: element.email,
          fatura: element.fatura,
          situacao: element.situacao
        });
      });

      //* Prepara os cartões do cliente para envio a base
      var cartoesClienteCommit = [];
      this.cartoesCliente.forEach((element) => {
        cartoesClienteCommit.push({
          id: element.id,
          idCliente: this.id,
          identificacao: element.identificacao,
          nome: element.nome,
          email: element.email,
          numero: element.numero,
          cvv: element.cvv,
          validade: element.validade,
          situacao: element.situacao
        });
      });

      //* Prepara as contas do cliente para envio a base
      var contasClienteCommit = [];
      this.contasCliente.forEach((element) => {
        contasClienteCommit.push({
          id: element.id,
          idCliente: this.id,
          idBanco: element.idBanco,
          identificacao: element.identificacao,
          nome: element.nome,
          banco: element.bancoNumero,
          agencia: element.agencia,
          numero: element.numero,
          situacao: element.situacao
        });
      });

      //* Prepara as unidades consumidoras do cliente para envio a base
      var celescsClienteCommit = [];
      this.celescsCliente.forEach((element) => {
        celescsClienteCommit.push({
          id: element.id,
          idCliente: this.id,
          identificacao: element.identificacao,
          unidadeConsumidora: element.unidadeConsumidora,
          situacao: element.situacao
        });
      });

      //* Prepara os pix do cliente para envio a base
      var pixsClienteCommit = [];
      this.pixsCliente.forEach((element) => {
        pixsClienteCommit.push({
          id: element.id,
          idCliente: this.id,
          tipoChave: element.tipoChave,
          tipoConta: element.tipoConta,
          chave: element.chave,
          identificacao: element.identificacao,
          nome: element.nome,
          situacao: element.situacao
        });
      });

      //* Prepara os dependentes do cliente para envio a base
      var dependentesClienteCommit = [];
      this.dependentesCliente.forEach((element) => {
        var produtosClubeCommit = [];
        element.produtosClube.forEach((produto) => {
          produtosClubeCommit.push({ idDependente: element.id, idProduto: produto.id });
        });
        dependentesClienteCommit.push({
          id: element.id,
          idCliente: this.id,
          identificacao: element.identificacao,
          nome: element.nome,
          dtNascimento: element.dtNasc == null ? null : util.formatDateUS(element.dtNasc),
          clubeCortesia: element.clubeCortesia,
          associacao: element.associacao,
          email: element.email,
          foneDDD: element.foneDDD,
          foneNumero: element.foneNumero,
          produtosClube: produtosClubeCommit,
          sexo: element.sexo,
          situacao: element.situacao
        });
      });

      //* Prepara os departamentos do cliente para envio a base
      var departamentosClienteCommit = [];
      this.departamentosCliente.forEach((element) => {
        departamentosClienteCommit.push({
          id: element.id,
          idCliente: this.id,
          departamento: element.departamento,
          situacao: element.situacao
        });
      });

      //* Prepara os observações do cliente para envio a base
      var observacoesClienteCommit = [];
      this.observacoesCliente.forEach((element) => {
        observacoesClienteCommit.push({
          id: element.id,
          idCliente: this.id,
          observacao: element.observacao,
          idUsuario: element.idUsuario,
          situacao: element.situacao
        });
      });

      //* Prepara os contatos do cliente para envio a base
      var contatosClienteCommit = [];
      this.contatosCliente.forEach((element) => {
        contatosClienteCommit.push({
          id: element.id,
          idCliente: this.id,
          idAssunto: element.idAssunto,
          idMotivo: element.idMotivo,
          idProcedimento: element.idProcedimento,
          idUsuario: element.idUsuario,
          contato: element.contato,
          status: element.status
        });
      });

      this.cliente.enderecos = enderecosClienteCommit;
      this.cliente.telefones = telefonesClienteCommit;
      this.cliente.emails = emailsClienteCommit;
      this.cliente.cartoes = cartoesClienteCommit;
      this.cliente.contas = contasClienteCommit;
      this.cliente.celescs = celescsClienteCommit;
      this.cliente.pixs = pixsClienteCommit;
      this.cliente.dependentes = dependentesClienteCommit;
      this.cliente.departamentos = departamentosClienteCommit;
      this.cliente.observacoes = observacoesClienteCommit;
      this.cliente.contatos = contatosClienteCommit;

      this.isUpdating = true;

      var clienteCommit = JSON.parse(JSON.stringify(this.cliente));
      if (this.rotaBanca) {
        clienteCommit.dadosBanca = this.banca;
        var item = this.tiposBanca.find((dado) => dado.id == this.banca.idTipoBanca);
        if (item) {
          clienteCommit.dadosBanca.casa = item.casa;
        }
        var itemD = this.distribuidoras.find((dado) => dado.id == this.banca.idBancaDist);
        if (itemD) {
          clienteCommit.dadosBanca.codAntigoBancaDist = itemD.codAntigoBanca;
        }
      }

      try {
        var retorno = "";
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "adm/cliente/" + this.id, clienteCommit);
        } else {
          this.dtCriacao = new Date();
          this.cliente.origem = this.rotaAnunciante ? "ANU" : this.rotaFornecedor || this.rotaCliente || this.rotaAgente ? "FIN" : "ASS";
          if (this.rotaAssinante) {
            //* Se cliente está sendo incluído na tela de assinantes, salva idCirculacao=0, pois clientes com idCirculacao != null tem esta origem
            if (!this.cliente.codAntigoCirculacao) {
              this.cliente.codAntigoCirculacao = 0;
            }
          }
          if (this.rotaAnunciante) {
            //* Se cliente está sendo incluído na tela de anunciantes, salva idComercial=0, pois clientes com idComercial != null tem esta origem
            if (!this.cliente.codAntigoComercial) {
              this.cliente.codAntigoComercial = 0;
            }
          }

          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "adm/cliente?idSite=" + this.idSitePrincipal, clienteCommit);
          this.id = retorno.data.id;
          this.cliente.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;

        if (this.rotaBanca) {
          this.banca.idCliente = this.id;
          this.salvarBanca();
        }
        if (this.rotaAgente) {
          this.agente.idCliente = this.id;
          this.salvarAgente();
        }

        if (novo == "true") {
          const destino = this.rotaAssinante
            ? "/assinaturas/assinante"
            : this.rotaBanca
            ? "/assinaturas/banca"
            : this.rotaFornecedor
            ? "/financeiro/fornecedor"
            : this.rotaAgente
            ? "/administrativo/agente"
            : this.rotaAnunciante
            ? "/comercial/anunciante"
            : "/financeiro/cliente";
          this.$router.push(destino);
          this.$router.go(destino);
        } else if (novo == "retornar") {
          setTimeout(() => {
            const destino = this.rotaAssinante
              ? "/assinaturas/assinantes"
              : this.rotaBanca
              ? "/assinaturas/bancas"
              : this.rotaFornecedor
              ? "/financeiro/fornecedores"
              : this.rotaAgente
              ? "/administrativo/agentes"
              : this.rotaAnunciante
              ? "/comercial/anunciantes"
              : "/financeiro/clientes";
            this.$router.push(destino);
          }, 2000);
        } else {
          this.carregarEnderecos();
          this.carregarTelefones();
          this.carregarEmails();
          this.carregarCartoes();
          this.carregarContas();
          this.carregarCelescs();
          this.carregarPixs();
          this.carregarDependentes();
          this.carregarDepartamentos();
          this.carregarObservacoes();
          this.carregarContatos();
          this.carregarPromocoes();
          this.carregarPremios();
          this.carregarAssinaturas();
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },
    async carregarRamos() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/ramo-atividade/lista");

        this.ramos = response.data;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de ramos de atividade";
        this.snackErro = true;
      }
    },
    async carregarUFs() {
      try {
        //* Carrega os estados
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cep/ufs");
        this.ufs = response.data;
        if (this.$store.state.usuario.site_pais != "BR") {
          this.ufs.forEach((element) => {
            element.nome += "/" + element.pais;
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de estados";
        this.snackErro = true;
      }
    },
    async carregarBancosReceber() {
      try {
        //* Carrega os bancos de cedentes de débito em conta ativos
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/cedente/debito");
        this.bancosDebitoConta = response.data;
        this.bancosDebitoConta.forEach((element) => {
          element.id = element.idBanco;
          element.nome = element.bancoNumero + " - " + element.bancoNome;
        });
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de bancos para débito em conta";
        this.snackErro = true;
      }
    },
    async carregarBancosPagar() {
      try {
        //* Carrega os bancos para débito em conta a pagar ativos
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/banco/pagar");
        this.bancosDebitoConta = response.data;
        this.bancosDebitoConta.forEach((element) => {
          element.bancoNome = element.nome;
          element.nome = element.numero + " - " + element.nome;
          element.bancoNumero = element.numero;
        });
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de bancos para débito em conta";
        this.snackErro = true;
      }
    },
    async carregarModosEntrega() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/modo-entrega/lista");
        this.modosEntrega = response.data;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de modos de entrega";
        this.snackErro = true;
      }
    },
    async carregarTiposLogradouros() {
      this.tiposLogradouros = [];
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/tipo-logradouro/lista");
        this.tiposLogradouros = response.data;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de tipos de logradouros";
        this.snackErro = true;
      }
    },
    async carregarMotivosContato() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/motivo-contato/lista?idSite=" + this.idSitePrincipal);
        if (response.data) {
          response.data.forEach((element) => {
            if (element.tipo == 2) {
              this.motivos.push(element);
            } else if (element.tipo == 3) {
              this.procedimentos.push(element);
            } else {
              this.assuntos.push(element);
            }
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os motivos de contato";
        this.snackErro = true;
      }
    },
    async carregarDistribuidoras() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-banca/distribuidora/lista");
        this.distribuidoras = response.data;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de bancas distribuidoras";
        this.snackErro = true;
      }
    },
    async carregarTiposBanca() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/tipo-banca/lista");
        this.tiposBanca = response.data;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de tipos de bancas";
        this.snackErro = true;
      }
    },
    async carregarFormasPgto() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/forma-pagamento/lista");
        this.formasPgto = response.data;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de formas de pagamento";
        this.snackErro = true;
      }
    },
    async carregarProdutosClube() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/produto/lista?tipo=CLUBE");
        this.produtosClube = response.data;
      } catch (e) {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de produtos do clube";
        this.snackErro = true;
      }
    },

    filtrarMunicipios() {
      this.carregarMunicipios(this.ufEndereco.id);
    },
    async carregarMunicipios(idUF) {
      try {
        //* Carrega os municípios da UF
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cep/municipios?idUF=" + idUF);

        this.municipios = response.data;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de " + (this.$store.state.usuario.site_pais == "BR" ? "municípios da UF" : "concelhos do distrito");
        this.snackErro = true;
      }
    },
    async filtrarCEPs() {
      try {
        //* Carrega os logradouros do município
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cep/municipio/" + this.municipioEndereco.id);
        this.ceps = [];
        response.data.forEach((element) => {
          element.nome = element.cep + ": " + element.bairro + " - " + element.tipo + " " + element.logradouro + (element.faixa ? " - " : "") + element.faixa;
          if (this.enderecoItem.entrega) {
            if (element.entrega) {
              this.ceps.push(element);
            }
          } else {
            this.ceps.push(element);
          }
        });
        // this.ceps = response.data;
        // this.ceps.forEach(element => {
        //   element.nome = element.cep + ": " + element.bairro + " - " + element.tipo + " " + element.logradouro + (element.faixa ? " - " : "") + element.faixa;
        // });
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de logradouros do " + (this.$store.state.usuario.site_pais == "BR" ? "município" : "concelho");
        this.snackErro = true;
      }
    },
    async buscarCep() {
      if (this.cep != null && ((this.$store.state.usuario.site_pais == "PT" && this.cep.length == 8) || (this.$store.state.usuario.site_pais == "BR" && this.cep.length == 9))) {
        this.validacao.cep = false;
        this.ufEndereco = {};
        this.municipioEndereco = {};
        this.cepSelecionado = { id: null, cep: null };

        try {
          //* Carrega os logradouros com o CEP
          var cep = util.somenteNumeros(this.cep);
          const response = await api.get(
            process.env.VUE_APP_URL_WEB_API +
              "adm/cep/cep/" +
              cep +
              "?numero=" +
              (this.enderecoItem.numero ? this.enderecoItem.numero : "") +
              (this.isBanca ? "&vendaAvulsa=1" : "") +
              (this.enderecoItem.entrega ? "&entrega=1" : "")
          );

          this.ceps = response.data;
          this.ceps.forEach((element) => {
            element.nome = element.cep + ": " + element.bairro + " - " + element.tipo + " " + element.logradouro + (element.faixa ? " - " : "") + element.faixa;
          });

          var cep0 = this.ceps.length ? this.ceps[0] : null;
          if (cep0) {
            this.ufEndereco = {
              id: cep0.idUF,
              nome: cep0.nomeUF,
              sigla: cep0.uf
            };
            await this.carregarMunicipios(cep0.idUF);
            this.municipioEndereco = {
              id: cep0.idMunicipio,
              nome: cep0.municipio
            };
            if (this.ceps.length == 1 && cep0.logradouro) {
              this.cepSelecionado = {
                id: cep0.id,
                entrega: cep0.entrega,
                cep: cep0.cep,
                bairro: cep0.bairro,
                tipo: cep0.tipo,
                idTipo: cep0.idTipo,
                logradouro: cep0.logradouro,
                entregador: cep0.entregador,
                rota: cep0.rota,
                entregadorVA: cep0.entregadorVA,
                rotaVA: cep0.rotaVA
              };
            }
          } else {
            this.mensagem = "CEP não encontrado na base local";
            this.snackErro = true;
            if (this.$store.state.usuario.site_pais == "BR") {
              //* Busca na base externa para incluir
              this.buscarCepExterno();
            }
          }
        } catch {
          this.isUpdating = true;
          this.mensagem = "Ocorreu um erro ao carregar a lista de logradouros do " + (this.$store.state.usuario.site_pais == "BR" ? "município" : "concelho");
          this.snackErro = true;
        }
      }
    },

    async buscarCepExterno() {
      try {
        var nroCep = util.somenteNumeros(this.cep);
        //* Busca o cep no webservice gratuito, tem que ser axios, senão dá erro crossorigin
        const response = await axios.get("https://viacep.com.br/ws/" + nroCep + "/json/");

        var cep = response.data;
        if (cep.erro) {
          this.validacao.cep = "CEP não localizado";
        } else {
          try {
            //* Busca o município pelo código IBGE
            const responseMun = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/municipio/ibge/" + cep.ibge);
            var municipio = responseMun.data[0];
            if (municipio != null && municipio != undefined) {
              this.ufEndereco = {
                id: municipio.idUF,
                nome: municipio.nomeUF,
                sigla: municipio.uf
              };
              this.ufs = [];
              this.ufs.push(this.ufEndereco);
              this.municipioEndereco = {
                id: municipio.id,
                nome: municipio.nome
              };
              this.municipios = [];
              this.municipios.push(this.municipioEndereco);

              var tipoLogr = cep.logradouro.split(" ")[0];
              var logradouro = "";
              var itemTipo = this.tiposLogradouros.find((tipo) => tipo.nome == tipoLogr);
              if (!itemTipo) {
                if (tipoLogr.startsWith("S") || tipoLogr.startsWith("Q")) {
                  itemTipo = this.tiposLogradouros.find((tipo) => tipo.nome == "Setor");
                } else {
                  itemTipo = this.tiposLogradouros.find((tipo) => tipo.nome == "Rua");
                }
                logradouro = cep.logradouro;
              } else {
                logradouro = cep.logradouro.substring(tipoLogr.length + 1);
              }

              if (cep.logradouro) {
                var cepItem = {
                  id: null,
                  cobranca: this.enderecoItem.cobranca,
                  entrega: this.enderecoItem.entrega,
                  entregaVA: this.enderecoItem.entregaVA,
                  cep: nroCep,
                  bairro: cep.bairro,
                  idTipo: itemTipo.id,
                  tipo: itemTipo.nome,
                  logradouro: logradouro,
                  entregador: "",
                  rota: "",
                  entregadorVA: "",
                  rotaVA: ""
                };
                cepItem.nome = cepItem.cep + ": " + cepItem.bairro + " - " + cepItem.tipo + " " + cepItem.logradouro;

                this.ceps.push(cepItem);
                this.cepSelecionado = cepItem;
              } else {
                this.mensagem += " - ERRO: CEP genérico";
                this.snackErro = true;
              }
            }
          } catch {
            this.isUpdating = true;
            this.mensagem = "Ocorreu um erro ao carregar a lista de " + (this.$store.state.usuario.site_pais == "BR" ? "municípios da UF" : "concelhos do distrito");
            this.snackErro = true;
          }
        }
      } catch {
        this.mensagem = "Ocorreu um erro ao buscar os dados do cep";
        this.snackErro = true;
      }
    },

    async isClienteExiste() {
      this.validacao.identificacao = false;
      this.erroIdentificacao = "";

      if (this.cliente.identificacao) {
        try {
          //* Verifica se o cliente existe
          const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente/identificacao/" + this.cliente.identificacao);
          if (response.data && response.data.id) {
            this.validacao.identificacao =
              "Identificação já consta na base" +
              (response.data.cliente
                ? " como Cliente/Assinante/Anunciante"
                : response.data.fornecedor
                ? " como Fornecedor"
                : response.data.agente
                ? " como Agente/Agência"
                : response.data.banca
                ? " como Banca"
                : "");
          }
        } catch {
          this.isUpdating = true;
          this.mensagem = "Ocorreu um erro ao verificar a existência da identificação na base";
          this.snackErro = true;
        }
      }
    },
    async isEmailExiste() {
      this.validacao.email = false;
      this.erroEmail = "";

      try {
        if (this.id || !this.cliente.email) {
          return;
        }
        //* Verifica se o email do cliente existe
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente/email/" + this.cliente.email);
        if (response.data.length > 0) {
          this.validacao.email = "E-mail já consta na base";
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao verificar a existência do e-mail na base";
        this.snackErro = true;
      }
    },

    async carregarEnderecos() {
      try {
        //* Carrega todos os endereços do cliente, independente se está editando banca ou cliente
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-endereco/cliente/completo/" + this.cliente.id);
        this.enderecosCliente = response.data;
        this.enderecosCliente.forEach((element) => {
          element.municipio = element.municipio + "/" + element.uf;
        });
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de endereços do cliente";
        this.snackErro = true;
      }
    },

    async carregarTelefones() {
      try {
        //* Carrega os telefones do cliente
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-telefone/cliente/" + this.cliente.id);
        this.telefonesCliente = response.data;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de telefones do cliente";
        this.snackErro = true;
      }
    },

    async carregarEmails() {
      try {
        //* Carrega os emails do cliente
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-email/cliente/" + this.cliente.id);
        this.emailsCliente = response.data;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de emails do cliente";
        this.snackErro = true;
      }
    },

    async carregarCartoes() {
      try {
        //* Carrega os cartões do cliente
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-cartao/cliente/" + this.cliente.id);
        this.cartoesCliente = response.data;
        this.cartoesCliente.forEach((element) => {
          element.numero = util.escondeCartao(element.numero);
        });
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de cartões do cliente";
        this.snackErro = true;
      }
    },

    async carregarContas() {
      try {
        //* Carrega as contas do cliente
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-conta/cliente/" + this.cliente.id);
        if (this.$store.state.usuario.site_ocultarInativos) {
          this.contasCliente = [];
          response.data.forEach((element) => {
            if (element.situacao) {
              this.contasCliente.push(element);
            }
          });
        } else {
          this.contasCliente = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de contas do cliente";
        this.snackErro = true;
      }
    },

    async carregarCelescs() {
      try {
        //* Carrega as unidades consumidoras do cliente
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-celesc/cliente/" + this.cliente.id);
        this.celescsCliente = response.data;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de unidades consumidoras do cliente";
        this.snackErro = true;
      }
    },

    async carregarPixs() {
      try {
        //* Carrega as chaves pix do cliente
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-pix/cliente/" + this.cliente.id);
        this.pixsCliente = response.data;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de chaves PIX do cliente";
        this.snackErro = true;
      }
    },

    async carregarDependentes() {
      try {
        //* Carrega os dependentes do cliente
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-dependente/cliente/" + this.cliente.id);
        this.dependentesCliente = response.data;
        this.dependentesCliente.forEach((element) => {
          element.editar = false;
          element.foneNumeroV = `(${element.foneDDD}) ${element.foneNumero}`;
          element.verSenha = false;
          element.nomesProdutos = "";
          element.produtosClube = [];

          if (element.produtos && element.produtos.length) {
            var produtos = element.produtos.split(",");
            produtos.forEach((produto) => {
              element.nomesProdutos += (element.nomesProdutos ? "," : "") + produto.split("-")[1];
              element.produtosClube.push({ id: parseInt(produto.split("-")[0]), nome: produto.split("-")[1], tipo: "CLUBE" });
            });
          }
        });
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de dependentes do cliente";
        this.snackErro = true;
      }
    },
    isDependenteExiste() {
      this.validacao.identificacaoDependente = false;
    },

    async carregarDepartamentos() {
      try {
        //* Carrega os departamentos do cliente
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-departamento/cliente/" + this.cliente.id);
        this.departamentosCliente = response.data;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de departamentos do cliente";
        this.snackErro = true;
      }
    },

    async carregarObservacoes() {
      try {
        //* Carrega os observações do cliente
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-observacao/cliente/" + this.cliente.id);
        this.observacoesCliente = response.data;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de observações do cliente";
        this.snackErro = true;
      }
    },

    async carregarContatos() {
      try {
        //* Carrega os contatos do cliente
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-contato/cliente/" + this.cliente.id);
        this.contatosCliente = response.data;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de contatos do cliente";
        this.snackErro = true;
      }
    },

    async carregarPromocoes() {
      try {
        //* Carrega as promoções do cliente
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/promocao/cliente/" + this.cliente.id);
        this.promocoesCliente = response.data;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de promoções do cliente";
        this.snackErro = true;
      }
    },

    async carregarPremios() {
      try {
        //* Carrega os prêmios do cliente
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/promocao-assinante/cliente/" + this.cliente.id);
        this.premiosCliente = response.data;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de prêmios do cliente";
        this.snackErro = true;
      }
    },

    async carregarCortesias() {
      try {
        //* Carrega os códigos promocionais liberados para o cliente
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-cortesia/cliente/" + this.cliente.id);
        this.cortesiasCliente = response.data;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de códigos promocionais do cliente";
        this.snackErro = true;
      }
    },

    async carregarAssinaturas() {
      try {
        //* Carrega as assinaturas do cliente
        this.assinaturasCliente = [];

        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/assinatura/cliente/" + this.cliente.id);
        this.assinaturasCliente = response.data;
        this.loadingAss = false;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de assinaturas do cliente";
        this.snackErro = true;
      }
    },

    async carregarAnuncios() {
      try {
        //* Carrega os anúncios do cliente
        this.anunciosCliente = [];

        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "com/anuncio/cliente/" + this.cliente.id);
        response.data.forEach((element) => {
          element.selecionado = 0;
          this.anunciosCliente.push(element);
        });
        this.loadingAnu = false;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de anúncios do cliente";
        this.snackErro = true;
      }
    },
    ativarDesativarItem(item) {
      var dadoIndex = this.anunciosCliente.indexOf(item);
      var dadoItem = Object.assign({}, item);
      dadoItem.selecionado = dadoItem.selecionado == 1 ? 0 : 1;
      Object.assign(this.anunciosCliente[dadoIndex], dadoItem);
    },
    async emitirRecibo() {
      try {
        var ids = "";
        this.anunciosCliente.forEach((element) => {
          if (element.selecionado == 1) {
            ids += (ids ? "," : "") + element.id;
          }
        });
        if (!ids) {
          this.mensagem = "Selecione pelo menos 1 anuncio";
          this.snackErro = true;
          return;
        }
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/parcela/recibos/" + this.idSitePrincipal + "?anuncios=" + ids);

        util.imprimir(response.data);
      } catch (e) {
        this.mensagem = "ERRO: " + e.response.data;
        this.snackErro = true;
      }
    },

    async editarPromocao(item) {
      try {
        //* Armazena as propriedades da promoção
        this.promocaoItem.idAssinatura = item.idAssinatura;
        this.promocaoItem.idPromocao = item.idPromocao;
        this.promocaoItem.promocao = item.promocao;
        this.promocaoItem.idRenovacao = item.idRenovacao;

        //* Carrega os cupons da promoção
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "ass/promocao/cupons/" +
            this.cliente.id +
            "/" +
            this.promocaoItem.idAssinatura +
            "/" +
            this.promocaoItem.idRenovacao +
            "/" +
            this.promocaoItem.idPromocao
        );
        this.promocaoItem.cupons = response.data;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de cupons";
        this.snackErro = true;
      }

      this.dialogPromocao = true;
    },

    async salvarBanca() {
      this.validacao.banca = false;
      this.validacao.comissao = false;
      this.validacao.reparteMinimo = false;

      if (!this.banca.reparteMinimo) {
        this.banca.reparteMinimo = 0;
      }
      if (!this.banca.reparteMaximo) {
        this.banca.reparteMaximo = 0;
      }

      //* Envia os dados da banca
      try {
        var retorno = "";
        if (this.banca.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "adm/cliente-banca/" + this.banca.id, this.banca);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "adm/cliente-banca?idSite=" + this.idSitePrincipal, this.banca);
          this.banca.id = retorno.data.id;
        }
        this.msgBancaAgente = "Dados da banca foram atualizados";
        this.snackBancaAgente = true;
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      }
    },
    async carregarBancaCliente() {
      try {
        //* Carrega os dados da banca
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-banca/cliente/" + this.id);
        if (response.data) {
          this.banca = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os dados da banca";
        this.snackErro = true;
      }
    },

    async salvarAgente() {
      //* Envia os dados do agente
      try {
        var retorno = "";
        this.agente.nome = this.cliente.nome;
        this.agente.identificacao = this.cliente.identificacao;
        if (this.agente.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "adm/cliente-agente/" + this.agente.id, this.agente);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "adm/cliente-agente?idSite=" + this.idSitePrincipal, this.agente);
          this.agente.id = retorno.data.id;
        }
        this.msgBancaAgente = "Dados do agente/agência foram atualizados";
        this.snackBancaAgente = true;
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      }
    },
    async carregarAgenteCliente() {
      try {
        //* Carrega os dados do agente
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-agente/cliente/" + this.id);
        if (response.data) {
          this.agente = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os dados do agente/agência";
        this.snackErro = true;
      }
    },

    async incluirAssinatura() {
      if (this.direitoAssinaturas && this.id) {
        window.open("/assinaturas/assinatura?idCliente=" + this.id, "_blank");
      }
    },
    async visualizarAssinatura(item) {
      if (this.direitoAssinaturas && item.idSite == this.idSitePrincipal) {
        window.open("/assinaturas/assinatura/" + item.id, "_blank");
      }
    },

    async incluirAnuncio() {
      if (this.direitoCriarAnuncio) {
        window.open("/comercial/anuncio?idCliente=" + this.id, "_blank");
      }
    },
    async visualizarAnuncio(item) {
      if (this.direitoEditarAnuncio && item.idSite == this.idSitePrincipal) {
        window.open("/comercial/anuncio/" + item.id, "_blank");
      }
    },

    // * Métodos para controle dos itens
    editarComplemento(item) {
      this.enderecoIndex = this.enderecosCliente.indexOf(item);
      this.enderecoItem = Object.assign({}, item);
      this.enderecoItem.edicao = true;
      this.cepSelecionado = {
        id: this.enderecoItem.idCEP,
        entrega: this.enderecoItem.entrega,
        cep: this.enderecoItem.cep,
        bairro: this.enderecoItem.bairro,
        tipo: this.enderecoItem.tipo,
        idTipo: this.enderecoItem.idTipo,
        logradouro: this.enderecoItem.logradouro,
        entregador: this.enderecoItem.entregador,
        rota: this.enderecoItem.rota,
        entregadorVA: this.enderecoItem.entregadorVA,
        rotaVA: this.enderecoItem.rotaVA
      };
      this.dialogEndereco = true;
    },
    editarCEP(item) {
      window.open("/assinaturas/cep/" + item.idCEP, "_blank");
    },
    editarRoteiro(item) {
      window.open("/assinaturas/roteiro-entrega?idCEP=" + item.idCEP, "_blank");
    },

    async ativarDesativarEndereco(item, excluir) {
      var enderecoItem = Object.assign({}, item);
      var enderecoIndex = this.enderecosCliente.indexOf(item);
      const situacaoOriginal = enderecoItem.situacao;
      if (enderecoIndex == -1) {
        enderecoIndex = this.enderecoIndex;
      }
      enderecoItem.situacao = enderecoItem.situacao == 1 ? 0 : 1;
      if (excluir) {
        enderecoItem.situacao = 0;
      }
      if (!enderecoItem.situacao) {
        enderecoItem.dtExclusao = new Date();
      }

      //* Altera na lista para validar
      Object.assign(this.enderecosCliente[enderecoIndex], enderecoItem);
      var cobranca = false;
      var entrega = false;
      this.enderecosCliente.forEach((element) => {
        if (element.situacao) {
          if (element.cobranca) {
            cobranca = true;
          }
          if (element.entrega) {
            entrega = true;
          }
        }
      });
      item.situacao = item.situacao == 1 ? 0 : 1;

      if (this.moduloComercial || !this.moduloAssinaturas) {
        //* Se jornal possui o módulo Comercial, valida somente endereço de cobrança
        if (!cobranca) {
          this.msgEnderecos = "Pelo menos um endereço de cobrança deve estar ativo";
          this.snackEnderecos = true;
          enderecoItem.situacao = situacaoOriginal;
          Object.assign(this.enderecosCliente[enderecoIndex], enderecoItem);
          this.naoConfirmado();
          return;
        }
      } else {
        //* Se jornal não possui o módulo Comercial, valida endereços de cobrança e entrega
        if (!cobranca || !entrega) {
          this.msgEnderecos = "Pelo menos um endereço de cobrança e entrega deve estar ativo";
          this.snackEnderecos = true;
          enderecoItem.situacao = situacaoOriginal;
          Object.assign(this.enderecosCliente[enderecoIndex], enderecoItem);
          this.naoConfirmado();
          return;
        }
      }

      if (enderecoItem.id == null) {
        this.enderecosCliente.splice(enderecoIndex, 1);
      } else {
        var situacao = {
          idCliente: this.id,
          situacao: enderecoItem.situacao,
          cobranca: enderecoItem.cobranca,
          entrega: enderecoItem.entrega,
          entregaVA: enderecoItem.entregaVA
        };
        if (excluir) {
          situacao.excluir = excluir;
        }
        try {
          if (!enderecoItem.situacao || !enderecoItem.entrega) {
            this.dialogRecalculandoEdicoes = true;
          }

          await api.put(process.env.VUE_APP_URL_WEB_API + "adm/cliente-endereco/situacao/" + enderecoItem.id, situacao);

          if (excluir) {
            this.enderecosCliente.splice(enderecoIndex, 1);
            this.msgEnderecos = "Item Excluído";
            this.snackEnderecos = true;
          } else {
            Object.assign(this.enderecosCliente[enderecoIndex], enderecoItem);
            this.msgEnderecos = "Item " + (enderecoItem.situacao == 1 ? "Ativado" : "Desativado");
            this.snackEnderecos = true;
          }
        } catch (e) {
          this.msgEnderecos =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.snackEnderecos = true;
          this.carregarEnderecos();
        } finally {
          this.dialogRecalculandoEdicoes = false;
        }
      }
      this.naoConfirmado();
    },
    clickEndereco(item, tipo) {
      if (tipo == "cobranca") {
        this.enderecoItem.cobranca = item.cobranca == 1 ? 0 : 1;
      } else if (tipo == "entrega") {
        this.enderecoItem.entrega = item.entrega == 1 ? 0 : 1;
      } else if (tipo == "entregaVA") {
        this.enderecoItem.entregaVA = item.entregaVA == 1 ? 0 : 1;
      }
      this.filtrarCEPs();
    },

    async marcarDesmarcarEndereco(item, tipo) {
      var enderecoItem = Object.assign({}, item);
      var objeto = {};
      if (tipo == "cobranca") {
        enderecoItem.cobranca = enderecoItem.cobranca == 1 ? 0 : 1;
        objeto = { cobranca: enderecoItem.cobranca };
      }
      if (tipo == "entrega") {
        enderecoItem.entrega = enderecoItem.entrega == 1 ? 0 : 1;
        objeto = { entrega: enderecoItem.entrega };
      }
      if (tipo == "entregaVA") {
        enderecoItem.entregaVA = enderecoItem.entregaVA == 1 ? 0 : 1;
        objeto = { entregaVA: enderecoItem.entregaVA };
      }
      if (!enderecoItem.cobranca && !enderecoItem.entrega && !enderecoItem.entregaVA) {
        this.msgEnderecos = "Pelo menos um dos tipos deve estar marcado: Cobrança/Entrega";
        this.snackEnderecos = true;
        return;
      }

      //* Altera na lista para validar
      var enderecoIndex = this.enderecosCliente.indexOf(item);
      Object.assign(this.enderecosCliente[enderecoIndex], enderecoItem);
      var cobranca = false;
      var entrega = false;
      this.enderecosCliente.forEach((element) => {
        if (element.situacao) {
          if (element.cobranca) {
            cobranca = true;
          }
          if (element.entrega) {
            entrega = true;
          }
        }
      });
      if (tipo == "entrega") {
        item.entrega = enderecoItem.entrega == 1 ? 0 : 1;
      }
      if (tipo == "cobranca") {
        item.cobranca = enderecoItem.cobranca == 1 ? 0 : 1;
      }
      if (tipo == "entregaVA") {
        item.entregaVA = enderecoItem.entregaVA == 1 ? 0 : 1;
      }

      if (!cobranca || !entrega) {
        this.msgEnderecos = "Pelo menos um dos endereços deve estar marcado como: ";
        if (!cobranca) {
          this.msgEnderecos += " cobrança ";
        }
        if (!entrega) {
          this.msgEnderecos += (this.msgEnderecos.indexOf("cobrança") > -1 ? " / " : "") + " entrega ";
        }

        if (tipo == "cobranca") {
          enderecoItem.cobranca = enderecoItem.cobranca == 1 ? 0 : 1;
        } else if (tipo == "entrega") {
          enderecoItem.entrega = enderecoItem.entrega == 1 ? 0 : 1;
        } else if (tipo == "entregaVA") {
          enderecoItem.entregaVA = enderecoItem.entregaVA == 1 ? 0 : 1;
        }
        this.snackEnderecos = true;
        Object.assign(this.enderecosCliente[enderecoIndex], enderecoItem);
        return;
      }

      if (enderecoItem.id == null) {
        Object.assign(this.enderecosCliente[enderecoIndex], enderecoItem);
      } else {
        objeto.idCliente = this.id;
        try {
          if (enderecoItem.situacao && !enderecoItem.entrega) {
            this.dialogRecalculandoEdicoes = true;
          }
          await api.put(process.env.VUE_APP_URL_WEB_API + "adm/cliente-endereco/situacao/" + enderecoItem.id, objeto);

          Object.assign(this.enderecosCliente[enderecoIndex], enderecoItem);
          this.msgEnderecos = "Item Atualizado";
          this.snackEnderecos = true;
        } catch (e) {
          this.msgEnderecos =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.snackEnderecos = true;
        } finally {
          this.dialogRecalculandoEdicoes = false;
        }
      }
      this.enderecoItem = Object.assign({}, this.defaultEnderecoItem);
      this.enderecoIndex = -1;
      this.ufEndereco = {};
      this.municipioEndereco = {};
      this.cepSelecionado = { id: null, cep: null };
      this.cep = null;
    },

    excluirEndereco(item) {
      this.enderecoIndex = this.enderecosCliente.indexOf(item);
      this.enderecoItem = Object.assign({}, item);
      this.msgEnderecos = "";
      this.tipoConfirm = "ENDEREÇO";
      this.dialogConfirm = true;
    },
    naoConfirmado() {
      if (this.tipoConfirm == "ENDEREÇO") {
        this.enderecoItem = Object.assign({}, this.defaultEnderecoItem);
        this.enderecoIndex = -1;
        this.ufEndereco = {};
        this.municipioEndereco = {};
        this.cepSelecionado = { id: null, cep: null };
        this.cep = null;
      } else if (this.tipoConfirm == "TELEFONE") {
        this.telefoneItem = Object.assign({}, this.defaultTelefoneItem);
        this.telefoneIndex = -1;
      } else if (this.tipoConfirm == "EMAIL") {
        this.emailItem = Object.assign({}, this.defaultEmailItem);
        this.emailIndex = -1;
      } else if (this.tipoConfirm == "PIX") {
        this.pixItem = Object.assign({}, this.defaultPixItem);
        this.pixIndex = -1;
      }
      this.tipoConfirm = "";
      this.dialogConfirm = false;
    },
    async confirmado() {
      if (this.tipoConfirm == "ENDEREÇO") {
        await this.ativarDesativarEndereco(this.enderecoItem, true);
      } else if (this.tipoConfirm == "TELEFONE") {
        await this.excluiTelefone(this.telefoneItem);
      } else if (this.tipoConfirm == "EMAIL") {
        await this.excluiEmail(this.emailItem);
      } else if (this.tipoConfirm == "PIX") {
        await this.excluiPix(this.pixItem);
      }
    },

    closeEndereco() {
      this.dialogEndereco = false;
      setTimeout(() => {
        this.enderecoItem = Object.assign({}, this.defaultEnderecoItem);
        this.enderecoIndex = -1;
        this.ufEndereco = {};
        this.municipioEndereco = {};
        this.cepSelecionado = { id: null, cep: null };
        this.ceps = [];
        this.cep = null;
        this.$refs.formEndereco.resetValidation();
        this.enderecoItem.edicao = false;
      }, 300);
    },
    incluirCEP() {
      this.idCEP = "";
      this.dialogCEP = true;
    },
    async saveEndereco() {
      this.validacao.enderecos = false;
      this.validacao.cep = false;
      this.validacao.endereco = false;

      if (this.$refs.formEndereco.validate()) {
        if (this.enderecoItem.cobranca == 0 && this.enderecoItem.entrega == 0 && this.enderecoItem.entregaVA == 0) {
          this.validacao.endereco = "Selecione o tipo de endereço: Cobrança e/ou Entrega";
          return;
        }
        if (this.enderecoItem.entrega && !this.cepSelecionado.entrega) {
          this.validacao.endereco = "Logradouro selecionado não é de entrega";
          return;
        }
        this.$refs.formEndereco.resetValidation();
        try {
          if (this.enderecoItem.edicao && this.enderecoItem.id) {
            const complementoCommit = {
              complemento: this.enderecoItem.complemento,
              idModoEntrega: this.enderecoItem.idModoEntrega,
              complementoTipo: this.enderecoItem.complementoTipo,
              complementoNumero: this.enderecoItem.complementoNumero,
              complementoBloco: this.enderecoItem.complementoBloco,
              complementoEdificio: this.enderecoItem.complementoEdificio
            };

            await api.put(process.env.VUE_APP_URL_WEB_API + "adm/cliente-endereco/complemento/" + this.enderecoItem.id, complementoCommit);
            this.msgEnderecos = "Complemento alterado com sucesso";
            this.snackEnderecos = true;
          } else {
            if (!this.enderecoItem.edicao) {
              this.enderecoItem.idCEP = this.cepSelecionado.id;
              this.enderecoItem.idMunicipio = this.municipioEndereco.id;
              this.enderecoItem.uf = this.ufEndereco.sigla;
              this.enderecoItem.municipio = this.municipioEndereco.nome + "/" + this.ufEndereco.sigla;
              this.enderecoItem.cep = this.cepSelecionado.cep;
              this.enderecoItem.bairro = this.cepSelecionado.bairro;
              this.enderecoItem.tipo = this.cepSelecionado.tipo;
              this.enderecoItem.idTipo = this.cepSelecionado.idTipo;
              this.enderecoItem.logradouro = this.cepSelecionado.logradouro;
              this.enderecoItem.entregador = this.cepSelecionado.entregador;
              this.enderecoItem.rota = this.cepSelecionado.rota;
              this.enderecoItem.entregadorVA = this.cepSelecionado.entregadorVA;
              this.enderecoItem.rotaVA = this.cepSelecionado.rotaVA;
              this.enderecoItem.dtRegistro = new Date();
              this.ufEndereco = {};
              this.municipioEndereco = {};
              this.cepSelecionado = { id: null, cep: null };
              this.cep = null;
            }
          }

          if (this.enderecoIndex > -1) {
            Object.assign(this.enderecosCliente[this.enderecoIndex], this.enderecoItem);
          } else {
            this.enderecosCliente.push(this.enderecoItem);
          }
          this.closeEndereco();
          this.enderecoItem = Object.assign({}, this.defaultEnderecoItem);
          this.enderecoIndex = -1;
        } catch (e) {
          this.msgEnderecos =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.snackEnderecos = true;
        }
      }
    },

    clickTelefone(item, tipo) {
      if (tipo == "comercial") {
        this.telefoneItem.comercial = item.comercial == 1 ? 0 : 1;
      } else if (tipo == "residencial") {
        this.telefoneItem.residencial = item.residencial == 1 ? 0 : 1;
      } else if (tipo == "celular") {
        this.telefoneItem.celular = item.celular == 1 ? 0 : 1;
      } else if (tipo == "whatsapp") {
        this.telefoneItem.whatsapp = item.whatsapp == 1 ? 0 : 1;
      } else {
        this.telefoneItem.naoIncomodar = item.naoIncomodar == 1 ? 0 : 1;
      }
    },
    async ativarDesativarTelefone(item) {
      var telefoneItem = Object.assign({}, item);
      var telefoneIndex = this.telefonesCliente.indexOf(item);
      if (telefoneItem.id == null) {
        this.telefonesCliente.splice(telefoneIndex, 1);
      } else {
        telefoneItem.situacao = telefoneItem.situacao == 1 ? 0 : 1;
        if (!telefoneItem.situacao) {
          telefoneItem.dtExclusao = new Date();
        }
        var situacao = { situacao: telefoneItem.situacao };
        try {
          await api.put(process.env.VUE_APP_URL_WEB_API + "adm/cliente-telefone/situacao/" + telefoneItem.id, situacao);

          Object.assign(this.telefonesCliente[telefoneIndex], telefoneItem);
          this.msgTelefones = "Item " + (telefoneItem.situacao == 1 ? "Ativado" : "Desativado");
          this.snackTelefones = true;
        } catch (e) {
          this.msgTelefones =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.snackTelefones = true;
        }
      }
      this.telefoneItem = Object.assign({}, this.defaultTelefoneItem);
      this.telefoneIndex = -1;
    },
    async ativarDesativarResidencial(item) {
      var telefoneItem = Object.assign({}, item);
      var telefoneIndex = this.telefonesCliente.indexOf(item);
      if (telefoneItem.id == null) {
        telefoneItem.residencial = telefoneItem.residencial == 1 ? 0 : 1;
        Object.assign(this.telefonesCliente[telefoneIndex], telefoneItem);
      } else {
        telefoneItem.residencial = telefoneItem.residencial == 1 ? 0 : 1;
        var residencial = { residencial: telefoneItem.residencial };
        try {
          await api.put(process.env.VUE_APP_URL_WEB_API + "adm/cliente-telefone/residencial/" + telefoneItem.id, residencial);

          Object.assign(this.telefonesCliente[telefoneIndex], telefoneItem);
          this.msgTelefones = "Telefone Residencial " + (telefoneItem.residencial == 1 ? "marcado" : "desmarcado");
          this.snackTelefones = true;
        } catch (e) {
          this.msgTelefones =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.snackTelefones = true;
        }
      }
      this.telefoneItem = Object.assign({}, this.defaultTelefoneItem);
      this.telefoneIndex = -1;
    },
    async ativarDesativarComercial(item) {
      var telefoneItem = Object.assign({}, item);
      var telefoneIndex = this.telefonesCliente.indexOf(item);
      if (telefoneItem.id == null) {
        telefoneItem.comercial = telefoneItem.comercial == 1 ? 0 : 1;
        Object.assign(this.telefonesCliente[telefoneIndex], telefoneItem);
      } else {
        telefoneItem.comercial = telefoneItem.comercial == 1 ? 0 : 1;
        var comercial = { comercial: telefoneItem.comercial };
        try {
          await api.put(process.env.VUE_APP_URL_WEB_API + "adm/cliente-telefone/comercial/" + telefoneItem.id, comercial);

          Object.assign(this.telefonesCliente[telefoneIndex], telefoneItem);
          this.msgTelefones = "Telefone Comercial " + (telefoneItem.comercial == 1 ? "marcado" : "desmarcado");
          this.snackTelefones = true;
        } catch (e) {
          this.msgTelefones =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.snackTelefones = true;
        }
      }
      this.telefoneItem = Object.assign({}, this.defaultTelefoneItem);
      this.telefoneIndex = -1;
    },
    async ativarDesativarNaoIncomodar(item) {
      var telefoneItem = Object.assign({}, item);
      var telefoneIndex = this.telefonesCliente.indexOf(item);
      if (telefoneItem.id == null) {
        telefoneItem.naoIncomodar = telefoneItem.naoIncomodar == 1 ? 0 : 1;
        Object.assign(this.telefonesCliente[telefoneIndex], telefoneItem);
      } else {
        telefoneItem.naoIncomodar = telefoneItem.naoIncomodar == 1 ? 0 : 1;
        var naoIncomodar = { naoIncomodar: telefoneItem.naoIncomodar };
        try {
          await api.put(process.env.VUE_APP_URL_WEB_API + "adm/cliente-telefone/naoIncomodar/" + telefoneItem.id, naoIncomodar);

          Object.assign(this.telefonesCliente[telefoneIndex], telefoneItem);
          this.msgTelefones = "Ligação para este telefone " + (telefoneItem.naoIncomodar == 1 ? "bloqueada" : "permitida");
          this.snackTelefones = true;
        } catch (e) {
          this.msgTelefones =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.snackTelefones = true;
        }
      }
      this.telefoneItem = Object.assign({}, this.defaultTelefoneItem);
      this.telefoneIndex = -1;
    },
    async ativarDesativarWhatsApp(item) {
      if (item.celular) {
        var telefoneItem = Object.assign({}, item);
        var telefoneIndex = this.telefonesCliente.indexOf(item);
        if (telefoneItem.id == null) {
          telefoneItem.whatsapp = telefoneItem.whatsapp == 1 ? 0 : 1;
          Object.assign(this.telefonesCliente[telefoneIndex], telefoneItem);
        } else {
          telefoneItem.whatsapp = telefoneItem.whatsapp == 1 ? 0 : 1;
          var whatsapp = { whatsapp: telefoneItem.whatsapp };
          try {
            await api.put(process.env.VUE_APP_URL_WEB_API + "adm/cliente-telefone/whatsapp/" + telefoneItem.id, whatsapp);

            Object.assign(this.telefonesCliente[telefoneIndex], telefoneItem);
            this.msgTelefones = "Telefone WhatsApp " + (telefoneItem.whatsapp == 1 ? "marcado" : "desmarcado");
            this.snackTelefones = true;
          } catch (e) {
            this.msgTelefones =
              "ERRO: " +
              (e.toString().includes("status code 400")
                ? "SESSÃO EXPIRADA"
                : e.response && e.response.data && e.response.data.length > 0
                ? e.response.data[0].mensagem
                : "Indeterminado, verifique sua conexão");
            this.snackTelefones = true;
          }
        }
        this.telefoneItem = Object.assign({}, this.defaultTelefoneItem);
        this.telefoneIndex = -1;
      } else {
        this.msgTelefones = "Telefone não é celular";
        this.snackTelefones = true;
      }
    },
    async saveContatoFone(item) {
      var telefoneItem = Object.assign({}, item);
      if (telefoneItem.id != null) {
        try {
          await api.put(process.env.VUE_APP_URL_WEB_API + "adm/cliente-telefone/contato/" + telefoneItem.id, { contato: telefoneItem.contato });

          //            Object.assign(this.telefonesCliente[telefoneIndex], telefoneItem);
          this.msgTelefones = "Contato atualizado";
          this.snackTelefones = true;
        } catch (e) {
          this.msgTelefones =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.snackTelefones = true;
        }
      }
    },
    excluirTelefone(item) {
      this.telefoneIndex = this.telefonesCliente.indexOf(item);
      this.telefoneItem = Object.assign({}, item);
      this.msgTelefones = "";
      this.tipoConfirm = "TELEFONE";
      this.dialogConfirm = true;
    },
    async excluiTelefone() {
      try {
        await api.delete(process.env.VUE_APP_URL_WEB_API + "adm/cliente-telefone/" + this.telefoneItem.id);

        this.telefonesCliente.splice(this.telefoneIndex, 1);
        this.msgTelefones = "Item Excluído";
        this.snackTelefones = true;
      } catch (e) {
        this.msgTelefones =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackTelefones = true;
      } finally {
        this.telefoneItem = Object.assign({}, this.defaultTelefoneItem);
        this.telefoneIndex = -1;
        this.dialogConfirm = false;
      }
    },
    closeTelefone() {
      this.dialogTelefone = false;
      setTimeout(() => {
        this.telefoneItem = Object.assign({}, this.defaultTelefoneItem);
        this.telefoneIndex = -1;
        this.$refs.formTelefone.resetValidation();
      }, 300);
    },
    saveTelefone() {
      this.validacao.telefones = false;

      if (this.$refs.formTelefone.validate()) {
        this.telefoneItem.dtRegistro = new Date();
        if (this.telefoneIndex > -1) {
          Object.assign(this.telefonesCliente[this.telefoneIndex], this.telefoneItem);
        } else {
          this.telefonesCliente.push(this.telefoneItem);
        }
        this.closeTelefone();
        this.telefoneItem = Object.assign({}, this.defaultTelefoneItem);
        this.telefoneIndex = -1;
        this.$refs.formTelefone.resetValidation();
      }
    },

    clickEmail(item) {
      this.emailItem.fatura = item.fatura == 1 ? 0 : 1;
    },
    async ativarDesativarEmail(item) {
      var emailItem = Object.assign({}, item);
      var emailIndex = this.emailsCliente.indexOf(item);
      if (emailItem.id == null) {
        this.emailsCliente.splice(emailIndex, 1);
      } else {
        emailItem.situacao = emailItem.situacao == 1 ? 0 : 1;
        if (!emailItem.situacao) {
          emailItem.dtExclusao = new Date();
        }
        var situacao = { situacao: emailItem.situacao };
        try {
          await api.put(process.env.VUE_APP_URL_WEB_API + "adm/cliente-email/situacao/" + emailItem.id, situacao);

          Object.assign(this.emailsCliente[emailIndex], emailItem);
          this.msgEmails = "Item " + (emailItem.situacao == 1 ? "Ativado" : "Desativado");
          this.snackEmails = true;
        } catch (e) {
          this.msgEmails =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.snackEmails = true;
        }
      }
      this.emailItem = Object.assign({}, this.defaultEmailItem);
      this.emailIndex = -1;
    },
    async ativarDesativarFatura(item) {
      var emailItem = Object.assign({}, item);
      var emailIndex = this.emailsCliente.indexOf(item);
      if (emailItem.id == null) {
        emailItem.fatura = emailItem.fatura == 1 ? 0 : 1;
        Object.assign(this.emailsCliente[emailIndex], emailItem);
      } else {
        emailItem.fatura = emailItem.fatura == 1 ? 0 : 1;
        var fatura = { fatura: emailItem.fatura };
        try {
          await api.put(process.env.VUE_APP_URL_WEB_API + "adm/cliente-email/fatura/" + emailItem.id, fatura);
          Object.assign(this.emailsCliente[emailIndex], emailItem);
          this.msgEmails = "Envio de fatura neste e-mail " + (emailItem.fatura == 1 ? "ativado" : "desativado");
          this.snackEmails = true;
        } catch (e) {
          this.msgEmails =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.snackEmails = true;
        }
      }
      this.emailItem = Object.assign({}, this.defaultEmailItem);
      this.emailIndex = -1;
    },
    excluirEmail(item) {
      this.emailIndex = this.emailsCliente.indexOf(item);
      this.emailItem = Object.assign({}, item);
      this.msgEmails = "";
      this.tipoConfirm = "EMAIL";
      this.dialogConfirm = true;
    },
    async excluiEmail() {
      try {
        await api.delete(process.env.VUE_APP_URL_WEB_API + "adm/cliente-email/" + this.emailItem.id);

        this.emailsCliente.splice(this.emailIndex, 1);
        this.msgEmails = "Item Excluído";
        this.snackEmails = true;
      } catch (e) {
        this.msgEmails =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackEmails = true;
      } finally {
        this.emailItem = Object.assign({}, this.defaultEmailItem);
        this.emailIndex = -1;
        this.dialogConfirm = false;
      }
    },
    closeEmail() {
      this.dialogEmail = false;
      setTimeout(() => {
        this.emailItem = Object.assign({}, this.defaultEmailItem);
        this.emailIndex = -1;
        this.$refs.formEmail.resetValidation();
      }, 300);
    },
    saveEmail() {
      this.validacao.emails = false;
      this.validacao.emails_email = false;

      if (this.$refs.formEmail.validate()) {
        this.emailItem.dtRegistro = new Date();
        if (this.emailIndex > -1) {
          Object.assign(this.emailsCliente[this.emailIndex], this.emailItem);
        } else {
          this.emailsCliente.push(this.emailItem);
        }
        this.closeEmail();
        this.emailItem = Object.assign({}, this.defaultEmailItem);
        this.emailIndex = -1;
        this.$refs.formEmail.resetValidation();
      }
    },

    clickCartao(item) {
      this.dependenteItem.clubeCortesia = item.clubeCortesia == 1 ? 0 : 1;
    },
    async ativarDesativarCartao(item) {
      var cartaoIndex = this.cartoesCliente.indexOf(item);
      var cartaoItem = Object.assign({}, item);
      if (cartaoItem.id == null) {
        this.cartoesCliente.splice(cartaoIndex, 1);
      } else {
        cartaoItem.situacao = cartaoItem.situacao == 1 ? 0 : 1;
        if (!cartaoItem.situacao) {
          cartaoItem.dtExclusao = new Date();
        }
        var situacao = { idCliente: this.id, situacao: cartaoItem.situacao };
        try {
          await api.put(process.env.VUE_APP_URL_WEB_API + "adm/cliente-cartao/situacao/" + cartaoItem.id, situacao);

          Object.assign(this.cartoesCliente[cartaoIndex], cartaoItem);
          this.msgCartoes = "Item " + (cartaoItem.situacao == 1 ? "Ativado" : "Desativado");
          this.snackCartoes = true;
        } catch (e) {
          this.msgCartoes =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.snackCartoes = true;
        }
      }
      this.cartaoItem = Object.assign({}, this.defaultCartaoItem);
      this.cartaoIndex = -1;
    },

    async ativarDesativarConta(item) {
      var contaIndex = this.contasCliente.indexOf(item);
      var contaItem = Object.assign({}, item);
      if (contaItem.id == null) {
        this.contasCliente.splice(contaIndex, 1);
      } else {
        contaItem.situacao = contaItem.situacao == 1 ? 0 : 1;
        if (!contaItem.situacao) {
          contaItem.dtExclusao = new Date();
        }
        var situacao = { idCliente: this.id, situacao: contaItem.situacao };
        try {
          await api.put(process.env.VUE_APP_URL_WEB_API + "adm/cliente-conta/situacao/" + contaItem.id, situacao);
          Object.assign(this.contasCliente[contaIndex], contaItem);
          this.msgContas = "Item " + (contaItem.situacao == 1 ? "Ativado" : "Desativado");
          this.snackContas = true;
        } catch (e) {
          this.msgContas =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.snackContas = true;
        }
      }
      this.contaItem = Object.assign({}, this.defaultContaItem);
      this.contaIndex = -1;
    },
    async saveCodFinanceiro(item) {
      var contaItem = Object.assign({}, item);
      if (contaItem.id != null) {
        try {
          await api.put(process.env.VUE_APP_URL_WEB_API + "adm/cliente-conta/codFinanceiro/" + contaItem.id, { codFinanceiro: contaItem.codFinanceiro });

          this.msgContas = "Código Financeiro atualizado";
          this.snackContas = true;
        } catch (e) {
          this.msgContas =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.snackContas = true;
        }
      }
    },
    closeConta() {
      this.dialogConta = false;
      setTimeout(() => {
        this.bancoSelecionado = { bancoNumero: "" };
        this.contaItem = Object.assign({}, this.defaultContaItem);
        this.contaIndex = -1;
        this.$refs.formConta.resetValidation();
      }, 300);
    },
    saveConta() {
      this.validacao.contas = false;

      if (this.$refs.formConta.validate()) {
        this.contaItem.bancoNumero = this.bancoSelecionado.bancoNumero;
        this.contaItem.bancoNome = this.bancoSelecionado.bancoNome;
        this.contaItem.idBanco = this.bancoSelecionado.id;
        this.contaItem.dtRegistro = new Date();
        if (this.contaIndex > -1) {
          Object.assign(this.contasCliente[this.contaIndex], this.contaItem);
        } else {
          this.contasCliente.push(this.contaItem);
        }
        this.closeConta();
        this.contaItem = Object.assign({}, this.defaultContaItem);
        this.contaIndex = -1;
        this.$refs.formConta.resetValidation();
      }
    },

    async ativarDesativarCelesc(item) {
      var celescIndex = this.celescsCliente.indexOf(item);
      var celescItem = Object.assign({}, item);
      if (celescItem.id == null) {
        this.celescsCliente.splice(celescIndex, 1);
      } else {
        celescItem.situacao = celescItem.situacao == 1 ? 0 : 1;
        if (!celescItem.situacao) {
          celescItem.dtExclusao = new Date();
        }
        var situacao = { idCliente: this.id, situacao: celescItem.situacao };
        try {
          await api.put(process.env.VUE_APP_URL_WEB_API + "adm/cliente-celesc/situacao/" + celescItem.id, situacao);

          Object.assign(this.celescsCliente[celescIndex], celescItem);
          this.msgCelescs = "Item " + (celescItem.situacao == 1 ? "Ativado" : "Desativado");
          this.snackCelescs = true;
        } catch (e) {
          this.msgCelescs =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.snackCelescs = true;
        }
      }
      this.celescItem = Object.assign({}, this.defaultCelescItem);
      this.celescIndex = -1;
    },
    closeCelesc() {
      this.dialogCelesc = false;
      setTimeout(() => {
        this.celescItem = Object.assign({}, this.defaultCelescItem);
        this.celescIndex = -1;
        this.$refs.formCelesc.resetValidation();
      }, 300);
    },
    saveCelesc() {
      this.validacao.celescs = false;

      if (this.$refs.formCelesc.validate()) {
        this.celescItem.dtRegistro = new Date();
        if (this.celescIndex > -1) {
          Object.assign(this.celescsCliente[this.celescIndex], this.celescItem);
        } else {
          this.celescsCliente.push(this.celescItem);
        }
        this.closeCelesc();
        this.celescItem = Object.assign({}, this.defaultCelescItem);
        this.celescIndex = -1;
        this.$refs.formCelesc.resetValidation();
      }
    },

    async ativarDesativarPix(item) {
      var pixIndex = this.pixsCliente.indexOf(item);
      var pixItem = Object.assign({}, item);
      if (pixItem.id == null) {
        this.pixsCliente.splice(pixIndex, 1);
      } else {
        pixItem.situacao = pixItem.situacao == 1 ? 0 : 1;
        if (!pixItem.situacao) {
          pixItem.dtExclusao = new Date();
        }
        var situacao = { idCliente: this.id, situacao: pixItem.situacao };
        try {
          await api.put(process.env.VUE_APP_URL_WEB_API + "adm/cliente-pix/situacao/" + pixItem.id, situacao);

          Object.assign(this.pixsCliente[pixIndex], pixItem);
          this.msgPix = "Item " + (pixItem.situacao == 1 ? "Ativado" : "Desativado");
          this.snackPix = true;
        } catch (e) {
          this.msgPix =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.snackPix = true;
        }
      }
      this.pixItem = Object.assign({}, this.defaultPixItem);
      this.pixIndex = -1;
    },
    closePix() {
      this.dialogPix = false;
      setTimeout(() => {
        this.tipoChaveSelecionado = null;
        this.pixItem = Object.assign({}, this.defaultPixItem);
        this.pixIndex = -1;
        this.$refs.formPix.resetValidation();
      }, 300);
    },
    savePix() {
      this.validacao.pix = false;

      if (this.$refs.formPix.validate()) {
        this.pixItem.tipoChave = this.tipoChaveSelecionado;
        this.pixItem.dtRegistro = new Date();
        if (this.pixIndex > -1) {
          Object.assign(this.pixsCliente[this.pixIndex], this.pixItem);
        } else {
          this.pixsCliente.push(this.pixItem);
        }
        this.closePix();
        this.pixItem = Object.assign({}, this.defaultPixItem);
        this.pixIndex = -1;
        this.$refs.formPix.resetValidation();
      }
    },
    excluirPix(item) {
      this.pixIndex = this.pixsCliente.indexOf(item);
      this.pixItem = Object.assign({}, item);
      this.msgPix = "";
      this.tipoConfirm = "PIX";
      this.dialogConfirm = true;
    },
    async excluiPix() {
      try {
        await api.delete(process.env.VUE_APP_URL_WEB_API + "adm/cliente-pix/" + this.pixItem.id);

        this.pixsCliente.splice(this.pixIndex, 1);
        this.msgPix = "Item Excluído";
        this.snackPix = true;
      } catch (e) {
        this.msgPix =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackPix = true;
      } finally {
        this.pixItem = Object.assign({}, this.defaultPixItem);
        this.pixIndex = -1;
        this.dialogConfirm = false;
      }
    },

    async ativarDesativarDependente(item) {
      var dependenteItem = Object.assign({}, item);
      var dependenteIndex = this.dependentesCliente.indexOf(item);
      if (dependenteItem.id == null) {
        this.dependentesCliente.splice(dependenteIndex, 1);
      } else {
        dependenteItem.situacao = dependenteItem.situacao == 1 ? 0 : 1;
        if (!dependenteItem.situacao) {
          dependenteItem.dtExclusao = new Date();
        }
        var situacao = { situacao: dependenteItem.situacao };
        try {
          await api.put(process.env.VUE_APP_URL_WEB_API + "adm/cliente-dependente/situacao/" + dependenteItem.id, situacao);
          Object.assign(this.dependentesCliente[dependenteIndex], dependenteItem);
          this.msgDependentes = "Item " + (dependenteItem.situacao == 1 ? "Ativado" : "Desativado");
          this.snackDependentes = true;
        } catch (e) {
          this.msgDependentes =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.snackDependentes = true;
        }
      }
      this.dependenteItem = Object.assign({}, this.defaultDependenteItem);
      this.dependenteIndex = -1;
    },
    async ativarDesativarCortesiaClube(item) {
      var dependenteItem = Object.assign({}, item);
      var dependenteIndex = this.dependentesCliente.indexOf(item);
      if (dependenteItem.id == null) {
        dependenteItem.clubeCortesia = dependenteItem.clubeCortesia == 1 ? 0 : 1;
        Object.assign(this.dependentesCliente[dependenteIndex], dependenteItem);
      } else {
        dependenteItem.clubeCortesia = dependenteItem.clubeCortesia == 1 ? 0 : 1;
        var clubeCortesia = { clubeCortesia: dependenteItem.clubeCortesia };
        try {
          await api.put(process.env.VUE_APP_URL_WEB_API + "adm/cliente-dependente/clubeCortesia/" + dependenteItem.id, clubeCortesia);
          Object.assign(this.dependentesCliente[dependenteIndex], dependenteItem);
          this.msgDependentes = "Cortesia do Clube " + (dependenteItem.clubeCortesia == 1 ? "Ativada" : "Desativada");
          this.snackDependentes = true;
        } catch (e) {
          this.msgDependentes =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.snackDependentes = true;
        }
      }
      this.dependenteItem = Object.assign({}, this.defaultDependenteItem);
      this.dependenteIndex = -1;
    },
    closeDependente() {
      this.dialogDependente = false;
      setTimeout(() => {
        this.dependenteItem = Object.assign({}, this.defaultDependenteItem);
        this.dependenteIndex = -1;
        this.$refs.formDependente.resetValidation();
      }, 300);
    },
    editarDependente(item) {
      this.dependenteIndex = this.dependentesCliente.indexOf(item);
      this.dependenteItem = Object.assign({}, item);
      this.dependenteItem.editar = parseInt(item.identificacao) == item.id;
      this.dependenteItem.dtNasc = util.formatDate(this.dependenteItem.dtNascimento);
      this.dialogDependente = true;
    },
    async saveDependente() {
      this.validacao.dependentes = false;
      this.validacao.dependentes_email = false;

      if (this.$refs.formDependente.validate()) {
        this.dependenteItem.dtRegistro = new Date();
        this.dependenteItem.dtNascimento = util.formatDateUS(this.dependenteItem.dtNasc);
        this.dependenteItem.foneNumeroV = `(${this.dependenteItem.foneDDD}) ${this.dependenteItem.foneNumero}`;
        this.dependenteItem.produtos = "";
        this.dependenteItem.nomesProdutos = "";
        this.dependenteItem.produtosClube.forEach((produto) => {
          this.dependenteItem.produtos += (this.dependenteItem.produtos ? "," : "") + produto.id + "-" + produto.nome;
          this.dependenteItem.nomesProdutos += (this.dependenteItem.nomesProdutos ? "," : "") + produto.nome;
        });
        if (this.dependenteIndex > -1) {
          Object.assign(this.dependentesCliente[this.dependenteIndex], this.dependenteItem);
        } else {
          this.dependentesCliente.push(this.dependenteItem);
        }
        this.closeDependente();
        if (this.dependenteItem.id) {
          try {
            var dependenteCommit = Object.assign({}, this.dependenteItem);
            dependenteCommit.produtosClube.forEach((produto) => {
              produto.idDependente = this.dependenteItem.id;
              produto.idProduto = produto.id;
            });

            await api.put(process.env.VUE_APP_URL_WEB_API + "adm/cliente-dependente/" + this.dependenteItem.id, dependenteCommit);
            this.msgDependentes = "Dependente alterado com sucesso";
            this.snackDependentes = true;
          } catch (e) {
            this.msgDependentes =
              "ERRO: " +
              (e.toString().includes("status code 400")
                ? "SESSÃO EXPIRADA"
                : e.response && e.response.data && e.response.data.length > 0
                ? e.response.data[0].mensagem
                : "Indeterminado, verifique sua conexão");
            this.snackDependentes = true;
          }
        }
        this.dependenteItem = Object.assign({}, this.defaultDependenteItem);
        this.dependenteIndex = -1;
        this.$refs.formDependente.resetValidation();
      }
    },
    verSenha(item) {
      item.verSenha = true;
      this.msgDependentes = "Senha visível";
      this.snackDependentes = true;

      //precisei recriar a lista, pois não estava atualizando verSenha no front
      const depts = [];
      this.dependentesCliente.forEach((element) => {
        depts.push(element);
      });
      this.dependentesCliente = [];
      depts.forEach((element) => {
        this.dependentesCliente.push(element);
      });
    },

    async ativarDesativarDepartamento(item) {
      var departamentoItem = Object.assign({}, item);
      var departamentoIndex = this.departamentosCliente.indexOf(item);
      if (departamentoItem.id == null) {
        this.departamentosCliente.splice(departamentoIndex, 1);
      } else {
        departamentoItem.situacao = departamentoItem.situacao == 1 ? 0 : 1;
        if (!departamentoItem.situacao) {
          departamentoItem.dtExclusao = new Date();
        }
        var situacao = { situacao: departamentoItem.situacao };
        try {
          await api.put(process.env.VUE_APP_URL_WEB_API + "adm/cliente-departamento/situacao/" + departamentoItem.id, situacao);
          Object.assign(this.departamentosCliente[departamentoIndex], departamentoItem);
          this.msgDepartamentos = "Item " + (departamentoItem.situacao == 1 ? "Ativado" : "Desativado");
          this.snackDepartamentos = true;
        } catch (e) {
          this.msgDepartamentos =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.snackDepartamentos = true;
        }
      }
      this.departamentoItem = Object.assign({}, this.defaultDepartamentoItem);
      this.departamentoIndex = -1;
    },
    closeDepartamento() {
      this.dialogDepartamento = false;
      setTimeout(() => {
        this.departamentoItem = Object.assign({}, this.defaultDepartamentoItem);
        this.departamentoIndex = -1;
        this.$refs.formDepartamento.resetValidation();
      }, 300);
    },
    saveDepartamento() {
      this.validacao.departamentos = false;

      if (this.$refs.formDepartamento.validate()) {
        this.departamentoItem.dtRegistro = new Date();
        if (this.departamentoIndex > -1) {
          Object.assign(this.departamentosCliente[this.departamentoIndex], this.departamentoItem);
        } else {
          this.departamentosCliente.push(this.departamentoItem);
        }
        this.closeDepartamento();
        this.departamentoItem = Object.assign({}, this.defaultDepartamentoItem);
        this.departamentoIndex = -1;
        this.$refs.formDepartamento.resetValidation();
      }
    },

    async ativarDesativarObservacao(item) {
      var observacaoItem = Object.assign({}, item);
      var observacaoIndex = this.observacoesCliente.indexOf(item);
      if (observacaoItem.id == null) {
        this.observacoesCliente.splice(observacaoIndex, 1);
      } else {
        observacaoItem.situacao = observacaoItem.situacao == 1 ? 0 : 1;
        if (!observacaoItem.situacao) {
          observacaoItem.dtExclusao = new Date();
        }
        var situacao = { situacao: observacaoItem.situacao };
        try {
          await api.put(process.env.VUE_APP_URL_WEB_API + "adm/cliente-observacao/situacao/" + observacaoItem.id, situacao);
          Object.assign(this.observacoesCliente[observacaoIndex], observacaoItem);
          this.msgObservacoes = "Item " + (observacaoItem.situacao == 1 ? "Ativado" : "Desativado");
          this.snackObservacoes = true;
        } catch (e) {
          this.msgObservacoes =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.snackObservacoes = true;
        }
      }
      this.observacaoItem = Object.assign({}, this.defaultObservacaoItem);
      this.observacaoIndex = -1;
    },
    closeObservacao() {
      this.dialogObservacao = false;
      setTimeout(() => {
        this.observacaoItem = Object.assign({}, this.defaultObservacaoItem);
        this.observacaoIndex = -1;
        this.$refs.formObservacao.resetValidation();
      }, 300);
    },
    saveObservacao() {
      this.validacao.observacoes = false;

      if (this.$refs.formObservacao.validate()) {
        this.observacaoItem.usuario = this.$store.state.usuario.nome;
        this.observacaoItem.idUsuario = this.$store.state.usuario.id;
        this.observacaoItem.dtRegistro = new Date();
        if (this.observacaoIndex > -1) {
          Object.assign(this.observacoesCliente[this.observacaoIndex], this.observacaoItem);
        } else {
          this.observacoesCliente.push(this.observacaoItem);
        }
        this.closeObservacao();
        this.observacaoItem = Object.assign({}, this.defaultObservacaoItem);
        this.observacaoIndex = -1;
        this.$refs.formObservacao.resetValidation();
      }
    },

    visualizarContato(item) {
      this.contatoIndex = this.contatosCliente.indexOf(item);
      this.contatoItem = Object.assign({}, item);
      this.assuntoSelecionado = {
        id: item.idAssunto,
        nome: item.assunto
      };
      this.motivoSelecionado = {
        id: item.idMotivo,
        nome: item.motivo
      };
      this.procedimentoSelecionado = {
        id: item.idProcedimento,
        nome: item.procedimento
      };
      this.dialogContato = true;
    },
    closeContato() {
      this.dialogContato = false;
      setTimeout(() => {
        this.contatoItem = Object.assign({}, this.defaultContatoItem);
        this.contatoIndex = -1;
        this.assuntoSelecionado = {};
        this.motivoSelecionado = {};
        this.procedimentoSelecionado = {};
        this.$refs.formContato.resetValidation();
      }, 300);
    },
    saveContato() {
      if (this.$refs.formContato.validate()) {
        this.contatoItem.dtRegistro = new Date();
        this.contatoItem.usuario = this.$store.state.usuario.nome;
        this.contatoItem.idUsuario = this.$store.state.usuario.id;
        if (this.contatoIndex > -1) {
          Object.assign(this.contatosCliente[this.contatoIndex], this.contatoItem);
          //Como não estava atualizando o contato ao editar, inclui um push e splice para atualizar o array
          this.contatosCliente.push({});
          this.contatosCliente.splice(this.contatosCliente.length - 1, 1);
        } else {
          this.contatosCliente.push(this.contatoItem);
        }
        this.closeContato();
        this.$refs.formContato.resetValidation();
        this.validacao.contatos = false;
      }
    },
    excluirContato(item) {
      this.contatoIndex = this.contatosCliente.indexOf(item);
      this.contatoItem = Object.assign({}, item);
      if (this.contatoItem.id == null) {
        this.contatosCliente.splice(this.contatoIndex, 1);
        this.contatoItem = Object.assign({}, this.defaultContatoItem);
        this.contatoIndex = -1;
        this.assuntoSelecionado = {};
        this.motivoSelecionado = {};
        this.procedimentoSelecionado = {};
        return;
      }
    },

    closePromocao() {
      this.mensagemPromocaoCupons = "";
      this.dialogPromocao = false;
      setTimeout(() => {
        this.promocaoItem = Object.assign({}, this.defaultPromocaoItem);
        this.$refs.formPromocao.resetValidation();
      }, 300);
    },

    async downloadPromocao() {
      await api.get(
        process.env.VUE_APP_URL_WEB_API +
          "ass/renovacao-promocao-cupom/cuponsCliente/" +
          this.cliente.id +
          "/" +
          this.promocaoItem.idAssinatura +
          "/" +
          this.promocaoItem.idRenovacao +
          "/" +
          this.promocaoItem.idPromocao
      );

      var caminhoArquivo = `arquivos/${this.idSitePrincipal}/cupons/${this.cliente.id}_${this.promocaoItem.idAssinatura}_${this.promocaoItem.idRenovacao}_${this.promocaoItem.idPromocao}.pdf`;

      download(process.env.VUE_APP_URL_WEB_API + caminhoArquivo);
    },

    enviarEmailPromocao() {
      //* Altera mensagem na tela
      this.mensagemPromocaoCupons = "Cupons direcionados para envio por e-mail"; //para " + destinatario + "

      api.get(
        process.env.VUE_APP_URL_WEB_API +
          "ass/renovacao-promocao-cupom/cuponsCliente/" +
          this.cliente.id +
          "/" +
          this.promocaoItem.idAssinatura +
          "/" +
          this.promocaoItem.idRenovacao +
          "/" +
          this.promocaoItem.idPromocao +
          "?email=1"
      );
    },

    async ativarDesativarCortesia(item) {
      var cortesiaIndex = this.cortesiasCliente.indexOf(item);
      var cortesiaItem = Object.assign({}, item);
      cortesiaItem.situacao = cortesiaItem.situacao == 1 ? 0 : 1;
      if (!cortesiaItem.situacao) {
        cortesiaItem.dtExclusao = new Date();
      }
      var situacao = { idCliente: this.id, situacao: cortesiaItem.situacao };
      try {
        await api.put(process.env.VUE_APP_URL_WEB_API + "adm/cliente-cortesia/situacao/" + cortesiaItem.id, situacao);

        Object.assign(this.cortesiasCliente[cortesiaIndex], cortesiaItem);
        this.msgCortesias = "Item " + (cortesiaItem.situacao == 1 ? "Ativado" : "Desativado");
        this.snackCortesias = true;
      } catch (e) {
        this.msgCortesias =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackCortesias = true;
      }
    },
    async saveCortesia() {
      this.validacao.promocoes = false;

      if (this.$refs.formCortesia.validate()) {
        if (!this.codigosPromocionais || (!this.$store.state.usuario.site_codPromoUnico && parseInt(this.codigosPromocionais) == 0)) {
          this.msgCortesias = "Código(s) promocional(is) inválido(s)";
          this.snackCortesias = true;
          return;
        }
      } else {
        return;
      }
      try {
        await api.post(process.env.VUE_APP_URL_WEB_API + "adm/cliente-cortesia/" + this.codigosPromocionais + "?idSite=" + this.idSitePrincipal, {
          idCliente: this.id,
          email: this.cliente.email
        });
        this.msgCortesias = this.codigosPromocionais + " códigos promocionais gerados";
        this.snackCortesias = true;
        this.closeCortesia();
        this.carregarCortesias();
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else {
          this.msgCortesias = "ERRO: " + (e.response && e.response.data && e.response.data.length > 0 ? e.response.data[0].mensagem : "Indeterminado, verifique sua conexão");
          this.snackCortesias = true;
        }
      }
    },
    closeCortesia() {
      this.dialogCortesia = false;
    },

    closeRamo() {
      this.dialogRamo = false;
      setTimeout(() => {
        this.carregarRamos();
      }, 300);
    },
    closeCEP() {
      this.dialogCEP = false;
    },

    async relatorio() {
      var dtInicio = "";
      var dtFim = "";
      if (this.intervaloQuitacaoUS && this.intervaloQuitacaoUS.length > 1) {
        dtInicio = this.intervaloQuitacaoUS[0];
        dtFim = this.intervaloQuitacaoUS[1];
      }
      if (!dtInicio || !dtFim) {
        this.mensagem = "Verifique o período da quitação";
        this.snackErro = true;
        return;
      }

      this.loader = "loading";
      const l = this.loader;
      this[l] = !this[l];

      try {
        var parametros = "?idAgente=" + this.agente.id + "&agente=" + this.cliente.nome + "&dtInicio=" + dtInicio + "&dtFim=" + dtFim;

        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/parcela/comissao" + parametros);

        util.imprimir(response.data);
      } catch (e) {
        var erro = e.response.data;
        if (erro.length > 0) {
          erro = erro[0].mensagem;
        }
        this.mensagem = erro;
        this.snackErro = true;
      } finally {
        this[l] = false;
        this.loader = null;
      }
    },

    visualizarLog(item) {
      this.idClienteEndereco = item.id + "";
      this.dialogLogAlteracao = true;
    },
    closeLogAlteracao() {
      this.dialogLogAlteracao = false;
      this.idClienteEndereco = "";
    },

    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    filtrarItens(item, queryText, itemText) {
      return util.filtrarItens(item, queryText, itemText);
    },
    filtrarUFs(item, queryText, itemText) {
      return util.filtrarUFs(item, queryText, itemText);
    },

    //* Chamadas a métodos públicos
    pLetra(text) {
      return util.pLetra(text);
    },
    removeEspeciais(text) {
      return util.removeEspeciais(text.toUpperCase());
    },
    isDDDvalido(ddd) {
      return util.isDDDvalido(ddd);
    },
    formatDate(date) {
      return util.formatDate(date);
    },
    formatDateUS(date) {
      return util.formatDateUS(date);
    },
    formatDateTime(datetime) {
      return util.formatDateTime(datetime);
    },
    formatDateUSTime(datetime) {
      return util.formatDateUSTime(datetime);
    },
    formatCurrency(valor) {
      return util.formatCurrency(valor);
    },
    valida_identificacao(text, obriga) {
      if (text || obriga || this.$store.state.usuario.site_obrigaIdentDep) {
        if (this.$store.state.usuario.site_pais == "BR") {
          if (text.length != 11 && text.length != 14) {
            return "Identificação deve ter 11 ou 14 dígitos";
          } else {
            if (text.length == 11) {
              return util.valida_cpf(text) ? true : "CPF inválido";
            } else {
              return util.valida_cnpj(text) ? true : "CNPJ inválido";
            }
          }
        } else {
          if (text) {
            if (text.length != 9) {
              this.erroIdentificacao = "Identificação deve ter 9 dígitos";
            } else {
              if (!util.valida_nif(text)) {
                this.erroIdentificacao = "NIF inválido";
              }
            }
          }
          return true;
        }
      } else {
        return true;
      }
    },
    valida_email_identificacao(text) {
      if (!util.valida_email(text)) {
        //* Caso o cliente não possua e-mail, permite entrar com a identificação para não quebrar a chave única
        if (this.$store.state.usuario.site_pais == "BR") {
          if (text != util.somenteNumeros(text) || (!util.valida_cpf(text) && !util.valida_cnpj(text))) {
            return "E-mail inválido.";
          }
        } else {
          if (text && !util.valida_nif(text) && this.cliente.identificacao != text.toUpperCase()) {
            this.erroEmail = "E-mail inválido";
          }
        }
      }
      return true;
    },
    valida_email_email(text) {
      if (!util.valida_email(text)) {
        return "E-mail inválido.";
      } else {
        return true;
      }
    },
    valida_hora(hora) {
      if (hora && !util.valida_hora(hora)) {
        return "Hora inválida";
      } else {
        return true;
      }
    },
    valida_data_nasc(date) {
      return util.valida_data_nasc_BR(date);
    },
    valida_data_passada(date) {
      return util.valida_data_passada_BR(date, true, false);
    },
    trataChave(text) {
      text = util.removeEspeciaisMais(text);
      text = text.toLowerCase();
      return text;
    },
    valida_chave(text) {
      if (this.pixItem.tipoChave == "01") {
        if (!text) {
          return "Preencha o número do telefone: +55DDNNNNNNNNN";
        }
        if (text.substr(0, 3) != "+55") {
          return "Chave PIX para telefone deve iniciar com +55";
        }
        if (!util.isDDDvalido(text.substr(3, 2))) {
          return "DDD inválido";
        }
      } else if (this.pixItem.tipoChave == "02") {
        if (!text) {
          return "Preencha o e-mail";
        }
        if (!util.valida_email(text)) {
          return "E-mail inválido.";
        }
      } else if (this.pixItem.tipoChave == "03") {
        if (!text || (text.length != 11 && text.length != 14)) {
          return "Identificação deve ter 11 ou 14 dígitos";
        } else {
          if (text.length == 11) {
            return util.valida_cpf(text) ? true : "CPF inválido";
          } else {
            return util.valida_cnpj(text) ? true : "CNPJ inválido";
          }
        }
      } else if (this.pixItem.tipoChave == "04") {
        if (!text) {
          return "Chave aleatória deve ter 36 caracteres";
        }
        if (!text || text.length != 36) {
          return "Chave aleatória deve ter 36 caracteres";
        }
      } else if (this.pixItem.tipoChave == "05") {
        if (!text) {
          return "Preencha os dados bancários: BBBAAAAANNNNNNNNNNNNN";
        }
        if (text.length != 21) {
          return "Preencha os dados bancários no formato: BBBAAAAANNNNNNNNNNNNN (com zeros a esquerda da agência e número)";
        }
      }
      return true;
    },
    tipoIdent(identificacao) {
      if (this.$store.state.usuario.site_pais == "BR") {
        if (identificacao.length == 11) {
          return "CPF";
        } else {
          return "CNPJ";
        }
      } else {
        return "NIF";
      }
    },
    ajustaBanca() {
      var temp = this.banca.comissao;
      this.banca.comissao = "0,00";
      setTimeout(() => {
        this.banca.comissao = temp;
      }, 300);
    },
    ajustaAgente() {
      var temp1 = this.agente.descMaximoCom;
      var temp2 = this.agente.comissaoPadraoCom;
      this.agente.descMaximoCom = "0,00";
      this.agente.comissaoPadraoCom = "0,00";
      setTimeout(() => {
        this.agente.descMaximoCom = temp1;
        this.agente.comissaoPadraoCom = temp2;
      }, 300);
    }
  },

  async created() {
    if (this.$route.path.includes("/assinante")) {
      this.rotaAssinante = true;
    } else if (this.$route.path.includes("/anunciante")) {
      this.rotaAnunciante = true;
    } else if (this.$route.path.includes("/cliente")) {
      this.rotaCliente = true;
    } else if (this.$route.path.includes("/banca")) {
      this.rotaBanca = true;
    } else if (this.$route.path.includes("/agente")) {
      this.rotaAgente = true;
    } else if (this.$route.path.includes("/fornecedor")) {
      this.rotaFornecedor = true;
    }

    this.isUpdating = false;
    this.bloqueiaBotoes = true;
    this.id = this.$route.params.id ? this.$route.params.id : "";

    this.enderecoItem.entrega = this.moduloAssinaturas ? 1 : 0;
    this.defaultEnderecoItem.entrega = this.moduloAssinaturas ? 1 : 0;

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach((element) => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINATURAS_EDITAR") {
        this.direitoAssinaturas = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINANTES_RENOVAÇÃO_AUTOMÁTICA") {
        this.direitoRenovacaoAutomatica = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_ANÚNCIOS_CRIAR") {
        this.direitoCriarAnuncio = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_ANÚNCIOS_EDITAR") {
        this.direitoEditarAnuncio = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ADMINISTRATIVO_RAMOS_ATIVIDADE_CRIAR") {
        this.direitoRamos = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ADMINISTRATIVO_CEPS_CRIAR") {
        this.direitoCEPs = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ROTEIRO_ACESSO") {
        this.direitoRoteiro = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_RECIBO") {
        this.direitoRecibo = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_CLIENTES_ALTERAR_CRÉDITO") {
        this.direitoAlterarCredito = true;
      }

      if (this.rotaAssinante) {
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINANTES_VER_SENHA") {
          this.direitoVerSenha = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINANTES_CRIAR") {
          this.direitoCriar = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINANTES_EDITAR_AVANÇADO") {
          this.direitoAvancado = true;
        }
      } else if (this.Banca) {
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_BANCAS_CRIAR") {
          this.direitoCriar = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_BANCAS_EDITAR_AVANÇADO") {
          this.direitoAvancado = true;
        }
      } else if (this.rotaFornecedor) {
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_FORNECEDORES_CRIAR") {
          this.direitoCriar = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_FORNECEDORES_EDITAR_AVANÇADO") {
          this.direitoAvancado = true;
        }
      } else if (this.rotaAgente) {
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ADMINISTRATIVO_AGENTES_CRIAR") {
          this.direitoCriar = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ADMINISTRATIVO_AGENTES_EDITAR_AVANÇADO") {
          this.direitoAvancado = true;
        }
      } else if (this.rotaAnunciante) {
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_ANUNCIANTES_CRIAR") {
          this.direitoCriar = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_ANUNCIANTES_EDITAR_AVANÇADO") {
          this.direitoAvancado = true;
        }
      } else {
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_CLIENTES_CRIAR") {
          this.direitoCriar = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_CLIENTES_EDITAR_AVANÇADO") {
          this.direitoAvancado = true;
        }
      }
    });
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

    //* Carrega os dados dos selects
    await this.carregarRamos();
    await this.carregarUFs();
    await this.carregarModosEntrega();
    await this.carregarTiposLogradouros();
    await this.carregarMotivosContato();
    if (this.rotaFornecedor || this.rotaAgente) {
      await this.carregarBancosPagar();
    } else {
      await this.carregarBancosReceber();
    }
    await this.carregarProdutosClube();

    //* Verifica se o site do usuário está em GRUPO - OBS: ou ele compartilha a informação ou deixa exclusiva para o seu site
    var idGrupoSite = this.$store.state.usuario.site_idGrupo;
    if (idGrupoSite != null) {
      this.siteGrupo = [
        {
          id: this.idSitePrincipal,
          nome: this.$store.state.usuario.site_nome
        }
      ];
    }
    if (this.rotaBanca) {
      this.isBanca = true;
      await this.carregarDistribuidoras();
      await this.carregarTiposBanca();
      await this.carregarFormasPgto();
    }
    //* Se é edição, carrega o cliente
    if (this.id == "") {
      if (this.rotaAssinante) {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_ASSINANTES_CRIAR"]);
        this.cliente.cliente = 1;
      } else if (this.rotaBanca) {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_BANCAS_CRIAR"]);
        this.cliente.banca = 1;
      } else if (this.rotaFornecedor) {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_FORNECEDORES_CRIAR"]);
        this.cliente.fornecedor = 1;
      } else if (this.rotaAgente) {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ADMINISTRATIVO_AGENTES_CRIAR"]);
        this.cliente.agente = 1;
      } else if (this.rotaAnunciante) {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "COMERCIAL_ANUNCIANTES_CRIAR"]);
        this.cliente.cliente = 1;
      } else {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_CLIENTES_CRIAR"]);
        this.cliente.cliente = 1;
      }
      setTimeout(() => {
        this.$refs.focusIdentificacao.focus();
      }, 300);
    } else {
      var tela = "cliente";
      if (this.rotaAssinante) {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_ASSINANTES_EDITAR"]);
      } else if (this.rotaBanca) {
        tela = "banca";
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_BANCAS_EDITAR"]);
      } else if (this.rotaFornecedor) {
        tela = "fornecedor";
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_FORNECEDORES_EDITAR"]);
      } else if (this.rotaAgente) {
        tela = "agente";
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ADMINISTRATIVO_AGENTES_EDITAR"]);
      } else if (this.rotaAnunciante) {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "COMERCIAL_ANUNCIANTES_EDITAR"]);
      } else {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_CLIENTES_EDITAR"]);
      }
      try {
        //* Carrega o cliente - somente se for público (idSite=null) ou for do site do usuário logado
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente/" + this.id + "?idSite=" + this.idSitePrincipal + "&tela=" + tela);

        if (!response.data) {
          if (this.rotaAssinante) {
            this.$router.push("/assinaturas/assinantes");
          } else if (this.rotaBanca) {
            this.$router.push("/assinaturas/bancas");
          } else if (this.rotaFornecedor) {
            this.$router.push("/financeiro/fornecedores");
          } else if (this.rotaAgente) {
            this.$router.push("/administrativo/agentes");
          } else if (this.rotaAnunciante) {
            this.$router.push("/comercial/anunciantes");
          } else {
            this.$router.push("/financeiro/clientes");
          }
          return;
        }
        this.cliente = response.data;
        this.dtCriacao = this.cliente.dtCriacao;
        this.dtCadastro = util.formatDate(this.cliente.dtCadastro);
        if (this.cliente.dtNascimento != null) {
          this.dtNascimento = util.formatDate(this.cliente.dtNascimento);
        }
        //* Carrega os subitens de clientes
        await this.carregarEnderecos();
        await this.carregarTelefones();
        await this.carregarEmails();
        await this.carregarCartoes();
        this.cartaoItem = Object.assign({}, this.defaultCartaoItem);
        await this.carregarContas();
        this.contaItem = Object.assign({}, this.defaultContaItem);
        await this.carregarCelescs();
        this.celescItem = Object.assign({}, this.defaultCelescItem);
        await this.carregarPixs();
        this.pixItem = Object.assign({}, this.defaultPixItem);
        await this.carregarDependentes();
        await this.carregarDepartamentos();
        await this.carregarObservacoes();
        await this.carregarContatos();
        await this.carregarPromocoes();
        await this.carregarPremios();
        await this.carregarCortesias();
        this.carregarAssinaturas();
        this.carregarAnuncios();
        if (this.cliente.banca) {
          setTimeout(async () => {
            await this.carregarBancaCliente();
          }, 1000);
        }
        if (this.rotaAgente) {
          setTimeout(async () => {
            await this.carregarAgenteCliente();
          }, 1000);
        }
      } catch {
        this.mensagem = "Ocorreu um erro ao carregar os dados do cliente";
        this.snackErro = true;
      }
    }
    setTimeout(() => {
      this.bloqueiaBotoes = false;
    }, 1000);
  }
};
</script>
