<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">grid_off</v-icon>
      <v-toolbar-title style="color: white" class="font-weight-light">Motivo de Cancelamento</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12 md12 v-if="siteGrupo.length > 0">
            <v-autocomplete
              v-model="motivoCancelamento.idSite"
              :disabled="isUpdating"
              :items="siteGrupo"
              :filter="filtrarObjetos"
              label="Restrito ao Site"
              item-text="nome"
              item-value="id"
              :rules="[validacao.idSite == false || validacao.idSite]"
              no-data-text
              clearable
              @change="validacao.idSite = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md12>
            <v-text-field
              autofocus
              v-model="motivoCancelamento.nome"
              label="Nome do Motivo de Cancelamento"
              maxlength="50"
              hint="Obrigatório"
              :rules="[validacao.nome == false || validacao.nome, (value) => !!value || 'Obrigatório.', (value) => value.length > 2 || 'Preencha o nome do motivo de cancelamento']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="
                motivoCancelamento.nome = pLetra(motivoCancelamento.nome);
                validacao.nome = false;
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs12>
            <v-autocomplete
              v-model="motivoCancelamento.cancelarParcelas"
              :disabled="isUpdating"
              :items="itensParc"
              :filter="filtrarObjetos"
              label="Cancelar Parcelas"
              item-text="nome"
              item-value="id"
              :rules="[validacao.cancelarParcelas == false || validacao.cancelarParcelas]"
              no-data-text
              @change="validacao.cancelarParcelas = false"
            >
              <template v-slot:selection="data">
                <v-chip color="blue lighten-3">
                  {{ data.item.nome }}
                </v-chip>
                <span class="ml-2">{{ data.item.descricao }}</span>
              </template>

              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.nome"></v-list-item-title>
                  <v-list-item-subtitle v-html="data.item.descricao"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-flex>

          <!-- <v-flex xs12 md4>
            <v-switch v-model="motivoCancelamento.recuperacao" :label="`${motivoCancelamento.recuperacao == 1 ? 'Recuperação' : 'Não é recuperação'}`"></v-switch>
          </v-flex>
          <v-flex xs12 md4>
            <v-switch v-model="motivoCancelamento.inadimplencia" :label="`${motivoCancelamento.inadimplencia == 1 ? 'Inadimplência' : 'Não é inadimplência'}`"></v-switch>
          </v-flex>
          <v-flex xs12 md4>
            <v-switch v-model="motivoCancelamento.espontaneo" :label="`${motivoCancelamento.espontaneo == 1 ? 'Espontâneo' : 'Não é espontâneo'}`"></v-switch>
          </v-flex> -->
          <v-flex xs12 md4 v-if="$store.state.usuario.site_diasRecuperacao">
            <v-switch
              v-model="motivoCancelamento.imediato"
              :label="`${motivoCancelamento.imediato == 1 ? 'Cancelamento Imediato (sem etapa de RECUPERAÇÃO)' : 'Cancelamento com etapa de RECUPERAÇÃO'}`"
            ></v-switch>
          </v-flex>
          <v-flex xs12 md12>
            <v-switch v-model="motivoCancelamento.situacao" :label="`${motivoCancelamento.situacao == 1 ? 'Ativo' : 'Inativo'}`"></v-switch>
          </v-flex>
          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">
              {{ validacao.mensagemErro }}
            </v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn to="/assinaturas/motivos-cancelamento" color="orange lighten-2 white" style="color: white" id="btn-rsn">
        <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
      </v-btn>
      <v-btn :loading="isUpdating" color="blue white" style="color: white" id="btn-rsn" depressed @click="salvar('false')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar
      </v-btn>

      <v-btn :loading="isUpdating" color="orange white" style="color: white" id="btn-rsn" outlined @click="salvar('retornar')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn :loading="isUpdating" color="blue white" style="color: white" id="btn-rsn" outlined @click="salvar('true')" v-if="direitoCriar">
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Novo
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      motivoCancelamento: {
        id: "",
        idSite: null,
        nome: "",
        cancelarParcelas: 0,
        codAntigo: 0,
        recuperacao: 0,
        inadimplencia: 0,
        espontaneo: 0,
        imediato: 0,
        situacao: 1
      },
      validacao: {
        id: false,
        idSite: false,
        nome: false,
        cancelarParcelas: false,
        codAntigo: false,
        recuperacao: false,
        inadimplencia: false,
        espontaneo: false,
        imediato: false,
        situacao: false,
        mensagemErro: false
      },

      siteGrupo: [],

      itensParc: [
        { id: 0, nome: "NÃO", descricao: "Não mexe nas parcelas do contrato" },
        {
          id: 1,
          nome: "CANCELA",
          descricao: `Cancela ${this.$store.state.usuario.site_cancelaFuturas ? "" : "TODAS"} as parcelas ${
            this.$store.state.usuario.site_cancelaFuturas ? "FUTURAS" : ""
          } do contrato, EXCETO se estiverem em lote de faturamento"}`
        },
        { id: 2, nome: "RECALCULA", descricao: "Cancela parcelas com base no que foi ENTREGUE" }
      ],

      idSitePrincipal: null,
      direitoCriar: false,
      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      this.isUpdating = true;
      try {
        var retorno = "";
        //se tiver id é um update
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "ass/motivo-cancelamento/" + this.id, this.motivoCancelamento);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "ass/motivo-cancelamento?idSite=" + this.idSitePrincipal, this.motivoCancelamento);
          this.id = retorno.data.id;
          this.motivoCancelamento.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push("/assinaturas/motivo-cancelamento");
          this.$router.go("/assinaturas/motivo-cancelamento");
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/assinaturas/motivos-cancelamento");
          }, 2000);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    pLetra(text) {
      return util.pLetra(text);
    }
  },

  async created() {
    this.id = this.$route.params.id ? this.$route.params.id : "";

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach((element) => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_MOTIVOS_CANCELAMENTO_CRIAR") {
        this.direitoCriar = true;
      }
    });

    //* Verifica se o site do usuário está em GRUPO - OBS: ou ele compartilha a informação ou deixa exclusiva para o seu site
    var idGrupoSite = this.$store.state.usuario.site_idGrupo;
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
    if (idGrupoSite != null) {
      this.siteGrupo = [
        {
          id: this.idSitePrincipal,
          nome: this.$store.state.usuario.site_nome
        }
      ];
    }
    try {
      //* Se é edição, carrega o elemento
      if (this.id == "") {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_MOTIVOS_CANCELAMENTO_CRIAR"]);
      } else {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_MOTIVOS_CANCELAMENTO_EDITAR"]);
        //* Carrega o elemento - somente se for público (idSite=null) ou for do site do usuário logado
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/motivo-cancelamento/" + this.id + "?idSite=" + this.idSitePrincipal);
        if (!response.data) {
          this.$router.push("/assinaturas/motivos-cancelamento");
        } else {
          this.motivoCancelamento = response.data;
        }
      }
    } catch {
      this.mensagem = "Ocorreu um erro ao carregar os dados";
      this.snackErro = true;
    }
  }
};
</script>
