export default {
  itensDASH: [],
  itensAministrativo: [
    {
      icon: "keyboard_arrow_up",
      "icon-alt": "keyboard_arrow_down",
      text: "Estrutura Principal",
      model: false,
      children: [
        {
          icon: "share",
          text: "Redes de Sites",
          link: "/administrativo/redes",
          direito: "WEBSUITA_ADMIN_SUITA",
          acesso: true
        },
        {
          icon: "web",
          text: "Grupos de Sites",
          link: "/administrativo/grupos",
          direito: "WEBSUITA_ADMIN_SUITA",
          acesso: true
        },
        {
          icon: "location_city",
          text: "Países",
          link: "/administrativo/paises",
          direito: "WEBSUITA_ADMIN_SUITA",
          acesso: true
        },
        {
          icon: "location_city",
          text: "Estados",
          link: "/administrativo/ufs",
          direito: "WEBSUITA_ADMIN_SUITA",
          acesso: true
        },
        {
          icon: "location_city",
          text: "Municípios",
          link: "/administrativo/municipios",
          direito: "WEBSUITA_ADMIN_SUITA",
          acesso: true
        },
        {
          icon: "web_asset",
          text: "Sites",
          link: "/administrativo/sites",
          direito: "WEBSUITA_ADMIN_SUITA",
          acesso: true
        },
        {
          icon: "person",
          text: "Usuários",
          link: "/administrativo/usuarios",
          direito: "WEBSUITA_ADMIN_SUITA",
          acesso: true
        },
        {
          icon: "lock",
          text: "Perfis de Acesso",
          link: "/administrativo/perfis",
          direito: "ADMINISTRATIVO_PERFIS_ACESSO",
          acesso: true
        }
      ]
    },
    {
      icon: "keyboard_arrow_up",
      "icon-alt": "keyboard_arrow_down",
      text: "Acessórios",
      model: false,
      children: [
        {
          icon: "short_text",
          text: "Tipos Logradouro",
          link: "/administrativo/tipos-logradouro",
          direito: "ADMINISTRATIVO_TIPOS_LOGRADOURO_ACESSO",
          acesso: true
        },
        {
          icon: "location_on",
          text: "Logradouros (CEP)",
          link: "/administrativo/ceps",
          direito: "ADMINISTRATIVO_CEPS_ACESSO",
          acesso: true
        },
        {
          icon: "add_shopping_cart",
          text: "Agentes/Agências",
          link: "/administrativo/agentes",
          direito: "ADMINISTRATIVO_AGENTES_ACESSO",
          acesso: true
        },
        {
          icon: "mail_outline",
          text: "Config. E-mail",
          link: "/administrativo/configs-emails",
          direito: "ADMINISTRATIVO_CONFIG_EMAIL_ACESSO",
          acesso: true
        },
        {
          icon: "flight_takeoff",
          text: "Feriados",
          link: "/administrativo/feriados",
          direito: "ADMINISTRATIVO_FERIADOS_ACESSO",
          acesso: true
        },
        {
          icon: "credit_card",
          text: "Formas de Pagamento",
          link: "/administrativo/formas-pagamento",
          direito: "ADMINISTRATIVO_FORMAS_PAGAMENTO_ACESSO",
          acesso: true
        },
        {
          icon: "contacts",
          text: "Motivos de Contato",
          link: "/administrativo/motivos-contato",
          direito: "ADMINISTRATIVO_MOTIVOS_CONTATO_ACESSO",
          acesso: true
        },
        {
          icon: "work",
          text: "Ramos de Atividade",
          link: "/administrativo/ramos-atividade",
          direito: "ADMINISTRATIVO_RAMOS_ATIVIDADE_ACESSO",
          acesso: true
        }
      ]
    },
    {
      icon: "keyboard_arrow_up",
      "icon-alt": "keyboard_arrow_down",
      text: "Diversos",
      model: false,
      children: [
        {
          icon: "radio",
          text: "Programação Rádio",
          link: "/administrativo/radios",
          direito: "ADMINISTRATIVO_RÁDIO_TV_ACESSO",
          acesso: true
        },
        {
          icon: "tv",
          text: "Programação TV",
          link: "/administrativo/tvs",
          direito: "ADMINISTRATIVO_RÁDIO_TV_ACESSO",
          acesso: true
        }
      ]
    },
    {
      icon: "keyboard_arrow_up",
      "icon-alt": "keyboard_arrow_down",
      text: "Acervo",
      model: false,
      children: [
        {
          icon: "add_location",
          text: "Logradouros (CEP)",
          link: "/administrativo/acervo-ceps",
          direito: "ADMINISTRATIVO_ACERVO_CEPS_ACESSO",
          acesso: true
        },
        {
          icon: "person_add",
          text: "Clientes",
          link: "/administrativo/acervo-clientes",
          direito: "ADMINISTRATIVO_ACERVO_CLIENTES_ACESSO",
          acesso: true
        }
      ]
    },
    {
      icon: "keyboard_arrow_up",
      "icon-alt": "keyboard_arrow_down",
      text: "Editorial",
      model: false,
      children: [
        {
          icon: "folder_open",
          text: "Editorias",
          link: "/administrativo/editorias",
          direito: "ADMINISTRATIVO_EDITORIAS_ACESSO",
          acesso: true
        }
      ]
    }
  ],
  itensAministrativoPT: [
    {
      icon: "keyboard_arrow_up",
      "icon-alt": "keyboard_arrow_down",
      text: "Estrutura Principal",
      model: false,
      children: [
        {
          icon: "share",
          text: "Redes de Sites",
          link: "/administrativo/redes",
          direito: "WEBSUITA_ADMIN_SUITA",
          acesso: true
        },
        {
          icon: "web",
          text: "Grupos de Sites",
          link: "/administrativo/grupos",
          direito: "WEBSUITA_ADMIN_SUITA",
          acesso: true
        },
        {
          icon: "location_city",
          text: "Países",
          link: "/administrativo/paises",
          direito: "ADMINISTRATIVO_PAÍSES_ACESSO",
          acesso: true
        },
        {
          icon: "location_city",
          text: "Distritos",
          link: "/administrativo/ufs",
          direito: "ADMINISTRATIVO_UFS_ACESSO",
          acesso: true
        },
        {
          icon: "location_city",
          text: "Concelhos",
          link: "/administrativo/municipios",
          direito: "ADMINISTRATIVO_MUNICÍPIOS_ACESSO",
          acesso: true
        },
        {
          icon: "web_asset",
          text: "Sites",
          link: "/administrativo/sites",
          direito: "WEBSUITA_ADMIN_SUITA",
          acesso: true
        },
        {
          icon: "person",
          text: "Usuários",
          link: "/administrativo/usuarios",
          direito: "WEBSUITA_ADMIN_SUITA",
          acesso: true
        },
        {
          icon: "lock",
          text: "Perfis de Acesso",
          link: "/administrativo/perfis",
          direito: "ADMINISTRATIVO_PERFIS_ACESSO",
          acesso: true
        }
      ]
    },
    {
      icon: "keyboard_arrow_up",
      "icon-alt": "keyboard_arrow_down",
      text: "Acessórios",
      model: false,
      children: [
        {
          icon: "short_text",
          text: "Tipos Logradouro",
          link: "/administrativo/tipos-logradouro",
          direito: "ADMINISTRATIVO_TIPOS_LOGRADOURO_ACESSO",
          acesso: true
        },
        {
          icon: "location_on",
          text: "Logradouros (CEP)",
          link: "/administrativo/ceps",
          direito: "ADMINISTRATIVO_CEPS_ACESSO",
          acesso: true
        },
        {
          icon: "add_shopping_cart",
          text: "Agentes/Agências",
          link: "/administrativo/agentes",
          direito: "ADMINISTRATIVO_AGENTES_ACESSO",
          acesso: true
        },
        {
          icon: "mail_outline",
          text: "Configurações de E-mail",
          link: "/administrativo/configs-emails",
          direito: "ADMINISTRATIVO_CONFIG_EMAIL_ACESSO",
          acesso: true
        },
        {
          icon: "flight_takeoff",
          text: "Feriados",
          link: "/administrativo/feriados",
          direito: "ADMINISTRATIVO_FERIADOS_ACESSO",
          acesso: true
        },
        {
          icon: "credit_card",
          text: "Formas de Pagamento",
          link: "/administrativo/formas-pagamento",
          direito: "ADMINISTRATIVO_FORMAS_PAGAMENTO_ACESSO",
          acesso: true
        },
        {
          icon: "work",
          text: "Ramos de Atividade",
          link: "/administrativo/ramos-atividade",
          direito: "ADMINISTRATIVO_RAMOS_ATIVIDADE_ACESSO",
          acesso: true
        }
      ]
    },
    {
      icon: "keyboard_arrow_up",
      "icon-alt": "keyboard_arrow_down",
      text: "Diversos",
      model: false,
      children: [
        {
          icon: "radio",
          text: "Programação Rádio",
          link: "/administrativo/radios",
          direito: "ADMINISTRATIVO_RÁDIO_TV_ACESSO",
          acesso: true
        },
        {
          icon: "tv",
          text: "Programação TV",
          link: "/administrativo/tvs",
          direito: "ADMINISTRATIVO_RÁDIO_TV_ACESSO",
          acesso: true
        }
      ]
    },
    {
      icon: "keyboard_arrow_up",
      "icon-alt": "keyboard_arrow_down",
      text: "Acervo",
      model: false,
      children: [
        {
          icon: "add_location",
          text: "Logradouros (CEP)",
          link: "/administrativo/acervo-ceps",
          direito: "ADMINISTRATIVO_ACERVO_CEPS_ACESSO",
          acesso: true
        },
        {
          icon: "person_add",
          text: "Clientes",
          link: "/administrativo/acervo-clientes",
          direito: "ADMINISTRATIVO_ACERVO_CLIENTES_ACESSO",
          acesso: true
        }
      ]
    },
    {
      icon: "keyboard_arrow_up",
      "icon-alt": "keyboard_arrow_down",
      text: "Editorial",
      model: false,
      children: [
        {
          icon: "folder_open",
          text: "Editorias",
          link: "/administrativo/editorias",
          direito: "ADMINISTRATIVO_EDITORIAS_ACESSO",
          acesso: true
        }
      ]
    }
  ],
  itensAssinaturas: [
    {
      icon: "account_circle",
      text: "Assinantes",
      link: "/assinaturas/assinantes",
      direito: "ASSINATURAS_ASSINANTES_ACESSO",
      acesso: true
    },
    {
      icon: "assignment",
      text: "Assinaturas",
      link: "/assinaturas/assinaturas",
      direito: "ASSINATURAS_ASSINATURAS_ACESSO",
      acesso: true
    },
    {
      icon: "keyboard_arrow_up",
      "icon-alt": "keyboard_arrow_down",
      text: "Ações",
      model: false,
      children: [
        {
          icon: "card_giftcard",
          text: "Cartões Clube",
          link: "/assinaturas/clube-assinante",
          direito: "ASSINATURAS_CARTÃO_CLUBE_ACESSO",
          acesso: true
        },
        {
          icon: "monetization_on",
          text: "Cobrança",
          link: "/assinaturas/cobranca",
          direito: "ASSINATURAS_COBRANÇA_ACESSO",
          acesso: true
        },
        {
          icon: "contact_phone",
          text: "Contatos",
          link: "/assinaturas/contatos",
          direito: "ASSINATURAS_CONTATOS_ACESSO",
          acesso: true
        },
        {
          icon: "dns",
          text: "Cupons de Promoções",
          link: "/assinaturas/cupons-promocoes",
          direito: "ASSINATURAS_CUPONS_PROMOÇÕES_ACESSO",
          acesso: true
        },
        {
          icon: "import_contacts",
          text: "Reclamações",
          link: "/assinaturas/reclamacoes",
          direito: "ASSINATURAS_RECLAMAÇÕES_ACESSO",
          acesso: true
        },
        {
          icon: "supervisor_account",
          text: "Recuperação",
          link: "/assinaturas/recuperacao",
          direito: "ASSINATURAS_RECUPERAÇÃO_ACESSO",
          acesso: true
        }
      ]
    },
    {
      icon: "keyboard_arrow_up",
      "icon-alt": "keyboard_arrow_down",
      text: "Paywall",
      model: false,
      children: [
        {
          icon: "dashboard",
          text: "Planos Paywall",
          link: "/assinaturas/paywall/planos",
          direito: "ASSINATURAS_PAYWALL_PLANOS_ACESSO",
          acesso: true
        },
        {
          icon: "play_for_work",
          text: "Telas do 'Assine'",
          link: "/assinaturas/paywall/assines",
          direito: "ASSINATURAS_PAYWALL_ASSINE_ACESSO",
          acesso: true
        }
      ]
    },
    {
      icon: "keyboard_arrow_up",
      "icon-alt": "keyboard_arrow_down",
      text: "Roteiro de Entrega",
      model: false,
      children: [
        {
          icon: "motorcycle",
          text: "Entregadores",
          link: "/assinaturas/entregadores",
          direito: "ASSINATURAS_ENTREGADORES_ACESSO",
          acesso: true
        },
        {
          icon: "ac_unit",
          text: "Rotas",
          direito: "ASSINATURAS_ROTAS_ACESSO",
          link: "/assinaturas/rotas",
          acesso: true
        },
        {
          icon: "short_text",
          text: "Tipos Logradouro",
          link: "/assinaturas/tipos-logradouro",
          direito: "ADMINISTRATIVO_TIPOS_LOGRADOURO_ACESSO",
          acesso: true
        },
        {
          icon: "location_on",
          text: "Logradouros (CEP)",
          link: "/assinaturas/ceps",
          direito: "ADMINISTRATIVO_CEPS_ACESSO",
          acesso: true
        },
        // { //ocultado temporariamente, até o desenvolvimento
        //   icon: "edit_location",
        //   text: "Regras de Endereços",
        //   direito: "ASSINATURAS_REGRAS_ENDEREÇOS_ACESSO",
        //   link: "/assinaturas/regras-enderecos",
        //   acesso: true
        // },
        {
          icon: "format_list_numbered",
          text: "Ordem de Roteiro",
          link: "/assinaturas/roteiro-ordem",
          direito: "ASSINATURAS_ROTEIRO_ACESSO",
          acesso: true
        },
        {
          icon: "ac_unit",
          text: "Roteiro de Entrega",
          link: "/assinaturas/roteiro-entrega",
          direito: "ASSINATURAS_ROTEIRO_ACESSO",
          acesso: true
        },
        {
          icon: "inventory",
          text: "Histórico de Roteiros",
          link: "/assinaturas/roteiro-historico",
          direito: "ASSINATURAS_ROTEIRO_ACESSO",
          acesso: true
        }
      ]
    },
    {
      icon: "keyboard_arrow_up",
      "icon-alt": "keyboard_arrow_down",
      text: "Administração Planos",
      model: false,
      children: [
        {
          icon: "subtitles",
          text: "Modalidades",
          link: "/assinaturas/modalidades",
          direito: "ASSINATURAS_MODALIDADES_ACESSO",
          acesso: true
        },
        {
          icon: "view_week",
          text: "Produtos",
          direito: "ASSINATURAS_PRODUTOS_ACESSO",
          link: "/assinaturas/produtos",
          acesso: true
        },
        {
          icon: "toys",
          text: "Promoções",
          direito: "ASSINATURAS_PROMOÇÕES_ACESSO",
          link: "/assinaturas/promocoes",
          acesso: true
        },
        {
          icon: "adjust",
          text: "Regras Ajustes Preços",
          direito: "ASSINATURAS_REGRAS_AJUSTE_ACESSO",
          link: "/assinaturas/regras-ajuste",
          acesso: true
        },

        {
          icon: "dashboard",
          text: "Planos de Assinaturas",
          direito: "ASSINATURAS_PLANOS_ACESSO",
          link: "/assinaturas/planos",
          acesso: true
        }
      ]
    },
    {
      icon: "keyboard_arrow_up",
      "icon-alt": "keyboard_arrow_down",
      text: "Administração Diversos",
      model: false,
      children: [
        {
          icon: "toc",
          text: "Etapas de Cobrança",
          link: "/assinaturas/etapas-cobranca",
          direito: "ASSINATURAS_ETAPAS_COBRANÇA_ACESSO",
          acesso: true
        },
        {
          icon: "play_for_work",
          text: "Modos de Entrega",
          link: "/assinaturas/modos-entrega",
          direito: "ASSINATURAS_MODOS_ENTREGA_ACESSO",
          acesso: true
        },
        {
          icon: "grid_off",
          text: "Motivos de Cancelamento",
          link: "/assinaturas/motivos-cancelamento",
          direito: "ASSINATURAS_MOTIVOS_CANCELAMENTO_ACESSO",
          acesso: true
        },
        {
          icon: "eject",
          text: "Motivos de Reclamação",
          direito: "ASSINATURAS_RECLAMAÇÕES_PADRÃO_ACESSO",
          link: "/assinaturas/reclamacoes-padrao",
          acesso: true
        },
        {
          icon: "speaker_notes",
          text: "Motivos de Suspensão",
          link: "/assinaturas/motivos-suspensao",
          direito: "ASSINATURAS_MOTIVOS_SUSPENSÃO_ACESSO",
          acesso: true
        },
        {
          icon: "trending_up",
          text: "Origens de Venda",
          direito: "ASSINATURAS_ORIGENS_ACESSO",
          link: "/assinaturas/origens",
          acesso: true
        },
        {
          icon: "line_weight",
          text: "Setores",
          direito: "ASSINATURAS_SETORES_ACESSO",
          link: "/assinaturas/setores",
          acesso: true
        }
      ]
    },
    {
      icon: "keyboard_arrow_up",
      "icon-alt": "keyboard_arrow_down",
      text: "Venda Avulsa",
      model: false,
      children: [
        {
          icon: "storefront",
          text: "Tipos de Banca",
          link: "/assinaturas/tipos-banca",
          direito: "ASSINATURAS_TIPOS_BANCA_ACESSO",
          acesso: true
        },
        {
          icon: "store",
          text: "Bancas",
          link: "/assinaturas/bancas",
          direito: "ASSINATURAS_BANCAS_ACESSO",
          acesso: true
        },
        {
          icon: "chrome_reader_mode",
          text: "Tabela de Preços",
          direito: "ASSINATURAS_VENDA_AVULSA_TABELA_ACESSO",
          link: "/assinaturas/venda-avulsa-tabelas",
          acesso: true
        },
        {
          icon: "open_with",
          text: "Repartes",
          link: "/assinaturas/venda-avulsa-repartes",
          direito: "ASSINATURAS_VENDA_AVULSA_REPARTE_ACESSO",
          acesso: true
        },
        {
          icon: "ac_unit",
          text: "Roteiro de Entrega",
          link: "/assinaturas/venda-avulsa-roteiro-entrega",
          direito: "ASSINATURAS_VENDA_AVULSA_ROTEIRO_ACESSO",
          acesso: true
        },
        {
          icon: "list_alt",
          text: "Faturamento",
          link: "/assinaturas/venda-avulsa-faturamento",
          direito: "ASSINATURAS_VENDA_AVULSA_FATURAMENTO_ACESSO",
          acesso: true
        }
      ]
    },
    {
      icon: "keyboard_arrow_up",
      "icon-alt": "keyboard_arrow_down",
      text: "Configurações",
      model: false,
      children: [
        {
          icon: "settings_applications",
          text: "Fechamento Roteiro",
          link: "/assinaturas/config-fechamento-roteiro",
          direito: "ASSINATURAS_CONFIG_FECHAMENTO_ROTEIRO_ACESSO",
          acesso: true
        },
        {
          icon: "settings_input_antenna",
          text: "Configuração IVC",
          link: "/assinaturas/config-ivc",
          direito: "ASSINATURAS_CONFIG_IVC_ACESSO",
          acesso: true
        },
        {
          icon: "track_changes",
          text: "IVC - Municípios",
          link: "/assinaturas/municipios-ivc",
          direito: "ASSINATURAS_MUNICÍPIOS_IVC_ACESSO",
          acesso: true
        }
      ]
    },
    {
      icon: "keyboard_arrow_up",
      "icon-alt": "keyboard_arrow_down",
      text: "Regras Comissionamento",
      model: false,
      children: [
        {
          icon: "account_balance_wallet",
          text: "Agentes/Agências",
          link: "/assinaturas/regras-comissionamento",
          direito: "ASSINATURAS_REGRAS_COMISSIONAMENTO_AGENTES_ACESSO",
          acesso: true
        }
        // {
        //   icon: "switch_account",
        //   text: "Usuários",
        //   link: "/assinaturas/regras-comissionamento-usuarios",
        //   direito: "WEBSUITA_ADMIN_SUITA", //ASSINATURAS_REGRAS_COMISSIONAMENTO_USUÁRIOS_ACESSO",
        //   acesso: true
        // }
      ]
    },
    {
      icon: "keyboard_arrow_up",
      "icon-alt": "keyboard_arrow_down",
      text: "Relatórios",
      model: false,
      children: [
        {
          icon: "dns",
          text: "Carteira",
          link: "/assinaturas/relatorios/carteira",
          direito: "ASSINATURAS_RELATÓRIOS_CARTEIRA",
          acesso: true
        },
        {
          icon: "account_balance_wallet",
          text: "Comissionamento",
          link: "/assinaturas/relatorios/comissionamento",
          direito: "ASSINATURAS_RELATÓRIOS_COMISSIONAMENTO_AGENTES",
          acesso: true
        },
        {
          icon: "ac_unit",
          text: "Distribuição",
          link: "/assinaturas/relatorios/distribuicao",
          direito: "ASSINATURAS_RELATÓRIOS_DISTRIBUIÇÃO",
          acesso: true
        },
        {
          icon: "track_changes",
          text: "IVC",
          link: "/assinaturas/relatorios/ivc",
          direito: "ASSINATURAS_RELATÓRIOS_IVC",
          acesso: true
        }
      ]
    }
  ],
  itensComercial: [
    {
      icon: "account_circle",
      text: "Anunciantes",
      link: "/comercial/anunciantes",
      direito: "COMERCIAL_ANUNCIANTES_ACESSO",
      acesso: true
    },
    {
      icon: "library_books",
      text: "Pacotes",
      link: "/comercial/pacotes",
      direito: "COMERCIAL_PACOTES_ACESSO",
      acesso: true
    },
    {
      icon: "chrome_reader_mode",
      text: "Anúncios",
      link: "/comercial/anuncios",
      direito: "COMERCIAL_ANÚNCIOS_ACESSO",
      acesso: true
    },
    {
      icon: "keyboard_arrow_up",
      "icon-alt": "keyboard_arrow_down",
      text: "Ações",
      model: false,
      children: [
        {
          icon: "monetization_on",
          text: "Cobrança",
          link: "/comercial/cobranca",
          direito: "COMERCIAL_COBRANÇA_ACESSO",
          acesso: true
        },
        {
          icon: "contact_phone",
          text: "Contatos",
          link: "/comercial/contatos",
          direito: "COMERCIAL_CONTATOS_ACESSO",
          acesso: true
        }
      ]
    },
    {
      icon: "keyboard_arrow_up",
      "icon-alt": "keyboard_arrow_down",
      text: "Administração",
      model: false,
      children: [
        {
          icon: "local_offer",
          text: "Produtos",
          link: "/comercial/produtos",
          direito: "COMERCIAL_PRODUTOS_ACESSO",
          acesso: true
        },
        {
          icon: "import_contacts",
          text: "Cadernos",
          link: "/comercial/cadernos",
          direito: "COMERCIAL_CADERNOS_ACESSO",
          acesso: true
        },
        {
          icon: "expand",
          text: "Condições Especiais",
          direito: "COMERCIAL_CONDIÇÕES_ESPECIAIS_ACESSO",
          link: "/comercial/condicoes-especiais",
          acesso: true
        },
        {
          icon: "adjust",
          text: "Regras Ajustes Preços",
          direito: "COMERCIAL_REGRAS_AJUSTE_ACESSO",
          link: "/comercial/regras-ajuste",
          acesso: true
        },
        {
          icon: "colorize",
          text: "Formatos",
          link: "/comercial/formatos",
          direito: "COMERCIAL_FORMATOS_ACESSO",
          acesso: true
        },
        {
          icon: "filter_none",
          text: "Determinações",
          link: "/comercial/determinacoes",
          direito: "COMERCIAL_DETERMINAÇÕES_ACESSO",
          acesso: true
        },
        {
          icon: "picture_in_picture",
          text: "Colocações",
          link: "/comercial/colocacoes",
          direito: "COMERCIAL_COLOCAÇÕES_ACESSO",
          acesso: true
        },
        {
          icon: "format_list_numbered",
          text: "Seções",
          link: "/comercial/secoes",
          direito: "COMERCIAL_SEÇÕES_ACESSO",
          acesso: true
        },
        {
          icon: "money",
          text: "Tabelas Preço",
          link: "/comercial/anuncio-tabelas",
          direito: "COMERCIAL_TABELAS_PREÇO_ACESSO",
          acesso: true
        },
        {
          icon: "description",
          text: "Textos Padrão",
          link: "/comercial/textos",
          direito: "COMERCIAL_TEXTOS_ACESSO",
          acesso: true
        }
      ]
    },
    {
      icon: "keyboard_arrow_up",
      "icon-alt": "keyboard_arrow_down",
      text: "Administração Diversos",
      model: false,
      children: [
        {
          icon: "toc",
          text: "Etapas de Cobrança",
          link: "/comercial/etapas-cobranca",
          direito: "COMERCIAL_ETAPAS_COBRANÇA_ACESSO",
          acesso: true
        }
      ]
    }
    // {
    //   icon: "photo_size_select_large",
    //   text: "Diagramas",
    //   link: "/comercial/diagramas",
    //   direito: "COMERCIAL_DIAGRAMAS_ACESSO",
    //   acesso: true
    // }
  ],
  itensFinanceiro: [
    {
      icon: "people",
      text: "Clientes",
      link: "/financeiro/clientes",
      direito: "FINANCEIRO_CLIENTES_ACESSO",
      acesso: true
    },
    {
      icon: "keyboard_arrow_up",
      "icon-alt": "keyboard_arrow_down",
      text: "Contas a Receber",
      model: false,
      children: [
        {
          icon: "add_circle",
          text: "Operações",
          link: "/financeiro/operacoes/receber",
          direito: "FINANCEIRO_CONTAS_RECEBER_ACESSO",
          acesso: true
        },
        {
          icon: "add_circle_outline",
          text: "Parcelas",
          link: "/financeiro/parcelas/receber",
          direito: "FINANCEIRO_PARCELAS_RECEBER_ACESSO",
          acesso: true
        },
        {
          icon: "call_made",
          text: "Remessas",
          link: "/financeiro/remessas/receber",
          direito: "FINANCEIRO_REMESSAS_RECEBER_ACESSO",
          acesso: true
        },
        {
          icon: "call_received",
          link: "/financeiro/retornos/receber",
          text: "Retornos",
          direito: "FINANCEIRO_RETORNOS_RECEBER_ACESSO",
          acesso: true
        }
      ]
    },
    {
      icon: "keyboard_arrow_up",
      "icon-alt": "keyboard_arrow_down",
      text: "Contas a Pagar",
      model: false,
      children: [
        {
          icon: "remove_circle",
          text: "Operações",
          link: "/financeiro/operacoes/pagar",
          direito: "FINANCEIRO_CONTAS_PAGAR_ACESSO",
          acesso: true
        },
        {
          icon: "remove_circle_outline",
          text: "Parcelas",
          link: "/financeiro/parcelas/pagar",
          direito: "FINANCEIRO_PARCELAS_PAGAR_ACESSO",
          acesso: true
        },
        {
          icon: "flash_off",
          text: "Bloqueios Celesc",
          link: "/financeiro/celesc-bloqueios",
          direito: "FINANCEIRO_CELESC_BLOQUEIOS_ACESSO",
          acesso: true
        },
        {
          icon: "bolt",
          text: "Títulos Celesc",
          link: "/financeiro/celesc-titulos",
          direito: "FINANCEIRO_CELESC_TITULOS_ACESSO",
          acesso: true
        },
        {
          icon: "offline_bolt",
          text: "Recepções Celesc",
          link: "/financeiro/celesc-recepcoes",
          direito: "FINANCEIRO_CELESC_RECEPÇÕES_ACESSO",
          acesso: true
        },
        {
          icon: "trending_up",
          text: "Remessas Celesc",
          link: "/financeiro/celesc-remessas",
          direito: "FINANCEIRO_CELESC_REMESSAS_ACESSO",
          acesso: true
        },
        {
          icon: "trending_down",
          text: "Retornos Celesc",
          link: "/financeiro/celesc-retornos",
          direito: "FINANCEIRO_CELESC_RETORNOS_ACESSO",
          acesso: true
        },
        {
          icon: "call_made",
          text: "Remessas",
          link: "/financeiro/remessas/pagar",
          direito: "FINANCEIRO_REMESSAS_PAGAR_ACESSO",
          acesso: true
        },
        {
          icon: "call_received",
          link: "/financeiro/retornos/pagar",
          text: "Retornos",
          direito: "FINANCEIRO_RETORNOS_PAGAR_ACESSO",
          acesso: true
        }
      ]
    },
    {
      icon: "keyboard_arrow_up",
      "icon-alt": "keyboard_arrow_down",
      text: "Diversos",
      model: false,
      children: [
        {
          icon: "compare_arrows",
          text: "Transferências",
          link: "/financeiro/transferencias",
          direito: "FINANCEIRO_TRANSFERÊNCIAS_ACESSO",
          acesso: true
        },
        {
          icon: "monetization_on",
          text: "Cobrança",
          link: "/financeiro/cobranca",
          direito: "FINANCEIRO_COBRANÇA_ACESSO",
          acesso: true
        },
        {
          icon: "contact_phone",
          text: "Contatos",
          link: "/financeiro/contatos",
          direito: "FINANCEIRO_CONTATOS_ACESSO",
          acesso: true
        },
        {
          icon: "mail_outline",
          text: "E-mails",
          link: "/financeiro/emails",
          direito: "FINANCEIRO_EMAILS_ACESSO",
          acesso: true
        },
        {
          icon: "library_books",
          text: "Extratos",
          link: "/financeiro/extratos",
          direito: "FINANCEIRO_EXTRATOS_ACESSO",
          acesso: true
        },
        {
          icon: "menu_open",
          text: "Fluxo de Caixa",
          link: "/financeiro/fluxo-caixa",
          direito: "FINANCEIRO_FLUXO_CAIXA_ACESSO",
          acesso: true
        }
      ]
    },
    {
      icon: "keyboard_arrow_up",
      "icon-alt": "keyboard_arrow_down",
      text: "Administração",
      model: false,
      children: [
        {
          icon: "account_balance",
          text: "Bancos",
          link: "/financeiro/bancos",
          direito: "FINANCEIRO_BANCOS_ACESSO",
          acesso: true
        },
        {
          icon: "account_balance",
          text: "Cedentes",
          link: "/financeiro/cedentes",
          direito: "FINANCEIRO_CEDENTES_ACESSO",
          acesso: true
        },
        {
          icon: "account_tree",
          text: "Centros de Custo",
          link: "/financeiro/centros-custo",
          direito: "FINANCEIRO_CENTROS_CUSTO_ACESSO",
          acesso: true
        },
        {
          icon: "perm_identity",
          text: "Contabilistas",
          link: "/financeiro/contabilistas",
          direito: "WEBSUITA_ADMIN_SUITA",
          acesso: true
        },
        {
          icon: "toc",
          text: "Etapas de Cobrança",
          link: "/financeiro/etapas-cobranca",
          direito: "FINANCEIRO_ETAPAS_COBRANÇA_ACESSO",
          acesso: true
        },
        {
          icon: "portrait",
          text: "Fornecedores",
          link: "/financeiro/fornecedores",
          direito: "FINANCEIRO_FORNECEDORES_ACESSO",
          acesso: true
        },
        {
          icon: "remove_done",
          text: "Motivos Padrão",
          link: "/financeiro/motivos-parcela",
          direito: "FINANCEIRO_MOTIVOS_PARCELA_ACESSO",
          acesso: true
        }
      ]
    },
    {
      icon: "keyboard_arrow_up",
      "icon-alt": "keyboard_arrow_down",
      text: "Plano de Contas",
      model: false,
      children: [
        {
          icon: "bookmarks",
          text: "Ativo",
          link: "/financeiro/contas/1",
          direito: "FINANCEIRO_CONTAS_ACESSO",
          acesso: true
        },
        {
          icon: "bookmarks",
          text: "Passivo",
          link: "/financeiro/contas/2",
          direito: "FINANCEIRO_CONTAS_ACESSO",
          acesso: true
        },
        {
          icon: "bookmarks",
          text: "Receitas",
          link: "/financeiro/contas/3",
          direito: "FINANCEIRO_CONTAS_ACESSO",
          acesso: true
        },
        {
          icon: "bookmarks",
          text: "Despesas",
          link: "/financeiro/contas/4",
          direito: "FINANCEIRO_CONTAS_ACESSO",
          acesso: true
        }
      ]
    },
    {
      icon: "keyboard_arrow_up",
      "icon-alt": "keyboard_arrow_down",
      text: "Notas Fiscais",
      model: false,
      children: [
        {
          icon: "account_balance",
          text: "NFe",
          link: "/financeiro/nfe",
          direito: "FINANCEIRO_NFE_ACESSO",
          acesso: true
        },
        {
          icon: "account_balance",
          text: "NFSC",
          link: "/financeiro/nfsc",
          direito: "FINANCEIRO_NFSC_ACESSO",
          acesso: true
        },
        {
          icon: "account_balance",
          text: "NFSe",
          link: "/financeiro/nfse",
          direito: "FINANCEIRO_NFSE_ACESSO",
          acesso: true
        },
        {
          icon: "show_chart",
          text: "Fechamento NFSC",
          link: "/financeiro/fechamento/nfsc",
          direito: "FINANCEIRO_FECHAMENTO_NFSC_ACESSO",
          acesso: true
        },
        {
          icon: "multiline_chart",
          text: "Fechamento SPED",
          link: "/financeiro/fechamento/sped",
          direito: "FINANCEIRO_FECHAMENTO_SPED_ACESSO",
          acesso: true
        }
      ]
    },
    {
      icon: "keyboard_arrow_up",
      "icon-alt": "keyboard_arrow_down",
      text: "Relatórios",
      model: false,
      children: [
        {
          icon: "view_quilt",
          text: "Apresentação",
          link: "/financeiro/relatorios/apresentacao",
          direito: "FINANCEIRO_RELATÓRIOS_APRESENTAÇÃO",
          acesso: true
        },
        {
          icon: "sort",
          text: "Balancete",
          link: "/financeiro/relatorios/balancete",
          direito: "FINANCEIRO_RELATÓRIOS_BALANCETE_ACESSO",
          acesso: true
        },
        {
          icon: "transfer_within_a_station",
          text: "Cliente/Fornecedor",
          link: "/financeiro/relatorios/cliente-fornecedor",
          direito: "FINANCEIRO_RELATÓRIOS_CLIENTE_FORNECEDOR_ACESSO",
          acesso: true
        },
        {
          icon: "list",
          text: "Conciliacao",
          link: "/financeiro/relatorios/conciliacao",
          direito: "FINANCEIRO_RELATÓRIOS_CONCILIAÇÃO_ACESSO",
          acesso: true
        }
      ]
    },
    {
      icon: "keyboard_arrow_up",
      "icon-alt": "keyboard_arrow_down",
      text: "Configurações",
      model: false,
      children: [
        {
          icon: "account_balance",
          text: "NFe",
          link: "/financeiro/config-nfe",
          direito: "FINANCEIRO_CONFIG_NFE_ACESSO",
          acesso: true
        },
        {
          icon: "account_balance",
          text: "NFSC",
          link: "/financeiro/config-nfsc",
          direito: "FINANCEIRO_CONFIG_NFSC_ACESSO",
          acesso: true
        },
        {
          icon: "account_balance",
          text: "NFSe",
          link: "/financeiro/config-nfse",
          direito: "FINANCEIRO_CONFIG_NFSE_ACESSO",
          acesso: true
        }
      ]
    }
  ],
  itensAPP: [],
  itensFlip: [
    {
      icon: "flip",
      text: "Edições",
      link: "/flip/edicoes",
      direito: "FLIP_EDIÇÕES_ACESSO",
      acesso: true
    }
  ],
  itensEditorial: [],
  itensArqmidia: []
};
