<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">chrome_reader_mode</v-icon>
      <v-toolbar-title style="color: white" class="font-weight-light">{{ anuncio.id ? "Anuncio - " + anuncio.id : "Novo Anúncio" }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card :class="$vuetify.breakpoint.xsOnly ? '' : 'fixed-bar'" color="grey lighten-2">
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12 md4>
            <v-card class="fixed-bar" color="blue lighten-4" min-height="158">
              <v-container fluid grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <h4>Anúncio Atual</h4>
                  </v-flex>
                  <v-flex xs12>
                    <h5 class="font-italic">
                      Tipo de Cobrança:
                      {{ tipoCobranca }}
                    </h5>
                  </v-flex>
                  <v-flex xs6>
                    <h5 class="font-italic">
                      Preço de Tabela:
                      {{ formatCurrency(vlrTabela) }}
                    </h5>
                    <h5 class="font-italic">
                      Preço Ajustado:
                      {{ formatCurrency(vlrAjustado) }}
                    </h5>
                    <h5 class="font-italic">
                      Preço Cobrado:
                      {{ formatCurrency(id == "" && parcelas.length > 0 ? vlrCobrado : Math.max(vlrCobrado - vlrPermutado, 0)) }}
                    </h5>
                    <h5 class="font-italic" v-if="vlrPermutado != 0">
                      Valor Permuta:
                      {{ formatCurrency(vlrPermutado > vlrAjustado ? vlrAjustado : vlrPermutado) }}
                    </h5>
                    <h5 class="font-italic" v-if="vlrPacotes != 0">
                      Valor Pacotes:
                      {{ formatCurrency(vlrPacotes > vlrAjustado ? vlrAjustado : vlrPacotes) }}
                    </h5>
                  </v-flex>
                  <v-flex xs6>
                    <h5 class="font-italic">
                      Data Inicial:
                      {{ formatDate(dtInicio) }}
                    </h5>
                    <h5 class="font-italic">
                      Data Final:
                      {{ formatDate(dtFim) }}
                    </h5>
                    <h5 class="font-italic">
                      Publicações:
                      {{ nroPublicacoes }}
                    </h5>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
          </v-flex>
          <v-flex xs12 md4>
            <v-card class="fixed-bar" color="grey lighten-3" min-height="158">
              <v-container fluid grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <h4>
                      Último Anúncio
                      <v-icon v-if="ultimoAnuncio.id" color="yellow darken-2" title="Editar" @click="visualizarAnuncio()">chrome_reader_mode</v-icon>
                    </h4>
                  </v-flex>
                  <v-flex xs12>
                    <v-progress-linear :active="loadingUltAnu" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
                  </v-flex>
                  <div v-if="ultimoAnuncio.id">
                    <v-flex xs12>
                      <h5 class="font-italic">
                        Contrato:
                        {{ ultimoAnuncio.id + " - " + ultimoAnuncio.status }}
                      </h5>
                      <h5 class="font-italic">
                        Valor:
                        {{ formatCurrency(ultimoAnuncio.vlrLiquido) }}
                      </h5>
                      <h5 class="font-italic">
                        Tamanho:
                        {{ ultimoAnuncio.tamanho }}
                      </h5>
                    </v-flex>
                    <v-flex xs12>
                      <h5 class="font-italic">
                        Total Geral Cliente:
                        {{ formatCurrency(ultimoAnuncio.total) }}
                      </h5>
                    </v-flex>
                  </div>
                </v-layout>
              </v-container>
            </v-card>
          </v-flex>
          <v-flex xs12 md4>
            <v-card class="fixed-bar" color="grey lighten-3" min-height="158">
              <v-container fluid grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <h4>
                      Última Assinatura
                      <v-icon v-if="ultimaAssinatura.id && direitoAssinatura" color="red" title="Editar" @click="visualizarRenovacao()">assignment</v-icon>
                    </h4>
                  </v-flex>
                  <v-flex xs12>
                    <v-progress-linear :active="loadingUltAss" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
                  </v-flex>
                  <div v-if="ultimaAssinatura.id">
                    <v-flex xs12>
                      <h5 class="font-italic">
                        Contrato:
                        {{ ultimaAssinatura.id + " - " + ultimaAssinatura.status }}
                      </h5>
                      <h5 class="font-italic">
                        Valor:
                        {{ formatCurrency(ultimaAssinatura.vlrLiquido) }}
                      </h5>
                      <h5 class="font-italic">
                        Plano:
                        {{ ultimaAssinatura.plano }}
                      </h5>
                    </v-flex>
                    <v-flex xs12>
                      <h5 class="font-italic">
                        Período:
                        {{ ultimaAssinatura.periodo }}
                      </h5>
                    </v-flex>
                  </div>
                </v-layout>
              </v-container>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>

    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12 md3>
            <v-text-field v-model="anuncio.status" label="Status" readonly></v-text-field>
          </v-flex>

          <v-flex xs12 md3 v-if="id != ''">
            <v-text-field v-model="dtCriacaoBR" label="Captado em" readonly color="blue-grey lighten-2"></v-text-field>
          </v-flex>

          <v-flex xs12 md3 v-if="id == ''">
            <v-menu ref="mnuDtCriacao" v-model="mnuDtCriacao" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dtCriacao"
                  label="Captação"
                  v-mask="'##/##/####'"
                  hint="Obrigatório"
                  :rules="[validacao.dtCriacao == false || validacao.dtCriacao, (value) => valida_data(value) || 'Data inválida', (value) => !!value || 'Obrigatório.']"
                  :disabled="isUpdating"
                  prepend-icon="event"
                  v-on="on"
                  color="blue-grey lighten-2"
                  autocomplete="disabled"
                  :name="Math.random()"
                  @keyup="dtCriacaoUS = valida_data(dtCriacao) ? formatDateUS(dtCriacao) : null"
                  @change="
                    atualizaParcelas();
                    validacao.dtCriacao = false;
                  "
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dtCriacaoUS"
                locale="pt-br"
                no-title
                @input="
                  mnuDtCriacao = false;
                  atualizaParcelas();
                "
              ></v-date-picker>
            </v-menu>
          </v-flex>

          <v-flex xs12 md3>
            <v-text-field v-model="anuncio.usuario" label="Captado por" readonly color="blue-grey lighten-2"></v-text-field>
          </v-flex>

          <v-flex xs12 md3>
            <v-text-field
              v-model="anuncio.autorizacao"
              label="Autorização/PI"
              hint="Deixe em branco se for geração automática"
              :rules="[validacao.autorizacao == false || validacao.autorizacao, (value) => valida_nome(value) || 'Autorização inválida']"
              :persistent-hint="anuncio.pi == null"
              maxlength="15"
              :disabled="isUpdating"
              :readonly="!editar || anuncio.pi"
              color="blue-grey lighten-2"
              @change="validacao.autorizacao = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs11>
            <v-autocomplete
              autofocus
              v-model="clienteSelecionado"
              :items="clientes"
              :filter="filtrarObjetos"
              label="Anunciante"
              item-text="nome"
              item-value="id"
              hint="Obrigatório"
              :rules="[validacao.idCliente == false || validacao.idCliente, (value) => (!!value && !!value.id) || 'Obrigatório.']"
              :disabled="id != ''"
              no-data-text
              placeholder="Pesquisa pelos primeiros 50 anunciantes que coincidirem com o nome ou identificação digitada"
              :loading="loadingClientes"
              :search-input.sync="search"
              @keyup.enter="buscarCliente"
              clearable
              return-object
              @change="validacao.idCliente = false"
            >
              <template v-slot:append v-if="anuncio.idCliente == null">
                <v-btn color="blue" fab small>
                  <v-icon color="white" @click="buscarCliente" title="Busca pelo nome ou identificação do anunciante">search</v-icon>
                </v-btn>
              </template>
            </v-autocomplete>
          </v-flex>
          <v-flex xs1 class="text-right">
            <v-spacer></v-spacer>
            <v-icon v-if="direitoAnunciante && anuncio.idCliente != null" color="orange" @click="editarCliente" title="Editar Anunciante">account_circle</v-icon>
          </v-flex>

          <v-flex xs12 md12 v-if="departamentosCliente.length > 0">
            <v-autocomplete
              v-model="anuncio.idDeptoCliente"
              :items="departamentosCliente"
              :filter="filtrarObjetos"
              label="Departamento do Anunciante"
              item-text="departamento"
              item-value="id"
              :rules="[validacao.idDeptoCliente == false || validacao.idDeptoCliente]"
              :disabled="!editar"
              no-data-text
              clearable
              @change="validacao.idDeptoCliente = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md4>
            <v-autocomplete
              v-model="produtoSelecionado"
              :items="produtos"
              :rules="[validacao.idProduto == false || validacao.idProduto, (value) => (!!value && !!value.id) || 'Obrigatório.']"
              :filter="filtrarObjetos"
              label="Produto"
              item-text="nome"
              item-value="id"
              :disabled="id != ''"
              no-data-text
              return-object
              @change="validacao.idProduto = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md4>
            <v-autocomplete
              v-model="cadernoSelecionado"
              :items="cadernos"
              :rules="[validacao.idCaderno == false || validacao.idCaderno, (value) => (!!value && !!value.id) || 'Obrigatório.']"
              :filter="filtrarObjetos"
              label="Caderno"
              item-text="nome"
              item-value="id"
              :disabled="id != ''"
              no-data-text
              return-object
              @change="validacao.idCaderno = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md4>
            <v-autocomplete
              v-model="formatoSelecionado"
              :items="formatos"
              :rules="[validacao.idFormatoCaderno == false || validacao.idFormatoCaderno, (value) => (!!value && !!value.id) || 'Obrigatório.']"
              :filter="filtrarObjetos"
              label="Formato"
              item-text="nome"
              item-value="id"
              :disabled="id != ''"
              no-data-text
              return-object
              @change="validacao.idFormatoCaderno = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md6 v-if="produtoSelecionado.tipo && produtoSelecionado.tipo != 'CLASSIFICADOS'">
            <v-autocomplete
              v-model="determinacaoSelecionada"
              :items="determinacoes"
              :rules="[validacao.idDeterminacao == false || validacao.idDeterminacao, (value) => (!!value && !!value.id) || 'Obrigatório.']"
              :filter="filtrarObjetos"
              label="Determinação"
              item-text="nome"
              item-value="id"
              :disabled="id != ''"
              no-data-text
              clearable
              return-object
              @change="validacao.idDeterminacao = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md6 v-if="produtoSelecionado.tipo && produtoSelecionado.tipo != 'CLASSIFICADOS'">
            <v-autocomplete
              v-model="colocacaoSelecionada"
              :items="colocacoes"
              :rules="[validacao.idColocacao == false || validacao.idColocacao, (value) => (!!value && !!value.id) || 'Obrigatório.']"
              :filter="filtrarObjetos"
              label="Colocação"
              item-text="nome"
              item-value="id"
              :disabled="id != ''"
              no-data-text
              clearable
              return-object
              @change="validacao.idColocacao = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md6 v-if="produtoSelecionado.tipo && produtoSelecionado.tipo == 'CLASSIFICADOS'">
            <v-autocomplete
              v-model="secaoSelecionada"
              :items="secoes"
              :filter="filtrarObjetos"
              :rules="[validacao.idSecao == false || validacao.idSecao]"
              label="Seção"
              item-text="nome"
              item-value="id"
              :disabled="!editar"
              no-data-text
              return-object
              @change="validacao.idSecao = false"
            >
              <template v-slot:selection="data">
                <span class="ml-2">{{ data.item.nome }}</span>
              </template>
              <template v-slot:item="data">
                <v-list-item-avatar size="12" :class="data.item.titulo ? 'grey' : 'blue'"> </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.nomeS"></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-flex>

          <v-flex xs6 md3 v-if="tabelaAnuncio.tipoCobranca > 0 && tabelaAnuncio.tipoCobranca < 4">
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="anuncio.colunas"
              :label="tabelaAnuncio.tipoCobranca == 3 ? 'Largura' : 'Colunas'"
              :decimalLength="tabelaAnuncio.tipoCobranca == 1 ? 0 : 2"
              :allowNegative="false"
              :max="
                formatoSelecionado.tipo == 'S'
                  ? 5000
                  : tabelaAnuncio.tipoCobranca == 3
                  ? cadernoSelecionado.paginaLargura - cadernoSelecionado.margemEsquerda - cadernoSelecionado.margemDireita
                  : cadernoSelecionado.numeroColunas * 2
              "
              :rules="[validacao.colunas == false || validacao.colunas, (value) => valida_colunas(value)]"
              color="blue-grey lighten-2"
              :disabled="isUpdating || !editar"
              :readonly="!editar"
              @change="
                limpaParcelas('colunas');
                validacao.colunas = false;
              "
            ></v-currency-field>
          </v-flex>

          <v-flex xs6 md3 v-if="tabelaAnuncio.tipoCobranca > 0 && tabelaAnuncio.tipoCobranca < 4">
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="anuncio.altura"
              label="Altura"
              :decimalLength="tabelaAnuncio.tipoCobranca == 1 || tabelaAnuncio.tipoCobranca == 3 ? 2 : 0"
              :allowNegative="false"
              :max="formatoSelecionado.tipo == 'S' ? 5000 : cadernoSelecionado.paginaAltura - cadernoSelecionado.margemSuperior - cadernoSelecionado.margemInferior"
              :rules="[validacao.altura == false || validacao.altura, (value) => valida_altura(value)]"
              color="blue-grey lighten-2"
              :disabled="isUpdating || !editar"
              :readonly="!editar"
              @change="
                limpaParcelas('altura');
                validacao.altura = false;
              "
            ></v-currency-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="vlrTaxado"
              label="Valor Taxado"
              :allowNegative="false"
              :max="999999"
              :rules="[validacao.vlrTaxado == false || validacao.vlrTaxado, (value) => (value && value.length < 11) || 'Valor Taxado inválido']"
              color="blue-grey lighten-2"
              :disabled="isUpdating || !editar"
              :readonly="!editar || !direitoTaxar"
              @change="
                validacao.vlrTaxado = false;
                limpaParcelas('vlrTaxado');
              "
            ></v-currency-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-text-field
              v-model="carencia"
              label="Carência (dias)"
              maxlength="3"
              :rules="[
                validacao.carencia == false || validacao.carencia,
                (value) => !limiteCarencia || (parseInt(value) >= limiteCarencia * -1 && parseInt(value) <= limiteCarencia) || 'Carência inválida'
              ]"
              color="blue-grey lighten-2"
              :disabled="isUpdating || !editar"
              :readonly="!editar || !direitoTaxar"
              @change="
                validacao.carencia = false;
                limpaParcelas('carencia');
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs12>
            <!-- v-if="formatoSelecionado.tipo == 'LT'" removido a pedido do Leandro, pois jornais precisam do título no Diagrama -->
            <v-text-field
              v-model="anuncio.titulo"
              label="Título do Anúncio"
              maxlength="255"
              :rules="[validacao.titulo == false || validacao.titulo]"
              :disabled="isUpdating"
              autocomplete="disabled"
              :name="Math.random()"
              @change="
                anuncio.titulo = pLetra(anuncio.titulo);
                limpaParcelas('titulo');
                validacao.titulo = false;
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs12 v-if="textos.length && editar">
            <v-autocomplete
              v-model="textoSelecionado"
              :items="textos"
              :filter="filtrarObjetos"
              label="Texto Padrão"
              item-text="nome"
              item-value="id"
              no-data-text
              return-object
              :disabled="isUpdating"
              clearable
              @click:clear="limparTextoPadrao"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12>
            <v-textarea
              v-model="anuncio.textoAnuncio"
              label="Texto do Anúncio"
              :rules="[validacao.textoAnuncio == false || validacao.textoAnuncio]"
              :maxlength="formatoSelecionado.maxCaracteres || 10000"
              :counter="formatoSelecionado.maxCaracteres || 10000"
              :hint="calculo"
              persistent-hint
              :readonly="!editar"
              :disabled="isUpdating"
              @change="
                limpaParcelas('texto');
                validacao.textoAnuncio = false;
              "
              ><template v-slot:message="{ message }"> <span v-html="message"></span> </template
            ></v-textarea>
          </v-flex>

          <v-flex xs12>
            <v-text-field
              v-model="anuncio.textoFatura"
              label="Texto para Fatura"
              maxlength="100"
              :rules="[validacao.descricao == false || validacao.descricao]"
              :disabled="isUpdating || id != ''"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.descricao = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-switch
              :readonly="!editar || !direitoDescComissao || vlrPacotes != 0"
              v-model="anuncio.descComissao"
              :disabled="isUpdating"
              :label="`${anuncio.descComissao == 1 ? 'Descontar Comissão da Agência' : 'Sem Desconto de Comissão'}`"
              @change="limpaParcelas"
            ></v-switch>
          </v-flex>
          <!-- !editar || vlrPermutado > 0 para permitir alterar descComissao, parcelas teriam que ser excluídas e recriadas -->

          <v-flex xs6 md3>
            <v-switch
              v-model="anuncio.emNegociacao"
              :label="`${anuncio.emNegociacao == 1 ? 'Anúncio em Negociação' : 'Cobrança Liberada'}`"
              @change="ajustaEmNegociacao"
              :disabled="isUpdating || (anuncio.emNegociacao == 1 && !direitoNegociacao) || anuncio.status == 'CANCELADO'"
            ></v-switch>
          </v-flex>

          <v-flex xs6 md3>
            <v-switch
              v-model="anuncio.bloquearPublicacao"
              :label="`${anuncio.bloquearPublicacao == 1 ? 'Publicação Bloqueada' : 'Publicação Liberada'}`"
              @change="ajustaBloquearPublicacao"
              :disabled="isUpdating || (anuncio.bloquearPublicacao == 1 && !direitoPublicacao) || anuncio.status == 'CANCELADO'"
            ></v-switch>
          </v-flex>

          <v-flex xs12>
            <v-alert v-if="anuncio.dtCancelamento" :value="true" color="error" icon="priority_high" outlined>
              Este anúncio foi cancelado em
              {{ formatDateTime(anuncio.dtCancelamento) }}.
            </v-alert>
          </v-flex>
          <v-flex xs12>
            <v-alert v-if="nfEmitida" :value="true" color="info" icon="priority_high" outlined> Anúncio com Nota Fiscal Emitida. </v-alert>
          </v-flex>
          <v-flex xs12>
            <v-alert v-if="!nfEmitida && emCobranca" :value="true" color="info" icon="priority_high" outlined> Anúncio com Parcela em Cobrança. </v-alert>
          </v-flex>
        </v-layout>

        <v-layout wrap>
          <v-expansion-panels v-model="painel" focusable>
            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.publicacoes ? 'red--text' : ''">
                <strong>Publicações</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-autocomplete v-if="publicacoes.length == 0" v-model="diasSelecionados" autofocus :items="diasPublicacao" label="Dias de Publicação" no-data-text multiple>
                      <template v-slot:selection="data">
                        <v-chip color="blue lighten-3" x-small close @click:close="remove(data)">
                          {{ data.item }}
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </v-flex>

                  <v-flex sm12 md4 lg3>
                    <v-tabs v-model="tab" center-active>
                      <v-tab :disabled="publicacoes.length > 0">Período</v-tab>
                      <v-tab>Datas</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab">
                      <v-tab-item>
                        <v-date-picker v-model="publicacoesUS" locale="pt-br" no-title range :allowed-dates="allowedDates" @click:date="ajustaPublicacoes"></v-date-picker>
                      </v-tab-item>
                      <v-tab-item>
                        <v-date-picker v-model="publicacoesUS" locale="pt-br" no-title multiple :allowed-dates="allowedDates" @click:date="aplicaPublicacao"></v-date-picker>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-flex>

                  <v-flex sm12 md8 lg9>
                    <v-card :min-height="$vuetify.breakpoint.xsOnly ? 0 : 340">
                      <v-data-table
                        mobile-breakpoint="0"
                        :headers="headerPublicacoes"
                        :items="publicacoes"
                        no-data-text
                        no-results-text
                        :footer-props="{
                          itemsPerPageText: 'Registros por página',
                          itemsPerPageOptions: [10, 25, 50],
                          pageText: ''
                        }"
                      >
                        <template v-slot:[getItemActions()]="{ item }">
                          <!-- <v-icon
                            v-if="editar"
                            color="blue lighten-2"
                            title="Arte"
                            @click="editarArte(item)"
                            >image</v-icon
                          > -->
                          <!-- editar / !nfEmitida - não precisa pois publicação será somente marcada como não publicar -->
                          <v-icon v-if="item.futura && item.statusPublicacao != 4" color="error" title="Cancelar" @click="exclusaoPublicacao(item)">delete</v-icon>
                          <v-icon v-if="editar && item.futura && item.statusPublicacao == 4" color="success" title="Reverter Cancelamento" @click="reversaoPublicacao(item)"
                            >undo</v-icon
                          >
                          <v-icon v-if="direitoLog" color="blue" title="Visualizar Log" @click="visualizarLogPublicacao(item)">info</v-icon>
                        </template>
                        <template v-slot:[getItemDtPublicacao()]="{ item }">
                          {{ formatDate(item.dtPublicacao) }}
                        </template>
                        <template v-slot:[getItemVlrBruto()]="{ item }">
                          {{ item.statusPublicacao == 4 ? "" : formatCurrency(item.vlrBruto) }}
                        </template>
                        <template v-slot:[getItemVlrLiquido()]="{ item }">
                          {{ item.statusPublicacao == 4 ? "" : anuncio.id ? formatCurrency(item.vlrLiquido) : "" }}
                        </template>
                        <template v-slot:[getItemStatusPublicacao()]="{ item }">
                          <span
                            :class="
                              item.statusPublicacao == 1
                                ? 'orange--text'
                                : item.statusPublicacao == 2
                                ? 'amber--text'
                                : item.statusPublicacao == 3
                                ? 'green--text'
                                : item.statusPublicacao == 4
                                ? 'red--text'
                                : 'blue--text'
                            "
                            v-html="
                              item.statusPublicacao == 1
                                ? 'Excluído Diagrama'
                                : item.statusPublicacao == 2
                                ? 'Alterado Comercial'
                                : item.statusPublicacao == 3
                                ? '<strong>Publicado</strong>' + (item.paginaPublicado ? ' Página ' + item.paginaPublicado : '')
                                : item.statusPublicacao == 4
                                ? '<strong>Cancelado Comercial</strong>'
                                : 'Não Publicado'
                            "
                          ></span>
                        </template>
                      </v-data-table>
                      <v-alert v-if="validacao.publicacoes" :value="true" color="error" icon="priority_high" outlined>{{ validacao.publicacoes }}</v-alert>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" v-if="vlrTaxado == 0 || condicoesEspeciais.length > 0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.condicoesEspeciais ? 'red--text' : ''">
                <strong>Condições Especiais</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap>
                  <v-flex xs12 md6 v-if="vlrTaxado == 0">
                    <v-autocomplete
                      v-model="condicaoEspecialSelecionada"
                      autofocus
                      label="Incluir Condição Especial"
                      :disabled="isUpdating"
                      :items="condicoesEspeciaisFormato"
                      :filter="filtrarObjetos"
                      item-text="nome"
                      item-value="id"
                      autocomplete="disabled"
                      :name="Math.random()"
                      no-data-text
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs12 md12>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headerCondicoesEspeciais"
                      :items="condicoesEspeciais"
                      no-data-text
                      no-results-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [10, 25, 50],
                        pageText: ''
                      }"
                    >
                      <template v-slot:[getItemActions()]="{ item }">
                        <v-icon v-if="editar" color="error" title="Remover" @click="excluirCondicaoEspecial(item)">delete</v-icon>
                      </template>
                    </v-data-table>
                    <v-alert v-if="validacao.condicoesEspeciais" :value="true" color="error" icon="priority_high" outlined>{{ validacao.condicoesEspeciais }}</v-alert>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.agentes ? 'red--text' : ''">
                <strong>Agentes/Agências</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar>
                      <v-spacer></v-spacer>
                      <v-dialog persistent v-model="dialogAgente" max-width="600px">
                        <template v-slot:activator="{ on }">
                          <v-btn v-if="editar" color="success" fab small v-on="on">
                            <v-icon color="white" title="Incluir">add</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form ref="formAgente" v-model="agenteValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">Agente/Agência</span>
                              <v-spacer></v-spacer>
                              <v-btn icon @click="closeAgente">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container fluid grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs10>
                                    <v-autocomplete
                                      autofocus
                                      v-model="agenteSelecionado"
                                      :items="agentesFormato"
                                      :filter="filtrarObjetosIdentificacao"
                                      :rules="[validacao.idAgente == false || validacao.idAgente, (value) => (!!value && !!value.id) || 'Obrigatório.']"
                                      hint="Obrigatório"
                                      label="Selecione o Agente"
                                      item-text="nome"
                                      item-value="id"
                                      no-data-text
                                      return-object
                                    ></v-autocomplete>
                                  </v-flex>
                                  <v-flex xs2>
                                    <v-currency-field
                                      :locale="`pt-${$store.state.usuario.site_pais}`"
                                      v-model="agenteItem.comissao"
                                      label="Comissão"
                                      :allowNegative="false"
                                      :max="100"
                                      suffix="%"
                                      hint="Obrigatório"
                                      :readonly="!direitoComissao"
                                      :rules="[
                                        validacao.comissao == false || validacao.comissao,
                                        (value) => !value || parseFloat(value.replace('.', '').replace(',', '.')) <= 99.99 || 'Verifique a comissão'
                                      ]"
                                      color="blue-grey lighten-2"
                                    ></v-currency-field>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closeAgente">Retornar</v-btn>
                              <v-btn color="blue darken-1" @click="saveAgente">Atualizar</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headerAgentes"
                      :items="agentesOperacao"
                      no-data-text
                      no-results-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [10, 25, 50],
                        pageText: ''
                      }"
                    >
                      <template v-slot:[getItemActions()]="{ item }">
                        <v-icon v-if="editar" color="error" title="Remover" @click="excluirAgente(item)">delete</v-icon>
                      </template>
                      <template v-slot:[getItemComissao()]="{ item }">
                        {{ formatCurrency(item.comissao) }}
                      </template>
                    </v-data-table>
                    <v-alert v-if="validacao.agentes" :value="true" color="error" icon="priority_high" outlined>{{ validacao.agentes }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.observacoes ? 'red--text' : ''">
                <strong>Observações</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar>
                      <v-spacer></v-spacer>
                      <v-dialog persistent v-model="dialogObservacao" max-width="600px">
                        <template v-slot:activator="{ on }">
                          <v-btn v-if="editar" color="success" fab small v-on="on">
                            <v-icon color="white" title="Incluir">add</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form ref="formObservacao" v-model="observacaoValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">Observação</span>
                              <v-spacer></v-spacer>
                              <v-btn icon @click="closeObservacao">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container fluid grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs12>
                                    <v-textarea
                                      autofocus
                                      v-model="observacaoItem.observacao"
                                      label="Texto da Observação"
                                      maxlength="255"
                                      counter="255"
                                      rows="3"
                                      hint="Obrigatório"
                                      :rules="[
                                        (value) => !!value || 'Obrigatório.',
                                        (value) => (value != null && value.length >= 10 && value.length <= 255) || 'Texto da observação deve ter entre 10 e 255 caracteres'
                                      ]"
                                      color="blue-grey lighten-2"
                                    ></v-textarea>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closeObservacao">Retornar</v-btn>
                              <v-btn color="blue darken-1" @click="saveObservacao">Atualizar</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headerObservacoes"
                      :items="observacoesAnuncio"
                      no-data-text
                      no-results-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [10, 25, 50],
                        pageText: ''
                      }"
                    >
                      <template v-slot:[getItemActions()]="{ item }">
                        <v-icon
                          :color="item.situacao == 1 ? 'blue lighten-1' : 'blue-grey lighten-3'"
                          :title="item.situacao == 1 ? 'Desativar' : 'Ativar'"
                          @click="ativarDesativarObservacao(item)"
                        >
                          {{ item.situacao == 1 ? "radio_button_checked" : "radio_button_unchecked" }}
                        </v-icon>
                      </template>
                      <template v-slot:[getItemDtRegistro()]="{ item }">
                        {{ formatDateTime(item.dtRegistro) }}
                      </template>
                    </v-data-table>
                    <v-alert v-if="validacao.observacoes" :value="true" color="error" icon="priority_high" outlined>{{ validacao.observacoes }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-if="permutasPagar.length > 0 && vlrPacotes == 0" dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.permutasPagar ? 'red--text' : ''">
                <strong>Permutas</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap>
                  <v-flex xs8 md3 ml-4>
                    <v-checkbox
                      v-if="id == ''"
                      v-model="selectAll"
                      :label="selectAll ? 'Desmarcar todos' : 'Marcar todos da página'"
                      @change="marcarDesmarcarTodos(selectAll)"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 md12>
                    <v-data-table mobile-breakpoint="0" :headers="headerPermutasPagar" :items="permutasPagar" no-data-text no-results-text :footer-props="rodapePermutasPagar">
                      <template v-slot:[getItemActions()]="{ item }">
                        <v-icon v-if="item.id && id == ''" color="orange" title="Selecionar" @click="ativarDesativarItem(item)">
                          {{ item.selecionado == 1 ? "check_box" : "check_box_outline_blank" }}
                        </v-icon>
                        <v-icon v-if="direitoOperacao && item.id && id != ''" color="blue" title="Operação" @click="visualizarOperacao(item)">remove_circle</v-icon>
                      </template>
                      <template v-slot:[getItemDtVecto()]="{ item }">
                        <span>{{ formatDate(item.dtVecto) }}</span>
                      </template>
                      <template v-slot:[getItemValor()]="{ item }">
                        <span>{{ formatCurrency(item.valor) }}</span>
                      </template>
                    </v-data-table>
                    <v-alert v-if="validacao.permutasPagar" :value="true" color="error" icon="priority_high" outlined>{{ validacao.permutasPagar }}</v-alert>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-if="pacotes.length > 0 && vlrPermutado == 0" dark class="elevation-0" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.pacotes ? 'red--text' : ''">
                <strong>Pacotes</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap>
                  <v-flex xs8 md3 ml-4>
                    <v-checkbox
                      v-if="id == ''"
                      v-model="selectAllPac"
                      :label="selectAll ? 'Desmarcar todos' : 'Marcar todos da página'"
                      @change="marcarDesmarcarTodosPac(selectAllPac)"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 md12>
                    <v-data-table mobile-breakpoint="0" :headers="headerPacotes" :items="pacotes" no-data-text no-results-text :footer-props="rodapePacotes">
                      <template v-slot:[getItemActions()]="{ item }">
                        <v-icon v-if="item.id && id == ''" color="orange" title="Selecionar" @click="ativarDesativarItemPac(item)">
                          {{ item.selecionado == 1 ? "check_box" : "check_box_outline_blank" }}
                        </v-icon>
                        <v-icon v-if="direitoPacote && item.id && id != ''" color="blue" title="Pacote" @click="visualizarPacote(item)">library_books</v-icon>
                      </template>
                      <template v-slot:[getItemDtCriacao()]="{ item }">
                        <span>{{ formatDate(item.dtCriacao) }}</span>
                      </template>
                      <template v-slot:[getItemVlrLiquido()]="{ item }">
                        <span>{{ formatCurrency(item.vlrLiquido) }}</span>
                      </template>
                      <template v-slot:[getItemVlrCredito()]="{ item }">
                        <span>{{ formatCurrency(item.vlrCredito) }}</span>
                      </template>
                      <template v-slot:[getItemVlrUtilizado()]="{ item }">
                        <span>{{ formatCurrency(item.vlrUtilizado) }}</span>
                      </template>
                      <template v-slot:[getItemVlrSaldo()]="{ item }">
                        <span>{{ formatCurrency(item.vlrSaldo) }}</span>
                      </template>
                    </v-data-table>
                    <v-alert v-if="validacao.pacotes" :value="true" color="error" icon="priority_high" outlined>{{ validacao.pacotes }}</v-alert>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" v-if="id != '' || (vlrAjustado - vlrPermutado > 0 && vlrPacotes == 0)" :disabled="isUpdating">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.parcelas ? 'red--text' : ''">
                <strong>Parcelas</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap>
                  <v-flex xs12 md3 v-if="formaPagamentoSelecionar || parcelas.length == 0">
                    <v-menu ref="mnuDtVecto" v-model="mnuDtVecto" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="dtVecto"
                          autofocus
                          label="Vencimento 1ª Parcela"
                          v-mask="'##/##/####'"
                          hint="Obrigatório"
                          :rules="[
                            validacao.dtVecto == false || validacao.dtVecto,
                            (value) => valida_data_vecto(value) || 'Data de vencimento não pode ser inferior a da captação'
                          ]"
                          :disabled="isUpdating"
                          prepend-icon="event"
                          v-on="on"
                          color="blue-grey lighten-2"
                          autocomplete="disabled"
                          :name="Math.random()"
                          @keyup="dtVectoUS = valida_data_vecto(dtVecto) ? formatDateUS(dtVecto) : null"
                          clearable
                          @click:clear="
                            dtVectoUS = null;
                            atualizaParcelas();
                          "
                          @change="atualizaParcelas()"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="dtVectoUS"
                        locale="pt-br"
                        no-title
                        @change="
                          mnuDtVecto = false;
                          atualizaParcelas();
                        "
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>

                  <v-flex xs12 md3 v-if="formaPagamentoSelecionar || parcelas.length == 0">
                    <v-autocomplete
                      v-model="formaPagamentoSelecionada"
                      :items="formasPagamentoFormato"
                      :filter="filtrarObjetos"
                      label="Selecione a Forma de Pagamento"
                      item-text="nome"
                      item-value="idFormaPgto"
                      no-data-text
                      @click:clear="nroParcelas = 0"
                      @change="carregaItensFormaPgto"
                      return-object
                      clearable
                    ></v-autocomplete>
                  </v-flex>

                  <v-flex xs12 md3 v-if="parcelas.length == 0">
                    <v-autocomplete
                      v-model="parcelamentoSelecionado"
                      :items="parcelamento"
                      :filter="filtrarObjetos"
                      label="Selecione o Parcelamento"
                      item-text="nome"
                      item-value="nome"
                      no-data-text
                      return-object
                    >
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-title class="ml-2" v-html="data.item.nro + ' x ' + data.item.vlrUnit"></v-list-item-title>
                          <v-list-item-subtitle class="ml-2" v-html="'Total: <b>' + data.item.vlrTot + '</b>'"></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                </v-layout>

                <v-flex xs12 md12 v-if="!idClienteCelesc && formaPagamentoSelecionada && (formaPagamentoSelecionada.tipo == 'CELESC' || formaPagamentoSelecionada.tipo == 'SAMAE')">
                  <v-autocomplete
                    v-model="idClienteCelesc"
                    :items="celescsCliente"
                    :filter="filtrarObjetos"
                    hint="Obrigatório"
                    :rules="[validacao.idClienteCelesc == false || validacao.idClienteCelesc, (value) => !!value || 'Obrigatório.']"
                    label="Selecione a Unidade Consumidora"
                    item-text="nome"
                    item-value="id"
                    no-data-text
                  ></v-autocomplete>
                </v-flex>

                <v-flex xs12 md12 v-if="!idClienteCartao && formaPagamentoSelecionada && formaPagamentoSelecionada.tipo == 'CARTAO_CREDITO'">
                  <v-autocomplete
                    v-model="idClienteCartao"
                    :items="cartoesCliente"
                    :filter="filtrarObjetos"
                    hint="Obrigatório"
                    :rules="[
                      validacao.idClienteCartao == false || validacao.idClienteCartao,
                      (value) => !cartoesCliente.length || (cartoesCliente.length && !!value) || 'Obrigatório.'
                    ]"
                    label="Selecione o Cartão de Crédito"
                    item-text="nome"
                    item-value="id"
                    no-data-text
                  ></v-autocomplete>
                </v-flex>
                <!-- se não desejar que seja obrigatório, usar forma CARTAO_MANUAL -->

                <v-flex xs12 md12 v-if="!idClienteConta && formaPagamentoSelecionada && formaPagamentoSelecionada.tipo == 'DEBITO_CONTA'">
                  <v-autocomplete
                    v-model="idClienteConta"
                    :items="contasCliente"
                    :filter="filtrarObjetos"
                    hint="Obrigatório"
                    :rules="[validacao.idClienteConta == false || validacao.idClienteConta, (value) => !!value || 'Obrigatório.']"
                    label="Selecione a Conta Corrente"
                    item-text="nome"
                    item-value="id"
                    no-data-text
                  ></v-autocomplete>
                </v-flex>

                <v-flex
                  xs12
                  md12
                  v-if="!idClienteEndereco && formaPagamentoSelecionada && (formaPagamentoSelecionada.tipo == 'BOLETO' || formaPagamentoSelecionada.tipo == 'CARTEIRA')"
                >
                  <v-autocomplete
                    v-model="idClienteEndereco"
                    :items="enderecosCobrancaCliente"
                    :filter="filtrarObjetos"
                    hint="Obrigatório"
                    :rules="[validacao.idClienteEndereco == false || validacao.idClienteEndereco, (value) => !!value || 'Obrigatório.']"
                    label="Selecione o Endereço de Cobrança"
                    item-text="nome"
                    item-value="id"
                    no-data-text
                  ></v-autocomplete>
                </v-flex>

                <v-flex xs12 md12>
                  <v-data-table
                    mobile-breakpoint="0"
                    :headers="headerParcelas"
                    :items="parcelas"
                    no-data-text
                    no-results-text
                    :footer-props="{
                      itemsPerPageText: 'Registros por página',
                      itemsPerPageOptions: [12, 25, 50],
                      pageText: ''
                    }"
                  >
                    <template v-slot:[getItemActions()]="{ item }">
                      <v-icon v-if="item.id && editarParcelas" class="mr-2" color="blue lighten-2" title="Editar" @click="editarParcela(item)">edit</v-icon>
                      <v-icon v-if="!item.id && item.status != 'QUITADA'" class="mr-2" color="blue lighten-2" title="Editar" @click="editarAjuste(item)">edit</v-icon>
                    </template>
                    <template v-slot:[getItemDtVectoOriginal()]="{ item }">
                      {{ formatDate(item.dtVectoOriginal) }}
                    </template>
                    <template v-slot:[getItemDtPromessa()]="{ item }">
                      {{ formatDate(item.getItemDtPromessa) }}
                    </template>
                    <template v-slot:[getItemValor()]="{ item }">
                      {{ formatCurrency(item.valor + item.descAcr) }}
                    </template>
                  </v-data-table>
                  <v-alert v-if="validacao.parcelas" :value="true" color="error" icon="priority_high" outlined>{{ validacao.parcelas }}</v-alert>
                  <v-dialog persistent v-model="dialogAjuste" max-width="600px">
                    <v-card>
                      <v-form ref="formAjuste" v-model="ajusteValid" lazy-validation>
                        <v-toolbar>
                          <span class="headline">Ajustar Parcela</span>
                          <v-spacer></v-spacer>
                          <v-btn icon @click="closeAjuste">
                            <v-icon color="darken-1">cancel</v-icon>
                          </v-btn>
                        </v-toolbar>
                        <v-card-text>
                          <v-container grid-list-md>
                            <v-layout wrap>
                              <v-flex xs6 v-if="parcelas.length > 1">
                                <v-currency-field
                                  :locale="`pt-${$store.state.usuario.site_pais}`"
                                  v-model="ajusteItem.valor"
                                  label="Novo Valor"
                                  :allowNegative="false"
                                  :max="9999999"
                                  :rules="[validacao.ajuste_valor == false || validacao.ajuste_valor, (value) => (value && value.length < 13) || 'Verifique o valor da parcela']"
                                  color="blue-grey lighten-2"
                                  autocomplete="disabled"
                                  :name="Math.random()"
                                ></v-currency-field>
                              </v-flex>
                              <v-flex xs6>
                                <v-menu
                                  ref="mnuAjusteItemDtVectoO"
                                  v-model="mnuAjusteItemDtVectoO"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      v-model="ajusteItemDtVectoO"
                                      label="Novo Vencimento"
                                      v-mask="'##/##/####'"
                                      prepend-icon="event"
                                      v-on="on"
                                      return-v-masked-value
                                      hint="Obrigatório"
                                      :rules="[
                                        validacao.ajuste_vecto == false || validacao.ajuste_vecto,
                                        (value) => valida_data_vecto(value) || 'Data de vencimento não pode ser inferior a da captação',
                                        (value) => !!value || 'Obrigatório.',
                                        (value) => (value != null && value.length == 10) || 'Preencha a data de vencimento da parcela'
                                      ]"
                                      color="blue-grey lighten-2"
                                      @keyup="ajusteItemDtVectoOUS = valida_data_vecto(ajusteItemDtVectoO) ? formatDateUS(ajusteItemDtVectoO) : null"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker v-model="ajusteItemDtVectoOUS" locale="pt-br" no-title @input="mnuAjusteItemDtVectoO = false"></v-date-picker>
                                </v-menu>
                              </v-flex>
                              <v-flex xs12 v-if="parcelas.length > 1">
                                <v-autocomplete
                                  v-model="formaPgtoParcelaSelecionada"
                                  :items="formasPgtoParcela"
                                  :disabled="!direitoFormaPgto"
                                  hint="Obrigatório"
                                  :rules="[validacao.idFormaPgtoParcela == false || validacao.idFormaPgtoParcela, (value) => (!!value && !!value.id) || 'Obrigatório.']"
                                  :filter="filtrarObjetos"
                                  label="Forma de Pagamento"
                                  item-text="nome"
                                  item-value="id"
                                  no-data-text
                                  return-object
                                ></v-autocomplete>
                              </v-flex>

                              <v-flex xs12 md12 v-if="formaPgtoParcelaSelecionada && (formaPgtoParcelaSelecionada.tipo == 'CELESC' || formaPgtoParcelaSelecionada.tipo == 'SAMAE')">
                                <v-autocomplete
                                  v-model="idClienteCelesc"
                                  :items="celescsCliente"
                                  :filter="filtrarObjetos"
                                  hint="Obrigatório"
                                  :rules="[validacao.idClienteCelesc == false || validacao.idClienteCelesc, (value) => !!value || 'Obrigatório.']"
                                  label="Selecione a Unidade Consumidora"
                                  item-text="nome"
                                  item-value="id"
                                  no-data-text
                                ></v-autocomplete>
                              </v-flex>

                              <v-flex xs12 md12 v-if="formaPgtoParcelaSelecionada && formaPgtoParcelaSelecionada.tipo == 'CARTAO_CREDITO'">
                                <v-autocomplete
                                  v-model="idClienteCartao"
                                  :items="cartoesCliente"
                                  :filter="filtrarObjetos"
                                  hint="Obrigatório"
                                  :rules="[validacao.idClienteCartao == false || validacao.idClienteCartao, (value) => !!value || 'Obrigatório.']"
                                  label="Selecione o Cartão de Crédito"
                                  item-text="nome"
                                  item-value="id"
                                  no-data-text
                                ></v-autocomplete>
                              </v-flex>
                              <!-- se não desejar que seja obrigatório, usar forma CARTAO_MANUAL -->

                              <v-flex xs12 md12 v-if="formaPgtoParcelaSelecionada && formaPgtoParcelaSelecionada.tipo == 'DEBITO_CONTA'">
                                <v-autocomplete
                                  v-model="idClienteConta"
                                  :items="contasCliente"
                                  :filter="filtrarObjetos"
                                  hint="Obrigatório"
                                  :rules="[validacao.idClienteConta == false || validacao.idClienteConta, (value) => !!value || 'Obrigatório.']"
                                  label="Selecione a Conta Corrente"
                                  item-text="nome"
                                  item-value="id"
                                  no-data-text
                                ></v-autocomplete>
                              </v-flex>

                              <v-flex
                                xs12
                                md12
                                v-if="formaPgtoParcelaSelecionada && (formaPgtoParcelaSelecionada.tipo == 'BOLETO' || formaPgtoParcelaSelecionada.tipo == 'CARTEIRA')"
                              >
                                <v-autocomplete
                                  v-model="idClienteEndereco"
                                  :items="enderecosCobrancaCliente"
                                  :filter="filtrarObjetos"
                                  hint="Obrigatório"
                                  :rules="[validacao.idClienteEndereco == false || validacao.idClienteEndereco, (value) => !!value || 'Obrigatório.']"
                                  label="Selecione o Endereço de Cobrança"
                                  item-text="nome"
                                  item-value="id"
                                  no-data-text
                                ></v-autocomplete>
                              </v-flex>
                            </v-layout>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" @click="closeAjuste">Retornar</v-btn>
                          <v-btn color="blue darken-1" @click="saveAjuste">Atualizar</v-btn>
                        </v-card-actions>
                      </v-form>
                    </v-card>
                  </v-dialog>

                  <v-dialog persistent v-model="dialogParcela" max-width="700px">
                    <v-card>
                      <Parcela :id="idParcela" receber="true" :dialogParcela.sync="dialogParcela" />
                    </v-card>
                  </v-dialog>
                </v-flex>
                <v-flex xs12 md3 v-if="parcelas.length > 0 && parcelas[0].id && direitoParcelas">
                  <v-icon color="blue lighten-2" title="Abrir no Financeiro" @click="visualizarParcelas">add_circle_outline</v-icon>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">{{ validacao.mensagemErro }}</v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-card-actions id="flex-wrap">
      <v-btn to="/comercial/anuncios" color="orange lighten-2 white" id="btn-rsn" style="color: white"> <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar </v-btn>
      <v-btn v-if="editar" :loading="isUpdating" :disabled="bloqueiaBotoes" color="blue white" id="btn-rsn" style="color: white" depressed @click="salvar('false')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar
      </v-btn>
      <v-btn v-if="editar" :loading="isUpdating" :disabled="bloqueiaBotoes" color="orange white" style="color: white" id="btn-rsn" outlined @click="salvar('retornar')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="blue white" id="btn-rsn" style="color: white" outlined @click="salvar('true')" v-if="editar && direitoCriar">
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Novo
      </v-btn>
      <v-btn
        v-if="id != '' && direitoCriar && !anuncio.dtCancelamento"
        :loading="isUpdating"
        :disabled="bloqueiaBotoes"
        color="green"
        id="btn-rsn"
        style="color: white"
        outlined
        @click="copiar"
        title="Copia os dados para criação de novo anúncio"
      >
        <v-icon id="icon-rsn" left>autorenew</v-icon>Copiar
      </v-btn>

      <v-btn v-if="id != '' && direitoRecibo" :loading="isUpdating" :disabled="bloqueiaBotoes" color="cyan" id="btn-rsn" style="color: white" outlined @click="emitirRecibo">
        <v-icon id="icon-rsn" left>insert_drive_file</v-icon>Recibo
      </v-btn>

      <v-btn
        v-if="id != '' && permiteCancelar && direitoCancelar && !anuncio.dtCancelamento"
        :loading="isUpdating"
        :disabled="bloqueiaBotoes"
        color="red white"
        style="color: white"
        id="btn-rsn"
        depressed
        @click="cancelamento"
        title="Cancelar"
      >
        <v-icon id="icon-rsn" left>delete</v-icon>Cancelar
      </v-btn>
    </v-card-actions>

    <v-dialog v-model="dialogLogAlteracao" max-width="1200px">
      <v-card>
        <LogAlteracao :idPublicacao="idPublicacao" :dialogLogAlteracao.sync="dialogLogAlteracao" />
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfirm" persistent max-width="290">
      <v-card>
        <v-toolbar>
          <span class="headline">Confirmação</span>
        </v-toolbar>
        <br />
        <v-card-text><span style="color: black" v-html="msgConfirm"></span></v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="naoConfirmado">Não</v-btn>
          <v-btn color="green darken-1" text @click="confirmado">Sim</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogMensagem" max-width="400">
      <v-card>
        <v-toolbar>
          <span class="headline red--text">Importante!</span>
        </v-toolbar>
        <br />
        <v-card-text><span class="black--text" v-html="msgDialog"></span></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" @click="dialogMensagem = false">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <v-dialog v-model="dialogArte" max-width="400">
      <v-card>
        <v-form ref="formArte" v-model="arteValid" lazy-validation>
          <v-toolbar>
            <span class="headline">Arte da Publicação</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeArte">
              <v-icon color="darken-1">cancel</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-layout wrap>
              <v-flex xs12>
                <v-file-input
                  v-model="arteItem.file"
                  ref="file"
                  label="Selecione o arquivo"
                  hint="*Copie o caminho do arquivo ao selecionar"
                  persistent-hint=""
                  :rules="[value => !!value || 'Obrigatório']"
                ></v-file-input>
              </v-flex>
              <v-flex xs12><br /></v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="arteItem.caminho"
                  label="Caminho do arquivo"
                  hint="*Cole aqui o caminho do arquivo selecionado"
                  persistent-hint
                  color="blue-grey lighten-2"
                  :rules="[value => !!value || 'Obrigatório']"
                ></v-text-field>
              </v-flex>
              <v-flex xs12><br /></v-flex>
              <v-flex xs12>
                <v-switch
                  v-model="arteItem.todas"
                  :label="
                    `${
                      arteItem.todas == 0
                        ? 'Arte será incluída somente nesta publicação'
                        : 'Arte será incluída em TODAS as publicações'
                    }`
                  "
                ></v-switch>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" @click="closeArte">Retornar</v-btn>
            <v-btn color="blue darken-1" @click="saveArte">Atualizar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog> -->

    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<style scoped>
.fixed-bar {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 4em;
  z-index: 2;
}
</style>
<script>
import Parcela from "@/views/fin/Parcela.vue";
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
import LogAlteracao from "@/views/components/LogAlteracao.vue";
export default {
  components: {
    Parcela,
    LogAlteracao
  },
  data() {
    return {
      anuncio: {
        id: "",
        idSite: null,
        idCliente: null,
        idProduto: null,
        idCaderno: null,
        idFormatoCaderno: null,
        idDeterminacao: null,
        idColocacao: null,
        idSecao: null,
        status: "CORRENTE",
        dtCriacao: null,
        dtCancelamento: null,
        idPacote: null,
        colunas: 0,
        altura: 0,
        linha: 0,
        caracteres: 0,
        palavras: 0,
        autorizacao: null,
        titulo: null,
        textoAnuncio: null,
        textoFatura: "",
        online: 0,
        rede: 0,
        emailCobranca: 0,
        descComissao: 0,
        pi: null,
        emNegociacao: 0,
        bloquearPublicacao: 0,
        filaPublicacao: 0,
        usuario: this.$store.state.usuario.nome
      },
      validacao: {
        idSite: false,
        idCliente: false,
        idDeptoCliente: false,
        idProduto: false,
        idCaderno: false,
        idFormatoCaderno: false,
        idDeterminacao: false,
        idColocacao: false,
        idSecao: false,
        status: false,
        dtCriacao: false,
        dtCancelamento: false,
        idPacote: false,
        colunas: false,
        altura: false,
        linha: false,
        caracteres: false,
        palavras: false,
        autorizacao: false,
        descricao: false,
        titulo: false,
        textoAnuncio: false,
        online: false,
        rede: false,
        emailCobranca: false,
        descComissao: false,
        emNegociacao: false,
        bloquearPublicacao: false,
        filaPublicacao: false,
        vlrTaxado: false,
        carencia: false,
        idAgente: false,
        comissao: false,
        publicacoes: false,
        vlrBruto: false,
        agentes: false,
        condicoesEspeciais: false,
        parcelas: false,
        observacoes: false,
        permutasPagar: false,
        pacotes: false,
        idFormaPgto: false,
        idClienteCartao: false,
        idClienteConta: false,
        idClienteEndereco: false,
        idClienteCelesc: false,
        ajuste_valor: false,
        ajuste_vecto: false,
        idFormaPgtoParcela: false,
        dtVecto: false,
        mensagemErro: false
      },

      ultimoAnuncio: {},
      loadingUltAnu: false,
      ultimaAssinatura: {},
      loadingUltAss: false,

      dtCriacao: null,
      dtCriacaoUS: null,
      dtCriacaoBR: null,
      mnuDtCriacao: false,

      dtVecto: null,
      dtVectoUS: null,
      mnuDtVecto: false,

      vlrTabela: 0,
      vlrAjustado: 0,
      vlrCobrado: 0,
      dtInicio: null,
      dtFim: null,
      nroPublicacoes: 0,
      tipoCobranca: "",
      nroParcelas: 0,

      produtos: [],
      cadernos: [],
      formatos: [],
      determinacoes: [],
      colocacoes: [],
      secoes: [],

      headerPublicacoes: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Data Publicação", value: "dtPublicacao" },
        { text: "Status", value: "statusPublicacao" },
        { text: "Valor Bruto", value: "vlrBruto", align: "end" },
        { text: "Valor Líquido", value: "vlrLiquido", align: "end" }
      ],
      headerCondicoesEspeciais: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Condição Especial", value: "condicaoEspecial" }
      ],
      headerAgentes: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Agente/Agência", value: "agente" },
        { text: "Comissão(%)", value: "comissao", align: "end" }
      ],
      headerObservacoes: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Observação", value: "observacao" },
        { text: "Usuário", value: "usuario" },
        { text: "Registro", value: "dtRegistro" }
      ],
      headerPermutasPagar: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Índice", value: "indice" },
        { text: "Parcela", value: "numero" },
        { text: "Vencimento", value: "dtVecto" },
        { text: "Valor", value: "valor", align: "end" }
      ],
      headerPacotes: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Criação", value: "dtCriacao" },
        { text: "Cobrado", value: "vlrLiquido", align: "end" },
        { text: "Crédito", value: "vlrCredito", align: "end" },
        { text: "Utilizado", value: "vlrUtilizado", align: "end" },
        { text: "Saldo", value: "vlrSaldo", align: "end" }
      ],
      headerParcelas: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Índice", value: "indice" },
        { text: "Parcela", value: "numero" },
        { text: "Status", value: "status" },
        { text: "Forma de Pagamento", value: "formaPagamento" },
        { text: "Vencimento Original", value: "dtVectoOriginal" },
        { text: "Promessa", value: "dtPromessa" },
        { text: "Lote", value: "idLoteFaturamento" },
        { text: "NF", value: "idNF" },
        { text: "Valor", value: "valor", align: "end" }
      ],
      rodapePermutasPagar: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50, 100]
      },
      rodapePacotes: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50, 100]
      },

      produtoSelecionado: {},
      cadernoSelecionado: {},
      formatoSelecionado: {},
      determinacaoSelecionada: {},
      colocacaoSelecionada: {},
      secaoSelecionada: {},
      textoSelecionado: {},

      agentesFormato: [],
      agentesOperacao: [],

      observacoesAnuncio: [],
      textos: [],

      permutasPagar: [],
      selectAll: false,
      vlrPermutado: 0,

      pacotes: [],
      selectAllPac: false,
      vlrPacotes: 0,

      condicoesEspeciais: [],
      condicoesEspeciaisFormato: [],
      condicaoEspecialItem: {},

      formasPagamentoFormato: [],
      parcelas: [],
      publicacoes: [],
      idsPublicacao: [],

      clientes: [],
      departamentosCliente: [],
      clienteSelecionado: {},

      formaPagamentoSelecionada: {
        idFormaPgto: null,
        nome: "",
        tipo: "",
        maxParcelas: 0,
        bloqueioPublicacao: 0
      },
      formaPagamentoSelecionar: false,
      parcelamentoSelecionado: {},
      parcelamento: [],

      cartoesCliente: [],
      celescsCliente: [],
      contasCliente: [],
      enderecosCobrancaCliente: [],

      dialogLogAlteracao: false,
      idPublicacao: "",

      //* Dados para o componente DialogParcela
      dialogParcela: false,
      idParcela: "",

      dialogMensagem: false,
      msgDialog: "",

      dialogArte: false,
      arteValid: true,
      arteIndex: -1,
      arteItem: {
        file: null,
        caminho: null,
        todas: 0
      },
      defaultArteItem: {
        file: null,
        caminho: null,
        todas: 0
      },

      agenteSelecionado: {},
      dialogAgente: false,
      agenteValid: true,
      agenteIndex: -1,
      agenteItem: {
        id: null,
        idAgente: null,
        agente: "",
        comissao: 0,
        descMaximoCom: 0
      },
      defaultAgenteItem: {
        id: null,
        idAgente: null,
        agente: "",
        comissao: 0,
        descMaximoCom: 0
      },

      dialogObservacao: false,
      observacaoValid: true,
      observacaoIndex: -1,
      observacaoItem: {
        id: null,
        observacao: "",
        situacao: 1
      },
      defaultObservacaoItem: {
        id: null,
        observacao: "",
        situacao: 1
      },

      condicaoEspecialSelecionada: {},

      dialogAjuste: false,
      ajusteValid: true,
      ajusteIndex: -1,
      ajusteItem: {
        valor: 0,
        dtVectoOriginal: null,
        dtVectoO: null, //* para apresentação
        idFormaPgto: null,
        idClienteCelesc: null,
        idClienteCartao: null,
        idClienteConta: null,
        idClienteEndereco: null
      },
      ajusteItemDtVectoO: null,
      ajusteItemDtVectoOUS: null,
      mnuAjusteItemDtVectoO: false,
      formaPgtoParcelaSelecionada: {
        id: null,
        nome: "",
        tipo: "",
        bloqueioPublicacao: 0
      },
      formasPgtoParcela: [],

      publicacoesUS: [],
      tabelaAnuncio: {},
      vlrTaxado: 0,
      carencia: 0,
      limiteCarencia: 90,

      vlrTaxadoAnterior: 0,
      carenciaAnterior: 0,
      colunasAnterior: 0,
      alturaAnterior: 0,
      tituloAnterior: "",
      textoAnterior: "",
      secaoAnterior: null,
      negociacaoAnterior: 0,

      emCobranca: false,
      nfEmitida: false,
      editar: true,
      permiteCancelar: true,
      editarParcelas: true,
      direitoCriar: false,
      direitoCancelar: false,
      direitoAnunciante: false,
      direitoAssinatura: false,
      direitoLog: false,
      direitoOperacao: false,
      direitoPacote: false,
      direitoTaxar: false,
      direitoFormaPgto: false,
      direitoNegociacao: false,
      direitoPublicacao: false,
      direitoParcelas: false,
      direitoComissao: false,
      direitoDescComissao: false,
      direitoRecibo: false,

      inicial: false,
      dialogConfirm: false,
      tipoConfirm: "",
      msgConfirm: "",

      idClienteCartao: null,
      idClienteConta: null,
      idClienteEndereco: null,
      idClienteCelesc: null,

      diasPublicacao: [],
      diasSelecionados: null,

      carregandoCli: true,
      idSitePrincipal: null,
      painel: [0, 0, 0, 0, 0, 0, 0],
      tab: null,
      search: "",
      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,
      loadingClientes: false,

      isUpdating: false,
      bloqueiaBotoes: false
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    getItemActions() {
      return "item.actions";
    },
    getItemNumero() {
      return "item.numero";
    },
    getItemDtPublicacao() {
      return "item.dtPublicacao";
    },
    getItemVlrBruto() {
      return "item.vlrBruto";
    },
    getItemVlrLiquido() {
      return "item.vlrLiquido";
    },
    getItemComissao() {
      return "item.comissao";
    },
    getItemDtVectoOriginal() {
      return "item.dtVectoOriginal";
    },
    getItemDtPromessa() {
      return "item.dtPromessa";
    },
    getItemValor() {
      return "item.valor";
    },
    getItemSituacao() {
      return "item.situacao";
    },
    getItemDtRegistro() {
      return "item.dtRegistro";
    },
    getItemStatusPublicacao() {
      return "item.statusPublicacao";
    },
    getItemDtVecto() {
      return "item.dtVecto";
    },
    getItemDtCriacao() {
      return "item.dtCriacao";
    },
    getItemVlrCredito() {
      return "item.vlrCredito";
    },
    getItemVlrUtilizado() {
      return "item.vlrUtilizado";
    },
    getItemVlrSaldo() {
      return "item.vlrSaldo";
    },

    async copiar() {
      if (!this.produtoSelecionado.situacao) {
        this.mensagem = "Produto encontra-se inativo";
        this.snackErro = true;
        return;
      }
      if (!this.cadernoSelecionado.situacao) {
        this.mensagem = "Caderno encontra-se inativo";
        this.snackErro = true;
        return;
      }
      if (!this.formatoSelecionado.formatoSituacao || !this.formatoSelecionado.situacao) {
        this.mensagem = "Formato encontra-se inativo";
        this.snackErro = true;
        return;
      }
      if (this.determinacaoSelecionada && this.determinacaoSelecionada.id && !this.determinacaoSelecionada.situacao) {
        this.mensagem = "Determinação encontra-se inativa";
        this.snackErro = true;
        return;
      }
      if (this.colocacaoSelecionada && this.colocacaoSelecionada.id && !this.colocacaoSelecionada.situacao) {
        this.mensagem = "Colocação encontra-se inativa";
        this.snackErro = true;
        return;
      }
      await this.carregarTabela();
      if (!this.tabelaAnuncio || !this.tabelaAnuncio.id) {
        return;
      }

      this.$router.push("/comercial/anuncio");

      this.id = "";
      this.anuncio.id = null;
      this.anuncio.idOperacao = null;
      this.anuncio.status = "CORRENTE";
      this.anuncio.usuario = this.$store.state.usuario.nome;
      this.dtCriacaoUS = util.dateToStringUS(new Date());
      this.anuncio.dtOperacao = null;
      this.anuncio.idUsuario = null;
      this.editar = true;

      if (this.vlrPermutado || this.vlrPacotes) {
        //* Se é permuta ou pacote, remove as parcelas para tentar associar novamente
        this.parcelas = [];
        this.vlrPermutado = 0;
        this.vlrPacotes = 0;
        this.formaPagamentoSelecionada = {
          idFormaPgto: null,
          nome: "",
          tipo: "",
          maxParcelas: 0,
          bloqueioPublicacao: 0
        };
      }

      var dtHoje = util.parseDateUS(util.dateToStringUS(new Date()));

      var mesesDiff = 0;
      if (this.parcelas.length > 0) {
        //* Busca a diferença em meses entre a data atual e o vencimento da primeira parcela
        var dtPrimeira = util.parseDateUS(this.parcelas[0].dtVectoOriginal);
        mesesDiff = (dtHoje.getTime() - dtPrimeira.getTime()) / (1000 * 60 * 60 * 24) / 30;
      }

      this.parcelas.forEach((element) => {
        element.id = null;
        element.idOperacao = null;
        element.indice = null;
        if (util.isFormaPgtoValorZero(this.formaPagamentoSelecionada.tipo)) {
          element.status = "QUITADA";
          element.dtQuitacao = new Date();
        } else {
          element.status = "ABERTA";
          element.dtQuitacao = null;
        }
        element.statusCobranca = 0;
        // element.idLoteFaturamento = null;
        // element.dtVectoRemessa = null
        // element.valorRemessa=null
        // element.valorTaxaRemessa=null
        // element.dtBloqueio=null
        // element.dtPromessa=null
        // element.nossoNumero=null
        if (mesesDiff > 0) {
          var dtVectoOriginal = util.parseDateUS(element.dtVectoOriginal);
          dtVectoOriginal.setMonth(dtVectoOriginal.getMonth() + mesesDiff);
          element.dtVectoOriginal = util.dateToStringUS(dtVectoOriginal);
          element.dtVecto = util.dateToStringUS(dtVectoOriginal);
        }
        var dtVecto = util.parseDateUS(element.dtVecto);
        if (dtVecto < dtHoje) {
          element.dtVectoOriginal = util.dateToStringUS(dtHoje);
          element.dtVecto = util.dateToStringUS(dtHoje);
        }
      });

      var publicacoesTemp = [];
      this.dtInicio = null;
      this.dtFim = null;
      var limpar = false;
      this.publicacoesUS = [];
      this.publicacoes.forEach((element) => {
        var elementTemp = element;
        if (util.parseDateUS(element.dtPublicacao) > dtHoje) {
          if (!this.dtInicio) {
            this.dtInicio = element.dtPublicacao;
          }
          this.dtFim = element.dtPublicacao;
          elementTemp.id = null;
          elementTemp.idAnuncio = null;
          elementTemp.statusPublicacao = 0;
          elementTemp.idUsuario = null;
          elementTemp.dtAlteracao = null;
          // elementTemp.paginaPublicado = null;
          // elementTemp.paginaTop = null;
          // elementTemp.paginaLeft = null;
          // elementTemp.paginaBottom = null;
          // elementTemp.paginaRight = null;
          // elementTemp.idFrame = null;
          // elementTemp.idCadernoCorrente = null;
          publicacoesTemp.push(elementTemp);
          this.publicacoesUS.push(util.dateToStringUS(element.dtPublicacao));
        } else {
          limpar = true;
        }
      });
      this.publicacoes = publicacoesTemp;

      var agentesTemp = [];
      this.agentesOperacao.forEach((element) => {
        this.agentesFormato.forEach((agente) => {
          if (element.idAgente == agente.idAgente && agente.situacao) {
            element.id = null;
            element.idOperacao = null;
            agentesTemp.push(element);
          }
        });
      });
      this.agentesOperacao = agentesTemp;

      var condicoesTemp = [];
      this.condicoesEspeciais.forEach((element) => {
        if (element.condicaoEspecialSituacao) {
          element.id = null;
          condicoesTemp.push(element);
        }
      });
      this.condicoesEspeciais = condicoesTemp;

      this.observacoesAnuncio = [];
      // .forEach(element => {
      //   element.id = null;
      // });

      this.carregandoCli = false;
      this.inicial = false;
      var clienteTemp = this.clienteSelecionado;
      this.clienteSelecionado = {};
      this.clienteSelecionado = clienteTemp;

      if (limpar) {
        //* Se não importou todas as publicações, ajusta as parcelas
        this.limpaParcelas();
      }
    },

    async salvar(novo, confirmado) {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }
      if (!this.tabelaAnuncio || !this.tabelaAnuncio.id) {
        this.mensagem = "Não existe tabela de preços para esta configuração de anúncio";
        this.snackErro = true;
        return;
      }

      if (!this.id) {
        if (this.dtCriacaoUS != null) {
          if (util.valida_data(this.dtCriacaoUS)) {
            this.anuncio.dtCriacao = this.dtCriacaoUS;
            this.anuncio.dtOperacao = this.dtCriacaoUS;
          } else {
            this.validacao.dtCriacao = "Data inválida";
            this.mensagem = "Verifique os campos indicados em vermelho neste formulário"; //não deveria cair aqui
            this.snackErro = true;
            return;
          }
        } else {
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário"; //não deveria cair aqui
          this.snackErro = true;
          return;
        }
      }

      var erro = false;
      if (this.tabelaAnuncio.tipoCobranca == 1 || this.tabelaAnuncio.tipoCobranca == 3) {
        //* cm x col ou alt x larg
        //* Confere colunas/largura
        var colMax = 5000;
        if (this.formatoSelecionado.tipo != "S") {
          colMax =
            this.tabelaAnuncio.tipoCobranca == 3
              ? this.cadernoSelecionado.paginaLargura - this.cadernoSelecionado.margemEsquerda - this.cadernoSelecionado.margemDireita
              : this.cadernoSelecionado.numeroColunas * 2;
        }
        if (parseFloat(this.anuncio.colunas) < 1 || parseFloat(this.anuncio.colunas) > colMax) {
          this.validacao.colunas = this.tabelaAnuncio.tipoCobranca == 1 ? "Número de colunas inválido" : "Largura inválida";
          erro = true;
        }
        //* Confere altura
        var altMax = 5000;
        if (this.formatoSelecionado.tipo != "S") {
          altMax = this.cadernoSelecionado.paginaAltura - this.cadernoSelecionado.margemSuperior - this.cadernoSelecionado.margemInferior;
        }
        if (this.anuncio.altura > altMax) {
          this.validacao.colunas = "Altura inválida";
          erro = true;
        }
      }
      if (this.publicacoes.length == 0) {
        this.validacao.publicacoes = "Selecione pelo menos 1 data de publicação";
        erro = true;
      }
      if (this.agentesOperacao.length == 0) {
        this.validacao.agentes = "Selecione pelo menos 1 agente/agência";
        erro = true;
      }
      if (this.agentesOperacao.length > 2) {
        this.validacao.agentes = "Máximo 2 agentes de venda por anúncio (1º Agência, 2º Agente)";
        erro = true;
      }
      if (!this.anuncio.idSecao && !["P", "S"].includes(this.formatoSelecionado.tipo)) {
        //* Anúncio de classificados deve ter seção
        this.validacao.idSecao = "Selecione a Seção";
        erro = true;
      }

      if (this.parcelas.length == 0 && this.id == "" && this.vlrPermutado && this.vlrPermutado < this.vlrAjustado) {
        //* Se tem permuta e esta não consumiu o valor total, solicita parcelamento do restante
        this.validacao.parcelas = "Selecione a forma de pagamento e o parcelamento para gerar as parcelas do valor restante não permutado";
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      if (this.vlrPacotes && this.vlrPacotes < this.vlrAjustado) {
        this.validacao.pacotes = "Selecione mais pacotes ou cadastre um novo pacote para o cliente para liquidar o valor total";
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      if (this.vlrPermutado && this.formaPagamentoSelecionada.tipo == "PERMUTA_RECEBER") {
        this.formaPagamentoSelecionada = {
          idFormaPgto: null,
          nome: "",
          tipo: "",
          maxParcelas: 0,
          bloqueioPublicacao: 0
        };
        this.mensagem = "Defina o valor líquido igual ao total permutado ou selecione outra forma de pagamento para liquidar o valor restante";
        this.snackErro = true;
        return;
      }

      if (this.id == "" && this.vlrPermutado) {
        var existePP = false;
        this.parcelas.forEach((element) => {
          if (element.formaPagamentoTipo == "PERMUTA_RECEBER") {
            existePP = true;
          }
        });
        if (!existePP) {
          //* Cria a parcela para PERMUTA A PAGAR
          var dtVecto = new Date();
          var vlrPm = this.vlrPermutado > this.vlrAjustado ? this.vlrAjustado : this.vlrPermutado;
          var parcela = {
            id: null,
            idOperacao: null,
            indice: null,
            numero: this.parcelas.length + 1,
            status: "QUITADA",
            idFormaPgto: 17,
            formaPagamento: "Permuta a Receber",
            formaPagamentoTipo: "PERMUTA_RECEBER",
            dtVectoOriginal: dtVecto,
            dtVecto: dtVecto,
            dtVectoO: util.formatDate(dtVecto),
            dtQuitacao: dtVecto,
            idClienteCartao: null,
            idClienteConta: null,
            idClienteEndereco: null,
            idClienteCelesc: null,
            valor: vlrPm,
            liquido: util.formatCurrency(this.vlrPm),
            descAcr: 0,
            obsCobranca: util.removeEspeciais(util.removeAcentos(this.anuncio.textoFatura)),
            ajustada: 0
          };
          this.parcelas.push(parcela);
        }
      }

      if (this.id == "" && this.vlrPacotes) {
        var existePac = false;
        this.parcelas.forEach((element) => {
          if (element.formaPagamentoTipo == "PACOTE") {
            existePac = true;
          }
        });
        if (!existePac) {
          //* Cria a parcela para PACOTE
          var dtVectoPac = new Date();
          var vlrPac = this.vlrPacotes > this.vlrAjustado ? this.vlrAjustado : this.vlrPacotes;
          this.parcelas = [];
          var parcelaPac = {
            id: null,
            idOperacao: null,
            indice: null,
            numero: this.parcelas.length + 1,
            status: "QUITADA",
            idFormaPgto: 15,
            formaPagamento: "Pacote",
            formaPagamentoTipo: "PACOTE",
            dtVectoOriginal: dtVectoPac,
            dtVecto: dtVectoPac,
            dtVectoO: util.formatDate(dtVectoPac),
            dtQuitacao: dtVectoPac,
            idClienteCartao: null,
            idClienteConta: null,
            idClienteEndereco: null,
            idClienteCelesc: null,
            valor: vlrPac,
            liquido: util.formatCurrency(this.vlrPac),
            descAcr: 0,
            obsCobranca: util.removeEspeciais(util.removeAcentos(this.anuncio.textoFatura)),
            ajustada: 0
          };
          this.parcelas.push(parcelaPac);
        }
      }

      if (this.parcelas.length == 0) {
        this.validacao.parcelas = "Selecione a forma de pagamento e o parcelamento para gerar as parcelas";
        erro = true;
      }

      var descMaximo = 100;
      this.agentesOperacao.forEach((element) => {
        descMaximo = Math.min(element.descMaximoCom, descMaximo);
      });
      var somaParc = 0;
      var bloqueioPublicacao = 0;
      this.parcelas.forEach((element) => {
        if (element.bloqueioPublicacao) {
          bloqueioPublicacao = 1;
        }
        somaParc += parseFloat(element.valor) + parseFloat(element.descAcr);
      });
      var percDesconto = 100 - (somaParc / this.vlrTabela) * 100;
      if (percDesconto > descMaximo) {
        this.validacao.agentes = "Desconto aplicado (" + util.formatCurrency(percDesconto) + "%) não permitido para o Agente/Agência (" + util.formatCurrency(descMaximo) + "%)";
        if (!confirmado) {
          this.tipoConfirm = "LIMITE_AGENTE";
          this.msgConfirm = this.validacao.agentes + ". <br /><br />Salvar mesmo assim?";
          this.dialogConfirm = true;
          return;
        }
        this.anuncio.emNegociacao = 1;
        this.anuncio.bloquearPublicacao = 1;
        await this.ajustaEmNegociacao();
      }

      if (!this.id && bloqueioPublicacao) {
        //* Se é anúncio novo e pelo menos 1 das formas de pagamento das parcelas tiver bloqueio, bloqueia (somente a publicação)
        this.anuncio.bloquearPublicacao = 1;
        this.anuncio.filaPublicacao = 1;
      }

      var vlrMinimo = this.formatoSelecionado.valorMinimo;
      var vlrMaximo = this.formatoSelecionado.valorMaximo;
      if (somaParc < vlrMinimo || somaParc > vlrMaximo) {
        this.validacao.mensagemErro = "Valor do anúncio está fora da faixa: " + util.formatCurrency(vlrMinimo) + " a " + util.formatCurrency(vlrMaximo);
        erro = true;
      }

      if (erro) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      this.isUpdating = true;
      try {
        var anuncioCommit = {
          idSite: this.idSitePrincipal,
          dtCriacao: this.anuncio.dtCriacao,
          idFormatoCaderno: this.anuncio.idFormatoCaderno,
          idDeterminacao: this.anuncio.idDeterminacao,
          idColocacao: this.anuncio.idColocacao,
          idSecao: this.anuncio.idSecao,
          status: "CORRENTE",
          cor: this.formatoSelecionado.cor,
          colunas: this.anuncio.colunas,
          altura: this.anuncio.altura,
          caracteres: this.anuncio.caracteres,
          palavras: this.anuncio.palavras,
          autorizacao: this.anuncio.autorizacao,
          titulo: this.anuncio.titulo,
          textoAnuncio: this.anuncio.textoAnuncio,
          descComissao: this.anuncio.descComissao,
          emNegociacao: this.anuncio.emNegociacao,
          bloquearPublicacao: this.anuncio.bloquearPublicacao,
          filaPublicacao: this.anuncio.filaPublicacao,
          tipoCobranca: this.tabelaAnuncio.tipoCobranca,
          tipoProduto: this.produtoSelecionado.tipo,
          produto: this.produtoSelecionado.nome
        };

        var vlrCobrado = 0; //* valor cobrado original do anúncio
        this.parcelas.forEach((element) => {
          vlrCobrado += parseFloat(element.valor) + parseFloat(element.descAcr);
          if (this.anuncio.emNegociacao) {
            element.status = "BLOQUEADA";
          } else if (this.negociacaoAnterior) {
            element.status = "ABERTA";
          }
        });
        // Calcula o valor líquido proporcional das publicações
        var somaLiquido = 0;
        this.publicacoes.forEach((element) => {
          if (element.statusPublicacao != 4) {
            element.vlrLiquido = (element.vlrBruto / this.vlrTabela) * vlrCobrado;
            element.vlrLiquido = parseFloat(element.vlrLiquido.toFixed(2));
            somaLiquido += element.vlrLiquido;
          }
        });
        if (somaLiquido > vlrCobrado) {
          this.publicacoes[0].vlrLiquido -= somaLiquido - vlrCobrado;
        } else if (vlrCobrado > somaLiquido) {
          this.publicacoes[0].vlrLiquido += vlrCobrado - somaLiquido;
        }

        var operacaoCommit = {};
        var publicacoesCommit = [];
        var parcelasCommit = [];

        var retorno = "";
        if (this.id) {
          operacaoCommit = {
            id: this.anuncio.idOperacao,
            // idCliente: this.anuncio.idCliente,
            // idDeptoCliente: this.anuncio.idDeptoCliente,
            dtCriacao: this.anuncio.dtCriacao,
            dtOperacao: this.anuncio.dtOperacao,
            vlrBruto: this.vlrTabela,
            vlrTaxado: this.anuncio.vlrTaxado,
            //vlrLiquido: this.anuncio.vlrLiquido, - se usar o original do anúncio, não irá gravar alterações
            vlrLiquido: vlrCobrado,
            //frete: this.anuncio.frete,
            duplicatas: this.anuncio.duplicatas,
            descricao: this.anuncio.textoFatura,
            nroParcelas: this.parcelas.length,
            mensal: 0,
            confirmada: 1,
            formaPagamentoTipo: this.formaPagamentoSelecionada.tipo
          };
          this.publicacoes.forEach((element) => {
            var publicacao = {
              id: element.id,
              vlrBruto: element.vlrBruto,
              vlrLiquido: element.vlrLiquido
            };
            if (element.statusPublicacao == 2) {
              //* Só altera o status se for para 2
              publicacao.statusPublicacao = 2;
            }
            publicacoesCommit.push(publicacao);
          });
          anuncioCommit.publicacoes = publicacoesCommit;

          if (!this.parcelas[0].id) {
            //* Se parcelas foram recriadas, atualiza líquido das publicações, operação e reenvia parcelas
            operacaoCommit = {
              id: this.anuncio.idOperacao,
              dtCriacao: this.anuncio.dtCriacao,
              dtOperacao: this.anuncio.dtOperacao,
              vlrBruto: this.vlrTabela,
              vlrTaxado: this.vlrTaxado,
              //vlrLiquido: this.vlrTaxado ? this.vlrTaxado : vlrCobrado, - deve ser sempre a soma do que será quitado, senão ficará saldo no cliente
              vlrLiquido: vlrCobrado,
              //frete: this.anuncio.frete,
              duplicatas: this.anuncio.duplicatas,
              descricao: this.anuncio.textoFatura,
              nroParcelas: this.parcelas.length,
              mensal: 0,
              confirmada: 1,
              formaPagamentoTipo: this.formaPagamentoSelecionada.tipo
            };

            this.parcelas.forEach((element) => {
              parcelasCommit.push({
                id: element.id,
                indice: element.indice,
                numero: element.numero,
                status:
                  util.isFormaPgtoValorZero(element.formaPagamentoTipo) || (this.vlrPermutado && element.formaPagamentoTipo == "PERMUTA_RECEBER") || this.vlrPacotes
                    ? "QUITADA"
                    : element.status == "BLOQUEADA"
                    ? "BLOQUEADA"
                    : "ABERTA",
                dtQuitacao:
                  util.isFormaPgtoValorZero(element.formaPagamentoTipo) || (this.vlrPermutado && element.formaPagamentoTipo == "PERMUTA_RECEBER") || this.vlrPacotes
                    ? element.dtQuitacao
                    : null,
                idFormaPgto: element.idFormaPgto,
                formaPagamentoTipo: element.formaPagamentoTipo,
                dtVectoOriginal: element.dtVectoOriginal,
                dtVecto: element.dtVectoOriginal,
                idClienteCartao: this.idClienteCartao ? this.idClienteCartao : element.idClienteCartao,
                idClienteConta: this.idClienteConta ? this.idClienteConta : element.idClienteConta,
                idClienteEndereco: this.idClienteEndereco ? this.idClienteEndereco : element.idClienteEndereco,
                idClienteCelesc: this.idClienteCelesc ? this.idClienteCelesc : element.idClienteCelesc,
                valorBruto: element.vlrBruto,
                valor: element.valor,
                descAcr: element.descAcr,
                obsCobranca: element.obsCobranca,
                ajustada: element.ajustada
              });
            });
            anuncioCommit.parcelas = parcelasCommit;
          }

          anuncioCommit.operacao = operacaoCommit;
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "com/anuncio/principal/" + this.id, anuncioCommit);
        } else {
          operacaoCommit = {
            idSite: this.anuncio.idSite,
            tipo: 1,
            idCliente: this.anuncio.idCliente,
            idDeptoCliente: this.anuncio.idDeptoCliente,
            dtCriacao: this.anuncio.dtCriacao,
            dtOperacao: this.anuncio.dtOperacao,
            origem: "ANU",
            vlrBruto: this.vlrTabela,
            //vlrLiquido: this.vlrTaxado ? this.vlrTaxado : vlrCobrado, - deve ser sempre a soma do que será quitado, senão ficará saldo no cliente
            vlrLiquido: vlrCobrado,
            vlrTaxado: this.vlrTaxado,
            nroParcelas: this.parcelas.length,
            //frete: 9,
            duplicatas: 1,
            mensal: 0,
            confirmada: 1,
            descricao: this.anuncio.textoFatura,
            idUsuario: this.$store.state.usuario.id
          };
          anuncioCommit.operacao = operacaoCommit;

          this.publicacoes.forEach((element) => {
            publicacoesCommit.push({
              dtPublicacao: element.dtPublicacao,
              vlrBruto: element.vlrBruto,
              vlrLiquido: element.vlrLiquido,
              arteAnuncio: element.arteAnuncio
            });
          });
          anuncioCommit.publicacoes = publicacoesCommit;

          var agentesCommit = [];
          this.agentesOperacao.forEach((element) => {
            agentesCommit.push({
              idAgente: element.idAgente,
              comissao: this.vlrPacotes ? 0 : element.comissao
            });
          });
          anuncioCommit.agentes = agentesCommit;

          var condicoesEspeciaisCommit = [];
          this.condicoesEspeciais.forEach((element) => {
            condicoesEspeciaisCommit.push({
              idFormatoCondicaoEspecial: element.idFormatoCondicaoEspecial
            });
          });
          anuncioCommit.condicoesEspeciais = condicoesEspeciaisCommit;

          var observacoesCommit = [];
          this.observacoesAnuncio.forEach((element) => {
            observacoesCommit.push({
              observacao: element.observacao,
              idUsuario: element.idUsuario,
              situacao: 1
            });
          });
          anuncioCommit.observacoes = observacoesCommit;

          this.parcelas.forEach((element) => {
            parcelasCommit.push({
              id: element.id,
              indice: element.indice,
              numero: element.numero,
              status:
                util.isFormaPgtoValorZero(element.formaPagamentoTipo) || (this.vlrPermutado && element.formaPagamentoTipo == "PERMUTA_RECEBER") || this.vlrPacotes
                  ? "QUITADA"
                  : element.status == "BLOQUEADA"
                  ? "BLOQUEADA"
                  : "ABERTA",
              dtQuitacao:
                util.isFormaPgtoValorZero(element.formaPagamentoTipo) || (this.vlrPermutado && element.formaPagamentoTipo == "PERMUTA_RECEBER") || this.vlrPacotes
                  ? element.dtQuitacao
                  : null,
              idFormaPgto: element.idFormaPgto,
              formaPagamentoTipo: element.formaPagamentoTipo,
              dtVectoOriginal: element.dtVectoOriginal,
              dtVecto: element.dtVectoOriginal,
              idClienteCartao: this.idClienteCartao ? this.idClienteCartao : element.idClienteCartao,
              idClienteConta: this.idClienteConta ? this.idClienteConta : element.idClienteConta,
              idClienteEndereco: this.idClienteEndereco ? this.idClienteEndereco : element.idClienteEndereco,
              idClienteCelesc: element.idClienteCelesc ? element.idClienteCelesc : this.idClienteCelesc,
              valorBruto: element.vlrBruto,
              valor: element.valor,
              descAcr: element.descAcr,
              obsCobranca: util.removeEspeciais(util.removeAcentos(this.anuncio.textoFatura)),
              ajustada: element.ajustada
            });
          });
          anuncioCommit.parcelas = parcelasCommit;

          var permutasCommit = [];
          if (this.vlrPermutado) {
            operacaoCommit.vlrPermutado = this.vlrPermutado > this.vlrAjustado ? this.vlrAjustado : this.vlrPermutado;

            const vlr = parseFloat(this.vlrTaxado) > 0 ? parseFloat(this.vlrTaxado) : this.vlrAjustado;
            var vlrP = 0;
            this.permutasPagar.forEach((element) => {
              if (element.selecionado) {
                if (vlrP < vlr) {
                  permutasCommit.push({ idParcela: element.id });
                }
                vlrP += element.valor + element.descAcr;
              }
            });
          }
          anuncioCommit.permutas = permutasCommit;

          var pacotesCommit = [];
          if (this.vlrPacotes) {
            operacaoCommit.vlrPacotes = this.vlrPacotes > this.vlrAjustado ? this.vlrAjustado : this.vlrPacotes;

            var vlrLimite = parseFloat(this.vlrTaxado) > 0 ? parseFloat(this.vlrTaxado) : this.vlrAjustado;
            this.pacotes.forEach((element) => {
              if (element.selecionado) {
                if (vlrLimite > 0) {
                  var pacoteCommit = {
                    idPacote: element.id,
                    vlrUtilizado: Math.min(element.vlrSaldo, vlrLimite)
                  };
                  pacotesCommit.push(pacoteCommit);
                  vlrLimite -= element.vlrSaldo;
                }
              }
            });
          }
          anuncioCommit.pacotes = pacotesCommit;

          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "com/anuncio/novo?idSite=" + this.idSitePrincipal, anuncioCommit);
          this.id = retorno.data.id;
          this.anuncio.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "false") {
          //* É melhor recarregar toda a tela, pois há muitos parâmetros a serem ajustados aqui
          this.$router.push("/comercial/anuncio/" + this.id);
          this.$router.go("/comercial/anuncio/" + this.id);
        } else if (novo == "true") {
          this.$router.push("/comercial/anuncio");
          this.$router.go("/comercial/anuncio");
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/comercial/anuncios");
          }, 2000);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          if (this.validacao.vlrBruto) {
            this.validacao.publicacoes = this.validacao.vlrBruto;
          }
          if (this.validacao.pacotes && this.validacao.pacotes.indexOf("Pacote [") > -1) {
            this.carregarPacotesCliente();
          }
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },

    async cancelamento() {
      var publicado = false;
      for (let index = 0; index < this.publicacoes.length; index++) {
        const element = this.publicacoes[index];
        if (element.statusPublicacao == 3) {
          publicado = true;
          break;
        }
      }
      this.tipoConfirm = "CANCELAR";
      this.msgConfirm =
        "Tem certeza que deseja CANCELAR este anúncio? <span style='color:red'>(" + (publicado ? "pois já foi PUBLICADO - " : "") + "esta ação NÃO PODE ser desfeita)<span>";
      this.dialogConfirm = true;
    },
    async cancelar() {
      try {
        await api.put(process.env.VUE_APP_URL_WEB_API + "com/anuncio/cancelar/" + this.id);
        //* Recarrega a tela, pois algumas publicações e parcelas serão canceladas
        this.$router.push("/comercial/anuncio/" + this.id);
        this.$router.go("/comercial/anuncio/" + this.id);
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },

    async ajustaEmNegociacao() {
      if (this.id) {
        try {
          await api.put(process.env.VUE_APP_URL_WEB_API + "com/anuncio/negociacao/" + this.id, { emNegociacao: this.anuncio.emNegociacao });
          if (this.parcelas.length > 0) {
            if (this.parcelas[0].id) {
              //* Se parcelas estavam na base, recarrega
              this.carregarParcelasAnuncio();
            }
          }
          this.mensagem = "Cobrança " + (this.anuncio.emNegociacao ? "Bloqueada" : "Liberada");
          this.snackbar = true;
        } catch (e) {
          if (this.parcelas.length > 0) {
            if (this.parcelas[0].id) {
              //* Se parcelas estavam na base, recarrega
              this.carregarParcelasAnuncio();
            }
          }
          this.mensagem =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.snackErro = true;
        }
      }
    },

    async ajustaBloquearPublicacao() {
      if (this.id) {
        if (this.anuncio.bloquearPublicacao && this.dtFim && util.parseDateUS(this.dtFim) < new Date()) {
          this.mensagem = "Anúncio EXPIRADO";
          this.snackErro = true;
          setTimeout(() => {
            this.anuncio.bloquearPublicacao = 0;
          }, 300);
        } else {
          try {
            await api.put(process.env.VUE_APP_URL_WEB_API + "com/anuncio/publicacao/" + this.id, { bloquearPublicacao: this.anuncio.bloquearPublicacao });
            this.mensagem = "Publicação " + (this.anuncio.bloquearPublicacao ? "Bloqueada" : "Liberada");
            this.snackbar = true;
          } catch (e) {
            this.mensagem =
              "ERRO: " +
              (e.toString().includes("status code 400")
                ? "SESSÃO EXPIRADA"
                : e.response && e.response.data && e.response.data.length > 0
                ? e.response.data[0].mensagem
                : "Indeterminado, verifique sua conexão");
            this.snackErro = true;
          }
        }
      }
    },

    visualizarRenovacao() {
      window.open("/assinaturas/assinatura/" + this.ultimaAssinatura.id + "/" + this.ultimaAssinatura.idRenovacao, "_blank");
    },
    visualizarAnuncio() {
      window.open("/comercial/anuncio/" + this.ultimoAnuncio.id, "_blank");
    },
    visualizarPacote(item) {
      window.open("/comercial/pacote/" + item.id, "_blank");
    },
    visualizarOperacao(item) {
      window.open("/financeiro/operacao/pagar/" + item.idOperacao, "_blank");
    },
    visualizarParcelas() {
      window.open("/financeiro/parcelas/receber?idAnuncio=" + this.anuncio.id, "_blank");
    },

    //função realiza focus
    focusParcelas() {
      setTimeout(() => {
        if (this.$refs.focusAbaParcela) {
          this.$refs.focusAbaParcela.focus();
        }
      }, 500);
    },

    async conferePosicao() {
      try {
        if (this.$store.state.usuario.site_conferePosicao) {
          if (this.determinacaoSelecionada && this.determinacaoSelecionada.id && this.colocacaoSelecionada && this.colocacaoSelecionada.id && this.publicacoesUS.length > 0) {
            if (
              this.determinacaoSelecionada.nome == "INDETERMINADA"
              //&& this.colocacaoSelecionada.nome == "SEM COLOCAÇÃO"
            ) {
              return;
            }
            var datas = [];
            this.publicacoesUS.forEach((element) => {
              datas.push(util.dateToStringUS(element));
            });
            const response = await api.get(
              process.env.VUE_APP_URL_WEB_API +
                "com/anuncio/posicao?idAnuncio=" +
                this.anuncio.id +
                "&idDeterminacao=" +
                this.anuncio.idDeterminacao +
                "&idColocacao=" +
                this.anuncio.idColocacao +
                "&publicacoes=" +
                datas.join(",")
            );
            if (response.data && response.data.length > 0) {
              var msg =
                "Há " +
                response.data.length +
                " anúncio" +
                (response.data.length > 1 ? "s" : "") +
                " inserido" +
                (response.data.length > 1 ? "s" : "") +
                " nesta mesma determinação e colocação:";
              if (this.inicial) {
                this.mensagem = msg.replace(":", "");
                this.snackErro = true;
              }
              response.data.forEach((element) => {
                msg +=
                  "<br /><br />Anúncio: " +
                  element.id +
                  "<br />Cliente: " +
                  element.nome +
                  "<br />Data: " +
                  util.formatDate(element.dtPublicacao) +
                  "<br />" +
                  (this.tabelaAnuncio.tipoCobranca == 3 ? "Largura: " : "Colunas: ") +
                  element.colunas +
                  " Altura: " +
                  element.altura +
                  "<br />";
              });
              if (!this.inicial) {
                this.msgDialog = msg;
                this.dialogMensagem = true;
              }
            }
          }
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao conferir as posições do anúncio";
        this.snackErro = true;
      }
    },

    //PUBLICAÇÕES
    async carregarPublicacoes() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "com/anuncio-publicacao/anuncio/" + this.anuncio.id);
        if (response.data) {
          this.publicacoesUS = [];
          this.publicacoes = response.data;

          if (this.anuncio.status == "CANCELADO") {
            this.editar = false;
            this.permiteCancelar = false;
          } else {
            //* Se há parcela em cobrança, não permite alterar o anúncio
            this.existeParcelaEmCobranca();
          }

          //* Se há nota fiscal emitida, não permite alterar o anúncio
          this.verificaNF();

          this.publicacoes.forEach((element) => {
            this.publicacoesUS.push(util.dateToStringUS(element.dtPublicacao));
            element.futura = util.parseDateUS(element.dtPublicacao) > new Date();
          });
          this.atualizaValores();
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as publicações do anúncio";
        this.snackErro = true;
      }
    },
    ajustaPublicacoes() {
      if (this.publicacoesUS && this.publicacoesUS.length > 1) {
        if (!this.tabelaAnuncio || !this.tabelaAnuncio.id) {
          this.$refs.formElemento.validate();

          this.mensagem = "Selecione os dados do anúncio para obter os valores das publicações";
          this.snackErro = true;
          this.publicacoesUS = [];
          return;
        }

        var dtInicio = util.parseDateUS(this.publicacoesUS[0]);
        var dtFim = util.parseDateUS(this.publicacoesUS[1]);

        var dias = (dtFim - dtInicio) / 1000 / 60 / 60 / 24 + 1;

        this.publicacoesUS = [];
        var dia = dtInicio;
        for (let index = 0; index < dias; index++) {
          if (index) {
            dia.setDate(dia.getDate() + 1);
          }

          if (
            (this.cadernoSelecionado.publicaDom && this.diasSelecionados.includes("Dom") && dia.getDay() == 0) ||
            (this.cadernoSelecionado.publicaSeg && this.diasSelecionados.includes("Seg") && dia.getDay() == 1) ||
            (this.cadernoSelecionado.publicaTer && this.diasSelecionados.includes("Ter") && dia.getDay() == 2) ||
            (this.cadernoSelecionado.publicaQua && this.diasSelecionados.includes("Qua") && dia.getDay() == 3) ||
            (this.cadernoSelecionado.publicaQui && this.diasSelecionados.includes("Qui") && dia.getDay() == 4) ||
            (this.cadernoSelecionado.publicaSex && this.diasSelecionados.includes("Sex") && dia.getDay() == 5) ||
            (this.cadernoSelecionado.publicaSab && this.diasSelecionados.includes("Sáb") && dia.getDay() == 6)
          ) {
            this.publicacoesUS.push(util.dateToStringUS(dia));
          }
        }
        this.aplicaPublicacao();
        if (this.publicacoesUS && this.publicacoesUS.length > 0) {
          this.tab = 1;
        }
      }
    },
    async aplicaPublicacao() {
      this.validacao.publicacoes = false;
      if (!this.tabelaAnuncio || !this.tabelaAnuncio.id) {
        this.$refs.formElemento.validate();

        this.mensagem = "Selecione os dados do anúncio para obter os valores das publicações";
        this.snackErro = true;
        this.publicacoesUS.splice(this.publicacoesUS.length - 1, 1);
        return;
      }

      this.validacao.pacotes = "";
      var alterou = false;
      //* Se publicação não existe, inclui
      for (let index = 0; index < this.publicacoesUS.length; index++) {
        const element = this.publicacoesUS[index];
        var existe = false;
        this.publicacoes.forEach((publicacao) => {
          if (util.dateToStringUS(publicacao.dtPublicacao) == element) {
            existe = true;
          }
        });
        if (!existe) {
          alterou = true;
          if (this.anuncio.id) {
            await this.incluirPublicacao({
              idAnuncio: this.anuncio.id,
              dtPublicacao: element,
              vlrBruto: 0,
              vlrLiquido: 0
            });
          } else {
            this.publicacoes.push({
              id: null,
              dtPublicacao: element,
              vlrBruto: 0,
              vlrLiquido: 0,
              futura: true
            });
          }
        }
      }

      this.idsPublicacao = [];
      //* Se publicação está sobrando, exclui
      var publicacoesTemp = [];
      this.publicacoes.forEach((publicacao) => {
        var existe = false;
        this.publicacoesUS.forEach((element) => {
          if (util.dateToStringUS(publicacao.dtPublicacao) == element) {
            existe = true;
          }
        });
        if (this.anuncio.id) {
          if (!existe) {
            alterou = true;
            this.idsPublicacao.push(publicacao.id);
          }
        } else {
          if (existe) {
            publicacoesTemp.push(publicacao);
          } else {
            alterou = true;
          }
        }
      });

      if (this.idsPublicacao.length > 0) {
        this.excluirPublicacao();
      } else {
        this.publicacoes = [];
        Array.prototype.push.apply(this.publicacoes, publicacoesTemp);

        //* Reordena as publicações, pois usuário pode incluir fora de ordem
        this.publicacoes.sort(function (a, b) {
          if (a.dtPublicacao > b.dtPublicacao) {
            return 1;
          }
          if (a.dtPublicacao < b.dtPublicacao) {
            return -1;
          }
          return 0;
        });

        if (alterou) {
          if (this.anuncio.id) {
            await this.carregarPublicacoes();
          }
          this.limpaParcelas();
          this.conferePosicao();
        }
      }
    },
    async incluirPublicacao(itemCommit) {
      try {
        await api.post(process.env.VUE_APP_URL_WEB_API + "com/anuncio-publicacao?idSite=" + this.idSitePrincipal, itemCommit);
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },
    async excluirPublicacao() {
      if (this.publicacoes.length == 1) {
        this.mensagem = "Anúncio deve ter pelo menos 1 publicação";
        this.snackErro = true;
        this.carregarPublicacoes();
        return;
      }
      //* Não deixar excluir(4-cancelada), se não houver pelo menos 1 ativa
      var ativas = false;
      this.publicacoes.forEach((element) => {
        if (element.id != this.idsPublicacao[0] && element.statusPublicacao != 4) {
          //0=Não publicado; 1=Excluido Diagrama; 2=Alterado Comercial; 3=Publicado; 4=Cancelado comercial
          ativas = true;
        }
      });
      if (!ativas) {
        this.mensagem = "Anúncio deve ter pelo menos 1 publicação ativa";
        this.snackErro = true;
        this.carregarPublicacoes();
        return;
      }

      this.tipoConfirm = "EXCLUIR_PUBLICAÇÃO";
      this.msgConfirm =
        "Tem certeza que deseja excluir " + (this.idsPublicacao.length == 1 ? "esta publicação" : "TODAS AS DEMAIS PUBLICAÇÕES") + "? (esta ação não pode ser desfeita)";
      if (this.emCobranca) {
        this.msgConfirm += "<br /><strong>ATENÇÃO: Existe parcela em cobrança ou com nota fiscal emitida.</strong>";
      }
      this.dialogConfirm = true;
    },
    async excluiPublicacao() {
      for (let index = 0; index < this.idsPublicacao.length; index++) {
        try {
          await api.put(process.env.VUE_APP_URL_WEB_API + "com/anuncio-publicacao/status/" + this.idsPublicacao[index] + "?statusPublicacao=4");
        } catch (e) {
          this.mensagem =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.snackErro = true;
        }
      }
      this.carregarPublicacoes();
      this.limpaParcelas();
    },
    exclusaoPublicacao(item) {
      if (item.id) {
        this.idsPublicacao = [item.id];
        this.excluirPublicacao();
      } else {
        var itemIndex = this.publicacoes.indexOf(item);
        var usIndex = this.publicacoesUS.indexOf(item.dtPublicacao);
        this.publicacoes.splice(itemIndex, 1);
        this.publicacoesUS.splice(usIndex, 1);
        this.limpaParcelas();
      }
    },

    async reverterPublicacao() {
      this.tipoConfirm = "REVERTER_PUBLICAÇÃO";
      this.msgConfirm = "Tem certeza que deseja reverter o cancelamento desta publicação? (esta ação não pode ser desfeita)";
      if (this.emCobranca) {
        this.msgConfirm += "<br /><strong>ATENÇÃO: Existe parcela em cobrança ou com nota fiscal emitida.</strong>";
      }
      this.dialogConfirm = true;
    },
    async revertePublicacao() {
      try {
        await api.put(process.env.VUE_APP_URL_WEB_API + "com/anuncio-publicacao/status/" + this.idsPublicacao[0] + "?statusPublicacao=0");
        this.carregarPublicacoes();
        this.limpaParcelas();
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },
    reversaoPublicacao(item) {
      if (item.id) {
        this.idsPublicacao = [item.id];
        this.reverterPublicacao();
      }
    },

    //* AGENTES
    async carregarAgentesFormato() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "com/formato-agente/formato/" + this.formatoSelecionado.idFormato + "?ativos=1");
        if (response.data) {
          this.agentesFormato = response.data;
          var incluir = this.agentesOperacao.length == 0 && this.agentesFormato.length == 1;
          this.agentesFormato.forEach((element) => {
            if (incluir) {
              const agente = {
                id: null,
                idAgente: element.idAgente,
                agente: element.nome,
                nome: element.nome,
                comissao: 0,
                descComissao: element.descComissao,
                descMaximoCom: element.descMaximoCom,
                comissaoPadraoCom: element.comissaoPadraoCom,
                situacao: element.agenteSituacao && element.situacao
              };
              this.agentesOperacao.push(agente);
              this.validacao.agentes = false;
            }
          });
        }
        if (this.agentesFormato.length == 0) {
          this.carregarAgentes();
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os agentes do formato";
        this.snackErro = true;
      }
    },
    async carregarAgentes() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-agente/lista?idSite=" + this.idSitePrincipal);
        if (response.data) {
          this.agentesFormato = response.data;
          var incluir = this.agentesOperacao.length == 0 && this.agentesFormato.length == 1;
          this.agentesFormato.forEach((element) => {
            element.idAgente = element.id;
            if (incluir) {
              const agente = {
                id: null,
                idAgente: element.id,
                agente: element.nome,
                nome: element.nome,
                comissao: 0,
                descComissao: element.descComissao,
                descMaximoCom: element.descMaximoCom,
                comissaoPadraoCom: element.comissaoPadraoCom,
                situacao: element.situacao
              };
              this.agentesOperacao.push(agente);
              this.validacao.agentes = false;
            }
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de agentes";
        this.snackErro = true;
      }
    },
    async incluirAgente(item) {
      this.agentesOperacao = [];

      var itemCommit = {
        idOperacao: this.anuncio.idOperacao,
        idAgente: item.idAgente,
        comissao: item.comissao
      };
      try {
        await api.post(process.env.VUE_APP_URL_WEB_API + "fin/operacao-agente?idSite=" + this.idSitePrincipal, itemCommit);
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }

      this.carregarAgentesOperacao();
    },
    excluirAgente(item) {
      if (item.id) {
        if (this.agentesOperacao.length == 1) {
          this.mensagem = "Anúncio deve ter pelo menos 1 agente";
          this.snackErro = true;
          return;
        }
        this.agenteItem = item;
        this.tipoConfirm = "EXCLUIR_AGENTE";
        this.msgConfirm = "Tem certeza que deseja excluir este agente? (esta ação não pode ser desfeita)";
        this.dialogConfirm = true;
      } else {
        this.agentesOperacao.splice(item, 1);
        if (this.agentesOperacao.length == 1) {
          if (this.agentesOperacao[0].descComissao && !this.anuncio.descComissao) {
            this.anuncio.descComissao = 1;
          } else if (!this.agentesOperacao[0].descComissao && this.anuncio.descComissao) {
            this.anuncio.descComissao = 0;
          }
          if (this.parcelas.length > 0) {
            this.limpaParcelas();
          }
        }
      }
    },
    async excluiAgente() {
      this.agentesOperacao = [];

      try {
        await api.delete(process.env.VUE_APP_URL_WEB_API + "fin/operacao-agente/" + this.agenteItem.id);
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }

      await this.carregarAgentesOperacao();

      if (this.agentesOperacao.length == 1) {
        if (this.agentesOperacao[0].descComissao && !this.anuncio.descComissao) {
          this.anuncio.descComissao = 1;
        } else if (!this.agentesOperacao[0].descComissao && this.anuncio.descComissao) {
          this.anuncio.descComissao = 0;
        }
        if (this.parcelas.length > 0) {
          this.limpaParcelas();
        }
      }
    },
    closeAgente() {
      this.dialogAgente = false;
      setTimeout(() => {
        this.agenteItem = Object.assign({}, this.defaultAgenteItem);
        this.agenteIndex = -1;
        this.agenteSelecionado = {};
        this.$refs.formAgente.resetValidation();
      }, 300);
    },
    saveAgente() {
      if (this.$refs.formAgente.validate()) {
        this.validacao.idAgente = false;
        this.validacao.comissao = false;

        var incluido = false;
        this.agenteItem.idAgente = this.agenteSelecionado.idAgente;
        this.agenteItem.descMaximoCom = this.agenteSelecionado.descMaximoCom;
        this.agenteItem.descComissao = this.agenteSelecionado.descComissao;
        this.agentesOperacao.forEach((element) => {
          if (this.agenteItem.idAgente == element.idAgente) {
            incluido = true;
            this.validacao.idAgente = "Agente já foi incluído";
          }
        });
        if (this.agentesOperacao.length == 2) {
          this.validacao.idAgente = "Máximo 2 agentes de venda por anúncio (1º Agência, 2º Agente)";
          incluido = true;
        }

        if (!incluido) {
          if (this.vlrPacotes) {
            this.agenteItem.comissao = 0;
          } else {
            if (this.agentesOperacao.length == 0 && this.agenteSelecionado.descComissao) {
              this.agenteItem.comissao = this.agenteSelecionado.comissaoPadraoCom;
              this.anuncio.descComissao = 1;
              if (this.parcelas.length > 0) {
                this.limpaParcelas();
              }
            } else if (this.agenteSelecionado.comissaoPadraoCom) {
              this.agenteItem.comissao = this.agenteSelecionado.comissaoPadraoCom;
            }
          }
          this.agenteItem.agente = this.agenteSelecionado.nome;
          if (this.anuncio.id) {
            this.incluirAgente(this.agenteItem);
          } else {
            if (this.agentesOperacao.length == 0 && this.parcelas.length > 0 && this.anuncio.descComissao) {
              this.limpaParcelas();
            }
            this.agentesOperacao.push(this.agenteItem);
          }
          this.closeAgente();
          this.agenteItem = Object.assign({}, this.defaultAgenteItem);
          this.agenteIndex = -1;
          this.agenteSelecionado = {};
          this.$refs.formAgente.resetValidation();
          this.validacao.agentes = false;
        }
      }
    },
    async carregarAgentesOperacao() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/operacao-agente/operacao/" + this.anuncio.idOperacao);
        if (response.data) {
          this.agentesOperacao = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os agentes do anúncio";
        this.snackErro = true;
      }
    },

    //* OBSERVAÇÕES
    async incluirObservacao(item) {
      this.observacoesAnuncio = [];

      var itemCommit = {
        idAnuncio: this.anuncio.id,
        observacao: item.observacao,
        idUsuario: item.idUsuario,
        situacao: 1
      };
      try {
        await api.post(process.env.VUE_APP_URL_WEB_API + "com/anuncio-observacao?idSite=" + this.idSitePrincipal, itemCommit);
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }

      this.carregarObservacoesAnuncio();
    },
    async ativarDesativarObservacao(item) {
      var observacaoItem = Object.assign({}, item);
      var observacaoIndex = this.observacoesAnuncio.indexOf(item);
      if (observacaoItem.id == null) {
        this.observacoesAnuncio.splice(observacaoIndex, 1);
      } else {
        observacaoItem.situacao = observacaoItem.situacao == 1 ? 0 : 1;
        var situacao = { situacao: observacaoItem.situacao };

        try {
          await api.put(process.env.VUE_APP_URL_WEB_API + "com/anuncio-observacao/situacao/" + observacaoItem.id, situacao);

          Object.assign(this.observacoesAnuncio[observacaoIndex], observacaoItem);
          this.mensagem = "Item " + (observacaoItem.situacao == 1 ? "Ativado" : "Desativado");
          this.snackbar = true;
        } catch (e) {
          this.mensagem =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.snackbar = true;
        }
      }
      this.observacaoItem = Object.assign({}, this.defaultObservacaoItem);
      this.observacaoIndex = -1;
    },
    closeObservacao() {
      this.dialogObservacao = false;
      setTimeout(() => {
        this.observacaoItem = Object.assign({}, this.defaultObservacaoItem);
        this.observacaoIndex = -1;
        this.$refs.formObservacao.resetValidation();
      }, 300);
    },
    saveObservacao() {
      if (this.$refs.formObservacao.validate()) {
        this.observacaoItem.dtRegistro = new Date();
        this.observacaoItem.usuario = this.$store.state.usuario.nome;
        this.observacaoItem.idUsuario = this.$store.state.usuario.id;
        if (this.anuncio.id) {
          this.incluirObservacao(this.observacaoItem);
        } else {
          this.observacoesAnuncio.push(this.observacaoItem);
        }
        this.closeObservacao();
        this.observacaoItem = Object.assign({}, this.defaultObservacaoItem);
        this.observacaoIndex = -1;
        this.$refs.formObservacao.resetValidation();
        this.validacao.observacoes = false;
      }
    },
    async carregarObservacoesAnuncio() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "com/anuncio-observacao/anuncio/" + this.anuncio.id);
        if (response.data) {
          this.observacoesAnuncio = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as observações do anúncio";
        this.snackErro = true;
      }
    },

    async carregarPermutasPagarCliente() {
      try {
        this.permutasPagar = [];
        if (this.id) {
          const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/operacao-permuta/operacao/" + this.anuncio.idOperacao);
          if (response.data) {
            this.permutasPagar = response.data;
          }
        } else {
          var permitePP = false;
          this.formasPagamentoFormato.forEach((element) => {
            if (element.tipo == "PERMUTA_PAGAR") {
              permitePP = true;
            }
          });
          if (permitePP) {
            const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/parcela/permutas/" + this.anuncio.idCliente);
            if (response.data) {
              this.permutasPagar = response.data;
              this.selectAll = false;
            }
          }
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as permutas do anúncio/anunciante";
        this.snackErro = true;
      }
    },

    async carregarPacotesCliente() {
      try {
        this.pacotes = [];
        if (this.id) {
          const response = await api.get(process.env.VUE_APP_URL_WEB_API + "com/anuncio-pacote/anuncio/" + this.id);
          if (response.data) {
            this.pacotes = response.data;
            //* não calcula saldo, apenas apresenta os valores utilizados para o anúncio
            this.headerPacotes = [
              { text: "Ações", value: "actions" },
              { text: "ID", value: "id" },
              { text: "Criação", value: "dtCriacao" },
              { text: "Cobrado", value: "vlrLiquido", align: "end" },
              { text: "Crédito", value: "vlrCredito", align: "end" },
              {
                text: "Utilizado Neste Anúncio",
                value: "vlrUtilizado",
                align: "end"
              }
            ];
          }
        } else {
          this.vlrPacotes = 0;
          var permitePac = false;
          this.formasPagamentoFormato.forEach((element) => {
            if (element.tipo == "PACOTE") {
              permitePac = true;
            }
          });
          if (permitePac) {
            const response = await api.get(process.env.VUE_APP_URL_WEB_API + "com/pacote/cliente/" + this.anuncio.idCliente);
            if (response.data) {
              response.data.forEach((element) => {
                element.vlrSaldo = element.vlrCredito - element.vlrUtilizado;
                if (element.vlrSaldo > 0) {
                  this.pacotes.push(element);
                }
              });
              this.selectAllPac = false;
            }
          }
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os pacotes do anúncio/anunciante";
        this.snackErro = true;
      }
    },

    //* CONDIÇÕES ESPECIAIS
    async carregarCondicoesEspeciaisFormato() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "com/formato-condicao-especial/formato/" + this.formatoSelecionado.idFormato + "?situacao=1");
        if (response.data) {
          this.condicoesEspeciaisFormato = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as condições especiais do formato";
        this.snackErro = true;
      }
    },
    async carregarCondicoesEspeciais() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "com/anuncio-condicao-especial/anuncio/" + this.anuncio.id);
        if (response.data) {
          this.condicoesEspeciais = response.data;
          this.atualizaValores();
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as condições especiais do anúncio";
        this.snackErro = true;
      }
    },
    async incluirCondicaoEspecial(item) {
      this.condicoesEspeciais = [];

      var itemCommit = {
        idFormatoCondicaoEspecial: item.id,
        idAnuncio: this.anuncio.id
      };
      try {
        await api.post(process.env.VUE_APP_URL_WEB_API + "com/anuncio-condicao-especial?idSite=" + this.idSitePrincipal, itemCommit);
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }

      this.condicaoEspecialSelecionada = {};
      this.limpaParcelas();
      this.carregarCondicoesEspeciais();
    },
    async excluirCondicaoEspecial(item) {
      if (item.id) {
        this.condicaoEspecialItem = item;
        this.tipoConfirm = "EXCLUIR_CONDIÇÃO_ESPECIAL";
        this.msgConfirm = "Tem certeza que deseja excluir esta condição especial? (esta ação não pode ser desfeita)";
        this.dialogConfirm = true;
      } else {
        this.condicoesEspeciais.splice(item, 1);
        this.limpaParcelas();
      }
    },
    async excluiCondicaoEspecial() {
      this.condicoesEspeciais = [];
      try {
        await api.delete(process.env.VUE_APP_URL_WEB_API + "com/anuncio-condicao-especial/" + this.condicaoEspecialItem.id);
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }

      this.limpaParcelas();
      this.carregarCondicoesEspeciais();
    },

    atualizaValores() {
      if (!this.tabelaAnuncio || !this.tabelaAnuncio.id) {
        return;
      }
      this.vlrTabela = 0; //* valor de tabela total do anúncio
      this.dtInicio = null;
      this.dtFim = null;
      this.nroPublicacoes = this.publicacoes.length;
      this.publicacoes.forEach((element) => {
        if (!this.dtInicio) {
          this.dtInicio = element.dtPublicacao;
        }
        this.dtFim = element.dtPublicacao;

        var vlr = 0;
        var dtPub = util.parseDateUS(element.dtPublicacao);
        if (dtPub.getDay() == 0) {
          vlr = this.tabelaAnuncio.vlrDom;
        } else if (dtPub.getDay() == 1) {
          vlr = this.tabelaAnuncio.vlrSeg;
        } else if (dtPub.getDay() == 2) {
          vlr = this.tabelaAnuncio.vlrTer;
        } else if (dtPub.getDay() == 3) {
          vlr = this.tabelaAnuncio.vlrQua;
        } else if (dtPub.getDay() == 4) {
          vlr = this.tabelaAnuncio.vlrQui;
        } else if (dtPub.getDay() == 5) {
          vlr = this.tabelaAnuncio.vlrSex;
        } else if (dtPub.getDay() == 6) {
          vlr = this.tabelaAnuncio.vlrSab;
        }
        if (this.tabelaAnuncio.tipoCobranca == 1 || this.tabelaAnuncio.tipoCobranca == 3) {
          //cm x col, larg x lt
          vlr = vlr * this.anuncio.colunas * this.anuncio.altura;
        } else if (this.tabelaAnuncio.tipoCobranca == 4) {
          //palavras
          vlr = vlr * this.anuncio.palavras;
        } else if (this.tabelaAnuncio.tipoCobranca == 5) {
          //carac
          vlr = vlr * this.anuncio.caracteres;
        }

        element.vlrBruto = vlr;

        if (element.statusPublicacao != 4) {
          this.vlrTabela += element.vlrBruto;
        }
      });
      this.vlrTabela = parseFloat(this.vlrTabela.toFixed(2));

      if (this.vlrTaxado) {
        this.vlrAjustado = this.vlrTaxado; //* valor taxado ou
      } else {
        this.vlrAjustado = this.vlrTabela; //* valor de tabela menos as condições especiais
        //* como podem aplicar várias condições e cada uma tem configuração diferente, soma $ e % separados
        var vlr = 0;
        var perc = 0;
        this.condicoesEspeciais.forEach((element) => {
          if (element.tipo == "%") {
            perc += element.valor;
          } else {
            vlr += element.valor;
          }
        });
        if (perc) {
          this.vlrAjustado = this.vlrAjustado + (this.vlrAjustado * perc) / 100;
        } else {
          this.vlrAjustado += vlr;
        }
        this.vlrAjustado = parseFloat(this.vlrAjustado.toFixed(2));
      }
      this.vlrCobrado = 0; //* valor cobrado original do anúncio
      if (this.parcelas.length == 0) {
        this.vlrCobrado = this.vlrAjustado;
      } else {
        this.parcelas.forEach((element) => {
          this.vlrCobrado += parseFloat(element.valor) + parseFloat(element.descAcr);
        });
      }
    },

    async carregarParcelasAnuncio() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/parcela/anuncio/" + this.anuncio.id);
        if (response.data) {
          this.parcelas = response.data;
          this.parcelas.forEach((element) => {
            element.idNF = element.idNFe || element.idNFSC || element.idNFSe;
          });

          var parcela = this.parcelas[0];
          this.idClienteCartao = parcela.idClienteCartao;
          this.idClienteConta = parcela.idClienteConta;
          this.idClienteEndereco = parcela.idClienteEndereco;
          this.idClienteCelesc = parcela.idClienteCelesc;
          this.nroParcelas = this.parcelas.length;
          this.formaPagamentoSelecionada = {
            idFormaPgto: parcela.idFormaPgto,
            nome: parcela.formaPagamento,
            tipo: parcela.formaPagamentoTipo,
            maxParcelas: this.parcelas.length
          };

          this.atualizaValores();
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as parcelas do anúncio";
        this.snackErro = true;
      }
    },

    async carregarSecoes() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "com/secao/lista" + (this.anuncio.idSecao ? "?inativo=" + this.anuncio.idSecao : ""));

        //seta as variaveis com o retorno
        this.secoes = response.data;
        this.secoes.forEach((element) => {
          element.secao = "";
          if (element.numero.includes(".")) {
            element.secao = "&emsp;".repeat(element.numero.split(".").length - 1);
          }
          element.nomeS = element.secao + element.nome; //element.numero + " - " +
          element.nome =
            // element.numero +
            // " - " +
            (element.nome2 ? element.nome2 + "/" : "") + (element.nome1 ? element.nome1 + "/" : "") + element.nome;

          if (element.id == this.anuncio.idSecao) {
            this.secaoSelecionada = element;
          }
        });
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de seções";
        this.snackErro = true;
      }
    },

    async carregarProdutos() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "com/produto/lista" + (this.anuncio.idProduto ? "?inativo=" + this.anuncio.idProduto : ""));
        if (response.data) {
          this.produtos = response.data;
          this.produtos.forEach((element) => {
            if (element.id == this.anuncio.idProduto) {
              this.produtoSelecionado = element;
            }
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de produtos";
        this.snackErro = true;
      }
    },
    async carregarCadernos(produto) {
      try {
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API + "com/caderno/produto/" + produto.id + (this.anuncio.idCaderno ? "?inativo=" + this.anuncio.idCaderno : "")
        );
        if (response.data) {
          this.cadernos = response.data;
          var achou = false;
          this.cadernos.forEach((element) => {
            if (element.id == this.anuncio.idCaderno) {
              achou = true;
              this.cadernoSelecionado = element;
            }
          });
          if (!achou && this.cadernos.length == 1) {
            this.cadernoSelecionado = this.cadernos[0];
          }
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os cadernos do produto";
        this.snackErro = true;
      }
    },
    async carregarFormatos(caderno) {
      try {
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API + "com/formato-caderno/caderno/" + caderno.id + (this.anuncio.idFormatoCaderno ? "?inativo=" + this.anuncio.idFormatoCaderno : "")
        );
        if (response.data) {
          this.formatos = response.data;
          var achou = false;
          this.formatos.forEach((element) => {
            if (element.id == this.anuncio.idFormatoCaderno) {
              achou = true;
              this.formatoSelecionado = element;
            }
          });
          if (!achou && this.formatos.length == 1) {
            this.formatoSelecionado = this.formatos[0];
          }
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os formatos do caderno";
        this.snackErro = true;
      }
    },
    async carregarDeterminacoes() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "com/determinacao/lista" + (this.anuncio.idDeterminacao ? "?inativo=" + this.anuncio.idDeterminacao : ""));
        if (response.data) {
          this.determinacoes = response.data;
          var achou = false;
          this.determinacoes.forEach((element) => {
            if (element.id == this.anuncio.idDeterminacao) {
              achou = true;
              this.determinacaoSelecionada = element;
            }
          });
          if (!achou && this.determinacoes.length == 1) {
            this.determinacaoSelecionada = this.determinacoes[0];
          }
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de determinações";
        this.snackErro = true;
      }
    },
    async carregarColocacoes() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "com/colocacao/lista" + (this.anuncio.idColocacao ? "?inativo=" + this.anuncio.idColocacao : ""));
        if (response.data) {
          this.colocacoes = response.data;
          var achou = false;
          this.colocacoes.forEach((element) => {
            if (element.id == this.anuncio.idColocacao) {
              achou = true;
              this.colocacaoSelecionada = element;
            }
          });
          if (!achou && this.colocacoes.length == 1) {
            this.colocacaoSelecionada = this.colocacoes[0];
          }
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de colocações";
        this.snackErro = true;
      }
    },
    async carregarDeterminacoesColocacoes(formato) {
      try {
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "com/anuncio-tabela/formatoCaderno/" +
            formato.id +
            "?a=1" +
            (this.anuncio.idDeterminacao ? "&determinacaoInativa=" + this.anuncio.idDeterminacao : "") +
            (this.anuncio.idColocacao ? "&colocacaoInativa=" + this.anuncio.idColocacao : "")
        );
        if (response.data && response.data.length > 0) {
          if (this.produtoSelecionado.tipo != "CLASSIFICADOS" && (!response.data[0].idDeterminacao || !response.data[0].idColocacao)) {
            //* Se tabela de publicidade não tem determinação/colocação definida - carrega todas
            this.carregarDeterminacoes();
            this.carregarColocacoes();
            this.carregarTabela();
          } else {
            var achouDet = false;
            var achouCol = false;
            response.data.forEach((element) => {
              this.determinacoes.push({
                id: element.idDeterminacao,
                nome: element.determinacao,
                situacao: element.situacaoDeterminacao
              });
              if (element.idDeterminacao == this.anuncio.idDeterminacao) {
                achouDet = true;
                this.determinacaoSelecionada = {
                  id: element.idDeterminacao,
                  nome: element.determinacao,
                  situacao: element.situacaoDeterminacao
                };
              }

              this.colocacoes.push({
                id: element.idColocacao,
                nome: element.colocacao,
                situacao: element.situacaoColocacao
              });
              if (element.idColocacao == this.anuncio.idColocacao) {
                achouCol = true;
                this.colocacaoSelecionada = {
                  id: element.idColocacao,
                  nome: element.colocacao,
                  situacao: element.situacaoColocacao
                };
              }
            });
            if (!achouDet && this.determinacoes.length == 1) {
              this.determinacaoSelecionada = this.determinacoes[0];
            }
            if (!achouCol && this.colocacoes.length == 1) {
              this.colocacaoSelecionada = this.colocacoes[0];
            }
          }
        } else {
          this.mensagem = "Tabela de preço não encontrada para esta configuração";
          this.snackErro = true;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a tabela de preços";
        this.snackErro = true;
      }
    },
    async carregarTextos() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "com/texto/lista");
        if (response.data) {
          this.textos = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os textos padrão";
        this.snackErro = true;
      }
    },

    limpaListas() {
      //* Se na captação alterou o produto, caderno ou formato, limpa tudo para recomeçar (pois serão novos preços, agentes e formas de pagamentos/regras de ajuste)
      if (this.publicacoes.length) {
        this.validacao.publicacoes = "Publicações foram redefinidas";
      }
      if (this.agentesOperacao.length) {
        this.agentesOperacao = [];

        if (this.agentesFormato.length == 1) {
          const agente = {
            id: null,
            idAgente: this.agentesFormato[0].idAgente,
            agente: this.agentesFormato[0].nome,
            nome: this.agentesFormato[0].nome,
            comissao: 0,
            descComissao: this.agentesFormato[0].descComissao,
            descMaximoCom: this.agentesFormato[0].descMaximoCom,
            comissaoPadraoCom: this.agentesFormato[0].comissaoPadraoCom,
            situacao: this.agentesFormato[0].agenteSituacao && this.agentesFormato[0].situacao
          };
          this.agentesOperacao.push(agente);
        } else {
          this.validacao.agentes = "Agentes/Agências foram redefinidos";
        }
      }
      if (this.parcelas.length) {
        this.validacao.parcelas = "Parcelas foram redefinidas";
      }
      this.publicacoes = [];
      this.publicacoesUS = [];
      this.condicoesEspeciais = [];
      this.parcelas = [];
      this.atualizaValores();
    },

    async limpaParcelas(origem) {
      this.isUpdating = true;
      if (
        this.id &&
        ((origem == "colunas" && this.colunasAnterior != this.anuncio.colunas) ||
          (origem == "altura" && this.alturaAnterior != this.anuncio.altura) ||
          (origem == "titulo" && this.tituloAnterior != this.anuncio.titulo) ||
          (origem == "texto" && this.textoAnterior != this.anuncio.textoAnuncio))
      ) {
        //* Alterações de colunas/altura/texto: setar statusPublicacao=2-AlteradoComercial
        this.publicacoes.forEach((element) => {
          if (util.parseDateUS(element.dtPublicacao) > new Date()) {
            element.statusPublicacao = 2;
          }
        });
      }

      if (origem == "vlrTaxado") {
        if (this.vlrTaxadoAnterior == this.vlrTaxado) {
          //* Não recria parcelas se valor taxado não foi alterado
          this.isUpdating = false;
          return;
        } else {
          if (this.formaPagamentoSelecionada && this.formaPagamentoSelecionada.tipo) {
            if (util.isFormaPgtoValorZero(this.formaPagamentoSelecionada.tipo)) {
              this.validacao.vlrTaxado = "Não é possível taxar valor para a forma de pagamento selecionada";
              this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
              this.snackErro = true;
              this.isUpdating = false;
              return;
            } else {
              if (this.formatoSelecionado && this.formatoSelecionado.tipo && this.vlrTaxado) {
                var vlrMinimo = this.formatoSelecionado.valorMinimo;
                var vlrMaximo = this.formatoSelecionado.valorMaximo;
                if (parseFloat(this.vlrTaxado) < vlrMinimo || parseFloat(this.vlrTaxado) > vlrMaximo) {
                  this.validacao.vlrTaxado = "Valor taxado está fora da faixa: " + util.formatCurrency(vlrMinimo) + " a " + util.formatCurrency(vlrMaximo);
                  this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
                  this.snackErro = true;
                  this.isUpdating = false;
                  return;
                }
              }
            }
          }
        }
      }
      if (origem == "carencia") {
        if (this.carenciaAnterior == this.carencia) {
          //* Não recria parcelas se carência não foi alterada
          return;
        } else {
          if (parseInt(this.carencia) > this.limiteCarencia) {
            this.validacao.carencia = "Carência inválida";
            this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
            this.snackErro = true;
            this.isUpdating = false;
            return;
          }
        }
      }

      //1=CENTIMETRO X COLUNA, 2=PREÇO FIXO, 3=LARGURA X ALTURA, 4=NUMERO PALAVRAS, 5=NUMERO CARACTERES',
      if (origem == "colunas") {
        if (this.colunasAnterior == this.anuncio.colunas || (this.tabelaAnuncio.tipoCobranca != 1 && this.tabelaAnuncio.tipoCobranca != 3)) {
          //* Não recria parcelas se colunas não foi alterada ou se tipoCobrança não é por colunas/largura
          this.isUpdating = false;
          return;
        }
      }
      if (origem == "altura") {
        if (this.alturaAnterior == this.anuncio.altura || (this.tabelaAnuncio.tipoCobranca != 1 && this.tabelaAnuncio.tipoCobranca != 3)) {
          //* Não recria parcelas se altura não foi alterada ou se tipoCobrança não é por colunas/largura
          this.isUpdating = false;
          return;
        }
      }
      if (origem == "titulo") {
        if (this.tituloAnterior == this.anuncio.titulo || (this.tabelaAnuncio.tipoCobranca != 4 && this.tabelaAnuncio.tipoCobranca != 5)) {
          //* Não recria parcelas se título não foi alterado ou se tipoCobranca não é palvras ou caracteres
          this.isUpdating = false;
          return;
        }
      }
      if (origem == "texto") {
        if (this.textoAnterior == this.anuncio.textoAnuncio || (this.tabelaAnuncio.tipoCobranca != 4 && this.tabelaAnuncio.tipoCobranca != 5)) {
          //* Não recria parcelas se texto não foi alterado  ou se tipoCobranca não é palvras ou caracteres
          this.isUpdating = false;
          return;
        }
      }
      //* Alteração de determinacao e colocação não permitida pois envolve alteração de valores

      this.vlrTaxadoAnterior = this.vlrTaxado;
      this.carenciaAnterior = this.carencia;
      this.colunasAnterior = this.anuncio.colunas;
      this.secaoAnterior = this.anuncio.idSecao;
      this.alturaAnterior = this.anuncio.altura;
      this.tituloAnterior = this.anuncio.titulo;
      this.textoAnterior = this.anuncio.textoAnuncio;

      if (this.emCobranca) {
        this.msgDialog = "Valor do anúncio foi recalculado, porém suas <strong>PARCELAS</strong> não poderão ser recriadas por estarem em COBRANÇA ou com NOTA FISCAL emitida.";
        this.dialogMensagem = true;
        this.atualizaValores();
        this.isUpdating = false;
        return;
      }

      this.carregaItensFormaPgto();

      this.atualizaValores();
      this.criarParcelas(this.parcelamentoSelecionado);

      if (origem == "vlrTaxado" && this.condicoesEspeciais.length > 0) {
        this.mensagem = "Condições especiais foram removidas, pois será considerado o valor taxado";
        this.snackErro = true;
        this.condicoesEspeciais = [];
      }
      this.isUpdating = false;
    },

    //* CLIENTES
    editarCliente() {
      if (this.direitoAnunciante) {
        window.open("/comercial/anunciante/" + this.anuncio.idCliente, "_blank");
      }
    },
    limpaCliente() {
      this.clientes = [];
      this.cartoesCliente = [];
      this.celescsCliente = [];
      this.contasCliente = [];
      this.enderecosCobrancaCliente = [];
      this.anuncio.idCliente = null;
      this.anuncio.idDeptoCliente = null;
      this.departamentosCliente = [];
      this.permutasPagar = [];
      this.selectAll = false;
    },
    async buscarCliente() {
      if (this.loadingClientes || !this.search) {
        return;
      }

      this.loadingClientes = true;

      var filtro = "";
      if (util.valida_cpf(this.search) || util.valida_cnpj(this.search)) {
        filtro = "identificacao=" + this.search;
      } else {
        filtro = "nome=" + this.search + "&nomeFantasia=" + this.search;
      }

      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente?" + filtro + "&ordenacao=nome&ordem=asc&pagina=1&tamanho=50");
      if (response.data.data) {
        this.clientes = response.data.data;
        if (this.clientes.length == 1) {
          this.clienteSelecionado = this.clientes[0];
          this.anuncio.idCliente = this.clientes[0].id;
          this.recarregarItensCliente();
        }
      }
      this.loadingClientes = false;
    },

    //* PARCELAS
    async carregarFormasPagamentoFormato() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "com/formato-forma-pagamento/formato/" + this.formatoSelecionado.idFormato + "?situacao=1");
        if (response.data) {
          this.formasPagamentoFormato = response.data;
          if (this.anuncio.idCliente) {
            this.carregarPermutasPagarCliente();
            this.carregarPacotesCliente();
          }
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as formas de pagamento do formato de anúncio";
        this.snackErro = true;
      }
    },

    carregaItensFormaPgto() {
      if (this.formaPagamentoSelecionada && this.formaPagamentoSelecionada.tipo && (!this.clienteSelecionado || !this.clienteSelecionado.id)) {
        this.mensagem = "Selecione um anunciante";
        this.snackErro = true;
        return;
      }
      if (this.formaPagamentoSelecionada && this.formaPagamentoSelecionada.tipo == "PERMUTA_PAGAR") {
        this.formaPagamentoSelecionada = {
          idFormaPgto: null,
          nome: "",
          formaPagamento: "",
          formaPagamentoTipo: "",
          bloqueioPublicacao: 0,
          maxParcelas: 0
        };
        this.mensagem = "Selecione os itens a serem permutados na aba Permutas";
        this.snackErro = true;
        return;
      }
      if (this.formaPagamentoSelecionada && this.formaPagamentoSelecionada.tipo) {
        this.carregarParcelamento();
      } else {
        this.parcelas = [];
        this.parcelamento = [];
        this.parcelamentoSelecionado = {};
      }
    },

    async recarregarItensCliente() {
      this.anuncio.idCliente = this.clienteSelecionado.id;
      this.carregarDepartamentosCliente();
      await this.carregarPermutasPagarCliente();
      await this.carregarPacotesCliente();
      if (this.id) {
        //* Se cliente foi alterado
        this.editarParcelas = false;
        this.validacao.parcelas = "Após salvar, pode ser necessário editar as parcelas e ajustar os dados de cobrança para o novo cliente";
      }
    },
    async carregarDepartamentosCliente() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-departamento/cliente/" + this.anuncio.idCliente + "?ativos=1");
        if (response.data) {
          this.departamentosCliente = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os departamentos do cliente";
        this.snackErro = true;
      }
    },
    async existeParcelaEmCobranca() {
      try {
        //* Verifica se há alguma parcela da operação do anúncio em cobrança
        if (this.anuncio.idOperacao) {
          const responseCobranca = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/parcela/emCobranca/" + this.anuncio.idOperacao);
          if (responseCobranca.data && responseCobranca.data.id) {
            this.emCobranca = true;
            this.editar = false;
          }
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao verificar parcela em cobrança";
        this.snackErro = true;
      }
    },
    async verificaNF() {
      try {
        //* Verifica se há alguma parcela no anúncio com NF emitida
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "com/anuncio/nf/" + this.anuncio.id);
        if (response.data && response.data.id) {
          this.editar = false;
          this.nfEmitida = true;
        }
        if (this.vlrPermutado) {
          //* Não pode editar/cancelar anúncio de permuta a pagar, pois os valores já foram permutados com o fornecedor
          this.editar = false;
          this.permiteCancelar = false;
        }
        for (let index = 0; index < this.parcelas.length; index++) {
          const element = this.parcelas[index];
          if ((element.formaPagamentoTipo == "PERMUTA_RECEBER" || element.formaPagamentoTipo == "PERMUTA_PAGAR") && element.status == "QUITADA") {
            //* Não pode editar/cancelar anúncio de qualquer permuta já quitada, pois os valores já foram permutados com o fornecedor
            this.permiteCancelar = false;
            break;
          }
        }

        if (this.vlrPacotes) {
          if (this.editar) {
            //* Não pode editar anúncio de pacotes, pois o saldo já foi abatido e as operações já foram registradas
            this.editar = false;
            //* Se era possível editar, libera o cancelamento do anúncio de pacote
            this.permiteCancelar = true;
          } else {
            this.permiteCancelar = false;
          }
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao verificar notas fiscais";
        this.snackErro = true;
      }
    },
    async carregarEnderecos(tipo) {
      try {
        //* Carrega os endereços do cliente
        const responseEnderecosCliente = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-endereco/lista?idCliente=" + this.anuncio.idCliente + "&" + tipo + "=1");
        this.enderecosCobrancaCliente = responseEnderecosCliente.data;
        this.enderecosCobrancaCliente.forEach((element) => {
          element.nome = element.tipo + " " + element.logradouro + ", " + element.numero + " - " + element.bairro + " - " + element.municipio + "/" + element.uf;
        });
        if (this.enderecosCobrancaCliente.length == 1) {
          this.idClienteEndereco = this.enderecosCobrancaCliente[0].id;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os endereços de cobrança do cliente";
        this.snackErro = true;
      }
    },
    async carregarCartoes() {
      try {
        //* Carrega os cartões de crédito do cliente
        const responseCartoesCliente = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-cartao/lista?idCliente=" + this.anuncio.idCliente);
        this.cartoesCliente = responseCartoesCliente.data;
        this.cartoesCliente.forEach((element) => {
          element.nome = util.escondeCartao(element.numero) + " - " + element.validade;
        });
        if (this.cartoesCliente.length == 1) {
          this.idClienteCartao = this.cartoesCliente[0].id;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os cartões de crédito do cliente";
        this.snackErro = true;
      }
    },
    async carregarCelescs() {
      try {
        //* Carrega as unidades consumidoras do cliente
        const responseCelescsCliente = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-celesc/lista?idCliente=" + this.anuncio.idCliente);
        this.celescsCliente = responseCelescsCliente.data;
        this.celescsCliente.forEach((element) => {
          element.nome = element.unidadeConsumidora + " (" + element.identificacao + ")";
        });
        if (this.celescsCliente.length == 1) {
          this.idClienteCelesc = this.celescsCliente[0].id;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as unidades consumidoras do cliente";
        this.snackErro = true;
      }
    },
    async carregarContas() {
      try {
        //* Carrega as contas correntes do cliente
        const responseContasCliente = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-conta/lista?idCliente=" + this.anuncio.idCliente);
        this.contasCliente = responseContasCliente.data;
        this.contasCliente.forEach((element) => {
          element.nome = element.bancoNumero + ": " + element.agencia + " - " + element.numero;
        });
        if (this.contasCliente.length == 1) {
          this.idClienteConta = this.contasCliente[0].id;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as contas correntes do cliente";
        this.snackErro = true;
      }
    },

    async carregarUltimoAnuncio() {
      this.ultimoAnuncio = {};
      this.loadingUltAnu = true;
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "com/anuncio/cliente/ultimo/" + this.anuncio.idCliente + "?idAnuncio=" + this.id);
      this.loadingUltAnu = false;
      if (response.data) {
        this.ultimoAnuncio = response.data;
        if (this.ultimoAnuncio.tipoCobranca == 1) {
          this.ultimoAnuncio.tamanho = `${this.ultimoAnuncio.altura} Centímetro${this.ultimoAnuncio.altura > 1 ? "s" : ""} x ${this.ultimoAnuncio.colunas} Coluna${
            this.ultimoAnuncio.colunas > 1 ? "s" : ""
          }`;
        } else if (this.ultimoAnuncio.tipoCobranca == 2) {
          this.ultimoAnuncio.tamanho = `${this.ultimoAnuncio.altura} x ${this.ultimoAnuncio.colunas}`;
        } else if (this.ultimoAnuncio.tipoCobranca == 3) {
          this.ultimoAnuncio.tamanho = `Largura ${this.ultimoAnuncio.colunas} cm x Altura ${this.ultimoAnuncio.altura} cm`;
        } else if (this.ultimoAnuncio.tipoCobranca == 4) {
          this.ultimoAnuncio.tamanho = `${this.ultimoAnuncio.palavras} Palavras`;
        } else if (this.ultimoAnuncio.tipoCobranca == 5) {
          this.ultimoAnuncio.tamanho = `${this.ultimoAnuncio.caracteres} Caracteres`;
        }
      }
    },

    async carregarUltimaAssinatura() {
      this.ultimaAssinatura = {};
      this.loadingUltAss = true;
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/assinatura/cliente/ultima/" + this.anuncio.idCliente);
      this.loadingUltAss = false;
      if (response.data) {
        this.ultimaAssinatura = response.data;
        this.ultimaAssinatura.periodo = `${util.formatDate(this.ultimaAssinatura.dtInicio)} a ${util.formatDate(this.ultimaAssinatura.dtFim)}`;
      }
    },

    async carregarParcelamento() {
      try {
        if (this.vlrPacotes) {
          this.mensagem = "Anúncio de pacote não gera parcelas";
          this.snackErro = true;
          return;
        }
        if (!this.tabelaAnuncio || !this.tabelaAnuncio.id) {
          this.mensagem = "Não existe tabela de preços para esta configuração de anúncio";
          this.snackErro = true;
          return;
        }

        this.formaPagamentoSelecionar = true;

        if (this.parcelas.length) {
          this.validacao.parcelas = "Parcelas foram redefinidas" + (this.id ? ". Será necessário SALVAR novamente o anúncio." : "");
          this.mensagem = "Parcelas foram redefinidas" + (this.id ? ". Será necessário SALVAR novamente o anúncio." : "");
          this.snackErro = true;
        } else {
          this.validacao.parcelas = this.validacao.parcelas && this.validacao.parcelas.startsWith("Parcelas foram redefinidas") ? this.validacao.parcelas : false;
        }
        this.parcelas = [];

        this.validacao.produtos = false;
        this.validacao.vlrTaxado = false;
        this.validacao.carencia = false;

        var erro = false;
        if (!this.clienteSelecionado.id) {
          this.validacao.idCliente = "Selecione um anunciante";
          erro = true;
        }
        if (!this.formatoSelecionado || !this.formatoSelecionado.id) {
          this.validacao.idFormatoCaderno = "Selecione o formato";
          erro = true;
        }

        if (this.publicacoes.length == 0) {
          this.validacao.publicacoes = "Selecione pelo menos 1 data de publicação";
          erro = true;
        }
        if (this.agentesOperacao.length == 0) {
          this.validacao.agentes = "Selecione pelo menos 1 agente/agência";
          erro = true;
        }

        if (this.vlrTaxado) {
          if (util.isFormaPgtoValorZero(this.formaPagamentoSelecionada.tipo)) {
            this.validacao.vlrTaxado = "Não é possível taxar valor para a forma de pagamento selecionada";
            this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
            this.snackErro = true;
            erro = true;
          } else {
            var vlrMinimo = this.formatoSelecionado.valorMinimo;
            var vlrMaximo = this.formatoSelecionado.valorMaximo;
            if (parseFloat(this.vlrTaxado) < vlrMinimo || parseFloat(this.vlrTaxado) > vlrMaximo) {
              this.validacao.vlrTaxado = "Valor taxado está fora da faixa: " + util.formatCurrency(vlrMinimo) + " a " + util.formatCurrency(vlrMaximo);
              this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
              this.snackErro = true;
              erro = true;
            }
          }
        }

        if (parseInt(this.carencia) > this.limiteCarencia) {
          this.validacao.carencia = "Carência inválida";
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
          erro = true;
        }

        if (erro) {
          this.formaPagamentoSelecionada = {
            idFormaPgto: null,
            nome: "",
            tipo: "",
            maxParcelas: 0,
            bloqueioPublicacao: 0
          };
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
          return;
        }

        if (util.isFormaPgtoValorZero(this.formaPagamentoSelecionada.tipo)) {
          //* Cria a parcela para CORTESIA, BONIFICAÇÃO ou COMPENSAÇÃO
          var dtVecto = new Date();
          var parcela = {
            id: null,
            idOperacao: this.anuncio.idOperacao,
            indice: null,
            numero: 1,
            status: "QUITADA",
            idFormaPgto: this.formaPagamentoSelecionada.idFormaPgto,
            formaPagamento: this.formaPagamentoSelecionada.nome,
            formaPagamentoTipo: this.formaPagamentoSelecionada.tipo,
            dtVectoOriginal: dtVecto,
            dtVecto: dtVecto,
            dtVectoO: util.formatDate(dtVecto),
            dtQuitacao: dtVecto,
            idClienteCartao: this.idClienteCartao,
            idClienteConta: this.idClienteConta,
            idClienteEndereco: this.idClienteEndereco,
            idClienteCelesc: this.idClienteCelesc,
            valor: 0,
            liquido: "0,00",
            descAcr: 0,
            obsCobranca: util.removeEspeciais(util.removeAcentos(this.anuncio.textoFatura)),
            ajustada: 0
          };
          this.parcelas.push(parcela);
          return;
        }

        //* Carrega os itens do parcelamento
        const responseParcelamento = await api.get(
          process.env.VUE_APP_URL_WEB_API + "ass/regra-ajuste/formatoFormaPgto/" + this.formatoSelecionado.idFormato + "/" + this.formaPagamentoSelecionada.idFormaPgto
        );
        this.parcelamento = [];
        this.parcelamentoSelecionado = {};

        var parcelamentoItens = [];
        var parcItens = [];
        var idRegra = 0;
        const processarParc = responseParcelamento.data.map(async (element) => {
          var ate = Math.min(this.formaPagamentoSelecionada.maxParcelas, element.ate);
          if (element.id != idRegra) {
            parcItens = [];
          }
          idRegra = element.id;
          //* Se 1 a 10, irá gerar 1 parcelamentoItens para 1-1, 1-2, 1-2-3 até 10 e apresentar: 1x=30,24, 2x=30,24 etc
          for (var x = element.de; x <= ate; x++) {
            var parcTemp = {
              id: element.id,
              nome: x + " x",
              tipo: element.tipo,
              composto: element.composto,
              nivelar: element.nivelar,
              prazoPrimeira: element.prazoPrimeira,
              intervalo: element.intervalo,
              automatica: element.automatica,
              itens: []
            };
            if (element.tipo == "$") {
              parcItens.push({
                numero: x,
                descAcr: element.valor
              });
            } else {
              parcItens = [];
              for (var y = 1; y <= x; y++) {
                parcItens.push({
                  numero: x,
                  descAcr: element.valor
                });
              }
            }
            parcTemp.itens = Object.assign([], parcItens);
            parcelamentoItens.push(parcTemp);
          }
        });
        await Promise.all(processarParc);

        const processarRegra = parcelamentoItens.map(async (regra) => {
          var itensRegra = regra.itens;

          var vlrBase = this.vlrTaxado ? parseFloat(this.vlrTaxado) : this.vlrAjustado;
          if (this.vlrPermutado) {
            vlrBase = vlrBase - this.vlrPermutado;
            if (vlrBase < 0) {
              //* Não deverá cair aqui se não sobrou valor a parcelar (é tudo permuta)
              vlrBase = 0;
            }
          }
          vlrBase = vlrBase / itensRegra.length;

          var vlrBaseFixo = 0;
          var vlrAjBase = 0;
          var vlrAjuste = 0;

          var contador = 0;
          regra.itens.forEach((item) => {
            contador++;
            if (regra.tipo == "%") {
              item.valor = vlrBase * (this.vlrTaxado ? 1 : 1 + item.descAcr / 100) + vlrBaseFixo;

              vlrAjBase += item.valor;
              // if (vlrAjBase == 0) {
              //   //* como todas as parcelas terão o mesmo percentual do item da regra, calcula o percentual final para ajustar a última parcela
              //   vlrAjBase = vlrBruto * (1 + item.descAcr / 100);
              // }
              if (contador == regra.itens.length) {
                item.valor = vlrAjBase - vlrAjuste;
              }
            } else if (regra.tipo == "$") {
              item.valor = vlrBase + (this.vlrTaxado ? 0 : item.descAcr) + vlrBaseFixo;
            } else {
              //=
              item.valor = (this.vlrTaxado ? parseFloat(this.vlrTaxado) : item.descAcr) + vlrBaseFixo;
            }
            if (item.valor < 0) {
              item.valor = 0;
            }
            if (regra.composto) {
              vlrBase = item.valor;
            }
            vlrAjuste += parseFloat(item.valor);
            item.valor = item.valor.toFixed(2);
          });
          regra.valor = vlrAjuste;
          regra.valor = parseFloat(regra.valor.toFixed(2));

          regra.nro = util.somenteNumeros(regra.nome);
          regra.vlrUnit = vlrAjuste / regra.nro;
          regra.vlrUnit = regra.vlrUnit.toFixed(2);
          regra.vlrUnit = util.formatCurrency(regra.vlrUnit, this.$store.state.usuario.site_pais, true);
          regra.vlrTot = util.formatCurrency(vlrAjuste.toFixed(2), this.$store.state.usuario.site_pais, true);

          regra.nome = regra.nome + " = " + regra.valor;
          this.parcelamento.push(regra);
        });
        await Promise.all(processarRegra);

        var parcelamentoAuto = null;
        //* Nivela os valores das parcelas, se foi solicitado
        this.parcelamento.forEach((parc) => {
          if (parc.nivelar) {
            var vlrNivelado = parc.valor / parc.itens.length;
            vlrNivelado = vlrNivelado.toFixed(2);
            var somaParc = 0;
            parc.itens.forEach((item) => {
              item.valor = parseFloat(vlrNivelado);
              somaParc += item.valor;
            });
            if (somaParc != parc.valor) {
              //* Ajusta diferença na primeira parcela
              var vlrAjNiv = parc.itens[0].valor + (parc.valor - somaParc);
              vlrAjNiv = vlrAjNiv.toFixed(2);
              parc.itens[0].valor = parseFloat(vlrAjNiv);
            }
          }
          if ((!this.idAssinatura && (parc.automatica == 1 || parc.automatica == 3)) || (this.idAssinatura && (parc.automatica == 2 || parc.automatica == 3))) {
            //* Se é regra automática da captação, aplica automaticamente
            parcelamentoAuto = Object.assign({}, parc);
          }
        });

        if (this.vlrTaxado) {
          this.parcelamento.forEach((parc) => {
            var vlr = parseFloat(this.vlrTaxado);
            if (this.vlrPermutado) {
              vlr = vlr - this.vlrPermutado;
              if (vlr < 0) {
                //* Não deverá cair aqui se não sobrou valor a parcelar (é tudo permuta)
                vlr = 0;
              }
            }
            parc.valor = vlr;

            parc.nome = parc.itens.length + "x = " + this.vlrTaxado;

            parc.nro = parc.itens.length;
            parc.vlrUnit = parc.valor / parc.nro;
            parc.vlrUnit = parc.vlrUnit.toFixed(2);
            parc.vlrUnit = util.formatCurrency(parc.vlrUnit, this.$store.state.usuario.site_pais, true);
            parc.vlrTot = util.formatCurrency(parc.valor.toFixed(2), this.$store.state.usuario.site_pais, true);

            var vlrP = (parc.valor / parc.itens.length).toFixed(2);
            vlrP = parseFloat(vlrP);
            var ajuste = parc.valor;
            parc.itens.forEach((item) => {
              if (item.numero == parc.itens.length) {
                item.valor = ajuste.toFixed(2);
              } else {
                item.valor = vlrP.toFixed(2);
                ajuste -= vlrP;
              }
            });

            if ((!this.idAssinatura && (parc.automatica == 1 || parc.automatica == 3)) || (this.idAssinatura && (parc.automatica == 2 || parc.automatica == 3))) {
              //* Se é regra automática da captação, aplica automaticamente
              parcelamentoAuto = Object.assign({}, parc);
            }
          });
        }

        if (parcelamentoAuto && this.parcelamento.length == 1) {
          this.parcelamentoSelecionado = parcelamentoAuto;
        } else if (this.nroParcelas) {
          this.parcelamento.forEach((element) => {
            if (element.itens.length == this.nroParcelas) {
              this.parcelamentoSelecionado = element;
            }
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as parcelas do anúncio";
        this.snackErro = true;
      }
    },

    atualizaParcelas() {
      this.criarParcelas(this.parcelamentoSelecionado);
    },

    async criarParcelas(parcelamento) {
      this.validacao.parcelas = false;
      this.validacao.dtVecto = false;

      if (this.parcelas.length) {
        if (util.isFormaPgtoValorZero(this.formaPagamentoSelecionada.tipo)) {
          return;
        }
        this.validacao.parcelas = "Parcelas foram redefinidas" + (this.id ? ". Será necessário SALVAR novamente o anúncio." : "");
      }

      this.parcelas = [];

      if (this.vlrPacotes) {
        //* Não gera parcelas para anúncio de pacote
        return;
      }

      if (this.vlrPermutado) {
        var vlr = parseFloat(this.vlrTaxado) > 0 ? parseFloat(this.vlrTaxado) : this.vlrAjustado;
        if (this.vlrPermutado >= vlr) {
          //* Se valor foi todo consumido por permuta, não gera parcelas
          return;
        }
      }
      if (this.vlrPermutado && this.formaPagamentoSelecionada.tipo == "PERMUTA_RECEBER") {
        this.formaPagamentoSelecionada = {
          idFormaPgto: null,
          nome: "",
          tipo: "",
          maxParcelas: 0,
          bloqueioPublicacao: 0
        };
        this.mensagem = "Defina o valor líquido igual ao total permutado ou selecione outra forma de pagamento para liquidar o valor restante";
        this.snackErro = true;
        return;
      }

      if (parcelamento && parcelamento.itens && parcelamento.itens.length > 0) {
        var formaPagamento = {
          id: this.formaPagamentoSelecionada.idFormaPgto,
          tipo: this.formaPagamentoSelecionada.tipo,
          maxParcelas: parcelamento.itens[parcelamento.itens.length - 1].numero,
          idClienteCartao: this.idClienteCartao,
          idClienteConta: this.idClienteConta,
          idClienteEndereco: this.idClienteEndereco,
          idClienteCelesc: this.idClienteCelesc
        };

        if (!this.valida_data_vecto(util.formatDate(this.dtVectoUS))) {
          this.dtVectoUS = null;
        }
        var dtInicioCarencia = util.parseDateUS(this.dtCriacaoUS);
        if (this.dtVectoUS) {
          dtInicioCarencia = util.parseDateUS(this.dtVectoUS);
        }
        if (this.carencia) {
          dtInicioCarencia.setDate(dtInicioCarencia.getDate() + parseInt(this.carencia));
        }

        var vlrBt = this.vlrAjustado;
        if (this.vlrPermutado) {
          vlrBt = vlrBt - this.vlrPermutado;
          if (vlrBt < 0) {
            vlrBt = 0;
          }
        }
        var vlrTx = parseFloat(this.vlrTaxado);
        if (this.vlrPermutado) {
          vlrTx = vlrTx - this.vlrPermutado;
          if (vlrTx < 0) {
            vlrTx = 0;
          }
        }

        var diaV = 0;
        if (this.dtVectoUS) {
          //* Se dtVecto inicial foi definida, altera o dia para o mesmo dia do vecto
          diaV = dtInicioCarencia.getDate();
        }

        var dadosParcelas = {
          idFormato: this.formatoSelecionado.idFormato,
          idFormaPgto: this.formaPagamentoSelecionada.idFormaPgto,
          idRegra: parcelamento.id,
          autorizacao: this.anuncio.textoFatura,
          diaVencimento: diaV ? diaV : this.clienteSelecionado.diaVencimento ? this.clienteSelecionado.diaVencimento : 1,
          vlrFixo: 0,
          vlrBruto: vlrBt,
          vlrTaxado: vlrTx,
          dtInicio: util.dateToStringUS(dtInicioCarencia),
          formaPagamento: formaPagamento
          //produtos: this.produtos
        };

        try {
          const retornoParcelas = await api.post(
            process.env.VUE_APP_URL_WEB_API + "ass/renovacao/parcelas?idAnuncio=" + this.id + "&idRegra=" + parcelamento.id + "?idSite=" + this.idSitePrincipal,
            dadosParcelas
          );
          this.parcelas = retornoParcelas.data;
        } catch (e) {
          this.mensagem =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.snackErro = true;
          return;
        }

        //* Se houve desconto de comissão, aplica nas parcelas e publicações, reduzindo o líquido em x%
        var comissao = 0;
        if (this.anuncio.descComissao) {
          comissao = this.agentesOperacao[0].comissao;
          if (!comissao) {
            this.mensagem = "Desconto de comissão não aplicado, pois não há comissão do primeiro agente";
            this.snackErro = true;
            this.anuncio.descComissao = 0;
          }
        }
        this.parcelas.forEach((element) => {
          element.formaPagamento = this.formaPagamentoSelecionada.nome;
          element.bloqueioPublicacao = this.formaPagamentoSelecionada.bloqueioPublicacao;
          if (comissao) {
            element.vlrBruto = element.valor;
            element.valor = element.vlrBruto * (1 - comissao / 100);
            element.valor = parseFloat(element.valor.toFixed(2));
          }
        });
        this.atualizaValores();
        if (this.parcelas.length == 0) {
          this.validacao.parcelas = "Selecione a forma de pagamento e o parcelamento para criar as parcelas";
        }
      }
    },
    editarParcela(item) {
      this.idParcela = item.id + "";
      this.dialogParcela = true;
    },
    closeParcela() {
      this.dialogParcela = false;
      this.idParcela = "";
    },

    async emitirRecibo() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/parcela/recibos/" + this.idSitePrincipal + "?anuncios=" + this.id);

        util.imprimir(response.data);
      } catch (e) {
        this.mensagem = "ERRO: " + e.response.data;
        this.snackErro = true;
      }
    },

    async editarAjuste(item) {
      try {
        this.ajusteIndex = this.parcelas.indexOf(item);
        this.ajusteItem = Object.assign({}, item);

        this.ajusteItemDtVectoOUS = util.dateToStringUS(this.ajusteItem.dtVectoOriginal);

        this.validacao.ajuste_valor = false;
        this.validacao.ajuste_vecto = false;

        //* Carrega as formas de pagamento
        this.formasPgtoParcela = [];
        //* Para captação deve ser bloqueado somente nas formas do plano, mas para cobrança, quem tem permissão para alterar pode tentar cobrar por qualquer outra forma
        var responseFormasPgto = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "adm/forma-pagamento/lista?idSite=" +
            this.idSitePrincipal +
            "&pagas=" +
            (util.isFormaPgtoValorZero(this.formaPagamentoSelecionada.tipo) ? "false" : "true")
        );
        var formas = responseFormasPgto.data;
        formas.forEach((element) => {
          //* Processa as formas de pagamento para remover aquelas inconsistentes com a do anúncio (ex: se CORTESIA, só poderá retornar CORTESIA, se BONIFICACAO idem - A_VISTA, pode ser alterada para outra)
          if (util.isFormaPgtoEspeciais(this.formaPagamentoSelecionada.tipo)) {
            //* Se forma de pagamento original da parcela for especial, irá incluir somente ela
            if (this.formaPagamentoSelecionada.tipo == element.tipo) {
              this.formasPgtoParcela.push(element);
            }
          } else {
            //* Se forma de pagamento da parcela não for especial, irá incluir as não especiais
            if (!util.isFormaPgtoEspeciais(element.tipo)) {
              this.formasPgtoParcela.push(element);
            }
          }
        });
        this.formaPgtoParcelaSelecionada = {
          id: item.idFormaPgto,
          nome: item.formaPagamento,
          tipo: item.formaPagamentoTipo,
          bloqueioPublicacao: item.bloqueioPublicacao
        };
        this.dialogAjuste = true;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as formas de pagamento da regra de ajuste";
        this.snackErro = true;
      }
    },
    closeAjuste() {
      this.dialogAjuste = false;
    },
    async saveAjuste() {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formAjuste.validate()) {
        return;
      }

      this.validacao.ajuste_valor = false;
      this.validacao.ajuste_vecto = false;
      this.validacao.parcelas = false;
      this.validacao.idFormaPgtoParcela = false;
      this.validacao.idClienteCelesc = false;
      this.validacao.idClienteCartao = false;
      this.validacao.idClienteConta = false;
      this.validacao.idClienteEndereco = false;

      var vlrOperacao = this.vlrAjustado;
      if (this.vlrPermutado) {
        vlrOperacao = vlrOperacao - this.vlrPermutado;
        if (vlrOperacao < 0) {
          //* Não deverá cair aqui se não sobrou valor a parcelar (é tudo permuta)
          vlrOperacao = 0;
        }
      } else if (this.anuncio.descComissao) {
        vlrOperacao = this.vlrCobrado;
      }

      this.ajusteItem.dtVectoOriginal = util.parseDate(this.ajusteItemDtVectoO);
      if (parseFloat(this.ajusteItem.valor) > vlrOperacao) {
        this.validacao.ajuste_valor = "Valor da parcela não pode ser superior ao valor do anúncio";
        return;
      }
      if (this.ajusteIndex > 0) {
        var dtAnt = this.parcelas[this.ajusteIndex - 1].dtVectoOriginal;
        var dtV = this.ajusteItem.dtVectoOriginal;
        if (dtV < dtAnt) {
          this.validacao.ajuste_vecto = "Vencimento deve ser posterior ao vencimento da parcela anterior";
          return;
        }
      }

      if (!this.formaPgtoParcelaSelecionada || !this.formaPgtoParcelaSelecionada.id) {
        this.validacao.idFormaPgtoParcela = "Selecione a forma de pagamento";
        return;
      }
      if (this.formaPgtoParcelaSelecionada.tipo == "CELESC" || this.formaPgtoParcelaSelecionada.tipo == "SAMAE") {
        if (this.idClienteCelesc == null) {
          this.validacao.idClienteCelesc = "Selecione a Unidade Consumidora";
          return;
        } else {
          this.ajusteItem.idClienteCelesc = this.idClienteCelesc;
        }
      }
      if (this.formaPgtoParcelaSelecionada.tipo == "CARTAO_CREDITO") {
        if (this.idClienteCartao == null) {
          this.validacao.idClienteCartao = "Selecione o Cartão de Crédito";
          return;
        } else {
          this.ajusteItem.idClienteCartao = this.idClienteCartao;
        }
      }
      if (this.formaPgtoParcelaSelecionada.tipo == "DEBITO_CONTA") {
        if (this.idClienteConta == null) {
          this.validacao.idClienteConta = "Selecione a Conta Corrente";
          return;
        } else {
          this.ajusteItem.idClienteConta = this.idClienteConta;
        }
      }
      if (this.formaPgtoParcelaSelecionada.tipo == "BOLETO" || this.formaPgtoParcelaSelecionada.tipo == "CARTEIRA") {
        if (this.idClienteEndereco == null) {
          this.validacao.idClienteEndereco = "Selecione o Endereço de Cobrança";
          return;
        } else {
          this.ajusteItem.idClienteEndereco = this.idClienteEndereco;
        }
      }

      this.ajusteItem.idFormaPgto = this.formaPgtoParcelaSelecionada.id;
      this.ajusteItem.formaPagamento = this.formaPgtoParcelaSelecionada.nome;
      this.ajusteItem.formaPagamentoTipo = this.formaPgtoParcelaSelecionada.tipo;
      this.ajusteItem.bloqueioPublicacao = this.formaPgtoParcelaSelecionada.bloqueioPublicacao;

      var parcelasTemp = [];
      this.parcelas.forEach((element) => {
        parcelasTemp.push(Object.assign({}, element));
      });

      if (this.ajusteIndex > -1) {
        Object.assign(parcelasTemp[this.ajusteIndex], this.ajusteItem);
      } else {
        parcelasTemp.push(this.ajusteItem);
      }

      var nroParcelas = parcelasTemp.length - 1;
      var vlrRestante = vlrOperacao;
      var vlrAjuste = vlrOperacao;
      for (var x = 0; x <= nroParcelas; x++) {
        var vlr = 0;
        if (parseInt(this.ajusteIndex) >= x) {
          vlr = parseFloat(parcelasTemp[x].valor);
          vlrRestante -= vlr;
        } else {
          if (x < nroParcelas) {
            vlr = vlrRestante / (nroParcelas - this.ajusteIndex);
            vlr = vlr.toFixed(2);
            parcelasTemp[x].valor = vlr;
          } else {
            parcelasTemp[x].valor = vlrAjuste;
          }
        }
        vlrAjuste -= parseFloat(vlr);
        vlrAjuste = vlrAjuste.toFixed(2);
      }
      var vlrTotal = 0;
      parcelasTemp.forEach((element) => {
        var vlr = parseFloat(element.valor);
        vlr = vlr.toFixed(2);
        if (parseFloat(vlr) <= 0) {
          this.validacao.ajuste_valor = "Não pode haver parcela com valor zero";
        }
        dtAnt = element.dtVectoOriginal;
        vlrTotal += parseFloat(vlr);
        vlrTotal = vlrTotal.toFixed(2);
        vlrTotal = parseFloat(vlrTotal);
      });
      if (this.validacao.ajuste_valor) {
        return;
      }
      if (vlrTotal != vlrOperacao) {
        this.validacao.ajuste_valor = "Soma das parcelas deve ser igual ao valor do anúncio";
        return;
      }
      if (this.$refs.formAjuste.validate()) {
        this.parcelas = [];
        parcelasTemp.forEach((element) => {
          this.parcelas.push(Object.assign({}, element));
        });
        this.closeAjuste();
        this.$refs.formAjuste.resetValidation();
      }
    },

    async carregarTabela() {
      try {
        this.tipoCobranca = "";
        if (this.formatoSelecionado.id) {
          const response = await api.get(
            process.env.VUE_APP_URL_WEB_API +
              "com/anuncio-tabela/item?idFormatoCaderno=" +
              this.formatoSelecionado.id +
              "&data=" +
              (this.anuncio.dtOperacao ? util.dateToStringUS(this.anuncio.dtOperacao) : util.dateToStringUS(new Date())) +
              (this.determinacaoSelecionada && this.determinacaoSelecionada.id ? "&idDeterminacao=" + this.determinacaoSelecionada.id : "") +
              (this.colocacaoSelecionada && this.colocacaoSelecionada.id ? "&idColocacao=" + this.colocacaoSelecionada.id : "")
          );
          if (response.data) {
            this.tabelaAnuncio = response.data;

            if (this.tabelaAnuncio.tipoCobranca == 1) {
              this.tipoCobranca = "Centímetro x Coluna";
            } else if (this.tabelaAnuncio.tipoCobranca == 2) {
              this.tipoCobranca = "Preço Fixo";
            } else if (this.tabelaAnuncio.tipoCobranca == 3) {
              this.tipoCobranca = "Largura x Altura";
            } else if (this.tabelaAnuncio.tipoCobranca == 4) {
              this.tipoCobranca = "Número de Palavras";
            } else if (this.tabelaAnuncio.tipoCobranca == 5) {
              this.tipoCobranca = "Número de Caracteres";
            }
            this.atualizaValores();
          } else {
            this.tabelaAnuncio = {};
            this.mensagem = "Não existe tabela de preços para esta configuração de anúncio";
            this.snackErro = true;
          }
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a tabela de preços";
        this.snackErro = true;
      }
    },

    confirmado() {
      this.dialogConfirm = false;
      if (this.tipoConfirm == "CANCELAR") {
        this.cancelar();
      } else if (this.tipoConfirm == "EXCLUIR_AGENTE") {
        this.excluiAgente();
      } else if (this.tipoConfirm == "EXCLUIR_PUBLICAÇÃO") {
        this.excluiPublicacao();
      } else if (this.tipoConfirm == "REVERTER_PUBLICAÇÃO") {
        this.revertePublicacao();
      } else if (this.tipoConfirm == "EXCLUIR_CONDIÇÃO_ESPECIAL") {
        this.excluiCondicaoEspecial();
      } else if (this.tipoConfirm == "LIMITE_AGENTE") {
        this.salvar("false", true);
      }
    },
    naoConfirmado() {
      if (this.tipoConfirm == "EXCLUIR_PUBLICAÇÃO") {
        this.carregarPublicacoes();
      }
      this.dialogConfirm = false;
    },
    setCalculo(textoAnuncio) {
      if (this.tabelaAnuncio.tipoCobranca > 3) {
        textoAnuncio = (this.anuncio.titulo ? this.anuncio.titulo + " " : "") + (textoAnuncio ? textoAnuncio : "");

        var caracteres = 0;
        var palavras = 0;
        if (textoAnuncio) {
          caracteres = textoAnuncio.length;
          var texto = textoAnuncio.replace(/(\r\n|\n|\r)/g, " ").trim();
          palavras = texto ? texto.split(/\s+/g).length : 0;
          if (textoAnuncio.match(/[\r\n]/g)) {
            var linhas = textoAnuncio.match(/[\r\n]/g).length;
            caracteres -= linhas;
          }
        }

        this.anuncio.caracteres = this.anuncio.titulo ? caracteres - 1 : caracteres; //o espaço após o título
        this.anuncio.palavras = palavras;
      } else if (this.tabelaAnuncio.tipoCobranca) {
        this.anuncio.caracteres = 0;
        this.anuncio.palavras = 0;
      }
    },

    visualizarLogPublicacao(item) {
      this.idPublicacao = item.id + "";
      this.dialogLogAlteracao = true;
    },
    closeLogAlteracao() {
      this.dialogLogAlteracao = false;
      this.idPublicacao = "";
    },

    valida_colunas(value) {
      value = value.toString();
      var retorno = true;
      if (this.tabelaAnuncio && this.tabelaAnuncio.id) {
        if (this.tabelaAnuncio.tipoCobranca == 1 || this.tabelaAnuncio.tipoCobranca == 3) {
          if (!value || parseFloat(value.replace(".", "").replace(" ", "").replace(",", ".")) == 0) {
            retorno = "Verifique " + (this.tabelaAnuncio.tipoCobranca == 3 ? "a largura do anúncio" : "o número de colunas do anúncio");
          } else {
            retorno = false;
            if (this.formatoSelecionado.tipo == "S") {
              if (parseFloat(value.replace(".", "").replace(" ", "").replace(",", ".")) <= 5000) {
                retorno = true;
              }
            } else {
              if (
                value &&
                parseFloat(value.replace(".", "").replace(" ", "").replace(",", ".")) <=
                  (this.tabelaAnuncio.tipoCobranca == 3
                    ? this.cadernoSelecionado.paginaLargura - this.cadernoSelecionado.margemEsquerda - this.cadernoSelecionado.margemDireita
                    : this.cadernoSelecionado.numeroColunas * 2)
              ) {
                retorno = true;
              }
            }
            if (!retorno) {
              retorno = "Verifique " + (this.tabelaAnuncio.tipoCobranca == 3 ? "a largura do anúncio" : "o número de colunas do anúncio");
            }
          }
        }
      }
      return retorno;
    },
    valida_altura(value) {
      value = value.toString();
      var retorno = true;
      if (this.tabelaAnuncio && this.tabelaAnuncio.id) {
        if (this.tabelaAnuncio.tipoCobranca == 1 || this.tabelaAnuncio.tipoCobranca == 3) {
          if (!value || parseFloat(value.replace(".", "").replace(" ", "").replace(",", ".")) == 0) {
            retorno = "Verifique a altura do anúncio";
          } else {
            retorno = false;
            if (this.formatoSelecionado.tipo == "S") {
              if (parseFloat(value.replace(".", "").replace(" ", "").replace(",", ".")) <= 5000) {
                retorno = true;
              }
            } else {
              if (
                value &&
                parseFloat(value.replace(".", "").replace(" ", "").replace(",", ".")) <=
                  this.cadernoSelecionado.paginaAltura - this.cadernoSelecionado.margemSuperior - this.cadernoSelecionado.margemInferior
              ) {
                retorno = true;
              }
            }
            if (!retorno) {
              retorno = "Verifique a altura do anúncio";
            }
          }
        }
      }
      return retorno;
    },
    remove(item) {
      this.diasSelecionados.splice(item.index, 1);
    },
    valida_data(date) {
      if (date) {
        return util.valida_data_BR(date);
      } else {
        return true;
      }
    },
    valida_data_vecto(date) {
      if (!this.dtCriacaoUS) {
        return false;
      }
      if (date) {
        var retorno = util.valida_data_BR(date);
        if (!retorno) {
          return false;
        }
        const dtOp = util.parseDateUS(this.dtCriacaoUS);
        const dtV = util.parseDate(date);
        if (dtV < dtOp) {
          return false;
        }
        return true;
      } else {
        return true;
      }
    },
    marcarDesmarcarTodos(key) {
      const vlr = parseFloat(this.vlrTaxado) > 0 ? parseFloat(this.vlrTaxado) : this.vlrAjustado;

      if (!vlr) {
        key = 0;
        this.mensagem = "Defina o valor do anúncio (colunas/altura/texto/publicações)";
        this.snackErro = true;
        return;
      }

      if (key == 1 && this.vlrPermutado >= vlr) {
        key = 0;
        this.mensagem = "Valor permutado já é suficiente para quitar o valor do anúncio";
        this.snackErro = true;
        return;
      }

      this.vlrPermutado = 0;
      var itens = 0;
      this.permutasPagar.forEach((element) => {
        if (key == 1 && this.vlrPermutado >= vlr) {
          this.mensagem = "Valor permutado já é suficiente para quitar o valor do anúncio";
          this.snackErro = true;
        } else {
          element.selecionado = key;
          if (element.selecionado) {
            itens++;
            this.vlrPermutado += element.valor + element.descAcr;
          }
        }
      });
      if (itens) {
        this.anuncio.descComissao = 0; //* Pois permuta não gera valor
        this.rodapePermutasPagar.pageText = itens + " selecionado" + (itens > 1 ? "s" : "") + " = " + util.formatCurrency(this.vlrPermutado);
      } else {
        this.rodapePermutasPagar.pageText = "";
      }
      //* incluiu ou excluiu permuta, parcelas devem ser recriadas, pois serão de valores diferentes
      this.limpaParcelas();
      this.validacao.permutasPagar = false;
    },
    ativarDesativarItem(item) {
      const vlr = parseFloat(this.vlrTaxado) > 0 ? parseFloat(this.vlrTaxado) : this.vlrAjustado;

      if (!vlr) {
        this.mensagem = "Defina o valor do anúncio (colunas/altura/texto/publicações)";
        this.snackErro = true;
        return;
      }

      if (item.selecionado == 0 && this.vlrPermutado >= vlr) {
        this.mensagem = "Valor permutado já é suficiente para quitar o valor do anúncio";
        this.snackErro = true;
        return;
      }
      var dadoIndex = this.permutasPagar.indexOf(item);
      var dadoItem = Object.assign({}, item);
      dadoItem.selecionado = dadoItem.selecionado == 1 ? 0 : 1;
      Object.assign(this.permutasPagar[dadoIndex], dadoItem);

      this.vlrPermutado = 0;
      var itens = 0;
      this.permutasPagar.forEach((element) => {
        if (element.selecionado) {
          itens++;
          this.vlrPermutado += element.valor + element.descAcr;
        }
      });
      if (itens) {
        this.anuncio.descComissao = 0; //* Pois permuta não gera valor
        this.rodapePermutasPagar.pageText = itens + " selecionado" + (itens > 1 ? "s" : "") + " = " + util.formatCurrency(this.vlrPermutado);
      } else {
        this.rodapePermutasPagar.pageText = "";
      }
      //* incluiu ou excluiu permuta, parcelas devem ser recriadas, pois serão de valores diferentes
      this.limpaParcelas();
      this.validacao.permutasPagar = false;
    },

    marcarDesmarcarTodosPac(key) {
      const vlr = parseFloat(this.vlrTaxado) > 0 ? parseFloat(this.vlrTaxado) : this.vlrAjustado;

      if (!vlr) {
        key = 0;
        this.mensagem = "Defina o valor do anúncio (colunas/altura/texto/publicações)";
        this.snackErro = true;
        return;
      }

      if (key == 1 && this.vlrPacotes >= vlr) {
        key = 0;
        this.mensagem = "Valor dos pacotes já é suficiente para quitar o valor do anúncio";
        this.snackErro = true;
        return;
      }

      this.vlrPacotes = 0;
      var itens = 0;
      this.pacotes.forEach((element) => {
        if (key == 1 && this.vlrPacotes >= vlr) {
          this.mensagem = "Valor dos pacotes já é suficiente para quitar o valor do anúncio";
          this.snackErro = true;
        } else {
          element.selecionado = key;
          if (element.selecionado) {
            itens++;
            this.vlrPacotes += element.vlrSaldo;
          }
        }
      });
      if (itens) {
        this.anuncio.descComissao = 0; //* Pois a operação de venda é o pacote
        this.rodapePacotes.pageText = itens + " selecionado" + (itens > 1 ? "s" : "") + " = " + util.formatCurrency(this.vlrPacotes);
      } else {
        this.rodapePacotes.pageText = "";
      }
      //* incluiu ou excluiu pacote, parcelas devem ser recriadas, pois serão de valores diferentes
      this.limpaParcelas();
      this.validacao.pacotes = false;
      if (!this.vlrPacotes) {
        this.painel = [0];
      }
    },
    ativarDesativarItemPac(item) {
      const vlr = parseFloat(this.vlrTaxado) > 0 ? parseFloat(this.vlrTaxado) : this.vlrAjustado;

      if (!vlr) {
        this.mensagem = "Defina o valor do anúncio (colunas/altura/texto/publicações)";
        this.snackErro = true;
        return;
      }

      if (item.selecionado == 0 && this.vlrPacotes >= vlr) {
        this.mensagem = "Valor dos pacotes já é suficiente para quitar o valor do anúncio";
        this.snackErro = true;
        return;
      }
      var dadoIndex = this.pacotes.indexOf(item);
      var dadoItem = Object.assign({}, item);
      dadoItem.selecionado = dadoItem.selecionado == 1 ? 0 : 1;
      Object.assign(this.pacotes[dadoIndex], dadoItem);

      this.vlrPacotes = 0;
      var itens = 0;
      this.pacotes.forEach((element) => {
        if (element.selecionado) {
          itens++;
          this.vlrPacotes += element.vlrSaldo;
        }
      });
      if (itens) {
        this.anuncio.descComissao = 0; //* Pois a operação de venda é o pacote
        this.rodapePacotes.pageText = itens + " selecionado" + (itens > 1 ? "s" : "") + " = " + util.formatCurrency(this.vlrPacotes);
      } else {
        this.rodapePacotes.pageText = "";
      }
      //* incluiu ou excluiu pacote, parcelas devem ser recriadas, pois serão de valores diferentes
      this.limpaParcelas();
      this.validacao.pacotes = false;
      if (!this.vlrPacotes) {
        this.painel = [0];
      }
    },

    limparTextoPadrao() {
      this.anuncio.textoAnuncio = "";
      this.vlrTaxado = 0;
      this.anuncio.vlrTaxado = 0;
      this.limpaParcelas("vlrTaxado");
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    filtrarObjetosIdentificacao(item, queryText, itemText) {
      return util.filtrarObjetosIdentificacao(item, queryText, itemText);
    },
    pLetra(text) {
      return util.pLetra(text);
    },
    valida_nome(text) {
      return util.valida_nome(text);
    },
    formatDate(date) {
      return util.formatDate(date);
    },
    formatDateTime(datetime) {
      return util.formatDateTime(datetime);
    },
    formatDateUS(date) {
      return util.formatDateUS(date);
    },
    formatCurrency(valor) {
      return util.formatCurrency(valor);
    }
  },

  computed: {
    calculo() {
      this.setCalculo(this.anuncio.textoAnuncio);
      return `<span class='blue--text'><b>${this.anuncio.caracteres ? "Caracteres: " + this.anuncio.caracteres : ""}&nbsp;&nbsp;&nbsp;${
        this.anuncio.palavras ? "Palavras: " + this.anuncio.palavras : ""
      }</b></span>`;
    },
    allowedDates() {
      return (val) =>
        this.editar &&
        //util.parseDateUS(val) > new Date() && - permitindo datas passadas
        ((this.cadernoSelecionado.publicaDom && this.diasSelecionados.includes("Dom") && util.parseDateUS(val).getDay() == 0) ||
          (this.cadernoSelecionado.publicaSeg && this.diasSelecionados.includes("Seg") && util.parseDateUS(val).getDay() == 1) ||
          (this.cadernoSelecionado.publicaTer && this.diasSelecionados.includes("Ter") && util.parseDateUS(val).getDay() == 2) ||
          (this.cadernoSelecionado.publicaQua && this.diasSelecionados.includes("Qua") && util.parseDateUS(val).getDay() == 3) ||
          (this.cadernoSelecionado.publicaQui && this.diasSelecionados.includes("Qui") && util.parseDateUS(val).getDay() == 4) ||
          (this.cadernoSelecionado.publicaSex && this.diasSelecionados.includes("Sex") && util.parseDateUS(val).getDay() == 5) ||
          (this.cadernoSelecionado.publicaSab && this.diasSelecionados.includes("Sáb") && util.parseDateUS(val).getDay() == 6));
    }
  },

  watch: {
    dtCriacaoUS() {
      this.dtCriacao = util.formatDate(this.dtCriacaoUS);
      this.mnuDtCriacao = false;
    },
    dtVectoUS() {
      this.dtVecto = util.formatDate(this.dtVectoUS);
      this.mnuDtVecto = false;
    },
    async clienteSelecionado(val) {
      if (val) {
        if (!this.carregandoCli || this.$route.query.idCliente) {
          if (!val.situacao) {
            this.mensagem = "Cliente [" + val.nome + "] encontra-se INATIVO.";
            this.snackErro = true;
            this.limpaCliente();
            return;
          }
          if (!val.statusCredito) {
            this.mensagem = "Cliente [" + val.nome + "] encontra-se com crédito BLOQUEADO.";
            this.snackErro = true;
            this.limpaCliente();
            return;
          }
          if (val.statusCredito == 2) {
            this.mensagem = "Cliente encontra-se com crédito EM ANÁLISE.";
            this.snackErro = true;
          }
        }
        this.anuncio.idCliente = val.id;
        if (!this.carregandoCli) {
          this.recarregarItensCliente();
          this.carregandoCli = false;
        }
        this.carregarUltimoAnuncio();
        this.carregarUltimaAssinatura();
        if (!this.inicial) {
          //* Carrega uma única vez todos os dados para permitir alteração
          this.idClienteEndereco = null;
          this.idClienteCartao = null;
          this.idClienteCelesc = null;
          this.idClienteConta = null;
          await this.carregarEnderecos("cobranca");
          await this.carregarCartoes();
          await this.carregarCelescs();
          await this.carregarContas();
          if (this.formaPagamentoSelecionada && this.formaPagamentoSelecionada.tipo) {
            setTimeout(() => {
              this.criarParcelas(this.parcelamentoSelecionado); //pois é outro melhor dia do cliente
            }, 500);
          }
        }
      } else {
        this.limpaCliente();
      }
    },
    produtoSelecionado(val) {
      this.cadernos = [];
      this.formatos = [];
      this.determinacoes = [];
      this.colocacoes = [];
      if (this.cadernoSelecionado && this.cadernoSelecionado.id) {
        this.cadernoSelecionado = {};
      }
      this.carregarCadernos(val);
      if (!this.anuncio.id) {
        this.limpaListas();
      }
    },
    cadernoSelecionado(val) {
      this.formatos = [];
      this.determinacoes = [];
      this.colocacoes = [];
      if (this.formatoSelecionado && this.formatoSelecionado.id) {
        this.formatoSelecionado = {};
      }
      this.carregarFormatos(val);
      if (!this.anuncio.id) {
        this.limpaListas();
      }
      this.diasPublicacao = [];
      this.diasSelecionados = [];
      if (this.cadernoSelecionado.publicaDom) {
        this.diasPublicacao.push("Dom");
        this.diasSelecionados.push("Dom");
      }
      if (this.cadernoSelecionado.publicaSeg) {
        this.diasPublicacao.push("Seg");
        this.diasSelecionados.push("Seg");
      }
      if (this.cadernoSelecionado.publicaTer) {
        this.diasPublicacao.push("Ter");
        this.diasSelecionados.push("Ter");
      }
      if (this.cadernoSelecionado.publicaQua) {
        this.diasPublicacao.push("Qua");
        this.diasSelecionados.push("Qua");
      }
      if (this.cadernoSelecionado.publicaQui) {
        this.diasPublicacao.push("Qui");
        this.diasSelecionados.push("Qui");
      }
      if (this.cadernoSelecionado.publicaSex) {
        this.diasPublicacao.push("Sex");
        this.diasSelecionados.push("Sex");
      }
      if (this.cadernoSelecionado.publicaSab) {
        this.diasPublicacao.push("Sáb");
        this.diasSelecionados.push("Sáb");
      }
    },
    async formatoSelecionado(val) {
      // if (val.tipo != "LT") {
      //   this.anuncio.titulo = "";
      // }
      this.determinacoes = [];
      this.colocacoes = [];
      const atDados = this.anuncio.idFormatoCaderno != val.id;
      this.anuncio.idFormatoCaderno = val.id;

      if (this.determinacaoSelecionada && this.determinacaoSelecionada.id) {
        this.determinacaoSelecionada = {};
      }
      if (this.colocacaoSelecionada && this.colocacaoSelecionada.id) {
        this.colocacaoSelecionada = {};
      }
      this.carregarAgentesFormato();
      this.carregarCondicoesEspeciaisFormato();
      this.carregarFormasPagamentoFormato();
      if (this.produtoSelecionado.tipo != "CLASSIFICADOS") {
        //* Busca colocações e determinações da tabela de preços pelo formato selecionado
        this.carregarDeterminacoesColocacoes(val);
      } else {
        await this.carregarTabela();
      }
      if (atDados) {
        setTimeout(() => {
          this.anuncio.colunas = val.colunas;
          this.anuncio.altura = val.altura;
        }, 3000);
      }
      if (!this.anuncio.id) {
        this.limpaListas();
      }
    },
    determinacaoSelecionada(val) {
      this.anuncio.idDeterminacao = val.id;
      if (val && val.id) {
        this.carregarTabela();
      }
      this.conferePosicao();
      if (!this.anuncio.id) {
        this.limpaListas();
      }
    },
    colocacaoSelecionada(val) {
      this.anuncio.idColocacao = val.id;
      if (val && val.id) {
        this.carregarTabela();
      }
      this.conferePosicao();
      if (!this.anuncio.id) {
        this.limpaListas();
      }
    },
    secaoSelecionada(val) {
      if (val) {
        this.anuncio.idSecao = val.id;
        if (this.id && this.secaoAnterior != this.anuncio.idSecao) {
          this.publicacoes.forEach((element) => {
            if (util.parseDateUS(element.dtPublicacao) > new Date()) {
              element.statusPublicacao = 2;
            }
          });
        }
      }
    },
    textoSelecionado(val) {
      if (val) {
        if (this.anuncio.textoAnuncio) {
          this.mensagem = "Limpe o texto do anúncio para aplicar novo texto";
          this.snackErro = true;
        } else {
          // this.anuncio.titulo = val.nome;
          this.anuncio.textoAnuncio = val.texto;
          this.vlrTaxado = val.valor;
          this.anuncio.vlrTaxado = val.valor;
          this.limpaParcelas("vlrTaxado");
        }
      }
    },
    agenteSelecionado() {
      this.validacao.idAgente = false;
    },
    condicaoEspecialSelecionada(val) {
      if (val && val.id) {
        var incluido = false;
        this.condicoesEspeciais.forEach((element) => {
          if (val.id == element.idFormatoCondicaoEspecial) {
            incluido = true;
            this.mensagem = "Condição Especial já incluída";
            this.condicaoEspecialSelecionada = {};
            this.snackErro = true;
          }
        });
        if (!incluido) {
          this.validacao.condicoesEspeciais = false;
          if (this.anuncio.id) {
            this.incluirCondicaoEspecial(val);
          } else {
            this.condicoesEspeciais.push({
              id: null,
              idFormatoCondicaoEspecial: val.id,
              idAnuncio: this.anuncio.id,
              condicaoEspecial: val.nome,
              tipo: val.tipo,
              valor: val.valor,
              condicaoEspecialSituacao: val.condicaoEspecialSituacao
            });
            this.condicaoEspecialSelecionada = {};
            this.limpaParcelas();
          }
        }
      }
    },
    parcelamentoSelecionado(val) {
      if (!this.nroParcelas && val && val.itens && val.itens.length > 0) {
        this.nroParcelas = val.itens.length;
      }
      this.criarParcelas(val);
    },
    dialogLogAlteracao(val) {
      val || this.closeLogAlteracao();
    },
    dialogAgente(val) {
      val || this.closeAgente();
    },
    dialogParcela(val) {
      if (val == null) {
        this.carregarParcelasAnuncio();
      }
      val || this.closeParcela();
    },
    ajusteItemDtVectoOUS() {
      this.ajusteItemDtVectoO = util.formatDate(this.ajusteItemDtVectoOUS);
      this.mnuAjusteItemDtVectoO = false;
    }
  },
  async created() {
    this.isUpdating = false;
    this.bloqueiaBotoes = true;
    this.id = this.$route.params.id ? this.$route.params.id : "";

    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

    this.$store.state.usuario.direitos.forEach((element) => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_ANÚNCIOS_CRIAR") {
        this.direitoCriar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_ANÚNCIOS_CANCELAR") {
        this.direitoCancelar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_ANUNCIANTES_EDITAR") {
        this.direitoAnunciante = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINATURAS_EDITAR") {
        this.direitoAssinatura = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_ANÚNCIOS_TAXAR") {
        this.direitoTaxar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_ANÚNCIOS_LOG") {
        this.direitoLog = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_CONTAS_PAGAR_EDITAR") {
        this.direitoOperacao = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_ACESSO") {
        this.direitoParcelas = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_PACOTES_EDITAR") {
        this.direitoPacote = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_ANÚNCIOS_ALTERAR_FORMA_PAGAMENTO") {
        this.direitoFormaPgto = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_ANÚNCIOS_LIBERAR_NEGOCIAÇÃO") {
        this.direitoNegociacao = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_ANÚNCIOS_LIBERAR_PUBLICAÇÃO") {
        this.direitoPublicacao = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_COMERCIAL_DEFINIR_COMISSÃO") {
        this.direitoComissao = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_COMERCIAL_EDITAR_DESCONTO_COMISSÃO") {
        this.direitoDescComissao = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_RECIBO") {
        this.direitoRecibo = true;
      }
    });

    this.anuncio.idSite = this.idSitePrincipal;

    await this.carregarTextos();

    //* Se é edição, carrega o item
    if (this.id == "") {
      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "COMERCIAL_ANÚNCIOS_CRIAR"]);
      this.dtCriacaoUS = util.dateToStringUS(new Date());
      await this.carregarProdutos();
      await this.carregarSecoes();

      if (this.$route.query.idCliente) {
        try {
          //* Carrega o cliente recebido na url
          const responseCliente = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente/" + this.$route.query.idCliente + "?idSite=" + this.idSitePrincipal);
          if (responseCliente.data) {
            var cliente = responseCliente.data;
            this.anuncio.idCliente = cliente.id;
            this.clientes.push({
              id: cliente.id,
              nome: cliente.nome,
              diaVencimento: cliente.diaVencimento,
              statusCredito: cliente.statusCredito,
              situacao: cliente.situacao
            });
            this.clienteSelecionado = {
              id: cliente.id,
              nome: cliente.nome,
              diaVencimento: cliente.diaVencimento,
              statusCredito: cliente.statusCredito,
              situacao: cliente.situacao
            };
            await this.recarregarItensCliente();
          }
        } catch {
          this.isUpdating = true;
          this.mensagem = "Ocorreu um erro ao carregar os dados do cliente";
          this.snackErro = true;
        }
      }
    } else {
      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "COMERCIAL_ANÚNCIOS_EDITAR"]);

      try {
        //* Carrega o item - somente se for público (idSite=null) ou for do site do usuário logado
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "com/anuncio/" + this.id + "?idSite=" + this.idSitePrincipal);
        if (!response.data) {
          this.$router.push("/comercial/anuncios");
        } else {
          this.inicial = true;
          setTimeout(() => {
            this.inicial = false;
          }, 2000);

          // setTimeout(() => {
          this.anuncio = response.data;
          await this.carregarProdutos();
          this.dtCriacaoBR = util.formatDateTime(this.anuncio.dtCriacao);
          this.dtCriacaoUS = util.dateToStringUS(this.anuncio.dtCriacao);
          this.vlrTaxado = this.anuncio.vlrTaxado;
          this.vlrPermutado = this.anuncio.vlrPermutado;
          this.vlrPacotes = this.anuncio.vlrPacotes;

          this.vlrTaxadoAnterior = this.anuncio.vlrTaxado;
          this.tituloAnterior = this.anuncio.titulo;
          this.textoAnterior = this.anuncio.textoAnuncio;
          this.secaoAnterior = this.anuncio.idSecao;
          this.negociacaoAnterior = this.anuncio.emNegociacao;

          //* Carrega inicialmente, somente o cliente da renovação (se permitida alteração, poderá buscar outros clientes)
          this.clientes.push({
            id: this.anuncio.idCliente,
            nome: this.anuncio.cliente,
            diaVencimento: this.anuncio.diaVencimento,
            statusCredito: this.anuncio.statusCredito,
            situacao: this.anuncio.situacaoCliente
          });
          this.clienteSelecionado = {
            id: this.anuncio.idCliente,
            nome: this.anuncio.cliente,
            diaVencimento: this.anuncio.diaVencimento,
            statusCredito: this.anuncio.statusCredito,
            situacao: this.anuncio.situacaoCliente
          };
          // }, 500);

          // setTimeout(() => {
          await this.carregarPublicacoes();
          await this.carregarAgentesOperacao();
          await this.carregarCondicoesEspeciais();
          await this.carregarParcelasAnuncio();
          await this.carregarObservacoesAnuncio();
          await this.carregarSecoes();
          // }, 600);
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os dados do anúncio";
        this.snackErro = true;
      }
    }
    setTimeout(() => {
      this.carregandoCli = false;
      this.bloqueiaBotoes = false;
    }, 1000);
  }
};
</script>
