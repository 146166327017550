<!-- <template>
  <div>
    <v-treeview :items="treeData"></v-treeview>
    <pre>{{ treeData }}</pre>
  </div>
</template> -->

<!-- <template>
  <div>
    <v-treeview :items="treeData" v-model="tree" :open="initiallyOpen" activatable item-key="name" open-on-click>
      <template v-slot:label="{ item, open }">
        <v-btn @click="enviarId(item.id)">
          <v-icon>
            {{ open ? "mdi-folder-open" : "mdi-folder" }}
          </v-icon>
          <span
            >{{ item.id }} - 
            {{ item.name }}</span
          >
        </v-btn>
      </template>
    </v-treeview>
    <pre>{{ treeData }}</pre>
  </div>
</template>  -->

<template>
  <div id="app">
    <v-app id="inspire">
      <v-row>
        <v-col cols="12" md="3" class="fixed-column border-right">
          <div class="pa-2" style="height: 100vh; overflow-y: auto">
            <v-treeview :items="treeData" v-model="tree" :open="initiallyOpen" activatable item-key="name" open-on-click dense>
              <template v-slot:label="{ item, open }">
                <v-btn @click="enviarId(item.id)" text>
                  <v-icon size="18"> {{ open ? "mdi-folder-open" : "mdi-folder" }} </v-icon>
                  <span class="text-caption"> {{ item.name }} </span>
                </v-btn>
              </template>
            </v-treeview>
          </div>
        </v-col>

        <v-col cols="12" md="9">
          <div class="pa-2">
            <!-- Conteúdo da coluna da direita com rolagem -->
            <div style="height: 50vh; overflow-y: auto">
              <v-card>
                <v-toolbar color="#333333">
                  <v-icon color="white" class="mr-2">format_list_numbered</v-icon>
                  <v-toolbar-title style="color: white" class="font-weight-light"> Editorias </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <router-link v-if="direitoCriar" to="/editorial/editoria/null/null" style="text-decoration: none">
                    <v-btn v-if="direitoCriar" color="success" fab small>
                      <v-icon color="white" title="Incluir">add</v-icon>
                    </v-btn>
                  </router-link>
                </v-toolbar>
                <div class="px-4 mt-3">
                  <v-layout wrap>
                    <v-spacer></v-spacer>
                    <v-flex xs12 md5>
                      <v-text-field
                        background-color="white"
                        class="mt-2 mb-2 elevation-0"
                        v-model="filtroPesquisa"
                        append-icon="search"
                        label="Digite para pesquisar..."
                        placeholder="Pesquisa pelo nome"
                        hide-details
                        @keyup.enter="limpaFiltro"
                        @click:append="limpaFiltro"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>

                  <v-data-table
                    mobile-breakpoint="0"
                    :headers="headers"
                    :items="registros"
                    :options.sync="options"
                    :no-data-text="mensagemPesquisa"
                    :footer-props="rodape"
                    :disable-sort="carregando"
                    :disable-pagination="carregando"
                  >
                    <template v-slot:[getItemActions()]="{ item }">
                      <router-link :to="'/editorial/editoria/' + item.idSuperior + '/' + item.id" style="text-decoration: none">
                        <v-icon color="blue" title="Editar">edit</v-icon>
                      </router-link>
                      <v-icon v-if="direitoExcluir && !item.filhas" color="error" title="Remover" @click="excluir(item)">delete</v-icon>
                    </template>

                    <template v-slot:[getItemNumero()]="{ item }">
                      <span v-html="item.editoria"></span>
                    </template>

                    <template v-slot:[getItemActions2()]="{ item }">
                      <router-link v-if="direitoCriar" :to="'/editorial/editoria/' + item.id" style="text-decoration: none">
                        <v-icon color="success" title="Incluir">add_circle</v-icon>
                      </router-link>
                    </template>

                    <template v-slot:[getItemTipo()]="{ item }">
                      <span v-html="item.tipo == 1 ? 'Internet' : item.tipo == 2 ? 'Lixeira' : item.tipo == 3 ? 'Produção' : 'Edição'"></span>
                    </template>

                    <template v-slot:[getItemSituacao()]="{ item }">
                      <span v-html="item.situacao == 1 ? 'Ativo' : item.situacao == 0 ? '<strong>Inativo</strong>' : ''"></span>
                    </template>
                  </v-data-table>
                  <div class="text-xs-center pt-2 marg-bt">
                    <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 7" circle></v-pagination>
                  </div>
                </div>
                <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
                <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
              </v-card>
            </div>

            <div style="height: 50vh; overflow-y: auto">
              <pre>{{ treeData }}</pre>
              <!-- Mais conteúdo aqui -->
            </div>
          </div>
        </v-col>
      </v-row>
    </v-app>
  </div>
</template>

<script>
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      headers: [
        { text: "Ações", value: "actions" },
        { text: "Editoria", value: "nome" },
        { text: "Mãe", value: "mae" },
        { text: "", value: "actions2", divider: "true" },
        { text: "ID", value: "id" },
        { text: "Tipo", value: "tipo" },
        { text: "Situação", value: "situacao" }
      ],
      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },
      direitoCriar: false,
      direitoEditar: false,
      direitoExcluir: false,
      mensagemPesquisa: "",
      filtroPesquisa: "",
      registros: [],
      registrosPagina: 50,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      ordenacao: "",
      ordem: "",
      snackbar: false,
      snackErro: false,
      mensagem: "",
      options: {},

      rawData: [
        { id: 161, nome: "CADERNOS_PROD", tipo: 3, mae: null },
        { id: 162, nome: "CIDADES_PROD", tipo: 3, mae: null },
        { id: 163, nome: "ECONOMIA_PROD", tipo: 3, mae: null },
        { id: 164, nome: "ESPORTES_PROD", tipo: 3, mae: null },
        { id: 165, nome: "EXTERIOR_PROD", tipo: 3, mae: null },
        { id: 166, nome: "FOTOGRAFIA_PROD", tipo: 3, mae: null },
        { id: 167, nome: "IMAGENS_PROD", tipo: 3, mae: null },
        { id: 168, nome: "INFOGRAFICOS_PROD", tipo: 3, mae: null },
        { id: 169, nome: "INTERNET_PROD", tipo: 3, mae: null },
        { id: 170, nome: "MUNDO_PROD", tipo: 3, mae: null },
        { id: 171, nome: "PAIS_PROD", tipo: 3, mae: null },
        { id: 172, nome: "PLACAR_PROD", tipo: 3, mae: null },
        { id: 173, nome: "PODER_PROD", tipo: 3, mae: null },
        { id: 174, nome: "POLITICA_PROD", tipo: 3, mae: null },
        { id: 175, nome: "SABORES_PROD", tipo: 3, mae: null },
        { id: 176, nome: "SAUDE_PROD", tipo: 3, mae: null },
        { id: 177, nome: "TECNOLOGIA_PROD", tipo: 3, mae: null },
        { id: 178, nome: "TELEVISAO_PROD", tipo: 3, mae: null },
        { id: 179, nome: "TURISMO_PROD", tipo: 3, mae: null },
        { id: 180, nome: "VEICULOS_PROD", tipo: 3, mae: 173 },
        { id: 181, nome: "ARTIGOS_PROD", tipo: 3, mae: 180 },
        { id: 182, nome: "BLOGS_PROD", tipo: 3, mae: null },
        { id: 183, nome: "CELULAR_PROD", tipo: 3, mae: null },
        { id: 184, nome: "CINEMA_PROD", tipo: 3, mae: 167 },
        { id: 185, nome: "COLUNISTAS_PROD", tipo: 3, mae: null },
        { id: 186, nome: "COMPORTAMENTO_PROD", tipo: 3, mae: null },
        { id: 187, nome: "CONCURSOS_PROD", tipo: 3, mae: null },
        { id: 188, nome: "CONTRAPONTO_PROD", tipo: 3, mae: null },
        { id: 189, nome: "CULTURA_PROD", tipo: 3, mae: null },
        { id: 190, nome: "DESTAQUES_PROD", tipo: 3, mae: null },
        { id: 191, nome: "EDUCACAO_PROD", tipo: 3, mae: null },
        { id: 192, nome: "EMPREGO_PROD", tipo: 3, mae: null },
        { id: 193, nome: "ENQUETES_PROD", tipo: 3, mae: null },
        { id: 194, nome: "ESPECIAIS_PROD", tipo: 3, mae: null },
        { id: 195, nome: "GUIA_PROD", tipo: 3, mae: null },
        { id: 196, nome: "HUMOR_PROD", tipo: 3, mae: null },
        { id: 197, nome: "IMOVEIS_PROD", tipo: 3, mae: null },
        { id: 198, nome: "MODA_PROD", tipo: 3, mae: null },
        { id: 199, nome: "MULHER_PROD", tipo: 3, mae: null },
        { id: 200, nome: "MUSICA_PROD", tipo: 3, mae: null },
        { id: 201, nome: "NEGOCIOS_PROD", tipo: 3, mae: null },
        { id: 202, nome: "NOVELAS_PROD", tipo: 3, mae: null },
        { id: 203, nome: "OPINIÃO_PROD", tipo: 3, mae: null },
        { id: 204, nome: "PLANETA_PROD", tipo: 3, mae: null },
        { id: 205, nome: "RECEITAS_PROD", tipo: 3, mae: null },
        { id: 206, nome: "RELACIONAMENTO_PROD", tipo: 3, mae: null },
        { id: 207, nome: "SERVICOS_PROD", tipo: 3, mae: null },
        { id: 208, nome: "SORTE_PROD", tipo: 3, mae: null },
        { id: 209, nome: "TVEJA_PROD", tipo: 3, mae: null },
        { id: 210, nome: "VIDEOS_PROD", tipo: 3, mae: null }
      ],
      registros: []
    };
  },

  async mounted() {
    console.log("########");
    try {
      // Faz a pesquisa no banco de dados
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "edt/editoria");

      // Seta as variáveis com o retorno
      this.registros = response.data.data; // Atribui os dados ao array
      console.log(JSON.stringify(this.registros) + "@@@@@@@@@@@@@@@@@@@");
    } catch (error) {
      console.error("Erro ao buscar editorias:", error);
      // Tratar o erro (exibir mensagem ao usuário, etc.)
    }
  },

  computed: {
    treeData() {
      const dataMap = {};

      this.rawData.forEach((item) => {
        // Ajustando o formato para o v-treeview
        dataMap[item.id] = {
          id: item.id,
          name: item.nome,
          tipo: item.tipo, // Você pode querer exibir 'tipo' também
          children: []
        };
      });

      const tree = [];
      this.rawData.forEach((item) => {
        if (item.mae === null) {
          tree.push(dataMap[item.id]);
        } else {
          const parent = dataMap[item.mae];
          if (parent) {
            parent.children.push(dataMap[item.id]);
          }
        }
      });

      console.log("Estrutura da árvore:", tree);
      return tree;
    }
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    getItemActions() {
      return "item.actions";
    },
    getItemNumero() {
      return "item.numero";
    },
    getItemActions2() {
      return "item.actions2";
    },
    getItemTipo() {
      return "item.tipo";
    },
    getItemSituacao() {
      return "item.situacao";
    },
    limpaFiltro() {
      this.paginaAtual = 1;
      this.pesquisaDados();
    },
    async pesquisaDados() {
      this.registros = [];
      //sinaliza que está carregando
      this.carregando = true;
      this.mensagemPesquisa = "Carregando...";

      try {
        //faz a pesquisa no banco de dados
        const response = await api.post(
          process.env.VUE_APP_URL_WEB_API +
            "edt/editoria/all?pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            "&filtro=" +
            this.filtroPesquisa +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem
        );

        //seta as variaveis com o retorno
        this.registros = response.data.data;

        this.registros.forEach((element) => {
          element.editoria = "";
          if (element.numero.includes(".")) {
            element.editoria = "&emsp;".repeat(element.numero.split(".").length - 1);
          }
          element.editoria += element.numero + " - " + element.nome;
        });
        this.totalRegistros = response.data.total;
        this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
        this.rodape.pageText = this.totalRegistros + " itens";
        //fecha o loading
        this.carregando = false;
        //se não achar registro seta a mensagem senão deixa em branco
        this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else {
          this.mensagemPesquisa = "Erro ao buscar dados: " + e;
        }
      }
    },

    async enviarId(itemId) {
      // Lógica para enviar o ID para algum lugar
      console.log("ID enviado:", itemId);

      try {
        // Cria o corpo da solicitação com o ID fornecido

        // Faz a solicitação GET

        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API + "edt/editorial/buscaMaterias?idEditoria=" + itemId + "&excluida=0&campoOrdenar=1&ordem=1&versoes=0&buscaQnt=0&inicio=0&limite=3"
        );

        console.log(JSON.stringify(response.data));
        // Retorna os dados da resposta
        return response.data;
      } catch (error) {
        // Trata o erro (exibir mensagem ao usuário, etc.)
        console.error("Erro ao buscar editorias:", error);
      }

      // Aqui você pode adicionar a lógica para enviar o ID para uma API,
      // redirecionar o usuário ou realizar qualquer outra ação desejada.
    }
  },

  beforeMount() {
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "EDITORIAL_ESTILOS_ACESSO"]);
    //* Verifica se o usuário logado possui direito para incluir
    this.$store.state.usuario.direitos.forEach((element) => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "EDITORIAL_ESTILOS_CRIAR") {
        this.direitoCriar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "EDITORIAL_ESTILOS_EDITAR") {
        this.direitoEditar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "EDITORIAL_ESTILOS_EXCLUIR") {
        this.direitoExcluir = true;
      }
    });
  },

  watch: {
    $route() {
      this.paginaAtual = 1;
      this.pesquisaDados();
    },
    paginaAtual() {
      this.pesquisaDados();
    },
    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }
        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }
        this.pesquisaDados();
      }
    }
  }
};
</script>

<style scoped>
/* Estilo adicional para diminuir o espaçamento entre os itens */
.v-treeview-node__root {
  margin-bottom: 4px !important; /* Ajuste o valor conforme necessário */
}
</style>
