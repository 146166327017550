<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar height="50" color="#333333">
      <v-icon color="white" class="mr-2">lock</v-icon>
      <v-toolbar-title style="color: white" class="font-weight-light">Perfil de Acesso</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12>
            <h4 class="overline font-weight-black font-italic">Dados Básicos</h4>
            <v-divider></v-divider>
          </v-flex>

          <v-flex xs12>
            <v-text-field
              autofocus
              background-color="white"
              v-model="perfil.nome"
              label="Nome do Perfil"
              maxlength="50"
              hint="Obrigatório"
              :rules="[
                validacao.nome == false || validacao.nome,
                (value) => !!value || 'Obrigatório.',
                (value) => valida_nome(value) || 'Nome inválido',
                (value) => value.length > 2 || 'Preencha o nome do perfil'
              ]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="
                perfil.nome = pLetra(perfil.nome);
                validacao.nome = false;
              "
              autocomplete="disabled"
              :name="Math.random()"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md6>
            <v-autocomplete
              v-model="siteSelecionado"
              :disabled="isUpdating || direitosPerfil.length > 0"
              :items="sites"
              hint="Obrigatório"
              :filter="filtrarObjetos"
              :rules="[validacao.idSite == false || validacao.idSite, (value) => !!value || 'Obrigatório.']"
              label="Selecione o Site"
              item-text="nome"
              item-value="id"
              no-data-text
              return-object
              @change="validacao.idSite = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md6>
            <v-autocomplete
              v-model="perfil.modulo"
              :disabled="isUpdating || direitosPerfil.length > 0"
              :items="modulos"
              :filter="filtrarObjetos"
              hint="Obrigatório"
              :rules="[validacao.modulo == false || validacao.modulo, (value) => !!value || 'Obrigatório.']"
              label="Selecione o Módulo"
              item-text="nome"
              item-value="nome"
              no-data-text
              @change="
                carregarDireitosModulo();
                validacao.modulo = false;
              "
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md12>
            <v-switch v-model="perfil.situacao" :label="`${perfil.situacao == 1 ? 'Ativo' : 'Inativo'}`"></v-switch>
          </v-flex>
        </v-layout>

        <v-layout wrap>
          <v-expansion-panels v-model="painel" focusable>
            <v-expansion-panel dark class="elevation-0">
              <v-expansion-panel-header :class="validacao.direitos ? 'red--text' : ''">
                <strong>Direitos do Perfil</strong>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-flex xs12>
                  <v-card class="elevation-0">
                    <v-flex xs12>
                      <v-layout wrap>
                        <v-flex md4>
                          <v-autocomplete
                            class="mr-6"
                            v-model="atalho"
                            autofocus
                            :items="atalhos"
                            :filter="filtrarItens"
                            clearable
                            no-data-text
                            label="Incluir/Remover Direitos por Categoria"
                            @change="aplicarAtalho"
                          ></v-autocomplete>
                        </v-flex>
                        <v-flex md3 mr-4>
                          <v-autocomplete
                            v-model="direitoSelecionado"
                            :disabled="isUpdating"
                            :items="direitos"
                            :filter="filtrarObjetos"
                            label="Incluir Direito Específico"
                            item-text="nome"
                            item-value="id"
                            no-data-text
                            @change="incluirDireito"
                            @blur="direitoSelecionado = null"
                            return-object
                          ></v-autocomplete>
                        </v-flex>
                        <v-spacer></v-spacer>
                        <v-flex md4>
                          <v-text-field
                            background-color="white"
                            class="mt-2 mb-2 elevation-0"
                            v-model="searchDireitosPerfil"
                            append-icon="search"
                            label="Digite para pesquisar..."
                            placeholder="Pesquisa pelo nome"
                            hide-details
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-data-table
                        mobile-breakpoint="0"
                        :headers="headersDireitosPerfil"
                        :items="direitosPerfil"
                        :search="searchDireitosPerfil"
                        no-data-text
                        no-results-text
                        :footer-props="{
                          itemsPerPageText: 'Registros por página',
                          itemsPerPageOptions: [10, 25, 50],
                          pageText: ''
                        }"
                      >
                        <template v-slot:[getItemActions()]="{ item }">
                          <v-icon color="error" title="Remover" @click="removerDireito(item)">delete</v-icon>
                        </template>

                        <template v-slot:no-results>
                          <v-alert :value="true" color="error" icon="warning">"{{ searchDireitosPerfil }}" não encontrado.</v-alert>
                        </template>
                      </v-data-table>
                      <v-alert v-if="validacao.direitos" :value="true" color="error" icon="priority_high" outlined>{{ validacao.direitos }}</v-alert>
                    </v-flex>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0">
              <v-expansion-panel-header :class="validacao.usuarios ? 'red--text' : ''">
                <strong>Usuários do Perfil</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12>
                  <v-card class="elevation-0">
                    <v-flex xs12>
                      <v-layout wrap>
                        <v-flex md4>
                          <v-autocomplete
                            class="mr-6"
                            v-model="usuarioSelecionado"
                            autofocus
                            :disabled="isUpdating"
                            :items="usuarios"
                            :filter="filtrarObjetos"
                            label="Incluir Usuário"
                            item-text="nome"
                            item-value="id"
                            no-data-text
                            @change="incluirUsuario"
                            @blur="usuarioSelecionado = null"
                            return-object
                          ></v-autocomplete>
                        </v-flex>
                        <v-spacer></v-spacer>
                        <v-flex md4>
                          <v-text-field
                            background-color="white"
                            class="mt-2 mb-2 elevation-0"
                            v-model="searchUsuariosPerfil"
                            append-icon="search"
                            label="Digite para pesquisar..."
                            placeholder="Pesquisa pelo nome"
                            hide-details
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-data-table
                        mobile-breakpoint="0"
                        :headers="headersUsuariosPerfil"
                        :items="usuariosPerfil"
                        :search="searchUsuariosPerfil"
                        no-data-text
                        no-results-text
                        :footer-props="{
                          itemsPerPageText: 'Registros por página',
                          itemsPerPageOptions: [10, 25, 50],
                          pageText: ''
                        }"
                      >
                        <template v-slot:[getItemActions()]="{ item }">
                          <v-icon color="error" title="Remover" @click="removerUsuario(item)">delete</v-icon>
                        </template>

                        <template v-slot:[getItemUsuarioSituacao()]="{ item }">
                          <span v-html="item.usuario_situacao == 1 ? 'Ativo' : item.usuario_situacao == 0 ? '<strong>Inativo</strong>' : ''"></span>
                        </template>

                        <template v-slot:pageText="props">
                          {{ props.pageStart }}-{{ props.pageStop }} de
                          {{ props.itemsLength }}
                        </template>
                        <template v-slot:no-results>
                          <v-alert :value="true" color="error" icon="warning">"{{ searchUsuariosPerfil }}" não encontrado.</v-alert>
                        </template>
                      </v-data-table>
                      <v-alert v-if="validacao.usuarios" :value="true" color="error" icon="priority_high" outlined>{{ validacao.usuarios }}</v-alert>
                    </v-flex>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" v-if="perfil.modulo && ['ASSINATURAS', 'COMERCIAL', 'FINANCEIRO'].includes(perfil.modulo)">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.formasPgto ? 'red--text' : ''">
                <strong>Formas de Pagamento Bloqueadas do Perfil</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12>
                  <v-card class="elevation-0">
                    <v-flex xs12>
                      <v-layout wrap>
                        <v-flex md4>
                          <v-autocomplete
                            class="mr-6"
                            v-model="formaPgtoSelecionada"
                            autofocus
                            :disabled="isUpdating"
                            :items="formasPgto"
                            :filter="filtrarObjetos"
                            label="Incluir Forma de Pagamento"
                            item-text="nome"
                            item-value="id"
                            no-data-text
                            @change="incluirFormaPgto"
                            @blur="formaPgtoSelecionada = null"
                            return-object
                          ></v-autocomplete>
                        </v-flex>
                        <v-spacer></v-spacer>
                        <v-flex md4>
                          <v-text-field
                            background-color="white"
                            class="mt-2 mb-2 elevation-0"
                            v-model="searchFormasPgtoPerfil"
                            append-icon="search"
                            label="Digite para pesquisar..."
                            placeholder="Pesquisa pelo nome"
                            hide-details
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-data-table
                        mobile-breakpoint="0"
                        :headers="headersFormasPgtoPerfil"
                        :items="formasPgtoPerfil"
                        :search="searchFormasPgtoPerfil"
                        no-data-text
                        no-results-text
                        :footer-props="{
                          itemsPerPageText: 'Registros por página',
                          itemsPerPageOptions: [10, 25, 50],
                          pageText: ''
                        }"
                      >
                        <template v-slot:[getItemActions()]="{ item }">
                          <v-icon color="error" title="Remover" @click="removerFormaPgto(item)">delete</v-icon>
                        </template>

                        <template v-slot:[getItemFormaPgtoSituacao()]="{ item }">
                          <span v-html="item.formaPgto_situacao == 1 ? 'Ativa' : item.formaPgto_situacao == 0 ? '<strong>Inativa</strong>' : ''"></span>
                        </template>

                        <template v-slot:pageText="props">
                          {{ props.pageStart }}-{{ props.pageStop }} de
                          {{ props.itemsLength }}
                        </template>
                        <template v-slot:no-results>
                          <v-alert :value="true" color="error" icon="warning">"{{ searchFormasPgtoPerfil }}" não encontrado.</v-alert>
                        </template>
                      </v-data-table>
                      <v-subheader>
                        <span style="color: #000000">* Inclua aqui somente as formas de pagamento que serão bloqueadas para os usuários do perfil na captação.</span>
                      </v-subheader>
                      <v-alert v-if="validacao.formasPgto" :value="true" color="error" icon="priority_high" outlined>{{ validacao.formasPgto }}</v-alert>
                    </v-flex>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">{{ validacao.mensagemErro }}</v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn to="/administrativo/perfis" id="btn-rsn" color="orange lighten-2 white" style="color: white"> <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar </v-btn>
      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="blue white" style="color: white" id="btn-rsn" depressed @click="salvar('false')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar
      </v-btn>
      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="orange white" style="color: white" id="btn-rsn" outlined @click="salvar('retornar')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn :loading="isUpdating" :disabled="bloqueiaBotoes" color="blue white" style="color: white" id="btn-rsn" outlined @click="salvar('true')" v-if="direitoCriar">
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Novo
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      perfil: {
        id: "",
        idSite: null,
        modulo: "",
        nome: "",
        situacao: 1
      },
      direitosPerfil: [],
      usuariosPerfil: [],
      formasPgtoPerfil: [],
      validacao: {
        id: false,
        idSite: false,
        modulo: false,
        nome: false,
        situacao: false,
        direitos: false,
        usuarios: false,
        formasPgto: false,
        mensagemErro: false
      },
      modulos: [],
      sites: [],
      direitos: [],
      usuarios: [],
      formasPgto: [],
      headersDireitosPerfil: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Grupo", value: "grupo" },
        { text: "Direito", value: "direito" }
      ],
      headersUsuariosPerfil: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Nome", value: "usuario_nome" },
        { text: "Situação", value: "usuario_situacao" }
      ],
      headersFormasPgtoPerfil: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Nome", value: "formaPgto_nome" },
        { text: "Situação", value: "formaPgto_situacao" }
      ],

      atalho: "",
      atalhos: [
        "INCLUIR TODOS*",
        "INCLUIR [ACESSO]",
        "INCLUIR [EDITAR]",
        "INCLUIR [CRIAR]",
        "REMOVER TODOS",
        "REMOVER [ACESSO]",
        "REMOVER [EDITAR]",
        "REMOVER [CRIAR]",
        "INCLUIR ESPECIAIS"
      ],

      idSitePrincipal: null,
      siteSelecionado: {},
      painel: [0, 0],
      elementoValid: true,
      direitoCriar: false,
      direitoSelecionado: null,
      usuarioSelecionado: null,
      formaPgtoSelecionada: null,
      searchDireitosPerfil: "",
      searchUsuariosPerfil: "",
      searchFormasPgtoPerfil: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false,
      bloqueiaBotoes: false
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    getItemActions() {
      return "item.actions";
    },
    getItemUsuarioSituacao() {
      return "item.usuario_situacao";
    },
    getItemFormaPgtoSituacao() {
      return "item.formaPgto_situacao";
    },

    incluirDireito(item) {
      this.validacao.direitos = false;
      //valida se ainda não existe para incluir
      if (!this.direitosPerfil.some((direito) => direito.grupo === item.grupo && direito.direito === item.direito)) {
        this.direitosPerfil.push({
          id: null,
          idDireito: item.id,
          grupo: item.grupo,
          direito: item.direito
        });
      }
      setTimeout(() => {
        this.direitoSelecionado = null;
      }, 300);
    },
    removerDireito(item) {
      const index = this.direitosPerfil.indexOf(item);
      this.direitosPerfil.splice(index, 1);
    },

    incluirUsuario(item) {
      //valida se ainda não existe para incluir
      this.validacao.usuarios = false;
      if (!this.usuariosPerfil.some((usuario) => usuario.usuario_nome === item.nome)) {
        this.usuariosPerfil.push({
          id: null,
          idUsuario: item.id,
          usuario_nome: item.nome,
          usuario_situacao: item.situacao
        });
      }
      setTimeout(() => {
        this.usuarioSelecionado = null;
      }, 300);
    },
    removerUsuario(item) {
      const index = this.usuariosPerfil.indexOf(item);
      this.usuariosPerfil.splice(index, 1);
    },

    incluirFormaPgto(item) {
      this.validacao.formasPgto = false;
      //valida se ainda não existe para incluir
      if (!this.formasPgtoPerfil.some((formaPgto) => formaPgto.formaPgto_nome === item.nome)) {
        this.formasPgtoPerfil.push({
          id: null,
          idFormaPgto: item.id,
          formaPgto_nome: item.nome,
          formaPgto_situacao: item.situacao
        });
      }
    },
    removerFormaPgto(item) {
      const index = this.formasPgtoPerfil.indexOf(item);
      this.formasPgtoPerfil.splice(index, 1);
    },

    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      var erro = false;
      if (this.direitosPerfil.length == 0) {
        erro = true;
        this.validacao.direitos = "Você deve selecionar pelo menos 1 direito para o perfil";
      }

      var usuariosInvalidos = "";
      this.usuariosPerfil.forEach((element) => {
        var usuarioValido = false;
        this.usuarios.forEach((usuario) => {
          if (element.idUsuario == usuario.id) {
            usuarioValido = true;
          }
        });
        if (!usuarioValido) {
          usuariosInvalidos += (usuariosInvalidos == "" ? "" : ", ") + element.usuario_nome;
        }
      });
      if (usuariosInvalidos != "") {
        erro = true;
        this.validacao.usuarios = "Remova os seguintes usuários, pois estão inativos (ou são de outro site): " + usuariosInvalidos;
      }

      var formasPgtoInvalidas = "";
      this.formasPgtoPerfil.forEach((element) => {
        var formasPgtoValida = false;
        this.formasPgto.forEach((formaPgto) => {
          if (element.idFormaPgto == formaPgto.id) {
            formasPgtoValida = true;
          }
        });
        if (!formasPgtoValida) {
          formasPgtoInvalidas += (formasPgtoInvalidas == "" ? "" : ", ") + element.formaPgto_nome;
        }
      });
      if (formasPgtoInvalidas != "") {
        erro = true;
        this.validacao.formasPgto = "Remova as seguintes formas de pagamento, pois estão inativas (ou são de outro site): " + formasPgtoInvalidas;
      }

      if (!this.$refs.formElemento.validate() || erro) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      var perfilDireitos = [];
      this.direitosPerfil.forEach((element) => {
        perfilDireitos.push({
          id: element.id,
          idDireito: element.idDireito,
          idPerfil: this.id
        });
      });
      this.perfil.direitosPerfil = perfilDireitos;

      var perfilUsuarios = [];
      this.usuariosPerfil.forEach((element) => {
        perfilUsuarios.push({
          id: element.id,
          idUsuario: element.idUsuario,
          idPerfil: this.id
        });
      });
      this.perfil.usuariosPerfil = perfilUsuarios;

      var perfilFormasPgto = [];
      this.formasPgtoPerfil.forEach((element) => {
        perfilFormasPgto.push({
          id: element.id,
          idFormaPgto: element.idFormaPgto,
          idPerfil: this.id
        });
      });
      this.perfil.formasPgtoPerfil = perfilFormasPgto;

      this.isUpdating = true;
      try {
        var retorno = "";
        //se tiver id é um update
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "adm/perfil/" + this.id, this.perfil);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "adm/perfil?idSite=" + this.idSitePrincipal, this.perfil);
          this.id = retorno.data.id;
          this.perfil.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push("/administrativo/perfil");
          this.$router.go("/administrativo/perfil");
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/administrativo/perfis");
          }, 2000);
        } else {
          //* Necessita recarregar para atualizar permissões
          this.$router.push("/administrativo/perfil/" + this.id);
          this.$router.go("/administrativo/perfil/" + this.id);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },

    async carregarDireitosPerfil() {
      try {
        //* Carrega os direitos do perfil
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/perfil-direito/perfil/" + this.perfil.id);
        this.direitosPerfil = response.data;
        this.direitosPerfil.forEach((element) => {
          element.nome = element.grupo + "/" + element.direito;
        });
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de direitos do perfil";
        this.snackErro = true;
      }
    },

    async carregarUsuariosPerfil() {
      try {
        //* Carrega os usuários do perfil
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/perfil-usuario?idPerfil=" + this.perfil.id);
        this.usuariosPerfil = response.data.data;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de usuários do perfil";
        this.snackErro = true;
      }
    },
    async carregarFormasPgtoPerfil() {
      try {
        //* Carrega as formas de pagamento do perfil
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/perfil-forma-pagamento?idPerfil=" + this.perfil.id);
        this.formasPgtoPerfil = response.data.data;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de formas de pagamento do perfil";
        this.snackErro = true;
      }
    },

    async carregarDireitosModulo() {
      try {
        //* Carrega os direitos do módulo selecionado
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/direito/modulo/" + this.perfil.modulo);
        this.direitos = [];
        var temp = response.data;
        temp.forEach((element) => {
          if (
            (element.grupo.includes("CELESC") && this.siteSelecionado.nome != "Adjori SC") ||
            ((element.grupo == "PAÍSES" || element.grupo == "UFS" || element.grupo == "MUNICÍPIOS") && this.siteSelecionado.nome != "O Mirante")
          ) {
            //* Só permite incluir direitos admin celesc para o site Adjori
            //* Só permite incluir direitos ufs e municípios para o site Mirante
          } else {
            element.nome = element.grupo + "/" + element.direito;
            this.direitos.push(element);
          }
        });
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de direitos do módulo";
        this.snackErro = true;
      }
    },

    async carregarSitesAtivos() {
      try {
        //* Carrega os sites ativos
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/site/lista");
        this.sites = response.data;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de sites";
        this.snackErro = true;
      }
    },

    async carregarUsuariosSite() {
      try {
        //* Carrega os usuários do site
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/site-usuario/usuariosSite/" + this.perfil.idSite);
        this.usuarios = [];
        response.data.forEach((element) => {
          if (element.situacao == 1) {
            //* disponibiliza somente os usuários ativos
            this.usuarios.push(element);
          }
        });
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de usuários";
        this.snackErro = true;
      }
    },

    async carregarFormasPgtoSite() {
      try {
        //* Carrega as formas de pagamento ativas do site
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/forma-pagamento/lista/" + this.perfil.idSite);
        this.formasPgto = response.data;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de formas de pagamento";
        this.snackErro = true;
      }
    },

    aplicarAtalho() {
      if (!this.atalho.includes("REMOVER")) {
        //* Inclui todos os direitos da categoria selecionada
        this.direitos.forEach((element) => {
          var incluir = false;
          if (this.atalho.includes("ACESSO") && element.direito == "ACESSO") {
            incluir = true;
          } else if (this.atalho.includes("EDITAR") && element.direito == "EDITAR") {
            incluir = true;
          } else if (this.atalho.includes("CRIAR") && element.direito == "CRIAR") {
            incluir = true;
          } else if (this.atalho == "INCLUIR TODOS*" || this.atalho == "INCLUIR ESPECIAIS") {
            incluir = true;
          }
          if (
            [
              "TAXAR",
              "FORÇAR_EXCLUSÃO",
              "FECHAMENTO_NFSC",
              "FECHAMENTO_SPED",
              "REVERTER_BANCO",
              "VER_SENHA",
              "UPLOAD",
              "DESTRAVAR",
              "CAIXA",
              "TROCO",
              "RENOVAÇÃO_AUTOMÁTICA",
              "REINÍCIO",
              "CORRIGIR_EDIÇÃO",
              "EDITAR_AVANÇADO"
            ].includes(element.direito) ||
            ["CONFIG_EMAIL", "PERFIS"].includes(element.grupo) ||
            (["FORMAS_PAGAMENTO"].includes(element.grupo) && ["CRIAR", "EDITAR"].includes(element.direito)) ||
            (["ETAPAS_COBRANÇA"].includes(element.grupo) && ["CRIAR", "EDITAR"].includes(element.direito)) ||
            (["EXTRATOS"].includes(element.grupo) && ["LANÇAMENTOS", "EXCLUIR", "EXCLUIR_CONCILIAÇÃO"].includes(element.direito)) ||
            (["CONTAS_PAGAR", "CONTAS_RECEBER", "NFE", "NFSC", "NFSE", "TRANSFERÊNCIAS"].includes(element.grupo) && ["CANCELAR"].includes(element.direito))
          ) {
            if (this.atalho != "INCLUIR ESPECIAIS") {
              incluir = false;
            }
          }
          if (incluir) {
            this.incluirDireito(element);
          }
        });
      } else {
        //* Remove todos os direitos da categoria selecionada
        var direitosPerfilTemp = [];
        this.direitosPerfil.forEach((element) => {
          var remover = false;
          if (this.atalho.includes("ACESSO") && element.direito == "ACESSO") {
            remover = true;
          } else if (this.atalho.includes("EDITAR") && element.direito == "EDITAR") {
            remover = true;
          } else if (this.atalho.includes("CRIAR") && element.direito == "CRIAR") {
            remover = true;
          } else if (this.atalho == "REMOVER TODOS") {
            remover = true;
          }
          if (!remover) {
            direitosPerfilTemp.push(element);
          }
        });
        this.direitosPerfil = [];
        direitosPerfilTemp.forEach((element) => {
          this.direitosPerfil.push(element);
        });
      }
    },

    filtrarItens(item, queryText, itemText) {
      return util.filtrarItens(item, queryText, itemText);
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    pLetra(text) {
      return util.pLetra(text);
    },
    valida_nome(text) {
      return util.valida_nome(text);
    }
  },

  watch: {
    siteSelecionado(val) {
      this.modulos = [];

      this.perfil.idSite = val.id;
      this.carregarUsuariosSite();
      this.carregarFormasPgtoSite();

      this.modulos.push({ nome: "CMS" });
      this.modulos.push({ nome: "ADMINISTRATIVO" });
      if (val.assinaturas) {
        this.modulos.push({ nome: "ASSINATURAS" });
      }
      if (val.comercial) {
        this.modulos.push({ nome: "COMERCIAL" });
      }
      this.modulos.push({ nome: "FINANCEIRO" });
      this.modulos.push({ nome: "FLIP" });
      if (val.editorial) {
        this.modulos.push({ nome: "DIAGRAMA" });
        this.modulos.push({ nome: "EDITORIAL" });
      }

      if (this.perfil.modulo) {
        this.carregarDireitosModulo(this.perfil.modulo);
      }
    }
  },

  async created() {
    this.isUpdating = false;
    this.bloqueiaBotoes = true;
    this.id = this.$route.params.id ? this.$route.params.id : "";

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach((element) => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ADMINISTRATIVO_PERFIS_CRIAR") {
        this.direitoCriar = true;
      }
    });

    //* Usuário só pode acessar perfil do seu site
    var direito = this.$store.state.usuario.direitos[0];

    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

    var idSite = this.$store.state.usuario.idSitePrincipal;

    if (direito.modulo + "_" + direito.grupo + "_" + direito.direito == "WEBSUITA_ADMIN_SUITA") {
      idSite = 0;
      await this.carregarSitesAtivos();
    } else {
      this.sites = [
        {
          id: idSite,
          nome: this.$store.state.usuario.site_nome,
          assinaturas: this.$store.state.usuario.site_assinaturas,
          comercial: this.$store.state.usuario.site_comercial,
          editorial: this.$store.state.usuario.site_editorial
        }
      ];
    }

    //* Se é edição, carrega o perfil, seus direitos e usuários
    if (!this.id) {
      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ADMINISTRATIVO_PERFIS_CRIAR"]);
    } else {
      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ADMINISTRATIVO_PERFIS_EDITAR"]);
      try {
        //* Carrega o perfil
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/perfil/" + this.id + "?idSite=" + this.idSitePrincipal);
        if (!response.data) {
          this.$router.push("/administrativo/perfis");
          return;
        }
        this.perfil = response.data;
        this.direitosPerfil = [];
        this.usuariosPerfil = [];
        this.formasPgtoPerfil = [];

        for (let index = 0; index < this.sites.length; index++) {
          const element = this.sites[index];
          if (element.id == this.perfil.idSite) {
            this.siteSelecionado = element;
          }
        }

        //* Carrega os usuários disponíveis para o site
        await this.carregarUsuariosSite();
        await this.carregarFormasPgtoSite();
        //* Carrega os direitos do módulo, direitos e usuários do perfil
        await this.carregarDireitosModulo(this.perfil.modulo);
        await this.carregarDireitosPerfil();
        await this.carregarUsuariosPerfil();
        await this.carregarFormasPgtoPerfil();
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os dados do perfil";
        this.snackErro = true;
      }
    }
    setTimeout(() => {
      this.bloqueiaBotoes = false;
    }, 1000);
  }
};
</script>
